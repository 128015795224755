import { createMachine } from 'xstate';

const moveElementMachine = createMachine({
  id: 'moveElementMachine',
  initial: 'idle',
  predictableActionArguments: true,
  states: {
    idle: {
      on: {
        pressElements: {
          target: 'holdedElements'
        }
      }
    },
    holdedElements: {
      on: {
        mouseUp: { target: 'idle' },
        escape: { target: 'idle' },
        loseFocus: { target: 'idle'},
        moveMouseDrag: {
          target: 'movingElement',
          actions: ['effect']
        }
      }
    },
    movingElement: {
      on: {
        mouseUpDrop: {
          target: 'idle'
        },
        moveMouseDrag: {
          target: 'movingElement',
          actions: ['effect']
        }
      }
    }
  }
});

export default moveElementMachine;
