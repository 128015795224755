<template>
  <li
    class="IobMenu-item"
    :class="classList.item"
  >
    <button 
      :tabindex="interactive ? 0 : -1"
      v-bind="$attrs"
      :disabled="props.disabled"
      class="IobMenu-item-header"
      :class="classList.header" 
      @click="handleClick"
    >
      <div
        v-if="(selected && !expanded) && interactive"
        class="IobMenu-item-header-indicator"
        :class="classList.indicator"
      />
      <icon-loader
        v-if="iconName"
        class="IobMenu-item-header-leftIcon"
        :class="classList.icon"
        :name="iconName"
        size="small"
      />
      <span 
        class="IobMenu-item-header-label" 
        :class="classList.label"
      >
        {{ text }}
      </span>
      <iob-badge 
        v-if="status"
        :color="status.color"
        size="xs"
        :type="status.type"
        :icon="status.icon"
        :label="status.label"
        class="IobMenu-item-header-badge"
      />
      <icon-loader
        v-if="expandable"
        class="IobMenu-item-header-expandIcon"
        :class="classList.expandIcon"
        name="ChevronDown"
        size="default"
      />
    </button>
    <div
      v-if="$slots.default"
      class="IobMenu-item-list"
      :style="{
        display: submenuDisplay
      }"
    >
      <slot />
    </div>
  </li>
</template>

<script setup>
import IconLoader from '../../../IconLoader/IconLoader.vue';
import IobBadge from '../../../Atoms/IobBadge/IobBadge.vue'
import { computed, defineProps, ref, watch } from 'vue';

const props = defineProps({
  text: {
    type: String,
    default: ''
  },
  iconName: {
    type: String,
    default: ''
  },
  selected: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  expandable: {
    type: Boolean,
    default: false
  },
  open: {
    type: Boolean,
    default: false
  },
  status: {
    type: Object,
    default: null
  },
  interactive: {
    type: Boolean,
    default: true
  }
})

const classList = computed(() => {
  const hasInteraction = (expanded.value && props.expandable) || props.selected
  return {
    item: {
      'IobMenu-item--default': !expanded.value && !props.selected && !props.disabled,
      'IobMenu-item--selected': hasInteraction && props.interactive,
      'IobMenu-item--disabled': props.disabled,
      'expandable': props.expandable,
      'IobMenu-item--interactive': props.interactive
    },
    header: {
      'focus focus-inside': props.interactive
    },
    label: {
      'expandable': props.expandable
    },
    indicator: {
      'menu-expanded': props.expandable
    },
    icon: {
      'expandable': props.expandable
    },
    expandIcon: {
      'menu-expanded': expanded.value
    },
  }
})

const handleClick = () => {
  if (props.expandable && props.interactive) {
    expanded.value = !expanded.value
  }
}

const expanded = ref(props.expandable && props.open);
const submenuDisplay = computed(() => expanded.value || !props.expandable ? 'flex' : 'none');

watch(() => expanded.value = props.open && props.expandable, {
  immediate: true
})
</script>

<style lang="scss" src="./IobMenuItem.scss" scoped />
