<template>
  <div class="IobActionFooter">
    <div class="IobActionFooter-left">
      <iob-button
        v-if="showLeft"
        type="ghost"
        color="secondarySoft"
        size="medium"
        :label="leftActionBtnLabel"
        :show-left-icon="true"
        left-icon="ArrowLeft"
        @click="$emit('left-click')"
      />
    </div>
    <div class="IobActionFooter-right">
      <iob-button
        v-if="showSecondaryBtn"
        color="secondarySoft"
        type="filled"
        size="medium"
        :label="rightActionBtnLabel"
        @click="$emit('secondary-click')"
      />
      <iob-button
        v-if="isPrimaryBtnVisible"
        :color="actionBtnColor"
        size="medium"
        :label="actionBtnLabel"
        :disabled="isPrimaryBtnDisabled"
        :has-spinner="hasSpinner "
        @click="$emit('primary-click')"
      />
    </div>
  </div>
</template>

<script>
import IobButton from '../IobButton/IobButton.vue';

export default {
  components: {IobButton},
  props: {
    showLeft: {
      type: Boolean,
      default: false
    },
    showSecondaryBtn: {
      type: Boolean,
      default: true
    },
    isPrimaryBtnVisible: {
      type: Boolean,
      default: true
    },
    rightActionBtnLabel: {
      type: String,
      default: 'Cancel'
    },
    leftActionBtnLabel: {
      type: String,
      default: 'Previous'
    },
    actionBtnLabel: {
      type: String,
      default: 'Next'
    },
    actionBtnColor: {
      type: String,
      default: 'primary'
    },
    isPrimaryBtnDisabled: {
      type: Boolean,
      default: false
    },
    hasSpinner : {
      type: Boolean,
      default: false
    },
  },
  emits: ['left-click', 'primary-click', 'secondary-click']
}
</script>

<style lang="scss" scoped src="./IobActionFooter.scss"></style>
<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-pop-up.scss" />
