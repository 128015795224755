<template>
  <machine-interpreter
    :machine="selectedInteractionMode"
    :active-machine="selectedInteractionMode"
  >
    <template #default="{send, state}">
      <board-api-wrapper
        :send="send"
        :state="state"
      />
    </template>
  </machine-interpreter>
</template>

<script>

import MachineInterpreter from 'SRC/hoc/MachineInterpreter.vue';
import BoardApiWrapper from 'SRC/board/BoardApiWrapper.vue';
import { mapState } from 'vuex';

export default {
  name: 'BoardMachineWrapper',
  components: { BoardApiWrapper, MachineInterpreter },
  computed: {
    ...mapState('board', [
      'selectedInteractionMode'
    ])
  }
};
</script>
