<template>
  <div
    class="MouseFollower"
    :style="containerStyle"
  />
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'MouseFollower',
  props: {
    relationSource: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      mousePosition: { ...this.relationSource.position },
      lastClick: {
        x: null,
        y: null
      },
      evListener: null
    };
  },
  computed: {
    ...mapState('board', ['zoomLevel', 'panX', 'panY']),
    containerStyle() {
      return {
        left: `${this.mousePosition.x}px`,
        top: `${this.mousePosition.y}px`
      };
    }
  },
  mounted() {
    this.$emit('mouse-follower-created');
    this.evListener = document.addEventListener('mousemove', this.move);
  },
  unmounted() {
    this.$emit('mouse-follower-destroyed');
    document.removeEventListener('mouseMove', this.evListener);
  },
  methods: {
    move(event) {
      if (!this.lastClick.x) {
        this.lastClick.x = event.clientX;
        this.lastClick.y = event.clientY;
        return;
      }
      const offsetX = Math.round((event.clientX - this.lastClick.x) / this.zoomLevel);
      const offsetY = Math.round((event.clientY - this.lastClick.y) / this.zoomLevel);
      this.mousePosition.x += offsetX;
      this.mousePosition.y += offsetY;
      this.lastClick.x = event.clientX;
      this.lastClick.y = event.clientY;
    }
  }
};
</script>

  <style>
.MouseFollower {
  position: absolute;
}
</style>

