<template>
  <div class="ToolBar" />
</template>

<script>
export default {
  name: 'ToolBar'
};
</script>

<style scoped src='./Toolbar.css' />
