import actions from './actions';
import mutations from './mutations';

const RelationsModule = {
  namespaced: true,
  state: {
    dependencies: {},
    visualLinks: {},
    hierarchicalRelations: {},
    lines: [],
    createdDependencies: [],
    relationType: null,
    isVisualLinkContextualMenuOpen: false,
    visualLinkBeingEdited: {},
    selectedVisualLinkAction: null,
    selectedVisualLinkType: null,
    isSelectedVisualLink: false,
    selectedVisualLink: null,
    searchResults: []
  },
  mutations,
  actions
};

export default RelationsModule;
