<template>
  <img
    class="Icon"
    :src="iconUrl"
  >
</template>

<script>
export default {
  name: 'IobIcon',
  props: {
    iconName: {
      type: String,
      required: true
    }
  },
  computed: {
    iconUrl() {
      return 'https://github.com/feathericons/feather/raw/master/icons/' + this.iconName + '.svg';
    }
  }
};
</script>

<style scoped src="./Icon.css"></style>
