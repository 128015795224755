<template>
  <machine-interpreter
    machine="moveElement"
    :active-machine="selectedInteractionMode"
  >
    <template #default="{ send }">
      <board-element
        :send="send"
        data-target="board-element"
        :dataset-element-id="element.datasetElement.id"
        :height="element.height"
        :width="element.width"
        :selected-interaction-mode="selectedInteractionMode"
        :attributes="attributes"
        v-bind="{ ...$attrs }"
      />
    </template>
  </machine-interpreter>
</template>

<script>

import MachineInterpreter from 'SRC/hoc/MachineInterpreter.vue';
import BoardElement from 'SRC/board/components/BoardElement/BoardElement.vue';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'BoardElementMachineWrapper',
  components: { MachineInterpreter, BoardElement },
  props: {
    element: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      attributes: []
    };
  },
  computed: {
    ...mapState('board', [
      'selectedInteractionMode',
      'datasetElements'
    ]),
    ...mapGetters('board', [
      'getFullAttributes'
    ]),
    currentDatasetElement() {
      return this.datasetElements[this.element.datasetElement.id];
    }
  },
  watch: {
    currentDatasetElement: {
      deep: true,
      immediate: true,
      handler(datasetElement) {
        if (!datasetElement) {
          return;
        }
        this.attributes = this.getFullAttributes(datasetElement.id);
      }
    }
  }
};
</script>
