import { SimpleDatasetElementContext, evaluate } from '@iobeya/vms-formula-evaluator/evaluator';
import { buildVmsTree } from '@iobeya/vms-formula-evaluator/vmsExprTree';

class DatasetElementContext extends SimpleDatasetElementContext {
  generateContextsArray(relation) {
    const dataType = Object.values(this._datasetElement.allDataTypes)
      .find((type) => type.id === relation.typeId);
    return new DatasetElementContext({
      ...relation,
      attributes: {...relation.attributes, typeName: dataType.name},
      dataType
    }, this.super);
  }

  async ownChildren() {
    if (!this._datasetElement.children) {
      return [];
    }
    const childrenCTX = this._datasetElement.children.map(this.generateContextsArray.bind(this));
    return childrenCTX;
  }
  async ownDependencies() {
    if (!this._datasetElement.dependencies) {
      return [];
    }
    const dependenciesCtxs = this._datasetElement.dependencies.map(this.generateContextsArray.bind(this));
    return dependenciesCtxs;
  }
  async ownAttribute(name) {
    if (!this._datasetElement.dataType) {
      return this.simpleOwnAttribute(name);
    }
    const computedByFormula = this._datasetElement
      .dataType.attributes.find((attr) => attr.name === name && attr.computedByFormula);
    if (computedByFormula) {
      const formula = this._datasetElement.formulas[`${name}--${this._datasetElement.dataType.name}`];
      if (formula) {
        const formulaExpression = formula.expression;
        const value = await evaluate(buildVmsTree(formulaExpression), this);
        return value;
      }
    }
    const dataType = this._datasetElement.dataType;
    const attributeProperties = dataType.attributes.find((attr) => attr.name === name);
    const isTimeStampedIndicator = attributeProperties.type === 'timestamped_indicator';
    if (isTimeStampedIndicator) {
      return this.simpleOwnAttribute(name, 'value');
    }
    return this.simpleOwnAttribute(name);
  }
}
export default DatasetElementContext;
