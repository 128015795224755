<template>
  <ul>
    <li>
      <div>
        <div 
          class="label-field"
          :style="{ paddingLeft: paddingComputed }"
          @mouseenter="isHovered = item.children && item.children.length ? true : false"
          @mouseleave="isHovered = false"
          @click="handleClick"
        >
          <iob-icon-shape
            v-if="isHovered && item.children && item.children.length && showLeftIcon"
            :icon-name="isNodeExpanded(item.id) ? 'ChevronDown' : 'ChevronRight'"
            size="xsmall"
            type="square"
            :is-clickable="true"
            :selected="false"
            :disabled="false"
            color="secondary"
            @click.stop="toggleNode(item)"
          />
          <div
            v-show="!isHovered && showLabelField"
            class="initials"
            :style="{ color: colors.textColor, backgroundColor: colors.bgColor }"
          >
            {{ getInitials(item.attributes[labelField]) }}
          </div>
          <span class="label-text">
            {{ item.attributes[labelField] }}
          </span>
          <icon-loader
            v-if="!isMultipleSelect && selectedItem && selectedItem.id === item.id"
            name="Check"
            type="default"
            size="default"
          />
          <iob-checkbox
            v-if="isMultipleSelect"
            :id="item.id"
            :checked="isCheckedItem"
            @change-checkbox="(value) => handleMultipleSelect(value, item)"
          />
        </div>
        <ul
          v-if="isNodeExpanded(item.id)"
        >
          <tree-item
            v-for="child in item.children"
            :key="child.id"
            :padding-left-value="paddingLeftValue + 1"
            :data-id="child.id"
            :item="child"
            :colors="child.attributes.colors"
            :label-field="labelField"
            :show-label-field="showLabelField"
            :is-multiple-select="isMultipleSelect"
            :expanded-nodes="expandedNodes"
            :show-left-icon="showLeftIcon"
            :selected-item="selectedItem"
            :multiple-selected-items="multipleSelectedItems"
            @select-item="$emit('select-item', $event)"
            @select-multiple-items="$emit('select-multiple-items', $event)"
            @toggle-node="$emit('toggle-node', $event)"
          />
        </ul>
      </div>
    </li>
  </ul>
</template>

<script>
import { getInitials } from '../../../../utils';
import IobCheckbox from '../../../Atoms/IobCheckbox/IobCheckbox.vue';
import IobIconShape from '../../../Atoms/IobIconShape/IobIconShape.vue';
import IconLoader from '../../../IconLoader/IconLoader.vue';

export default {
  name: 'TreeItem',
  components: { IobIconShape, IobCheckbox, IconLoader },
  props: {
    item: {
      type: Object,
      required: true
    },
    expandedNodes: {
      type: Array,
      required: true
    },
    colors: {
      type: Object,
      default: () => ({})
    },
    labelField: {
      type: String,
      default: ''
    },
    isMultipleSelect: {
      type: Boolean,
      default: false
    },
    showLeftIcon: {
      type: Boolean,
      default: true
    },
    showLabelField: {
      type: Boolean,
      default: true
    },
    selectedItem: {
      type: Object,
      default: () => ({})
    },
    multipleSelectedItems: {
      type: Array,
      default: () => []
    },
    paddingLeftValue: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      isHovered: false
    };
  },
  computed: {
    isCheckedItem() {
      return this.multipleSelectedItems.some((selectItem) => selectItem.id === this.item.id);
    },
    paddingComputed() {
      return `${this.paddingLeftValue * 12}px`;
    },
  },
  methods: {
    toggleNode(item) {
      this.$emit('toggle-node', item);
    },
    isNodeExpanded(nodeId) {
      return this.expandedNodes.includes(nodeId);
    },
    handleMonoSelect(item) {
      this.$emit('select-item', item);
    },
    getInitials(item) {
      return getInitials(item);
    },
    handleMultipleSelect(value, item) {
      const itemUpdated = {...item, checked :value.isChecked};
      this.$emit('select-multiple-items', itemUpdated);
    },
    handleClick() {
      if (this.isMultipleSelect) {
        this.handleMultipleSelect({ isChecked: !this.isCheckedItem }, this.item);
      } else {
        this.handleMonoSelect(this.item);
      }
    },
  }
};
</script>

<style lang="scss" scoped src="./TreeItem.scss" />
