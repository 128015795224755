const mutations = {
  setTimeSeriesPoint(state, {id, points}) {
    state.timeSeriesPoints[id] = points;
  },
  addTimeSeriesPoint(state, point) {
    state.timeSeriesPoints[point.id].push(point);
  },
  updateTimeSeriesPoint(state, updatedPoint) {
    let timeSeries = state.timeSeriesPoints[updatedPoint.id];
    timeSeries = timeSeries.map((point) =>
      point.timestamp === updatedPoint.timestamp ? updatedPoint : point
    );
    state.timeSeriesPoints[updatedPoint.id] = timeSeries;
  },
  setValueType(state, { id, valueType }) {
    state.valueType = {
      ...state.valueType,
      [id]: valueType
    };
  },
  setTimeSeriesActualValue(state, actualValue) {
    state.timeSeriesActualValues[actualValue.id] = actualValue;
  },
  resetTimeSeriesById(state, id) {
    delete state.timeSeriesPoints[id];
    delete state.timeSeriesActualValues[id];
  }
};

export default mutations;

