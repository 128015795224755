<template>
  <button class="IobSelectBadge focus-outside">
    <iob-shape-color
      :color="shapeColor"
      :type="shapeType"
      :size="shapeSize"
    />
    <span class="IobSelectBadge-label">
      {{ label }}
    </span>
    <icon-loader
      :key="chevronIcon"
      :name="chevronIcon"
      class="IobSelectBadge-chevron"
    />
  </button>
</template>

<script setup>
import IobShapeColor from '../../Atoms/IobShapeColor/IobShapeColor.vue';
import IconLoader from '../../IconLoader/IconLoader.vue';
import { defineProps } from 'vue';

defineProps({
  label: {
    type: String,
    default:  'button label'
  },
  shapeColor: {
    type: String,
    default: ''
  },
  shapeType: {
    type: String,
    default: 'square'
  },
  shapeSize: {
    type: String,
    default: 'default'
  },
  chevronIcon: {
    type: String,
    default: ''
  }
});
</script>

<style lang="scss" src="./IobSelectBadge.scss" scoped />