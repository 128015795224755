<template>
  <div class="IobSidebar-header">
    <slot />
  </div>
</template>

<script setup>

</script>

<style lang="scss" src="./IobSidebarHeader.scss" scoped />
