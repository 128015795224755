import { USER_INTERACTIONS, ZOOM, VERSION_CATEGORIES } from 'GLOBALS/constants.js';
import initialState from './initialState';
import utils from './utils';

const mutations = {
  setSelectedDatasetElements(state, selectedDatasetElements) {
    state.selectedDatasetElements = selectedDatasetElements;
  },
  setBoardId(state, boardId) {
    state.id = boardId;
  },
  setPanX(state, panX) {
    state.panX = panX;
  },
  setPanY(state, panY) {
    state.panY = panY;
  },
  startDragging(state) {
    state.dragging = true;
    state.canSelect = false;
  },
  stopDragging(state) {
    state.dragging = false;
  },
  setCanSelect(state) {
    state.canSelect = true;
  },
  resetCanSelect(state) {
    state.canSelect = false;
  },
  setBackgroundImage(state, value) {
    state.backgroundImage = value;
  },
  setIsMultipleMove(state, value) {
    state.isMultipleMove = value;
  },
  setZoomToFitLevel(state, zoomLevel) {
    state.zoomToFitLevel = zoomLevel;
  },
  setZoomLevelMin(state, zoomLevel) {
    state.zoomLevelMin = parseFloat(zoomLevel.toFixed(ZOOM.PRECISION));
  },
  setZoomLevel(state, zoomLevel) {
    const minLevel = Math.min(ZOOM.LEVEL_MIN, state.zoomToFitLevel);
    zoomLevel = Math.min(ZOOM.LEVEL_MAX, Math.max(zoomLevel, minLevel));
    state.zoomLevel = parseFloat(zoomLevel.toFixed(ZOOM.PRECISION));
  },
  setViewportReferencePointOrigin(state, point) {
    state.viewportReferencePointOrigin = point;
  },
  setViewportReferencePoint(state, point) {
    state.viewportReferencePoint = point;
  },
  setDx(state, value) {
    state.dx = value;
  },
  setDy(state, value) {
    state.dy = value;
  },
  zoomIn(state) {
    mutations.setZoomLevel(state, state.zoomLevel * state.zoomMultiplier);
  },
  zoomOut(state) {
    mutations.setZoomLevel(state, state.zoomLevel / state.zoomMultiplier);
  },
  setBoardElements(state, elements) {
    state.elements = elements;
  },
  setDatasetElements(state, datasetElements) {
    state.datasetElements = datasetElements;
  },
  addBoardRecentVersions(state, recentVersions) {
    state.recentVersions = recentVersions;
  },
  addBoardOldVersions(state, oldVersions) {
    state.oldVersions = oldVersions;
  },
  setCurrentVersion(state, currentVersion) {
    state.currentVersion = currentVersion;
  },
  addMarkedVersion(state, markedVersion) {
    state.recentVersions.unshift(markedVersion);
  },
  setEditedVersion(state, version) {
    let versionIndex = -1;
    if (version.category === VERSION_CATEGORIES[0]) {
      versionIndex = state.recentVersions.findIndex((item) => item.id === version.id);
      if (versionIndex !== -1) {
        state.recentVersions[versionIndex] = version;
      }
      return;
    }
    if (version.category === VERSION_CATEGORIES[1]) {
      for (const i in state.oldVersions) {
        versionIndex = state.oldVersions[i].findIndex((item) => item.id === version.id);
        if (versionIndex !== -1) {
          state.oldVersions[i][versionIndex] = version;
        }
      }
    }
  },
  setOpenedComponents(state, openedComponents) {
    state.openedComponents = openedComponents;
  },
  setSelectedVersion(state, selectedVersion) {
    state.selectedVersion = selectedVersion;
  },
  setMode(state, mode) {
    state.mode = mode;
  },
  addBoardElement(state, {
    elementId,
    element
  }) {
    state.elements[elementId] = element;
  },
  addDatasetElement(state, {
    datasetElementId,
    datasetElement
  }) {
    state.datasetElements[datasetElementId] = datasetElement;
  },
  deleteBoardElement(state, { elementId }) {
    delete state.elements[elementId];
  },
  deleteDatasetElement(state, { datasetElementId }) {
    delete state.datasetElements[datasetElementId];
  },
  updateBoardElement(state, {
    elementId,
    updates
  }) {
    Object.assign(state.elements[elementId], updates);
  },
  updateDatasetElement(state, {
    datasetElementId,
    updates
  }) {
    if (updates.attributes) {
      if (state.datasetElements[datasetElementId].attributes) {
        state.datasetElements[datasetElementId].attributes = {
          ...state.datasetElements[datasetElementId].attributes,
          ...updates.attributes
        };
      } else {
        state.datasetElements[datasetElementId].attributes = updates.attributes;
      }
      return;
    }
    Object.assign(state.datasetElements[datasetElementId], updates);
  },
  updateDatasetElementAttributes(state, {
    datasetElementId,
    updatedAttributes
  }) {
    if (!state.datasetElements[datasetElementId]) {
      return false;
    }
    const attributesPairs = Object.entries(updatedAttributes);
    attributesPairs.forEach(([key, value]) => {
      if (state.datasetElements[datasetElementId].attributes) {
        state.datasetElements[datasetElementId].attributes[key] = value;
      } else {
        state.datasetElements[datasetElementId].attributes = {[key]: value};
      }
    });
    return true;
  },
  setUsersForBoardElementInEdition(state, { boardElementId, users }) {
    state.boardElementsInEdition[boardElementId] = users;
  },
  deleteBoardElementInEdition(state, {boardElementId}) {
    delete state.boardElementsInEdition[boardElementId];
  },
  setUsersForBoardElementBeingMoved(state, { boardElementId, users }) {
    state.boardElementsBeingMoved[boardElementId] = users;
  },
  setUsersForBoardElementBeingSelected(state, data) {
    state.boardElementsBeingSelected = data;
  },
  setBoardElementsInEdition(state, boardElementsInEdition) {
    state.boardElementsInEdition = boardElementsInEdition;
  },
  setBoardElementsBeingMoved(state, boardElementsBeingMoved) {
    state.boardElementsBeingMoved = boardElementsBeingMoved;
  },
  setBoardElementsBeingSelected(state, boardElementsBeingSelected) {
    state.boardElementsBeingSelected = boardElementsBeingSelected;
  },
  setCanUndo(state, value) {
    state.canUndo = value;
  },
  setCanRedo(state, value) {
    state.canRedo = value;
  },
  setSelectedInteractionMode(state, {value, isQuickPanning}) {
    state.selectedInteractionMode = value;
    state.isLinksMenuOpen = value === 'links' && !isQuickPanning;
  },
  setIsLinksMenuOpen(state, value) {
    state.isLinksMenuOpen = value;
  },
  addHotKey(state, value) {
    state.hotKeys = state.hotKeys.includes(value) ? state.hotKeys : [...state.hotKeys, value];
  },
  removeHotKey(state, value) {
    state.hotKeys = state.hotKeys.filter((e) => e !== value);
  },
  setHighlightedBoardElements(state, value) {
    state.highlightedBoardElements = value;
  },
  resetHighlightedBoardElements(state) {
    state.highlightedBoardElements = [];
  },
  setUnHighlightedBoardElements(state, value) {
    state.unHighlightedBoardElements = value;
  },
  resetUnHighlightedBoardElements(state) {
    state.unHighlightedBoardElements = [];
  },
  showContextualMenu(state) {
    state.contextualMenuShown = true;
  },
  hideContextualMenu(state) {
    state.contextualMenuShown = false;
  },
  setUserSelectedElements(state, value) {
    state.userSelectedElements = value;
  },
  setLastBoardElementIdInEdition(state, value) {
    state.lastBoardElementIdInEdition = value;
  },
  setTranslation(state, value) {
    state.translation = value;
  },
  setFormulaInEditionBoardElementId(state, value) {
    state.formulaInEditionBoardElementId = value;
  },
  lockCursor(state) {
    state.isCursorLocked = true;
  },
  unlockCursor(state) {
    state.isCursorLocked = false;
  },
  setDialogBoxFlags(state, value) {
    // ensure all flags are reset to false before setting the new one
    state.dialogBoxFlags = utils.setObjectValuesToFalse(state.dialogBoxFlags);
    state.dialogBoxFlags = {...state.dialogBoxFlags, ...value};
  },
  setSpecificDialogBoxFlags(state, value) {
    // ensure all flags are reset to false before setting the new one
    state.specificDialogBoxFlags = utils.setObjectValuesToFalse(state.specificDialogBoxFlags);
    state.specificDialogBoxFlags = {...state.specificDialogBoxFlags, ...value};
  },
  setVersionToBeDeletedId(state, value) {
    state.versionToBeDeletedId = value;
  },
  setNotificationsInfo(state, arg) {
    if (arg) {
      state.notifications = {...state.notifications, ...arg};
    }
  },
  setDialogBoxInfo(state, info) {
    state.dialogBoxInfo = info;
  },
  setClickedElementId(state, value) {
    state.clickedElementId = value;
  },
  addHistory(state, value) {
    state.history = [...state.history, ...value];
  },
  setNextPageBoardHistoryUrl(state, value) {
    state.nextPageBoardHistoryUrl = value;
  },
  setHistory(state, value) {
    state.history = value;
  },
  addDatasetElementHistory(state, value) {
    state.datasetElementHistory = [...state.datasetElementHistory, ...value];
  },
  setNextPageDatasetElementHistoryUrl(state, value) {
    state.nextPageDatasetElementHistoryUrl = value;
  },
  setDatasetElementHistory(state, value) {
    state.datasetElementHistory = value;
  },
  setFieldTypeToBeAdded(state, value) {
    state.fieldTypebeingAdded = value;
  },
  setSelectedLinkType(state, value) {
    state.selectedLinkType = value;
    if (!value.isSelectedDefaultLinkType) {
      state.isLinksMenuOpen = false;
    }
  },
  setLink(state, value) {
    if (state.link.source) {
      state.link.target = value;
      return;
    }
    state.link.source = value;
    state.warning = '';
  },
  resetLink(state) {
    state.link.source = null;
    state.link.target = null;
    state.sourceCoordinates = null;
  },
  setLinkSourceCoordinates(state, value) {
    const borders = document.getElementsByClassName('BoardView')[0].getBoundingClientRect();
    state.sourceCoordinates = {
      x: (value.clientX - borders.left) / state.zoomLevel,
      y: (value.clientY - borders.top) / state.zoomLevel
    };
  },
  setWarning(state, value) {
    state.warning = value;
  },
  setFieldTypebeingManaged(state, value) {
    state.fieldTypebeingManaged = value;
  },
  toggleLinks(state) {
    state.linksShown = !state.linksShown;
  },
  setBoardData(state, data) {
    state.boardData = data;
  },
  resetBoardModule(state) {
    Object.assign(state, initialState());
  },
  setMultipleRelations(state, value) {
    state.multipleRelations = value;
  },
  setCollabSessionReady(state, value) {
    state.collabSessionReady = value;
  },
  setIndicatorsRelations(state, value) {
    state.indicatorsRelations = value;
  },
  setDidFetchIndicators(state, value) {
    state.didFetchIndicators = value;
  },
  setFilter(state, { id, elements, totalCount, isLoading, currentPage }) {
    state.filters = {
      ...state.filters,
      [id]: {
        elements,
        totalCount,
        isLoading,
        currentPage
      }
    };
  },
  removeFilter(state, { id }) {
    delete state.filters[id];
  },
  setAsset(state, { assetId, asset }) {
    state.assets[assetId] = asset;
  },
  setDidRetrieveDatasetElements(state, value) {
    state.didRetrieveDatasetElements = value;
  },
  setDidRetrieveBoardElements(state, value) {
    state.didRetrieveBoardElements = value;
  },
  updateUserInteractionOnBoardElements(state, interaction) {
    if (
      state.userInteractionOnBoardElements !== interaction &&
      Object.values(USER_INTERACTIONS).includes(interaction)
    ) {
      state.userInteractionOnBoardElements = interaction;
    }
  },
  resetUserInteractionOnBoardElements(state) {
    if (state.userInteractionOnBoardElements) {
      state.userInteractionOnBoardElements = null;
    }
  },
  setIsSelectionRectangle(state, value) {
    state.isSelectionRectangle = value;
  }
};

export default mutations;
