<template>
  <div class="relative w-full max-w-2xl max-h-full">
    <div class="relative bg-white rounded-lg shadow">
      <!-- Modal header -->
      <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
        <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
          {{ title }}
        </h3>
      </div>
      <!-- Modal body -->
      <div class="max-h-60 relative bg-white rounded-lg shadow dark:bg-gray-700 overflow-x-hidden overflow-y-scroll">
        <div v-if="isNotEmptyObject">
          <div
            v-for="(item, index) in Object.keys(detailsObject)"
            :key="index"
          >
            <p
              v-if="detailsObject[item]"
              class="text-base leading-relaxed text-gray-500 dark:text-gray-400 p-4 space-y-6"
            >
              <span
                v-if="detailsObject[item]"
                class="font-bold"
              > {{ item }}: </span>{{ detailsObject[item] }}
            </p>
          </div>
        </div>
        <div v-else>
          <p class="text-base p-4 leading-relaxed text-gray-500 dark:text-gray-400">
            <span
              v-if="detailsObject"
              class="font-bold"
            > {{ detailsObject }} </span>
            <span
              v-else
              class="font-bold"
            > No details </span>
          </p>
        </div>
      </div>
      <!-- Modal footer -->
      <div class="flex float-right items-center p-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
        <custom-button
          label="Ok"
          outlined
          @button-click="(event) => $emit('popup-button-click', event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from '../CustomButton/CustomButton.vue';
import { parseIfJson } from '../../utils';

export default {
  name: 'PopUp',
  components: {
    CustomButton
  },
  props: {
    details: {
      type: [String, Object],
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  emits: ['button-click'],
  computed: {
    detailsObject() {
      return parseIfJson(this.details) || null;
    },
    isNotEmptyObject() {
      return typeof this.detailsObject === 'object' && this.detailsObject !== null && Object.keys(this.detailsObject) && Object.keys(this.detailsObject).length;
    }
  }
};
</script>

<style scoped src="./PopUp.css" />
