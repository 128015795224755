import { DEFAULT_CARD_FIELDS, FIELD_TYPES } from 'GLOBALS/constants.js';

export default () => ({
  selectedTabIndex: 0,
  fieldId: null,
  fields: DEFAULT_CARD_FIELDS,
  fieldTypes: FIELD_TYPES,
  fieldTypebeingManaged: null,
  hierarchy: {
    parents: [],
    children: []
  },
  fieldPlacement: {},
  selectedLayout: null,
  cardName: 'NewCard',
  defaultColor: null,
  numberFormats: [],
  openedTile: null
});
