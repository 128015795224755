<script setup>
import { computed, onMounted, onUnmounted } from 'vue';
import { getImageSize } from 'SRC/board/utils/utils';
import { ToolTypes } from 'GLOBALS/constants';
import { useStore } from 'vuex';

const store = useStore();

const boardId = computed(() => store.state.board.id);
const viewTypes = computed(() => store.state.app.viewTypes);

const imageViewTypeId = computed(() => Object.entries(viewTypes.value).find(([, { toolType }]) => toolType === ToolTypes.IMAGE)?.[0]);
const getElementInitialPosition = (size) => store.getters['board/getElementInitialPosition'](size);

const handleImagePaste = async (event) => {
  if (['INPUT', 'TEXTAREA'].includes(document.activeElement.tagName)) {
    return;
  }
  const items = event.clipboardData?.items;
  if (!imageViewTypeId.value) {
    return;
  }
  Promise.all(Object.values(items)?.map(async (item) => {
    if (item.kind === 'file' && item.type.startsWith('image/')) {
      const file = item.getAsFile();
      const dimensions = await getImageSize(file);
      return store.dispatch('board/createBoardImage', {
        requestBody: {
          position: getElementInitialPosition(dimensions),
          viewTypeId: imageViewTypeId.value,
          boardId: boardId.value,
          width: dimensions.width,
          height: dimensions.height
        },
        file
      });
    }
  }));
};

onMounted(() => {
  window.addEventListener('paste', handleImagePaste);
});

onUnmounted(() => {
  window.removeEventListener('paste', handleImagePaste);
});
</script>
