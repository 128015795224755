<template>
  <div>
    <visual-link
      v-for="link in formattedVisualLinks"
      :key="link"
      :link="link"
      :link-id="link.id"
      :color="`#${link.relationTypeData.color}`"
    />
    <visual-link
      v-for="link in multipleRelations"
      :key="link.id"
      :link="link"
      :link-id="link.id"
      :color="`#${link.relationTypeData.color}`"
    />
    <mouse-follower
      v-if="creatingLink && selectedInteractionMode === 'links'"
      id="mouse-followerinsider"
      :relation-source="relationSource"
      @mouse-follower-created="setIsMouseFollower"
      @mouse-follower-destroyed="resetIsMouseFollower"
    />
    <visual-link
      v-if="
        creatingLink && isMouseFollower && selectedInteractionMode === 'links'
      "
      :link="{ sourceBoardElementId: link.source, targetBoardElementId: 'mouse-follower' }"
      link-id="mouseFollower"
      :color="mouseFollowerColor"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import VisualLink from '../VisualLink/VisualLink.vue';
import MouseFollower from 'SRC/components/MouseFollower/MouseFollower.vue';
import { updateLeaderLinesPosition } from 'BOARD/utils/utils';
import { formatVisualLinks } from './utils';

export default {
  name: 'VisualLinks',
  components: { VisualLink, MouseFollower },
  data: () => ({
    isMouseFollower: false
  }),
  computed: {
    ...mapState('board', [
      'elements',
      'link',
      'sourceCoordinates',
      'selectedInteractionMode',
      'selectedLinkType'
    ]),
    ...mapState('relations', ['dependencies', 'lines', 'visualLinks', 'hierarchicalRelations']),
    ...mapState('app', ['relationTypes']),
    creatingLink() {
      return this.link.source && !this.link.target;
    },
    relationSource() {
      if (this.link.source) {
        return {
          position: {
            x: this.sourceCoordinates.x,
            y: this.sourceCoordinates.y
          }
        };
      }
      return { position: { x: 0, y: 0 } };
    },
    mouseFollowerColor() {
      return `#${this.selectedLinkType.color}`;
    },
    formattedVisualLinks() {
      const {relations, multiples} = formatVisualLinks(this);
      if (multiples) {
        this.setMultipleRelations(multiples);
      }
      return relations ? relations : [];
    },
    multipleRelations() {
      const { multiples } = formatVisualLinks(this);
      if (multiples && Object.values(multiples).length > 0) {
        const entries = Object.values(multiples);
        const formatted = entries.map((relArray) => relArray.map((el, index) => ({
          ...el,
          sourceBoardElementId: `${el.sourceBoardElementId}-p-${index}`,
          targetBoardElementId: `${el.targetBoardElementId}-p-${index}`
        })));
        return formatted.flat();
      }
      return [];
    }
  },
  watch: {
    elements: {
      handler(value, oldValue) {
        const elKeys = Object.keys(value);
        const prevKeys = Object.keys(oldValue);
        if (elKeys && elKeys.length > 0) {
          updateLeaderLinesPosition(this.lines);
        }
        const deletedElementsIds = prevKeys.filter((el) => !elKeys.includes(el));
        if (deletedElementsIds && deletedElementsIds.length > 0 && this.lines && this.lines.length > 0) {
          const linesToDelete = this.lines.filter((line) => deletedElementsIds.find((el) =>
            line.start.id === `${el}insider` ||
            line.end.id === `${el}insider`)
          );
          if (linesToDelete.length > 0) {
            linesToDelete.forEach(async (line) => {
              this.removeLine(line.id);
            });
          }
        }
      }
    }
  },
  async mounted() {
    if (this.lines) {
      await this.$nextTick();
      updateLeaderLinesPosition(this.lines);
    }
    document.addEventListener('wheel', this.fixPosition);
    document.addEventListener('pointermove', this.fixPosition);
  },
  beforeUnmount() {
    document.removeEventListener('wheel', this.fixPosition);
    document.removeEventListener('pointermove', this.fixPosition);
  },
  methods: {
    ...mapMutations('relations', [
      'removeLine'
    ]),
    ...mapMutations('board', [
      'setMultipleRelations'
    ]),
    fixPosition() {
      updateLeaderLinesPosition(this.lines);
    },
    setIsMouseFollower() {
      this.isMouseFollower = true;
    },
    resetIsMouseFollower() {
      this.isMouseFollower = false;
    }
  }

};
</script>

<style scoped>
.BoardView {
  background-color: white;
  box-shadow: rgb(149 157 165 / 20%) 0 8px 24px;
  position: relative;
}
</style>
