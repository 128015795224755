import {
  LINKS_TYPES,
  MAXIMUM_DAYS_UNMARKED_VERSION,
  MINIMUM_HOURS,
  PAGE_SIZE,
  UNKNOWN_USER,
  VERSION_CATEGORIES,
  VERSION_DATETIME_OPTIONS
} from 'GLOBALS/constants.js';
import usersGetters from 'SRC/store/users/getters';
import UsersModule from 'SRC/store/users/index';
import boardGetters from './getters';
import {CurrentDateOccurence} from 'SRC/utils/currentDateOccurence/CurrentDateOccurence';
import {getAllItemsFromAllPages} from 'SRC/store/editor/hierarchy/actions';
import {API_URL} from 'SRC/config/api.js';
import {parseDateByFrequency, splitFrequency} from 'BOARD/utils/timeSeriesUtils';
import DateGenerator from '../timeseries/DateGenerator';

const utils = {
  formatVersion: (version, selected, today, creationDate) => {
    version.category = creationDate === today ? VERSION_CATEGORIES[0] : VERSION_CATEGORIES[1];
    const shortDateOptions = { month: 'short', year: 'numeric' };
    version.dateTime = new Intl.DateTimeFormat('en-EU', VERSION_DATETIME_OPTIONS).format(new Date(version.creationTimestamp));
    version.date = new Intl.DateTimeFormat('en-EU', shortDateOptions).format(new Date(version.creationTimestamp));
    version.isSelected = selected;
    version.isMarked = version.marked;
    version.daysLeft =
      utils.computeHoursPassed(version) > MINIMUM_HOURS && utils.computeDaysLeft(version) > 0 ? utils.computeDaysLeft(version) : null;
    version.numberOfDaysSinceCreation = utils.computeDaysSinceCreation(version);
  },
  computeDaysLeft: (version) => {
    const now = new Date();
    const creationDate = new Date(version.creationTimestamp);
    const msBetweenDates = Math.abs(creationDate.getTime() - now.getTime());
    return MAXIMUM_DAYS_UNMARKED_VERSION - Math.ceil(msBetweenDates / (1000 * 3600 * MINIMUM_HOURS)) + 1;
  },
  computeHoursPassed: (version) => {
    const now = new Date();
    const creationDate = new Date(version.creationTimestamp);
    return Math.abs(creationDate.getTime() - now.getTime()) / (60 * 60 * 1000);
  },
  computeDaysSinceCreation: (version) => {
    const now = new Date();
    const creationDate = new Date(version.creationTimestamp);
    const msBetweenDates = Math.abs(creationDate.getTime() - now.getTime());
    return Math.ceil(msBetweenDates / (1000 * 3600 * MINIMUM_HOURS));
  },
  addUserToHistoryData(history) {
    history.forEach((element) => {
      const user = usersGetters.getUserById(UsersModule.state)(element.userId);
      element.user = user || UNKNOWN_USER;
    });
    return history;
  },
  addUserPictureCheck(history) {
    for (let i = 1; i < history.length; i++) {
      if (history[i].user.id && history[i].user.id === history[i - 1].user.id) {
        history[i].displayPicture = false;
      }
    }
    return history;
  },
  fillDataObject(state, boardElementsIds, userObject, currentUserId) {
    const data = {};
    boardElementsIds.forEach((id) => {
      const isBoardElementSelected = !!state.boardElementsBeingSelected[id];
      if (!isBoardElementSelected) {
        data[id] = [userObject];
      } else {
        const isSelectedByThisUser = state.boardElementsBeingSelected[id].find((e) => e.userId === currentUserId);
        if (isSelectedByThisUser) {
          data[id] = state.boardElementsBeingSelected[id];
        } else {
          data[id] = [...state.boardElementsBeingSelected[id], userObject];
        }
      }
    });
    return data;
  },
  getWarningMessage(state, error, relationKind, sourceElement, targetElement) {
    if (relationKind === LINKS_TYPES.DependencyType) {
      return error.status === 500 ? error.detail : 'Relation error';
    }
    const sourceElementAttributes = boardGetters.getFullAttributes(state)(sourceElement.datasetElement.id);
    const targetElementAttributes = boardGetters.getFullAttributes(state)(targetElement.datasetElement.id);
    if (sourceElementAttributes && targetElementAttributes) {
      return `${sourceElementAttributes['friendly-id']} cannot be defined as the parent or child of ${targetElementAttributes['friendly-id']}`;
    }
  },
  async constructIndicatorValues({kpiId, kpiData, shouldUseTimeSeriesData}) {
    const {glidepathFrequency, timeSeriesFrequency, glidepathValues = [], timeseries: timeSeriesId} = kpiData.attributes;
    const frequency = shouldUseTimeSeriesData ? splitFrequency(timeSeriesFrequency).frequency : glidepathFrequency;
    let indicatorValues = [];
    if (shouldUseTimeSeriesData) {
      indicatorValues = await this.getTimeSeriesData(kpiId, timeSeriesId, glidepathValues, timeSeriesFrequency);
    } else {
      const traces = await getAllItemsFromAllPages(`${API_URL}/dataset-elements/${kpiId}/history/traces?page=1&size=${PAGE_SIZE}`, false);
      indicatorValues = traces.filter((trace) => trace?.action?.field?.name === 'currentValue')
        .map((trace) => trace.action.newValue);
    }
    const currentDateOccurenceInstance = new CurrentDateOccurence();
    currentDateOccurenceInstance.frequency = frequency;
    const formattedTraces = indicatorValues.map((data) => {
      currentDateOccurenceInstance.currentDate = new Date(data.timestamp);
      const period = String(currentDateOccurenceInstance.generate());
      return ({value: data.value, timestamp: data.timestamp, period});
    });
    return [kpiId, formattedTraces];
  },
  async getTimeSeriesData(kpiId, timeSeriesId, glidepathValues, timeSeriesFrequency) {
    if (!timeSeriesId) {
      console.warn('No time series id found for kpi', kpiId);
      return [kpiId, []];
    }
    const { minDate, maxDate } = Object.entries(glidepathValues)
      .map(([key]) => parseDateByFrequency(key, timeSeriesFrequency))
      .reduce(
        (acc, { startDate, endDate }) => ({
          minDate: !acc.minDate || startDate < acc.minDate ? startDate : acc.minDate,
          maxDate: !acc.maxDate || endDate > acc.maxDate ? endDate : acc.maxDate
        }),
        { minDate: null, maxDate: null }
      );
    const dateGenerator = new DateGenerator(timeSeriesFrequency, minDate, maxDate);
    const query = dateGenerator.generateDates().map((id) => `dates=${id}`).join('&');
    return await getAllItemsFromAllPages(
      `${API_URL}/timeseries/${timeSeriesId}?${query}&page=1&size=${PAGE_SIZE}`, false);
  }
  ,
  getLatestValuesByPeriod(data) {
    const latestValuesByPeriod = new Map();
    data.forEach((item) => {
      const { period, timestamp, value } = item;
      const currentTimestamp = new Date(timestamp).getTime();
      if (!latestValuesByPeriod.has(period)) {
        latestValuesByPeriod.set(period, { timestamp: currentTimestamp, value });
      } else {
        const existingItem = latestValuesByPeriod.get(period);
        if (currentTimestamp > existingItem.timestamp) {
          latestValuesByPeriod.set(period, { timestamp: currentTimestamp, value });
        }
      }
    });
    const latestValues = new Map();
    latestValuesByPeriod.forEach((value, period) => {
      latestValues.set(period, value.value);
    });
    return latestValues;
  },
  generateTracesMap(traces) {
    const cumulatedWeeklyValuesMap = traces.map(([id, values]) => {
      const cumulatedValues = this.getLatestValuesByPeriod(values);
      return [id, cumulatedValues];
    });
    const tracesMap = Object.fromEntries(cumulatedWeeklyValuesMap);/*getLastValuePerDayForAllKeys(*/
    return tracesMap;
  },
  setObjectValuesToFalse(object) {
    return Object.keys(object).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {});
  }
};

export default utils;
