<template>
  <div>
    <div
      v-if="!isCustomized"
      class="Notification w-fit"
    >
      <div class="Notification-content">
        <span class="Notification-message">
          {{ message }}
        </span>
        <span
          v-if="action"
          class="Notification-action"
          @click="callbackfn"
        >
          {{ action }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'NotificationComponent',
  data() {
    return {
      message: '',
      action: '',
      isCustomized: false,
      callbackfn: () => {}
    };
  },
  computed: {
    ...mapState('board', [
      'notifications'
    ])
  },
  watch: {
    notifications: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          const displayedNotification = Object.values(value).find(
            (notification) => notification.isNotificationDisplayed
          );

          if (displayedNotification) {
            this.message = displayedNotification.message;
            this.action = displayedNotification.actionName;
            this.isCustomized = displayedNotification.isCustomized;
            this.callbackfn = displayedNotification.callbackfn;
          }
        }
      }
    }
  }
};
</script>

<style scoped src="./Notification.css" type="module" />
