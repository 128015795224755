<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <!-- icon-with-label -->
  <div 
    v-if="fieldType === 'icon-with-label'"
    :class="['IobListItemAttributes-label', `IobListItemAttributes-label-${size}`]"
  >
    <iob-icon-shape 
      :icon-name="value.iconName"
      :color="value.iconColor"
      v-bind="additionalProps"
      :is-clickable="false"
    />
    <span>
      {{ value.text }}
    </span>
  </div>
  <!-- icon-dropdown -->
  <iob-icon-shape-dropdown
    v-if="fieldType === 'icon-dropdown'"
    size="default"
    :color="value.iconColor"
    type="square"
    :icon-name="value.iconName"
    :title="value.text"
  />
  <!-- title -->
  <div
    v-if="fieldType === 'title'"
    class="IobListItemAttributes-titleContainer"
  >
    <div :class="['IobListItemAttributes-title', `IobListItemAttributes-title-${size}`]">
      {{ value }}
    </div>
  </div>
  <!-- text -->
  <div
    v-if="fieldType === 'text'"
    class="IobListItemAttributes-textContainer"
  >
    <div :class="['IobListItemAttributes-text',`IobListItemAttributes-text-${size}`]">
      {{ value }}
    </div>
  </div>
  <!-- progress -->
  <iob-badge
    v-if="fieldType === 'progress' && value"
    :size="sizeMap"
    color="secondary"
    type="outlined"
    :label="`${value}%`"
  />
  <!-- priority -->
  <iob-badge
    v-if="fieldType === 'priority' && value"
    :size="sizeMap"
    :color="getBadgeColor"
    :label="value"
  />
  <!-- status -->
  <iob-badge
    v-if="fieldType === 'status' && value"
    :size="sizeMap"
    :color="getBadgeColor"
    :label="value"
  />
  <!-- user -->
  <iob-avatar
    v-if="fieldType === 'user'"
    :deleted-user="unknownUser"
    :picture="value.picture"
    :alt-text="value.altText"
    v-bind="additionalProps"
  />
  <!-- horizon -->
  <iob-badge
    v-if="fieldType === 'horizon'"
    :size="sizeMap"
    color="secondary"
    type="soft"
    icon="CalendarRange"
    :label="value"
  />
  <!-- dueDate -->
  <iob-badge
    v-if="fieldType === 'dueDate'"
    :size="sizeMap"
    color="secondary"
    type="soft"
    icon="CalendarCheck"
    :label="value"
  />
</template>
  
<script setup>
import IobBadge from '../../../Atoms/IobBadge/IobBadge.vue';
import IobAvatar from '../../../Molecules/IobAvatar/IobAvatar.vue';
import IobIconShapeDropdown from '../../../Molecules/IobIconShapeDropdown/IobIconShapeDropdown.vue';
import IobIconShape from '../../../Atoms/IobIconShape/IobIconShape.vue';
import { defaultBadgeColors } from '../../../../constants';
import { defineProps, computed } from 'vue';

const props = defineProps({
  fieldType: {
    type: String,
    default: 'title',
  },
  value: {
    type: [String, Object],
    default: '',
  },
  size: {
    type: String,
    default: 'small'
  }
});

const sizeMap = computed(() => {
  switch (props.size) {
  case 'medium':
    return 'list-item-medium';
  case 'large':
    return '2xl';
  default:
    return 's';
  }
});

const additionalProps = computed(() => {
  if (props.size === 'medium') {
    return {
      customDimension: 57.8,
      altTextStyle: 'font-size: 28.8px'
    }
  }
  return {
    size: 'default'
  };
});

const getBadgeColor = computed(() => {
  return defaultBadgeColors[props.value]
});

const unknownUser = computed(() => {
  if (props.value && (props.value.picture || props.value.altText)) {
    return false;
  }
  return true;
});

</script>
  
<style lang="scss" src="./IobListItemAttributes.scss" scoped />
  