<template>
  <div class="GhostListLine-container">
    <div
      class="GhostListLine-element"
      style="width: 11%;"
    />
    <div class="GhostListLine-separator" />
    <div
      class="GhostListLine-element"
      style="width: 88%;"
    />
  </div>
</template>

<script setup>
</script>

<style>
.GhostListLine-element {
  border-radius: 2px;
  height: 70%;
  background-color: rgba(232, 231, 236, 1);
}
.GhostListLine-separator {
  width: 1%;
  height: 70%;
  background-color: white;
}

.GhostListLine-container {
  display: flex;
  background-color: white;
}

</style>
