<template>
  <board
    :send="send"
    :state="state"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import Board from 'BOARD/Board.vue';
import { USER_TECHNICAL_TYPE } from 'GLOBALS/constants.js';
import { getWebsocketProvider, initCollabAwareness, getUndoManager, createSyncedStore } from 'SRC/utils/collab';
import synchronizeStore from 'SRC/store/syncStore';
import store from 'SRC/store';

export default {
  name: 'BoardApiWrapper',
  components: { Board },
  props: {
    send: {
      type: Function,
      default: () => {}
    },
    state: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return { undoManager: {} };
  },
  computed: {
    ...mapState('auth', [
      'token'
    ]),
    ...mapGetters('auth', [
      'isAuthenticated'
    ]),
    ...mapState('users', [
      'currentUser',
      'currentUserColor'
    ]),
    ...mapState({
      boardId: (state) => state.board.id,
      collabSessionReady: (state) => state.board.collabSessionReady,
      currentUser: (state) => state.users.currentUser
    })
  },
  watch: {
    async isAuthenticated(isAuthenticated) {
      if (isAuthenticated) {
        this.fetchAllData();
      }
    },
    async token(token) {
      if (token) {
        this.initWebSocketProvider();
      }
    },
    async currentUser(user) {
      if (user && !this.collabSessionReady && this.unsubscribe) {
        this.initCollabSession();
      }
    }
  },
  mounted() {
    if (this.token) {
      this.fetchAllData();
    }
  },
  unmounted() {
    try {
      this.unsubscribe();
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    ...mapActions('app', [
      'fetchViewTypes',
      'fetchDataTypes',
      'fetchRelationTypes',
      'fetchFormulas',
      'fetchSystemDataTypes'
    ]),
    ...mapActions('dock', [
      'fetchDockConfig'
    ]),
    ...mapActions('users', [
      'fetchAllUsers',
      'fetchCurrentUser'
    ]),
    ...mapActions('board', [
      'initWebSocketProvider',
      'fetchBoardData',
      'downloadBackgroundImage'
    ]),
    ...mapActions('toolsConfig', ['fetchNumberFormats']),
    ...mapMutations('board', [
      'setBoardId',
      'setCanUndo',
      'setCanRedo',
      'setCollabSessionReady'
    ]),
    ...mapMutations('users', [
      'setUsersOnBoard',
      'setCurrentUserColor'
    ]),
    handleStackChange(event) {
      if (event.type === 'undo') {
        const canUndo = this.undoManager.canUndo();
        this.setCanUndo(canUndo);
      } else if (event.type === 'redo') {
        const canRedo = this.undoManager.canRedo();
        this.setCanRedo(canRedo);
      }
    },
    initUsersOnBoard(wsProvider) {
      const awareness  = wsProvider.awareness;
      const retrieveConnectedUsers = () => {
        const users = Array.from(awareness.getStates().values())
          .filter((each) => !!each && Object.keys(each).length)
          .map((each) => each.user)
          .filter((user) => user && user.type !== USER_TECHNICAL_TYPE && user.id !== this.currentUser.id)
          .filter((each, index, self) => index === self.findIndex((item) => item && (item.id === each.id)));
        this.setUsersOnBoard(users);
      };
      retrieveConnectedUsers();
      awareness.on('change', (/*{ added, updated, removed }*/) => {
        retrieveConnectedUsers();
      });
    },
    initCollab() {
      this.setCurrentUserColor(this.currentUser.color);
      initCollabAwareness(
        this.currentUser.firstname,
        this.currentUser.lastname,
        this.currentUser.id,
        this.currentUser.picture,
        this.currentUserColor);
    },

    initCollabSession() {
      this.initWebSocketProvider();
      const wsProvider = getWebsocketProvider();
      wsProvider.on('sync', () => {
        this.initUsersOnBoard(wsProvider);
        this.initCollab();
        this.setCollabSessionReady(true);
      });
    },
    async fetchAllData() {
      const { boardId } = this.$route.params;
      if (boardId) {
        this.setBoardId(boardId);
      } else {
        this.$router.push({params: {boardId: this.boardId}});
      }
      const promise = (fn) =>
        new Promise((resolve, reject) => {
          fn().then((result) => {
            if (result && result.error) {
              reject({ err: result.error });
            } else {
              resolve(result);
            }
          });
        });
      await Promise.all([
        promise(this.fetchViewTypes),
        promise(this.fetchRelationTypes),
        promise(this.fetchDataTypes),
        promise(this.fetchNumberFormats),
        promise(this.fetchBoardData)
      ]);
      await this.fetchDockConfig();
      await createSyncedStore({
        boardElements: {},
        datasetElements: {},
        editorElements: {},
        boardElementsInEdition: {},
        boardElementsBeingMoved: {},
        backgroundImage: {},
        boardElementsBeingSelected: {},
        dependencies: {},
        visualLinks: {},
        hierarchicalRelations: {}
      });
      this.unsubscribe = synchronizeStore(store);
      if (this.currentUser) {
        this.initCollabSession();
      }
      await this.fetchFormulas();
      this.undoManager = getUndoManager();
      this.undoManager.on('stack-item-added', this.handleStackChange.bind(this));
      this.undoManager.on('stack-item-popped', this.handleStackChange.bind(this));
    }
  }
};
</script>
