export class DateRangeGenerator {
  /**
    * constructor description
    * @param  {, frame: string, period: string, type: string, year: string, end-date: string, start-date: string}
    */
  constructor({frame, period, type, year, startDate, endDate}) {
    this._startDate = startDate;
    this._endDate = endDate;
    this._frame = frame;
    this._period = period;
    this._type = type;
    this._year = year;
  }
  get startDate() {
    return this._startDate
  }
  set startDate(value) {
    this._startDate = value
  }
  get endDate() {
    return this._endDate
  }
  set endDate(value) {
    this._endDate = value
  }
  get frame() {
    return this._frame
  }
  set frame(value) {
    this._frame = value
  }
  get period() {
    return this._period
  }
  set period(value) {
    this._period = value
  }
  get type() {
    return this._type
  }
  set type(value) {
    this._type = value
  }
  get year() {
    return this._year
  }
  set year(value) {
    this._year = value
  }
  #frequencyMethodMap = {
    M: 'getLastMonthDay',
    S: 'getLastSemesterDay',
    Y: 'getLastYearDay',
    Q: 'getLastQuarterDay',
  };
  /**
   * 
   * @returns {Object} - Object of dates
   * @description - Generates an object of dates between startDate and endDate based on the frequency
   */
  generate() {
    if(this.type === 'custom') {
      const res = {startDate: this.startDate, endDate: this.endDate};
      return res;
    }
    return {
      startDate: this.startDate,
      endDate: this[this.#frequencyMethodMap[this.frame]](this._year, this._period)
    };
  }
  subtractOneDay(utcDate) {
    const dateObject = new Date(utcDate);
    dateObject.setUTCDate(dateObject.getUTCDate() - 1);
    return dateObject;
  }  
  getLastMonthDay(year, month) {
    const utcdateNextMonth = Date.UTC(year, month, 1);
    let lastDay = this.subtractOneDay(utcdateNextMonth);
    return lastDay.toISOString();
  }
  getLastSemesterDay(year, semester) {
    if (semester == 1) {
      return this.getLastMonthDay(year, 6);
    }
    return this.getLastMonthDay(year, 12);
  }
  getLastYearDay(year) {
    return this.getLastMonthDay(year, 12)
  }
  getLastQuarterDay(year, quarter) {
    let nextQuarterFirstDay = Date.UTC(year, quarter * 3, 1);
    let lastDay = this.subtractOneDay(nextQuarterFirstDay)
    return lastDay.toISOString();
  }
}
