<template>
  <div
    class="IobLink focus-outside"
    :class="classList"
    tabindex="0"
    data-test="IobLink-link"
    @click="handleLinkClick"
  >
    <span
      class="IobLink-text"
    > {{ text }} </span>
    <icon-loader
      v-if="linkIcon"
      class="IobLink-icon"
      :name="linkIcon"
      color="var(--link-color-text-default, #2D6BFF)"
      :size="iconSizeMap[size]"
    />
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';
import IconLoader from '../../IconLoader/IconLoader.vue';

const props = defineProps({
  text: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: 'default',
    validator: (value) => {
      return ['default', 'small', 'large'].includes(value);
    },
  },
  linkIcon: {
    type: String,
    default: '',
    validator: (value) => {
      return ['ArrowRight', 'ArrowUpRight', 'ExternalLink', 'ArrowLeft'].includes(value);
    },
  },
  underline: {
    type: Boolean,
    default: false,
  },
  alignIconLeft: {
    type: Boolean,
    default: false,
  },
  url: {
    type: String,
    default: '',
  },
  newTab: {
    type: Boolean,
    default: true,
  },
});
const classList = computed(() => ({
  'IobLink--small': props.size === 'small',
  'IobLink--default': props.size === 'default',
  'IobLink--large': props.size === 'large',
  'IobLink--underline': props.underline,
  'IobLink--icon-Left': props.alignIconLeft,
}));

const iconSizeMap = {
  small: 'xsmall',
  default: 'small',
  large: 'default',
};

const handleLinkClick = () => {
  if (props.url) {
    const target = props.newTab ? '_blank' : '_top';
    window.open(props.url, target);
  }
};
</script>

<style lang="scss" scoped>
.IobLink {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  border-radius: var(--focus-radius-outside-6x);
  min-height: var(--focus-size-6x);
  cursor: pointer;
  
    &:active {
      .IobLink-text {
        color: var(--link-color-text-pressed);
      }
  
      .IobLink-icon {
        transition: transform 0.3s ease-in-out; 
        transform: translateX(4px);
        
        &::v-deep(svg *) {
        fill: var(--link-color-text-pressed);
      }
    }
  }

    &:hover {
      .IobLink-text {
        color: var(--link-color-text-hover);
      }
  
      .IobLink-icon {
        transition: transform 0.3s ease-in-out; 
        transform: translateX(4px);

        &::v-deep(svg *) {
        fill: var(--link-color-text-hover);
      }
    }
  }

  &-text {
    color: var(--link-color-text-default);
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.12px;
  }

    &--small {
      .IobLink-text {
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.12px;
      }
    }

    &--default {
      .IobLink-text {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.14px;
    }
  }
    &--large {
      .IobLink-text {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.14px;
    }
  }

  &--underline {
    .IobLink-text {
      text-decoration-line: underline;
    }
  }

  &--icon-Left {
    flex-direction: row-reverse;
  }
}

</style>
<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-link.scss" />
