<template>
  <div>
    <iob-button
      size="medium"
      color="secondarySoft"
      type="outlined"
      :disabled="false"
      :label="$t('embed.powerbi.auth-btn')"
      left-icon="PowerBI"
      :show-left-icon="true"
      :selected="false"
      @click="authorize(id)"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'PowerBIOAuthButton',
  computed: {
    ...mapState('board', ['id'])
  },
  methods: {
    ...mapActions('powerbi', ['authorize'])
  }
};
</script>
