<template>
  <div
    class="IobEditor"
    @click.self="handleCloseEditor"
  >
    <div
      id="editor-content"
      class="IobEditor-container"
    >
      <div
        class="IobEditor-left"
        data-test-id="editor-left"
      >
        <div
          class="IobEditor-left-header"
          data-test-id="editor-left-header"
        >
          <div
            class="IobEditor-titleSection"
            data-test-id="editor-title-section"
          >
            <div
              class="IobEditor-titleSection-elementDescription"
              data-test-id="editor-title-description"
            >
              <iob-textarea
                v-if="titleAttribute"
                class-names="IobEditor-titleSection-elementDescription"
                :value="inputs[titleAttribute.name]"
                :is-focused="true"
                :placeholder="titlePlaceholder"
                max-caracters="250"
                :is-error="isTitleError"
                error-message="You must specify the title"
                :show-caracters-counter="!isTitleError"
                data-test-id="textarea-title"
                @update:modelValue="(value) => handleTextInput(titleAttribute.name, value)"
              >
                <div
                  class="IobEditor-titleSection-elementIdentifier"
                  data-test-id="editor-title-identifier"
                >
                  <div v-if="previousFriendlyIdAttirubutes ">
                    <span class="IobEditor-titleSection-elementIdentifier--tooltip">
                      {{ previousFriendlyIdAttirubutes }}
                    </span>
                    <iob-action-button
                      color="secondary"
                      type="ghost"
                      icon-name="ChevronLeft"
                      data-test-id="action-button-previous"
                      @click="emits('onClickPreviousElement')"
                    />
                  </div>
                  <div
                    class="IobEditor-titleSection-elementIdentifier--datasetType"
                    data-test-id="dataset-type"
                  >
                    <iob-icon-shape-dropdown
                      size="default"
                      :color="getIcon(dataTypeName, relationTypes[0]).color"
                      type="square"
                      :icon-name="getIcon(dataTypeName, relationTypes[0]).icon"
                      :items="changeDataTypeDropdown"
                      :selected-item="changeDataTypeDropdown.text"
                      class="IobEditor-titleSection-elementIdentifier--datasetType--iconShape"
                      data-test-id="icon-shape-dropdown"
                      @dropdown-element-click="handleAttributeChange('type', $event)"
                    />
                    <p data-test-id="datatype">
                      {{ datatypeType }}
                    </p>
                  </div>
                  <div
                    class="IobEditor-titleSection-elementIdentifier--seperator"
                    data-test-id="separator"
                  >
                    <iob-separator :is-vertical="true" />
                  </div>
                  <div
                    class="IobEditor-titleSection-elementIdentifier--elementId"
                    data-test-id="element-id"
                  >
                    <p>{{ props.datasetElement.attributes['friendly-id'] }}</p>
                  </div>
                </div>
              </iob-textarea>
              <div
                v-else
                class="IobEditor-titleSection-elementIdentifier"
                data-test-id="editor-title-identifier-else"
              >
                <div
                  class="IobEditor-titleSection-elementIdentifier--datasetType"
                  data-test-id="dataset-type-else"
                >
                  <iob-icon-shape-dropdown
                    size="default"
                    :color="getIcon(dataTypeName, relationTypes[0]).color"
                    type="square"
                    :icon-name="getIcon(dataTypeName, relationTypes[0]).icon"
                    :items="changeDataTypeDropdown"
                    :selected-item="changeDataTypeDropdown.text"
                    class="IobEditor-titleSection-elementIdentifier--datasetType--iconShape"
                    data-test-id="icon-shape-dropdown-else"
                    @dropdown-element-click="handleAttributeChange('type', $event)"
                  />
                  <p data-test-id="datatype-else">
                    {{ datatypeType }}
                  </p>
                </div>
                <div
                  class="IobEditor-titleSection-elementIdentifier--seperator"
                  data-test-id="separator-else"
                >
                  <iob-separator :is-vertical="true" />
                </div>
                <div
                  class="IobEditor-titleSection-elementIdentifier--elementId"
                  data-test-id="element-id-else"
                >
                  <p>{{ friendlyId }}</p>
                </div>
              </div>
            </div>
            <iob-editor-summary
              v-if="props.hasSummary"
              :current-value="props.summaryConfig.subAttr ?
                props.datasetElement.attributes[props.summaryConfig.currentValueAttr] ?
                  props.datasetElement.attributes[props.summaryConfig.currentValueAttr][props.summaryConfig.subAttr]
                  : 0
                : props.datasetElement.attributes[props.summaryConfig.currentValueAttr]
              "
              :target="props.datasetElement.attributes[props.summaryConfig.targetValueAttr]"
              :unit="getUnit(props.datasetElement.attributes, props.summaryConfig)"
              :target-comparator="props.datasetElement.attributes[props.summaryConfig.targetComparatorAttr]"
              :glide-path="props.datasetElement.attributes[props.summaryConfig.glidePathAttr]"
              :frequency="props.datasetElement.attributes[props.summaryConfig.frequencyAttr]"
              :timeseries-actual-value="timeseriesActualValueRef"
              :time-series-frequency="timeSeriesFrequency"
              :can-use-time-series-data="props.canUseTimeSeriesData"
              style="margin-top: 16px"
              data-test-id="editor-summary"
            />
            <div
              v-if="!props.hasSummary"
              class="IobEditor-titleSection--seperator"
              data-test-id="editor-summary-separator"
            >
              <iob-separator :is-vertical="false" />
            </div>
          </div>

          <div
            class="IobEditor-leftAttributes-wrapper"
            data-test-id="editor-attributes-wrapper"
          >
            <div
              v-if="editorWithTabs"
              class="IobEditor-leftAttributes-tabs"
              data-test-id="attributes-tabs"
            >
              <div
                v-for="(tab, index) in tabs"
                :key="index"
                class="IobEditor-leftAttributes-tabs--tab"
                :data-test-id="`tab-${index}`"
                @click.stop="handleActiveTab(index)"
              >
                <div
                  :class="tabsClasses(index)"
                >
                  <icon-loader
                    :name="tab.iconName"
                    size="large"
                    color="#1D51CE"
                  />
                  <h3>{{ index }}</h3>
                </div>
              </div>
            </div>
            <div
              class="IobEditor-leftAttributes-wrapper-content"
              data-test-id="attributes-wrapper-content"
            >
              <div
                v-if="isActiveTab === 'general' || !editorWithTabs"
                class="IobEditor-buttonGroup"
                data-test-id="button-group"
              >
                <iob-dropdown-button
                  title="Link to..."
                  size="medium"
                  :type-check="false"
                  icon-name="Link"
                  :items="linksDropdown"
                  data-test-id="dropdown-button"
                  @dropdownElementClick="displayAddRelationsSection"
                />
                <iob-dropdown-button
                  title="Attach"
                  size="medium"
                  :type-check="false"
                  :disabled="!props.showAttachmentDropdown"
                  icon-name="PaperClip"
                  :items="props.attachmentsDropdown"
                  :show-right-icon="false"
                  :dynamic-title="false"
                  data-test-id="attach-button"
                  dropdown-style="width: 276px"
                  @dropdownElementClick="(elem) => emits('onClick:dropdownElement', { element: elem })"
                  @dropdownButtonClosed="emits('dropdownButtonClosed')"
                  @update:modelValue="(value, fieldId) => emits('onChange:fieldValue', { value, fieldId })"
                />
              </div>
              <div
                class="IobEditor-left-content"
                data-test-id="left-content"
              >
                <div
                  v-if="isActiveTab === 'general' || !editorWithTabs"
                  class="IobEditor-left-content--seperator"
                  data-test-id="left-content-separator"
                >
                  <iob-separator :is-vertical="false" />
                </div>
                <div
                  class="IobEditor-left-content-detailsWrapper"
                  data-test-id="left-content-details-wrapper"
                >
                  <div
                    v-if="currentTabAttributes && currentTabAttributes.attributes"
                    class="IobEditor-left-content-detailsWrapper-attribute"
                    data-test-id="content-attribute"
                  >
                    <div
                      v-for="(section, index) in sections"
                      :key="section+index"
                      :style="section.name !== 'default' ? {display: 'flex','flex-direction': 'column'} : {}"
                      style="margin-bottom: 8px;"
                      data-test-id="section"
                    >
                      <div
                        v-if="section.name !== 'default'"
                        style="margin-bottom: 8px;"
                        class="IobEditor-section-title"
                        data-test-id="section-title"
                      >
                        {{ section.label || section.name }}
                      </div>
                      <div
                        v-if="section.description"
                        class="IobEditor-section-description"
                        data-test-id="section-description"
                      >
                        {{ section.description }}
                      </div>
                      <div
                        :style="{
                          display: section.name === 'default' ? '' : 'flex',
                          ...section.style
                        }"
                      >
                        <template
                          v-for="(dataTypeAttr, i) in getSectionAttributes(section.name, isActiveTab)"
                        >
                          <component-map
                            v-if="showOnMap[dataTypeAttr.name] && !hiddenAttributes.includes(dataTypeAttr.name)"
                            :key="dataTypeAttr.name + i"
                            :style="showOnMap[dataTypeAttr.name] ? dataTypeAttr.style : 'width: 0px'"
                            :sub-attributes="dataTypeAttr.subAttributes"
                            :attribute-data="props.dataTypeAttributes.find(attr => attr.name === dataTypeAttr.name)"
                            :component-type="dataTypeAttr.componentType"
                            :label-on-top="true"
                            :hide-label="dataTypeAttr.hideLabel"
                            :label="(dataTypeAttr.name === 'description') ? dataTypeNameTitle : dataTypeAttr.label"
                            :name="dataTypeAttr.name"
                            :inputs="inputs"
                            :postfix-type="props.datasetElement && props.datasetElement.attributes && props.datasetElement.attributes[dataTypeAttr.postFixTypeAttribute]"
                            :postfix-unit-value="props.datasetElement && props.datasetElement.attributes && props.datasetElement.attributes[dataTypeAttr.postFixAttribute]"
                            :timeframe-attr-value="props.datasetElement && props.datasetElement.attributes && props.datasetElement.attributes[dataTypeAttr.timeframeAttribute]"
                            :frequency-attr-value="props.datasetElement && props.datasetElement.attributes && props.datasetElement.attributes[dataTypeAttr.frequencyAttribute]"
                            :time-series-frequency="timeSeriesFrequency"
                            :can-use-time-series-data="props.canUseTimeSeriesData"
                            :time-series-data="props.timeSeriesData"
                            :index-part-of-attr-value="dataTypeAttr.indexPartOfAttrValue"
                            :attr-value-separator="dataTypeAttr.attrValueSeparator"
                            :use-same-attr-enum-item-id="dataTypeAttr.useSameAttrEnumItemId"
                            :display-description-counter="displayDescriptionCounter"
                            :friendly-name="dataTypeAttr.label"
                            :type="getDataTypeAttrType(dataTypeAttr.name)"
                            :enum="getEnum(dataTypeAttr.name)"
                            :dataset-element="datasetElement"
                            :users="users"
                            :input-dropdown="inputDropdown"
                            :handle-attribute-change="handleAttributeChange"
                            :handle-time-series-point="handleTimeSeriesPoint"
                            :get-user="getUser"
                            :get-attribute-value="getAttributeValue"
                            :get-input-type="getInputType"
                            :handle-text-input="handleTextInput"
                            :created-on="props.datasetElement.createdOn"
                            :initial-value="props.datasetElement && props.datasetElement.attributes && props.datasetElement.attributes[dataTypeAttr.initialValueAttribute]"
                            :final-target="props.datasetElement.attributes?.targetValue"
                            :horizon-value="horizonComputed"
                            :levels="levels"
                            :data-test-id="'left-section' + dataTypeAttr.name"
                            :attachments-dropdown="props.attachmentsDropdown"
                            :config="dataTypeAttr.config"
                            :source="dataTypeAttr.source"
                            :additional-data="additionalData"
                            @onClick:action="(params) => emits('onClick:action', params)"
                            @onClick:veil="(params) => emits('onClick:veil', params)"
                            @onClick:dropdownElement="(params) => emits('onClick:dropdownElement', params)"
                            @onChange:fieldValue="(params) => emits('onChange:fieldValue', params)"
                          />
                        </template>
                      </div>
                    </div>
                  </div>
                  <iob-editor-relations
                    v-if="isActiveTab === 'general' || !editorWithTabs"
                    :id="id"
                    :reload-key="reloadAddRelationsKey"
                    :data-type-id="dataTypeId"
                    :data-type-name="dataTypeName"
                    :selected-data-type-name="selectedDataTypeName"
                    :selected-data-type-types="selectedDataTypeTypes"
                    :eligible-children="eligibleChildren"
                    :eligible-parents="eligibleParents"
                    :eligible-dependencies="eligibleDependencies"
                    :children="children"
                    :parent="parent"
                    :dependencies="dependencies"
                    :relation-config-attributes="relationConfigAttributes"
                    :get-icon="getIcon"
                    :selected-type="selectedType"
                    :is-add-children-visible="isAddChildrenVisible"
                    :is-add-parent-visible="isAddParentVisible"
                    :is-add-dependencies-visible="isAddDependenciesVisible"
                    data-test-id="editor-relations"
                    @onClickOutsideAddRelations="hideAddRelations"
                    @onClickAddRelationsIcon="displayAddRelationsViaIcon"
                    @onClickTypeOption="(data) => emits('onClickTypeOption', data)"
                    @onClickCreateAsNewTypeName="createRelationWithNewDatasetElement"
                    @onClickAddRelationWithExistingDatasetElement="createRelationWithExistingDatasetElement"
                    @onClickDetailsMenu="(data) => emits('onClickDetailsMenu', data)"
                    @onClickDetailsIcon="handleDetailsIconClick"
                    @search="(data) => emits('search', data)"
                    @onClickDetailsItem="(id) => emits('onClickDetailsItem', id)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="IobEditor-right"
        data-test-id="editor-right"
      >
        <div
          class="IobEditor-right-container"
          data-test-id="editor-right-container"
        >
          <div
            class="IobEditor-right-actionButtons"
            data-test-id="editor-action-buttons"
          >
            <iob-button
              size="small"
              color="secondary"
              type="ghost"
              left-icon="MoreHorizontal"
              :show-left-icon="true"
              :disabled="true"
              data-test-id="button-more-options"
            />
            <iob-button
              size="small"
              color="secondary"
              type="ghost"
              icon-name="X"
              left-icon="X"
              :show-left-icon="true"
              class="IobEditor--closeButton"
              data-test-id="button-close-editor"
              @click="handleCloseEditor"
            />
          </div>
          <div
            class="IobEditor-right-content"
            data-test-id="editor-right-content"
          >
            <div
              class="IobEditor-right-attributes"
              data-test-id="editor-attributes"
            >
              <template
                v-for="(dataTypeAttr, index) in findDataType"
              >
                <div
                  v-if="showOnMap[dataTypeAttr.name] && !hiddenAttributes.includes(dataTypeAttr.name)"
                  :key="dataTypeAttr.name + index"
                  data-test-id="editor-attribute"
                >
                  <component-map
                    :name="dataTypeAttr.name"
                    :inputs="inputs"
                    :style="dataTypeAttr.style"
                    :label="dataTypeAttr.label"
                    :display-description-counter="displayDescriptionCounter"
                    :component-type="dataTypeAttr.componentType"
                    :friendly-name="dataTypeAttr.friendlyName"
                    :type="dataTypeAttr.type"
                    :enum="dataTypeAttr.enum"
                    :dataset-element="datasetElement"
                    :users="users"
                    :input-dropdown="inputDropdown"
                    :handle-attribute-change="handleAttributeChange"
                    :handle-time-series-point="handleTimeSeriesPoint"
                    :get-user="getUser"
                    :get-attribute-value="getAttributeValue"
                    :get-input-type="getInputType"
                    :handle-text-input="handleTextInput"
                    :compute-progress="computeProgress"
                    :created-on="dataTypeAttr.createdOn"
                    :icon-name="dataTypeAttr.iconName || ''"
                    :placeholder="placeholder"
                    :data-type="dataTypeAttr"
                    :levels="levels"
                    :data-test-id="'right-section' + dataTypeAttr.name"
                    :attachments-dropdown="props.attachmentsDropdown"
                    :config="dataTypeAttr.config"
                    :source="dataTypeAttr.source"
                    :additional-data="additionalData"
                    @onClick:action="(params) => emits('onClick:action', params)"
                    @onClick:veil="(params) => emits('onClick:veil', params)"
                    @onClick:dropdownElement="(params) => emits('onClick:dropdownElement', params)"
                    @onChange:fieldValue="(params) => emits('onChange:fieldValue', params)"
                  />
                  <iob-separator
                    v-if="dataTypeAttr.postDivider"
                    style="margin-top: 30px;"
                    data-test-id="separator"
                  />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, ref, onMounted, defineEmits, reactive, onBeforeUnmount, watch } from 'vue';
import { UNASSIGNED, dataTypesIcons, RELATION_TYPES, SHOW_COUNTER_ON_LENGTH } from '../../../constants';
import { generateDate, findMainPanelAttribute, getProgressAttributes, showOnFns } from './editor-utils.js';
import ComponentMap from './componentMap.vue';
import IobTextarea from '../../Atoms/IobTextarea/IobTextarea.vue';
import IobIconShapeDropdown from '../IobIconShapeDropdown/IobIconShapeDropdown.vue';
import IconLoader from '../../IconLoader/IconLoader.vue';
import IobButton from '../IobButton/IobButton.vue';
import IobEditorSummary from '../IobEditorSummary/IobEditorSummary.vue';
import IobSeparator from '../../Atoms/IobSeparator/IobSeparator.vue';
import IobDropdownButton from '../../Molecules/IobDropdownButton/IobDropdownButton.vue';
import IobEditorRelations from './IobEditorRelations/IobEditorRelations.vue';
import IobActionButton from '../IobActionButton/IobActionButton.vue';

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  dataTypeId: {
    type: String,
    required: true
  },
  dataTypeName: {
    type: String,
    default: ''
  },
  mainPanelAttributes: {
    type: Array,
    default: () => []
  },
  centralAttributesPanel: {
    type: Object,
    default: () => ({})
  },
  dataTypeAttributes: {
    type: Array,
    default: () => []
  },
  owner: {
    type: Object,
    default: () => ({})
  },
  users: {
    type: [Array, String],
    default: () => []
  },
  selectedDataTypeTypes: {
    type: Array,
    default: () => []
  },
  eligibleRelationsDatatypes: {
    type: [Array, String],
    default: () => []
  },
  eligibleChildDataTypes: {
    type: [Array, String],
    default: () => []
  },
  eligibleParentDataTypes: {
    type: [Array, String],
    default: () => []
  },
  eligibleChildren: {
    type: [Array, String],
    default: () => []
  },
  eligibleParents: {
    type: [Array, String],
    default: () => []
  },
  eligibleDependencies: {
    type: [Array, String],
    default: () => []
  },
  children: {
    type: [Array, String],
    default: () => []
  },
  parent: {
    type: [Object, String],
    default: null
  },
  dependencies: {
    type: [Object, String],
    default: () => ({})
  },
  datasetElement: {
    type: [Array, Object],
    default: () => []
  },
  relationConfigAttributes: {
    type: [Array, String],
    default: () => []
  },
  selectedType: {
    type: String,
    default: null
  },
  hasSummary: {
    type: Boolean,
    default: false
  },
  summaryConfig: {
    type: Object,
    default: () => ({})
  },
  previousDatasetElement: {
    type: Object,
    default: () => ({})
  },
  levels: {
    type: Array,
    default: () => []
  },
  timeseriesActualValue: {
    type: Object,
    default: () => ({})
  },
  showAttachmentDropdown: {
    type: Boolean,
    default: false
  },
  attachmentsDropdown: {
    type: Array,
    default: () => []
  },
  hiddenSections: {
    type: Array,
    default: () => []
  },
  hiddenAttributes: {
    type: Array,
    default: () => []
  },
  additionalData: {
    type: Object,
    default: () => ({})
  },
  hiddenTabs: {
    type: Array,
    default: () => []
  },
  canUseTimeSeriesData: {
    type: Boolean,
    default: false
  },
  timeSeriesData: {
    type: Array,
    default: () => []
  },
});

const emits = defineEmits([
  'closeEditor',
  'handleAttributeChange',
  'onChange:fieldValue',
  'onClickAddRelations',
  'onClickAddRelationWithExistingDatasetElement',
  'onClickCreateAsNewTypeName',
  'onClickTypeOption',
  'onClick:action',
  'onClick:dropdownElement',
  'onClick:veil',
  'handleTimeSeriesPoint'
]);

/** Data */
const isTitleError = ref(false);
const title = ref(props.datasetElement.attributes.title || '');
const displayDescriptionCounter = ref(false);
const datatypeType = ref(props.datasetElement.attributes.type || props.dataTypeName);
const driver = ref(props.datasetElement.attributes.driver || 'Sub-objective');
const ownerRef = ref(props.owner);
const timeseriesActualValueRef = ref(props.timeseriesActualValue);
const isAddParentVisible = ref(false);
const isAddChildrenVisible = ref(false);
const isAddDependenciesVisible = ref(false);
const inputs = reactive({});
const relationTypes = ref(RELATION_TYPES);
const keyTimeout = ref(null);
const tabs = ref(
  props.centralAttributesPanel
    ? Object.fromEntries(
      Object.entries(props.centralAttributesPanel)
        .filter(([key]) => !props.hiddenTabs.includes(key))
    )
    : {}
);
const isActiveTab = ref('general');
const selectedDataTypeName = ref(null);
const placeholder= ref('Unassigned');
const horizonValue= ref('');
const reloadAddRelationsKey = ref(0);
const timeSeriesFrequency = ref(props.datasetElement.attributes.timeSeriesFrequency);

onMounted(() => {
  updateInputs();
  keyTimeout.value = setTimeout(() => {
    document.addEventListener('keydown', handleCancelKey);
  }, 100);
});

onBeforeUnmount(()=> {
  document.removeEventListener('keydown', handleCancelKey);
  clearTimeout(keyTimeout.value);
});

const handleCancelKey = (e) => {
  if (e.key === 'Escape' && (!(isAddParentVisible.value || isAddChildrenVisible.value || isAddDependenciesVisible.value))) {
    handleCloseEditor();
  }
}
/** Computed */
const tabsClasses = (index) => {
  return {
    'IobEditor-leftAttributes-tabs--title': true,
    'IobEditor-leftAttributes-tabs--title-active': isActiveTab.value === index
  }
};

const currentTabAttributes = computed(() => {
  return tabs.value[isActiveTab.value];
});

const sections = computed(() => {
  return currentTabAttributes.value.sections.filter((section) => !props.hiddenSections.includes(section.name));
});

const editorWithTabs = computed(() => {
  return props.centralAttributesPanel && Object.keys(props.centralAttributesPanel).length > 1;
});

const getUser = (attributeName) => {
  if(attributeName === 'owner'){
    return ownerRef.value ? ownerRef.value : { firstname: '', lastname: '', picture: '' };
  }
  const userId = props.datasetElement.attributes[attributeName];
  if(!userId){
    return { firstname: '', lastname: '', picture: '' };
  }
  return props.users.find((user) => user.id === userId);
};

const titleAttribute = computed(() => {
  return props.dataTypeAttributes.find((attribute) => attribute.originRef === '/vms/title');
});

const computeProgress = computed(() => {
  return getProgressAttributes(props.dataTypeAttributes, props.datasetElement)
});
const findDataType = computed(() => {
  const filteredAttributes = props.mainPanelAttributes.filter((attribute) => attribute.name !== 'driver');
  return filteredAttributes.map((e) => {
    const foundAttribute = findMainPanelAttribute(e, props.dataTypeAttributes);
    const createdOn = props.datasetElement.createdOn ? generateDate(props.datasetElement.createdOn) : '';
    return {
      ...foundAttribute,
      componentType: e.componentType,
      showOn: e.showOn ? e.showOn : [],
      postDivider: e.postDivider ? e.postDivider : false,
      createdOn,
    };
  });
});

const previousFriendlyIdAttirubutes = computed(() => (
  (props.previousDatasetElement && Object.values(props.previousDatasetElement).length) ? `Back to ${props.previousDatasetElement.attributes['friendly-id']}`: null ));

const getDataTypeAttrType = (dataTypeAttrName) => {
  return props.dataTypeAttributes.find(attr => attr.name === dataTypeAttrName)?.type
};

const getEnum = (name) => {
  const attribute = props.dataTypeAttributes.find(el => el.name === name);
  return attribute.enum || []
}
const getSectionAttributes = (section, tab) => {
  const arr = props.centralAttributesPanel[tab].attributes.filter(attr => attr.section === section);
  return arr
}
const showOnMap = computed(() => {
  const showOnMap = {};
  props.mainPanelAttributes.forEach((attr) => {
    showOnMap[attr.name] = evaluateShowOn(attr, props.datasetElement.attributes);
  });
  const centralAttributes = Object.values(props.centralAttributesPanel).map(el => el.attributes).flat();
  centralAttributes.forEach((attr) => {
    showOnMap[attr.name] = evaluateShowOn(attr, props.datasetElement.attributes);
  });
  return showOnMap;
});

const inputDropdown = computed(() => (enumArray, attr = '', attrOptions = {}) => {
  if (!enumArray) return [];

  const enumItemsGroupId = getEnumItemGroupId(enumArray, attr, attrOptions);
  const uniqueItems = new Set(
    enumArray
      .filter(item => enumItemsGroupId === -1 || item.id === enumItemsGroupId)
      .map(item => getSpecificValue(item.value, attrOptions))
      .filter(Boolean)
  );

  return Array.from(uniqueItems);
});

const getEnumItemGroupId = (enumArray, attr = '', attrOptions = {}) => {
  const { attrValueSeparator, useSameAttrEnumItemId } = attrOptions;
  const attributeValue = props.datasetElement.attributes[attr];

  if (!useSameAttrEnumItemId || !attrValueSeparator || !attributeValue) return -1;

  const item = enumArray.find(item => item.value === attributeValue);

  return item?.id || -1;
};

const getSpecificValue = (value, attrOptions = {}) => {
  const { indexPartOfAttrValue = 0, attrValueSeparator } = attrOptions;
  if (attrValueSeparator) {
    const parts = value.split(attrValueSeparator);
    return parts[indexPartOfAttrValue] || value;
  }
  return value;
};

const changeDataTypeDropdown = computed(() => {
  const titleObject = { title: 'Change type', state: 'default', type: 'title' };
  const type = props.dataTypeAttributes.find(item => item.name === 'type');
  const typeEnum = type ? type.enum : [];
  if (typeEnum) {
    const typeObjects = typeEnum.map(item => ({
      text: `${item.value}`,
      state: 'default',
      iconName: `${item.value === datatypeType.value ? 'Check' : ''}`,
      iconSize: 'default',
      type: 'menu'
    }));

    return [titleObject, ...typeObjects];
  } else {
    return [];
  }
});

const getLinksDropdown = computed(() =>
  Object.values(props.eligibleRelationsDatatypes).map((item) => ({
    name: item.name,
    icon: getIcon(item.name, relationTypes.value[0]).icon,
  }))
);

const linksDropdown = computed(() => {
  return getLinksDropdown.value.map((item) => {
    return {
      text: item.name,
      state: 'default',
      iconName: item.icon,
      iconSize: 'default',
      type: 'menu',
      subItemslist: item.name === props.dataTypeName ? [
        {
          id:`add-parent-${item.name}`,
          text: `Add parent ${item.name}`,
          state: props.parent || (props.eligibleParentDataTypes && !props.eligibleParentDataTypes.length) ? 'disabled' : 'default',
          iconName: getIcon(item.name, relationTypes.value[0]).icon,
          iconSize: 'default',
          type: 'menu',
          action: 'addParent',
          level: 2
        },
        {
          id: `add-child-${item.name}`,
          text: `Add child-${item.name}`,
          state: 'default',
          iconName: getIcon(item.name, relationTypes.value[1]).icon,
          iconSize: 'default',
          type: 'menu',
          action: 'addChild',
          level: 2
        }
      ] : []
    };
  });
});

const titlePlaceholder = computed(() => {
  return `Add your ${datatypeType.value.length ? datatypeType.value : props.dataTypeName.toLowerCase()} title...`;
});

const dataTypeNameTitle = computed(() => {
  return `${datatypeType.value.length ? datatypeType.value : props.dataTypeName}`;
});

const horizonComputed = computed (() => horizonValue.value);
/** Methods */

const updateInputs = () => {
  Object.keys(props.datasetElement.attributes).forEach((attr) => {
    const value = props.datasetElement.attributes[attr];
    if (value && value.value) {
      inputs[attr] = value.value;
    } else {
      inputs[attr] = value === 0 ? '' : value;
    }
  });
};

const handleAttributeChange = (attribute, value, label = '', enumArray = [] , attrOptions = {}) => {
  const attrDataType = findAttribute(attribute);
  let attributeObject = {};
  if(attrDataType.name === 'driver'){
    driver.value = value.text;
    attributeObject = { attribute, value: value.text };
  }
  else if (attrDataType.name === 'type') {
    datatypeType.value = value.text;
    attributeObject = { attribute, value: value.text };
  }
  else if (attrDataType.name === 'horizon') {
    horizonValue.value = label;
    attributeObject = { attribute, value};
  }
  if(attrDataType.enum) {
    attributeObject = { attribute, value: value.text };
  }
  else {
    attributeObject = { attribute, value };
  }
  if (enumArray && attrOptions.attrValueSeparator) {
    const completeValue = enumArray.find(item => getSpecificValue(item.value, attrOptions) === value.text);
    timeSeriesFrequency.value = completeValue.value;
    attributeObject = { attribute, value: completeValue.value };
  }
  emits('handleAttributeChange', attributeObject);
};

const handleTimeSeriesPoint = (item) => {
  emits('handleTimeSeriesPoint', item);
};

const getInputType = (type) => {
  if (type === 'int') {
    return {
      inputType: 'number',
      isPositiveInt: true,
      isPositiveDouble: false
    };
  }
  if (type === 'double' || type === 'timestamped_indicator') {
    return {
      inputType: 'number',
      isPositiveInt: false,
      isPositiveDouble: true
    };
  }
  return { inputType: 'text' };
};

const getUnit = (attributes, summaryConfig) => {
  const {unitAttr, postfixType} = summaryConfig;
  if (attributes[postfixType] === '% Percentage') return '%'
  return attributes[unitAttr] || ''
}

const findAttribute = (attr) => {
  return props.dataTypeAttributes.find((el) => el.name === attr);
}

const evaluateShowOn = (dataTypeAttr, attributes) => {
  const { showOn } = dataTypeAttr;
  if(!showOn || showOn.length === 0){
    return true;
  }
  // get the attribute type
  const evaluateShowOnCondition = (showOn, attributes) => {
    const attr = findAttribute(showOn.attributeName);
    const type = attr.type;
    const value = attributes[showOn.attributeName];
    const { secondOperand, operator} = showOn;
    return showOnFns(value, secondOperand)[type][operator]();
  };
  for (let i=0; i < showOn.length ; i ++) {
    const result = evaluateShowOnCondition(showOn[i], attributes);
    if (result === false) {
      return false;
    }
  }
  return true;
};

const getAttributeValue = (attr, attrOptions = {}) => {
  if(!props.datasetElement.attributes[attr]){
    switch(attr){
    case 'status':
      return 'Not started';
    case 'priority':
      return 'High';
    case 'owner':
      return 'Unassigned';
    case 'escalation-level':
    case 'level':
      return UNASSIGNED;
    case 'driver':
      return 'Sub-objective';
    case 'effort':
      return 'Low';
    case 'stage':
      return 'Draft';
    default:
      return '';
    }
  }

  if (getDataTypeAttrType(attr) === 'level') {
    return getLevelAttributeValue(attr);
  }
  return getSpecificValue(props.datasetElement.attributes[attr], attrOptions);
}

const findLevelInTree = (levels, id) => {
  for (let level of levels) {
    if (level.id === id) {
      return level;
    }
    if (level.children) {
      const found = findLevelInTree(level.children, id);
      if (found) {
        return found;
      }
    }
  }
  return null;
};

const getLevelAttributeValue = (attr) => {
  const levelName = 'level-name';
  if (props.levels?.length) {
    const level = findLevelInTree(props.levels, props.datasetElement.attributes[attr]);
    if (level) {
      return {
        id: level.id,
        [levelName]: level.attributes[levelName],
        colors: level.attributes.colors || {}
      };
    }
  }
  return {
    [levelName]: UNASSIGNED
  };
};

const handleCloseEditor = () => {
  emits('closeEditor');
};

const handleTextInput = (attr, modelValue) => {
  const attributeType = findAttribute(attr);
  inputs[attr] = modelValue;
  if (attributeType && attr === 'title') {
    title.value = modelValue;
    isTitleError.value = false;
  }
  else if (attributeType && attr === 'description') {
    if (modelValue.length > SHOW_COUNTER_ON_LENGTH) {
      displayDescriptionCounter.value = true;
    }
    else
      displayDescriptionCounter.value = false;
  }
  const attributeObject = { attribute: attr, value: inputs[attr] };
  emits('handleAttributeChange', attributeObject);
  return modelValue;
}

const getIcon = (dataTypeName, hierarchy) => {
  if (!dataTypeName) {
    dataTypeName = props.dataTypeName;
  }
  return {
    icon: dataTypesIcons[dataTypeName]?.icons[hierarchy] || 'Target',
    color: dataTypesIcons[dataTypeName]?.color || 'primary'
  };
};

const handleActiveTab = (index) => {
  isActiveTab.value = index;
}

const hideAddRelations = (e) => {
  e.stopPropagation();
  isAddParentVisible.value = false;
  isAddChildrenVisible.value = false;
  isAddDependenciesVisible.value = false;
};

const displayAddRelationsViaIcon = ({ relation, dataTypeName }) => {
  selectedDataTypeName.value = dataTypeName;
  switch (relation) {
  case RELATION_TYPES[1]:
    isAddChildrenVisible.value = true;
    isAddDependenciesVisible.value = false;
    break;
  case RELATION_TYPES[2]:
    isAddDependenciesVisible.value = true;
    isAddChildrenVisible.value = false;
    break;
  }
  emits('onClickAddRelations', { relation, dataTypeName });
};

const displayAddRelationsSection = (item) => {
  reloadAddRelationsKey.value++;
  const isAddParent = item.id === `add-parent-${props.dataTypeName}`;
  const isAddChild = item.id === `add-child-${props.dataTypeName}`;
  isAddParentVisible.value = isAddParent;
  isAddChildrenVisible.value = isAddChild;

  let relationType;
  if (isAddParent) {
    relationType = RELATION_TYPES[0];
    selectedDataTypeName.value = props.dataTypeName;
    isAddDependenciesVisible.value = false;
  } else if (isAddChild) {
    relationType = RELATION_TYPES[1];
    selectedDataTypeName.value = props.dataTypeName;
    isAddDependenciesVisible.value = false;
  } else {
    relationType = RELATION_TYPES[2];
    selectedDataTypeName.value = item.text;
    isAddDependenciesVisible.value = true;
  }
  emits('onClickAddRelations', { relation: relationType, dataTypeName: selectedDataTypeName.value });
};

const createRelationWithExistingDatasetElement = ({relation, datasetElement, eligibleDatasetElementId}) => {
  if (relation === RELATION_TYPES[0]) {
    isAddParentVisible.value = false;
  }
  emits('onClickAddRelationWithExistingDatasetElement', {
    relation,
    datasetElement,
    eligibleDatasetElementId
  });
};

const createRelationWithNewDatasetElement = ({relation, body}) => {
  if (relation === RELATION_TYPES[0]) {
    isAddParentVisible.value = false;
  }
  emits('onClickCreateAsNewTypeName', {relation, body});
};

const handleDetailsIconClick = () => {
  isAddParentVisible.value = false;
  isAddChildrenVisible.value = false;
  isAddDependenciesVisible.value = false;
};

watch(
  () => props.datasetElement.attributes,
  () => {
    updateInputs();
  },
  { deep: true }
);

watch(
  () => props.owner,
  (newVal) => {
    ownerRef.value = newVal;
  },
  { deep: true }
);

watch(
  () => props.timeseriesActualValue,
  (newVal) => {
    timeseriesActualValueRef.value = newVal;
  },
  { deep: true }
);
</script>

<style lang="scss" src="./IobEditor.scss" scoped />
