<template>
  <div
    tabindex="0"
    :class="containerClasses"
    @click="onClickElement"
  >
    <div class="IobCheckListElement-left">
      <iob-avatar
        v-if="fieldType === 'avatar'"
        :picture="avatarPicture"
        :deleted-user="deletedUser"
        :size="iconSize"
      />
      <iob-avatar
        v-else-if="fieldType === 'initials'"
        :alt-text="altText"
        :color="initialsColor"
        :size="iconSize"
      />
      <icon-loader
        v-else-if="fieldType === 'icon'"
        id="icon"
        :name="iconName"
        :color="iconColor"
      />
      <icon-loader
        v-else-if="fieldType === 'icon-color'"
        has-background-color
        :name="iconName"
        :color="iconColor"
      />
      <iob-badge
        v-else-if="fieldType === 'badge'"
        :color="badgeColor"
        :icon="badgeIcon"
        :size="iconSize"
      />
      <iob-shape-color
        v-else-if="fieldType === 'shape-color'"
        :type="shapeType"
        :color="shapeColor"
      />
      <span
        v-if="label && label.length"
        :class="textClasses"
      >
        {{ label }}</span>
    </div>
    <icon-loader
      v-if="isClicked"
      class="IobCheckListElement-check"
      name="Check"
    />
  </div>
</template>

<script setup>
import { defineProps, computed, ref, defineEmits } from 'vue';
import IconLoader from '../../IconLoader/IconLoader.vue';
import IobAvatar from '../../Molecules/IobAvatar/IobAvatar.vue';
import IobBadge from '../../Atoms/IobBadge/IobBadge.vue';
import IobShapeColor from '../../Atoms/IobShapeColor/IobShapeColor.vue';

const props = defineProps({
  fieldType: { type: String, default: '' },
  iconName: { type: String, default: '' },
  avatarPicture: { type: String, default: '' },
  altText: { type: String, default: '' },
  deletedUser: { type: Boolean, default: false },
  initialsColor: { type: String, default: '' },
  label: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
  badgeColor: { type: String, default: '' },
  badgeIcon: { type: String, default: '' },
  iconColor: { type: String, default: '' },
  shapeType: { type: String, default: '' },
  shapeColor: { type: String, default: '' },
});

const emit = defineEmits(['onClick']);
const isClicked = ref(false);
const iconSize = ref('xsmall');

const onClickElement = (event) => {
  isClicked.value = !isClicked.value;
  emit('onClick', event);
};

const containerClasses = computed(() => {
  return `IobCheckListElement ${
    props.disabled && 'IobCheckListElement-disabled'
  } focus-outside`;
});

const textClasses = computed(() => {
  if (props.disabled) {
    return 'IobCheckListElement-disabled-text';
  }
  return 'IobCheckListElement-text';
});
</script>

<style lang="scss" scoped src="./IobCheckListElement.scss" />
