<template>
  <div>
    <div
      :class="globalStyles"
      @click="toggleMenu"
    >
      <div class="IobCombobox-container">
        <icon-loader
          v-if="showLeftIcon"
          :name="leftIcon.iconName"
          :type="leftIcon.type"
          :size="leftIcon.size"
        />
        <div
          v-if="text" 
          class="IobCombobox-container-text"
        >
          <span class="IobCombobox-container-text-span"> {{ text }}</span>
        </div>
        <slot />
      </div>
      <icon-loader
        v-if="showRightIcon"
        :name="rightIcon.iconName"
        :type="rightIcon.type"
        :size="rightIcon.size"
      />
    </div>
  </div>
</template>

<script setup>
import IconLoader from '../../IconLoader/IconLoader.vue';
import { defineProps, reactive, computed } from 'vue';

const props = defineProps({
  leftIcon: { type: Object, default: () => ({ iconName: 'Globe', type: 'default', size: 'default'})},
  showRightIcon: { type: Boolean, default: false},
  rightIcon: { type: Object, default: () => ({ iconName: 'ChevronDown', type: 'default', size: 'default'})},
  showLeftIcon: { type: Boolean, default: false},
  type: { type: String, default: 'outlined'},
  size: { type: String, default: 'meduim'},
  status: { type: String, default: ''},
  text: { type: String, default: ''},
  selectedItems: { type: [String, Array], default: () => [] },
  dropdownItems: { type: [String, Array], default: () => [] },
  singleSelectedItem: { type: Object, default: () => {} },
  maxDisplayedItems: { type: Number, default: 10 },
});

const state = reactive({
  sizeClassMap: {
    default: 'IobCombobox-default',
    meduim: 'IobCombobox-meduim',
    large: 'IobCombobox-large'
  },
  typeClassMap: {
    ghost: 'IobCombobox-ghost',
    outlined: 'IobCombobox-outlined'
  },
  statusClassMap: {
    disabled: 'IobCombobox-disabled',
    readOnly: 'IobCombobox-readOnly'
  }
});

const globalStyles = computed(() => {
  return [
    'IobCombobox',
    'focus-within',
    state.sizeClassMap[props.size],
    `${state.typeClassMap[props.type]}`,
    `${ props.status.length ? state.statusClassMap[props.status] : ''}`
  ].join(' ');
});

</script>
<style lang="scss" src="./IobCombobox.scss" scoped></style>
