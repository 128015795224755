<template>
  <button
    :class="globalStyles"
    :disabled="disabled"
    :style="`${timeframe ? 'height: var(--button-timeframe-size-height, 48px);' : buttonStyle}`"
  >
    <iob-circle-loader
      v-if="hasSpinner "
      :color="type ==='outlined' ? color : 'white'"
      :size="size"
    />
    <icon-loader
      v-if="showLeftIcon && leftIcon"
      :name="leftIcon"
      :color="iconColor"
      :stroke="iconColor"
      :size="leftIconSize ? leftIconSize : iconSize"
      class="IobButton-icon-loader"
    />
    <div
      class="IobButton-text"
      :style="customButtonText"
    >
      <span class="IobButton-text-container">
        <span
          v-if="(label && label.length > 0) && size !== 'small'"
          id="labelTag"
          class="IobButton-text-label"
        >
          {{ label }}
        </span>
      </span>
      <slot />
    </div>
    <icon-loader
      v-if="showRightIcon && rightIcon"
      :name="rightIcon"
      :color="iconColor"
      :stroke="iconColor"
      :size="rightIconSize && rightIconSize.length ? rightIconSize : iconSize"
      class="IobButton-icon-loader"
    />
  </button>
</template>

<script>
import IconLoader from '../../IconLoader/IconLoader.vue';
import IobCircleLoader from '../../Atoms/IobCirlceLoader/IobCircleLoader.vue';
export default {
  name: 'IobButton',
  components: {IobCircleLoader, IconLoader },
  props: {
    type: {
      type: String,
      default: 'filled',
    },
    color: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: 'default',
    },
    rightIconSize: {
      type: String,
      default: ''
    },
    leftIconSize: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    hasSpinner : {
      type: Boolean,
      default: false,
    },
    showLeftIcon: {
      type: Boolean,
      default: false,
    },
    showRightIcon: {
      type: Boolean,
      default: false,
    },
    leftIcon: {
      type: String,
      default: 'Check',
    },
    rightIcon: {
      type: String,
      default: 'RightArrow',
    },
    timeframe: {
      type: Boolean,
      default: false,
    },
    isFocused: {
      type: Boolean,
      default: false,
    },
    buttonStyle: {
      type: String,
      default: ''
    },
    customButtonText: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    sizeClassMap: {
      small: 'IobButton-small',
      default: 'IobButton-default',
      medium: 'IobButton-medium IobButton--text-medium',
      large: 'IobButton-large IobButton--text-large',
    },
    colorClassMap: {
      primary: 'IobButton-primary',
      secondary: 'IobButton-secondary',
      secondarySoft: 'IobButton-secondary-soft',
      black: 'IobButton-black',
      alert: 'IobButton-alert',
    },
    typeClassMap: {
      filled: 'filled',
      outlined: 'outlined',
      ghost: 'ghost',
      ghostAlternative: 'ghost-alternative',
    },
    roundedClassMap: {
      true: 'IobButton-rounded',
      false: '',
    },
    iconsClassMap: {
      label: 'IobButton-label',
      icon: 'IobButton-icon',
    }
  }),
  computed: {
    iconSize() {
      return this.size === 'medium' ? 'default' : this.size;
    },
    iconColor() {
      if(this.disabled)
        return `var(--button-color-text-${this.color}-${this.type}-disabled)`;
      return `var(--button-color-text-${this.color}-${this.type}-default)`;
    },
    formulateTypeClass() {
      if(this.selected && !this.disabled)
        return [this.colorClassMap[this.color], this.typeClassMap[this.type], 'pressed'].join('-');
      return [this.colorClassMap[this.color], this.typeClassMap[this.type]].join('-')
    },
    buttonType() {
      return (this.size === 'small' ||this.label === '')
        ? 'icon'
        : 'label';
    },
    globalStyles() {
      return [
        'IobButton',
        'focus-outside',
        'IobButton--transition',
        this.sizeClassMap[this.size],
        this.formulateTypeClass,
        this.roundedClassMap[this.rounded],
        this.iconsClassMap[this.buttonType],
        this.isFocused ? `${this.formulateTypeClass}-focused` : '',
      ].join(' ');
    }
  }
};
</script>

<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-button.scss" />
<style lang="scss" src="./IobButton.scss" scoped></style>
