import { API_URL } from 'SRC/config/api.js';
import store from 'SRC/store';
import AppMutations from 'SRC/store/app/mutations';
import CircuitBreaker from 'opossum';
import { makeRequest} from '../utils/CircuitBreaker';
import {vmsApiClient} from '@iobeya/common-tools';

function getUserHeader() {
  const user = store.getters['users/getCurrentUser'] || null;
  if (!user) {
    return;
  }
  return {
    'X-iObeya-VMS-User-Id': `${user.id}`
  };
}

function fetcher(method) {
  const fileFetch = (url, options, body) => {
    options.data = body;
    delete options.headers['Content-Type'];
    return vmsApiClient({url, ...options});
  };
  return async (url, body = null, toJson = true, isRelative = true, interrupts = false, isFile = false) => {
    const requestUrl = isRelative ? `${API_URL}${url}` : `${url}`;
    try {
      const options = {
        url: requestUrl,
        method,
        headers: {
          'Content-Type': 'application/json',
          'X-iObeya-VMS-Collab-Session': store.state.board.id,
          ...getUserHeader()
        },
        data: body ? JSON.stringify(body) : null
      };

      const response = isFile ? await fileFetch(requestUrl, options, body) : await vmsApiClient(options);

      if (response.status === 204) {
        return true;
      }

      if (toJson) {
        return response.data;
      }
      return true;
    } catch (error) {
      const exception = error?.error || error?.status >= 400
        ? new Exception(error.status, error.error, null, error.path)
        : new Exception(error.status, null, 'Something went wrong');

      if (interrupts) {
        store.state.app.exceptions.push(exception);
        AppMutations.setExceptions(store.state.app, store.state.app.exceptions);
      }

      throw exception;
    }
  };
}

class Exception {
  constructor(status, type, title, detail) {
    this.status = status;
    this.type = type;
    this.title = title;
    this.detail = detail;
  }
}

const userOptions = {
  errorThresholdPercentage: 50,
  resetTimeout: 5000,
  volumeThreshold: 2,
  timeout: 10000
};
const vmsOptions = {
  errorThresholdPercentage: 50,
  resetTimeout: 5000,
  volumeThreshold: 2,
  timeout: 20000
};
export const vmsCircuitBreaker = new CircuitBreaker(fetcher('GET'), vmsOptions);
export const userCircuitBreaker = new CircuitBreaker(fetcher('GET'), userOptions);
export const get = async (url, body, toJson, isRelative, circuitBreakerInfo = {}) => {
  const {isActivated = true, interrupts, isUsersAPI} = circuitBreakerInfo;

  if (!isActivated) {
    return fetcher('GET')(url, body, toJson, isRelative, circuitBreakerInfo = {});
  }
  if (isUsersAPI) {
    return makeRequest(userCircuitBreaker, url, body, toJson, isRelative, interrupts);
  }
  return makeRequest(vmsCircuitBreaker, url, body, toJson, isRelative, interrupts);
};

export const patch = fetcher('PATCH');
export const post = fetcher('POST');
export const put = fetcher('PUT');
export const del = fetcher('DELETE');

export default {
  get,
  patch,
  post,
  put,
  del
};
