<template>
  <div
    class="IobHiddenList"
    @mouseover="() => displayHiddenItems = true"
    @mouseleave="() => displayHiddenItems = false"
  >
    <div class="IobHiddenList-container">
      <span class="IobHiddenList-container-span">
        +{{ itemsNumber }}
      </span>
    </div>
    <iob-dropdown
      v-if="displayHiddenItems"
      class="IobHiddenList--dropdown"
      :items="hiddenItems"
      :is-scrollable="true"
      @click.stop
    />
  </div>
</template>

<script setup>
import IobDropdown from '../IobDropdown/IobDropdown.vue';

import { defineProps, ref, computed } from 'vue';

const props = defineProps({
  hiddenItems: {
    type: [String, Array],
    required: true
  },
});

const itemsNumber = computed(() => props.hiddenItems.length);
const displayHiddenItems = ref(true);
</script>

<style lang="scss" src="./IobHiddenList.scss" scoped />
