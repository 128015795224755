import { get, put } from 'SRC/api/index.js';
// import { FORMULA_API_URL } from 'SRC/config/api';
import { PAGE_SIZE, HIERARCHY } from 'GLOBALS/constants.js';
import utils from './utils';
import apiUtils from 'SRC/store/utils';
import formulasStatic from 'SRC/store/formulas.json';
import formulaUtils from 'SRC/store/app/utils';
import { LINKS_TYPES, DATA_TYPES_NAMES_SPACE, DATA_TYPES_NAMES } from 'GLOBALS/constants';
import { listViewType } from './listViewType';
import {API_HOST} from 'SRC/config/api';

const actions = {
  updateAttributeInParent({ rootState, commit }, elementId, attributes) {
    const parent = Object.values(rootState.board.datasetElements).find(
      (dsValue) =>
        dsValue.children &&
        dsValue.children.length > 0 &&
        dsValue.children.find((child) => child.id === elementId)
    );
    if (!parent) {
      return;
    }
    const datasetElements = {
      ...rootState.board.datasetElements,
      [parent.id]: {
        ...parent,
        children: parent.children.map((element) => {
          if ((element.id !== elementId)) {
            return element;
          }
          return {
            ...element,
            attributes: { ...element.attributes, ...attributes }
          };
        })
      }
    };
    commit('board/setDatasetElements', datasetElements, { root: true });
  },
  async getElementAttributesWithFormulaResult({ state, rootState, commit }, { datasetElement, computedAttributesByType }) {
    if (!datasetElement.children && !datasetElement.dependencies) {
      return {
        [datasetElement.id]: datasetElement
      };
    }
    const dataType = Object.values(state.dataTypes).find(
      (el) => el.id === datasetElement.typeId
    );
    const dataTypes = state.dataTypes;
    let formulaResult;
    const computedAttributes = computedAttributesByType[dataType.id];
    const kpiDataTypeId = Object.values(dataTypes).find((el) => el.name === 'KPI').id;
    for (const attr of computedAttributes) {
      if (state.formulas) {
        const formula = state.formulas[attr.computedByFormula];
        if (datasetElement.dependencies) {
          datasetElement.dependencies = datasetElement.dependencies.filter((dependency) => !(dependency.typeId === kpiDataTypeId
          && !dependency.attributes.targetValueComparator));
        }
        formulaResult = await utils.evaluateFormula(
          datasetElement,
          dataType,
          formula.expression,
          dataTypes
        );
        if ((typeof formulaResult === 'number' && formulaResult.toString() === 'NaN') || formulaResult instanceof Error) {
          formulaResult = 0;
        }
        if (typeof formulaResult === 'string') {
          formulaResult = formulaResult.replace('%', '');
        }
        formulaResult = parseFloat((Math.round(formulaResult * 100) / 100).toFixed(2)
          .toString().replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, ''));
        const ElementAttributes = rootState.board.datasetElements[rootState.editor.datasetElementId].attributes || {};
        const newAttributes = { ...ElementAttributes, [attr.name]: formulaResult };
        commit('board/updateDatasetElement', {
          datasetElementId: rootState.editor.datasetElementId,
          updates: { attributes: newAttributes }
        }, { root: true });
      }
    }
  },
  async addFormulaResultToDatasetElement({ state, rootState, dispatch }) {
    const { datasetElementId } = rootState.editor;
    const datasetElement = rootState.board.datasetElements[datasetElementId];
    if (rootState.hierarchy.dependencies && Object.keys(rootState.hierarchy.dependencies).length > 0) {
      datasetElement.dependencies = Object.values(rootState.hierarchy.dependencies)
        .reduce((acc, val) => acc.concat(val.map((item) => item.dependency)), []);
    }
    if (rootState.hierarchy.children && rootState.hierarchy.children.length > 0) {
      datasetElement.children = Object.values(rootState.hierarchy.children).reduce((acc, val) => acc.concat(val), []);
    }
    const computedAttributesByType = Object.values(state.dataTypes).reduce(
      (acc, { attributes, id }) => ({
        ...acc,
        [id]: attributes.filter((attr) => attr.computedByFormula)
      }),
      {}
    );
    await dispatch('getElementAttributesWithFormulaResult', { datasetElement, computedAttributesByType });
  },
  async fetchDatasetElements(_, {dataTypeIds = [], ofAllPages = false}) {
    let response;
    if (dataTypeIds.length > 0) {
      const query = dataTypeIds.map((id) => `dataTypeIds=${id}`).join('&');
      response = await get(`/dataset-elements?${query}&page=1&size=${PAGE_SIZE}`);
    }
    if (!response) {
      return { error: 'Error occurred in fetchDatasetElements' };
    }
    const elements = [...response.data];
    if (ofAllPages) {
      let nextPage = response.next;
      while (nextPage) {
        response = await get(nextPage, null, true, false);
        if (!response) {
          return {error: `Error occurred in fetchDatasetElements of page <${nextPage}>`};
        }
        elements.push(...response.data);
        nextPage = response.next;
      }
    }
    return elements;
  },
  async fetchViewTypes(context) {
    const elementViewTypes = await get(
      `/element-view-types?page=1&size=${PAGE_SIZE}`
    );
    if (elementViewTypes && elementViewTypes.data) {
      let elementViewTypesData = elementViewTypes.data;
      elementViewTypesData = await apiUtils.getNextPage(
        elementViewTypes,
        elementViewTypesData,
        get
      );
      const viewTypes = {};
      elementViewTypesData.map((el) => el.toolType === 'list' ? listViewType : el).forEach(
        ({ id, layouts, toolType, dataTypeId, dataTypeName, iconUri, hidden, config, resizable }) => {
          const contextualMenuItems = config?.contextualMenu || [];
          if (toolType === 'webcontent') {
            contextualMenuItems.map((item) => {
              if (item.dropdownTitle === 'zoomDropdownTitle') {
                item.list = [
                  {
                    text: 'Zoom In',
                    action: 'zoomIn',
                    value: null,
                    type: 'menu'
                  },
                  {
                    text: 'Zoom Out',
                    action: 'zoomOut',
                    value: null,
                    type: 'menu'
                  },
                  {
                    text: 'Zoom to 50%',
                    action: 'zoomIn',
                    value: 50,
                    type: 'menu'
                  },
                  {
                    text: 'Zoom to 100%',
                    action: 'zoomIn',
                    value: 100,
                    type: 'menu'
                  },
                  {
                    text: 'Zoom to 200%',
                    action: 'zoomIn',
                    value: 200,
                    type: 'menu'
                  }
                ];
              }
            });
          }
          if (toolType === 'card' || toolType === 'note') {
            contextualMenuItems.map((item) => {
              if (item.dropdownTitle === 'sizeDropdownTitle') {
                item.list = [
                  { text: 'S', type: 'menu' },
                  { text: 'M', type: 'menu' },
                  { text: 'L', type: 'menu' },
                  { text: 'XL', type: 'menu' }
                ];
              }
            });
          }
          viewTypes[id] = {
            dataTypeId,
            dataTypeName,
            layouts,
            toolType,
            iconUri,
            hidden,
            contextualMenuItems,
            resizable,
            ...(config?.sizeLayoutMap && { sizeMapLayout: config.sizeLayoutMap }),
            ...(config?.resize ? { resize: config.resize } : {})
          };
        }
      );
      context.commit('setViewTypes', viewTypes);
      return elementViewTypes;
    }
    return { error: 'Error occured in fetchViewTypes' };
  },
  async fetchDataTypes(context) {
    const elementDataTypes = await get(
      `/element-data-types?page=1&size=${PAGE_SIZE}`
    );
    if (elementDataTypes && elementDataTypes.data) {
      let elementDataTypesData = elementDataTypes.data;
      elementDataTypesData = await apiUtils.getNextPage(
        elementDataTypes,
        elementDataTypesData,
        get
      );
      const newCardIndex = utils.generateNewCardIndex(elementDataTypesData);
      const dataTypes = elementDataTypesData.reduce((acc, dataType) => {
        acc[dataType.id] = dataType;
        return acc;
      }, {});
      const systemDataTypes = {};
      elementDataTypesData.forEach((dataType) => {
        if (dataType.namespace === DATA_TYPES_NAMES_SPACE.SYSTEM) {
          systemDataTypes[dataType.id] = dataType;
        }
      });
      context.commit('setDataTypes', dataTypes);
      context.commit('setSystemDataTypes', systemDataTypes);
      await context.dispatch('fetchLevels');
      await context.dispatch('fetchLevelsTree');
      if (newCardIndex > 0) {
        context.commit('toolsConfig/setCardName', `NewCard(${newCardIndex})`, { root: true });
      }
      return elementDataTypes;
    }
    return { error: 'Error occured in fetchDataTypes' };
  },
  async fetchLayouts(context) {
    const layouts = await get(
      `/element-view-types/layouts?page=1&size=${PAGE_SIZE}`
    );
    if (layouts && layouts.data) {
      let layoutsData = layouts.data;
      layoutsData = await apiUtils.getNextPage(
        layouts,
        layoutsData,
        get,
        true
      );
      layoutsData = layoutsData.map((e) => ({ id: e.id, ...e.layouts.find((el) => el.levelOfDetail === 0) }));
      if (layoutsData.length > 0) {
        context.commit('setLayouts', layoutsData);
        context.commit('toolsConfig/setSelectedLayout', layoutsData[0].id, { root: true });
      }
    }
  },
  async fetchFormulas({ commit }) {
    // const dataTypesValues = Object.values(state.dataTypes);
    // if (dataTypesValues && dataTypesValues.length > 0) {
    //   let formulasIds = [];
    //   const FormulasDatatypes = {};
    //   dataTypesValues.forEach((dataType) => {
    //     dataType.attributes
    //       .filter((attribute) => attribute.computedByFormula)
    //       .map((el) => ({ name: el.name, id: el.computedByFormula }))
    //       .forEach(async ({ id }) => {
    //         formulasIds = [...formulasIds, id];
    //         FormulasDatatypes[id] = dataType.name;
    //       });
    //   });

    //   const maxSize = 100;
    //   // let formulas = {};
    //   const formulasIdsArrayOfArrays = [];
    //   for (let i = 0; i < formulasIds.length; i += maxSize) {
    //     formulasIdsArrayOfArrays.push(formulasIds.slice(i, i + maxSize));
    //   }
    //   const queries = formulasIdsArrayOfArrays.map((arrayOfIds) => (
    //     utils.constructFormulasQuery(arrayOfIds)
    //   ));
    // const results = await get(`${FORMULA_API_URL}/formulas?${queries}`, null, true, false,
    //   {isUsersAPI: false, isActivated: false, interrupts: false });
    // const newFormulas = utils.setFormulas(results.data, FormulasDatatypes);
    // formulas = {...formulas, ...newFormulas};
    // const results = utils.formatRawResultsArray(rawResults);
    const rawResults = formulasStatic;
    const results = rawResults.data;
    const formulas = formulaUtils.setFormulas(results);

    commit('setFormulas', formulas);
  },
  async updateViewTypeHiddenFlag({ state, commit }, { viewTypeId, hidden }) {
    const hiddenFlag = await put(`/element-view-types/${viewTypeId}/hidden`, hidden);
    const viewTypes = state.viewTypes;
    Object.keys(viewTypes).forEach(
      (id) => {
        if (id === viewTypeId) {
          viewTypes[viewTypeId].hidden = hiddenFlag;
        }
      }
    );
    commit('setViewTypes', viewTypes);
  },
  async fetchRelationTypes({ commit }) {
    const response = await get('/relation-types');
    if (response && response.data && response.data.length) {
      const relationTypes = response.data;
      relationTypes.forEach((relationType) => {
        relationType.label = relationType.name;
        relationType.type = LINKS_TYPES[relationType.kind];
      });
      commit('setRelationTypes', relationTypes);
    }
  },
  async fetchLevels({ state, commit, dispatch }) {
    const levelDataType = Object.values(state.systemDatatypes).find((element) => element.name === DATA_TYPES_NAMES.LEVEL);
    if (levelDataType) {
      const levelDataTypesId = levelDataType.id;
      const levels = await dispatch('fetchDatasetElements', {dataTypeIds: [levelDataTypesId], ofAllPages: true});
      commit('setLevels', levels);
    }
  },
  async buildHierarchyRelationsCommon({ state, dispatch }, {levelsIds, callback}) {
    if (!levelsIds.length) {
      return;
    }
    const data = await dispatch('hierarchy/fetchRelations', levelsIds, { root: true });
    const elementsById = utils.buildElementsById(data);
    const rootElement = Object.values(state.levels).find((element) => !elementsById[element.id]);
    const rootElementClone = utils.createRootElementClone(rootElement, elementsById);

    utils.buildChildren(data.datasetElementsWithChildren, elementsById)
      .reduce((elementsByIdAcc, relation) => {
        elementsByIdAcc[relation.datasetElementId] = elementsByIdAcc[relation.datasetElementId] || { id: relation.datasetElementId, children: [] };
        elementsByIdAcc[relation.datasetElementId].children.push(...relation.children.filter(Boolean));
        return elementsByIdAcc;
      }, elementsById);

    return callback(elementsById, rootElementClone);
  },
  async fetchLevelsTree({ state, dispatch, commit }) {
    const levelsIds = Object.values(state.levels).map((level) => level.id);
    const callback = (elementsById, rootElementClone) => [elementsById[rootElementClone.id]];
    const levelsTree = await dispatch('buildHierarchyRelationsCommon', {levelsIds, callback});
    if (!levelsTree) {
      return;
    }

    levelsTree.forEach((level) => {
      utils.initializeHierarchyColors(level, HIERARCHY.LEVELS_COLORS);
    });
    commit('setLevelsTree', levelsTree);
  },
  async fetchFeaturesList({ commit }) {
    const features = await get(`${API_HOST}/actuator/features`, null, true, false);
    if (features && features.length) {
      commit('setFeaturesList', features);
    }
  }
};

export default actions;
