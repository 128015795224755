<template>
  <div class="IobDialogBox-modal-overlay level-container level-3">
    <div
      :style="dialogBoxStyle"
      class="IobDialogBox-modal-wrapper"
    >
      <div class="IobDialogBox-confirmation-text">
        <div>
          {{ title }}
        </div>
        <div class="IobDialogBox-confirmation-text-disclaimer">
          {{ content }}
        </div>
      </div>
      <div class="IobDialogBox-confirmation-buttons">
        <iob-button
          :label="submitAction"
          size="medium"
          color="alert"
          type="filled"
          @click="$emit('submitAction')"
        />
        <iob-button
          v-if="hasCancelAction"
          :label="cancelAction"
          type="filled"
          size="medium"
          color="secondarySoft"
          @click="$emit('cancelAction')"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import IobButton from '../IobButton/IobButton.vue';

defineProps({
  title: {
    type: String,
    required: true
  },
  content: {
    type: String,
    default: '',
  },
  submitAction: {
    type: String,
    required: true
  },
  cancelAction: {
    type: String,
    default: ''
  },
  hasCancelAction: {
    type: Boolean,
    default: true
  },
  dialogBoxStyle: {
    type: String,
    default: 'max-width: 500px; max-height: 300px; overflow-y: auto;'
  }
});

</script>

<style lang="scss" scoped src="./IobDialogBox.scss" />