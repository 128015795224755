<template>
  <div
    class="IobImage"
    :class="aspectRatioClasses"
  >
    <div class="img-container">
      <img
        v-if="src"
        :src="src"
        class="IobImage-preview"
        alt=""
      >
      <iob-skeleton
        v-else
        width="100%"
        height="100%"
      />
    </div>
  </div>
</template>
<script setup>
import {computed} from 'vue';
import IobSkeleton from '../IobSkeleton/IobSkeleton.vue';

const props = defineProps({
  src: {
    type: String,
    required: true
  },
  renderType: {
    type: String,
    default: '16:9',
    validator: (value) => ['fit-to-box', 'fit-to-img', '1:1', '16:9', '4:3', '21:9', 'golden'].includes(value)
  },
  orientation: {
    type: String,
    default: 'landscape',
    validator: (value) => ['portrait', 'landscape'].includes(value)
  }
});

const ratios = ['1:1', '16:9', '4:3', '21:9', 'golden', 'fit-to-box', 'fit-to-img'];
const aspectRatioClasses = computed(() => ratios.includes(props.renderType)
  ? [
    'ratio',
    `ratio--${props.renderType.replace(':', '-')}`,
    'orientation',
    `orientation--${props.orientation}`
  ]
  : [props.renderType]
);

</script>
<style lang="scss" src="./IobImage.scss" scoped />
