<template>
  <iob-side-editor
    elevation="3"
    size="medium"
    :title="$t('embed.content.editor.title')"
    @close="closeEditor"
  >
    <template #content>
      <div class="form-group">
        <div class="form-group-label">
          <label for="board-name">{{ $t('embed.content.editor.label') }}</label>
        </div>
        <iob-label-field
          :model-value="url"
          :placeholder="$t('embed.editor.urlPlaceholder') "
          has-clear-button
          :has-error="!urlIsValid"
          @update:modelValue="(value) => handleChange('url', value.trim())"
        />
        <div class="form-group-message">
          <IconLoader
            v-if="urlIsValid"
            name="AlertCircle"
            color="#2D6BFF"
            size="xsmall"
          />
          <span :class="urlIsValid ? 'form-group-warning-text' : 'form-group-error-text'">
            {{ urlIsValid ? t('embed.content.editor.warning') : t('embed.content.editor.error') }}
          </span>
        </div>
      </div>
    </template>
  </iob-side-editor>
</template>

<script setup>
import { ref, computed, onMounted, defineEmits } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { FEATURES} from 'GLOBALS/constants.js';
import { isFeatureEnabled, isValidUrl} from 'SRC/utils/utils';
import { parseEmbed, sanitizeUrl } from 'BOARD/components/Embed/utils/embed-parser';
import { getViewTypeId } from 'BOARD/components/Embed/utils/embed-utils';
import {getElementId} from './utils/utils';
const store = useStore();
const { t } = useI18n();
const urlIsValid = ref(true);
const datasetElementId = computed(() => store.state.editor.datasetElementId);
const datasetElement = computed(() => store.state.board.datasetElements[datasetElementId.value]);
const url = computed(() => datasetElement.value ? datasetElement.value.attributes.url : '');
const isWebContentEnabled = () => isFeatureEnabled(store.state.app.featuresList, FEATURES.WEB_CONTENT);
const viewTypesList = computed(() => store.state.app.viewTypes);
const elements = computed(() => store.state.board.elements);

onMounted(() => {
  handleChange('url', url.value.trim());
});
const emit = defineEmits(['close']);
const closeEditor = () => {
  emit('close');
};
const handleChange = (name, url) => {

  let parsedData = isWebContentEnabled() ? parseEmbed(url) : { type: 'powerbi', url };
  urlIsValid.value = isValidUrl(parsedData.url);
  if (!urlIsValid.value) {
    parsedData = { type: 'webcontent', url };
  }
  const sanitizedUrl = sanitizeUrl(parsedData.url);

  store.dispatch('board/updateDatasetElementAttributes', {
    datasetElementId: datasetElementId.value,
    attributes: {
      ...datasetElement.value.attributes,
      [name]: sanitizedUrl
    }
  });
  // Change the view type to web content if the url is an iframe or url
  const embedViewTypeId = getViewTypeId(viewTypesList.value, parsedData.type);
  store.commit('board/updateBoardElement',
    {elementId: getElementId(elements.value, datasetElementId.value),
      updates: {viewTypeId: embedViewTypeId}});
};

defineExpose({
  handleChange,
  sanitizeUrl
});

</script>

<style lang="scss" scoped src="./EmbedEditor.scss" />
