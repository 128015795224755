import { MUTATION_TYPES } from 'GLOBALS/constants.js';
import { getUserClickedElementEvent } from 'GLOBALS/events.js';

export class ClickHandlersByTooltype {
  #tooltype = '';
  #elementId = '';
  constructor(tooltype, elementId) {
    this.tooltype = tooltype;
    this.elementId = elementId;
  }
  get elementId() {
    return this.#elementId;
  }
  set elementId(value) {
    this.#elementId = value;
  }
  get tooltype() {
    return this.#tooltype;
  }
  set tooltype(value) {
    this.#tooltype = value;
  }
  handleSelection({board, event}) {
    if (!event.shiftKey && board.isEditorOpen && this.elementId !== board.lastBoardElementIdInEdition) {
      document.dispatchEvent(getUserClickedElementEvent({ elementId: this.elementId }));
    }

    if (board.formulaInEditionBoardElementId) {
      board.removeFormulaFromSelection(board.formulaInEditionBoardElementId);
    }
    const shouldAppend = (event.shiftKey || event.ctrlKey) && !board.userSelectedElements.includes(this.elementId);
    const mutationType = shouldAppend ? MUTATION_TYPES.APPEND_TO_PREVIOUS : MUTATION_TYPES.ERASE_PREVIOUS;
    board.selectBoardElements({ boardElementsIds: [this.elementId], mutationType });
  }
  handleButtonClick({board, event}) {
    const linkElement = event.target.closest('[data-link]');
    if (linkElement) {
      return window.open(linkElement.getAttribute('data-link'), '_blank');
    }

    return { board, event };
  }
  handleListClick({board, event}) {
    const listItemElement = event.target.closest('[data-list-item-id]');
    if (listItemElement) {
      const itemListId = listItemElement.getAttribute('data-list-item-id');
      board.openEditor({ datasetElementId: itemListId, boardElementId: this.elementId });
    } else {
      this.handleSelection({board, event});
    }
  }
  #actionPerTooltype = {
    card: this.handleSelection,
    indicator: this.handleSelection,
    button: this.handleButtonClick,
    list: this.handleListClick,
    image: this.handleSelection
  };

  run({board, event}) {
    if (this.#actionPerTooltype[this.tooltype]) {
      const action = this.#actionPerTooltype[this.tooltype].bind(this);
      return action({board, event});
    }
    this.handleSelection({board, event});
  }
}
