import { FORMULA_API_URL } from 'SRC/config/api';
import { post, get } from 'SRC/api/index.js';
import getInitialState from './initialState';
import utils from '../app/utils';

const actions = {
  async addFormula(_empty, formula) {
    const response = await post(`${FORMULA_API_URL}/formulas`, formula, true, false);
    if (response && response.id) {
      return response.id;
    }
  },
  async fetchNumberFormats({commit}) {
    const response = await get('/number-format-definitions');
    if (response && response.data) {
      commit('setNumberFormats', response.data);
      return response;
    }
    return { error: 'Error occured in fetchNumberFormats'};
  },
  resetState({commit, rootState, state}, initialLayoutId) {
    const initialState = getInitialState();
    const numberFormats = state.numberFormats;
    const dataTypes = Object.values(rootState.app.dataTypes);
    const newCardIndex = utils.generateNewCardIndex(dataTypes);
    const cardName = newCardIndex === 0 ? 'NewCard' : `NewCard(${newCardIndex})`;
    const newState = Object.assign({}, initialState, {
      selectedLayout: initialLayoutId,
      numberFormats,
      cardName
    });
    commit('resetState', newState);
  }
};

export default actions;
