import dayjs from 'dayjs';
import {
  POINT_FREQUENCIES,
  splitFrequency,
  standardizeFrequency,
  TIME_SERIES_FREQUENCIES
} from 'SRC/board/utils/timeSeriesUtils';

class DateGenerator {
  constructor(frequency, startDate, endDate) {
    this.frequency = frequency;
    this.startDate = dayjs(startDate);
    this.endDate = dayjs(endDate);
  }

  /**
   * Generates a list of dates based on the selected frequency.
   */
  generateDates() {
    const dates = [];
    let currentDate = this.startDate;

    // Generate dates up to the end date
    while (currentDate.isBefore(this.endDate) || currentDate.isSame(this.endDate, 'day')) {
      if (this.isDateMatchingFrequency(currentDate)) {
        dates.push(currentDate.format('YYYY-MM-DD')); // Format as 'YYYY-MM-DD' or any required format
      }
      currentDate = currentDate.add(1, 'day'); // Move forward by one day
    }

    return dates;
  }

  /**
   * Checks if a date matches the selected frequency.
   */
  isDateMatchingFrequency(date) {
    if (!this.frequency) {
      return false;
    }

    const frequencyType = this.getFrequencyType();
    const handler = this.frequencyHandlers[frequencyType] || this.defaultFrequencyHandler;

    const frequency = this.getFrequency();
    return handler(date, frequency);
  }

  getFrequencyType() {
    const type = splitFrequency(this.frequency).frequency;
    return standardizeFrequency(type);
  }

  getFrequency() {
    const frequency = splitFrequency(this.frequency).pointFrequency;
    return standardizeFrequency(frequency);
  }

  /**
   * Frequency handlers.
   */
  frequencyHandlers = {
    [standardizeFrequency(TIME_SERIES_FREQUENCIES.WEEKLY)]: (date, frequency) => this.matchWeeklyFrequency(date, frequency),
    [standardizeFrequency(TIME_SERIES_FREQUENCIES.MONTHLY)]: (date, frequency) => this.matchMonthlyFrequency(date, frequency),
    [standardizeFrequency(TIME_SERIES_FREQUENCIES.QUARTERLY)]: (date, frequency) => this.matchQuarterlyFrequency(date, frequency),
    [standardizeFrequency(TIME_SERIES_FREQUENCIES.BIANNUAL)]: (date, frequency) => this.matchBiannuallyFrequency(date, frequency),
    [standardizeFrequency(TIME_SERIES_FREQUENCIES.YEARLY)]: (date, frequency) => this.matchYearlyFrequency(date, frequency),
    [standardizeFrequency(TIME_SERIES_FREQUENCIES.DAILY)]: () => true
  };

  /**
   * Match weekly frequencies.
   */
  matchWeeklyFrequency(date, frequency) {
    const dayMap = {
      [standardizeFrequency(POINT_FREQUENCIES.WEEKLY.EVERY_MONDAY)]: 1,
      [standardizeFrequency(POINT_FREQUENCIES.WEEKLY.EVERY_TUESDAY)]: 2,
      [standardizeFrequency(POINT_FREQUENCIES.WEEKLY.EVERY_WEDNESDAY)]: 3,
      [standardizeFrequency(POINT_FREQUENCIES.WEEKLY.EVERY_THURSDAY)]: 4,
      [standardizeFrequency(POINT_FREQUENCIES.WEEKLY.EVERY_FRIDAY)]: 5,
      [standardizeFrequency(POINT_FREQUENCIES.WEEKLY.EVERY_SATURDAY)]: 6,
      [standardizeFrequency(POINT_FREQUENCIES.WEEKLY.EVERY_SUNDAY)]: 0
    };
    return date.day() === dayMap[frequency];
  }

  /**
   * Match monthly frequencies.
   */
  matchMonthlyFrequency(date, frequency) {
    switch (frequency) {
    case standardizeFrequency(POINT_FREQUENCIES.MONTHLY.FIRST_DAY):
      return date.date() === 1;
    case standardizeFrequency(POINT_FREQUENCIES.MONTHLY.MID_MONTH):
      return date.date() === 15;
    case standardizeFrequency(POINT_FREQUENCIES.MONTHLY.LAST_DAY):
      return date.isSame(date.endOf('month'), 'day');
    case standardizeFrequency(POINT_FREQUENCIES.MONTHLY.FIRST_MONDAY):
      return date.date() <= 7 && date.day() === 1;
    case standardizeFrequency(POINT_FREQUENCIES.MONTHLY.LAST_FRIDAY):
      return date.day() === 5 && date.isSame(date.endOf('month').day(5), 'day');
    default:
      return false;
    }
  }

  /**
   * Match quarterly frequencies.
   */
  matchQuarterlyFrequency(date, frequency) {
    switch (frequency) {
    case standardizeFrequency(POINT_FREQUENCIES.QUARTERLY.FIRST_DAY):
      return date.isSame(date.startOf('quarter'), 'day');
    case standardizeFrequency(POINT_FREQUENCIES.QUARTERLY.LAST_DAY):
      return date.isSame(date.endOf('quarter'), 'day');
    case standardizeFrequency(POINT_FREQUENCIES.QUARTERLY.FIRST_MONDAY):
      return (
        date.date() <= 7 &&
          date.day() === 1 &&
          date.isSame(date.startOf('quarter'), 'month')
      );
    case standardizeFrequency(POINT_FREQUENCIES.QUARTERLY.LAST_FRIDAY):
      return date.day() === 5 && date.isSame(date.endOf('quarter').day(5), 'day');
    default:
      return false;
    }
  }

  /**
   * Match biannual frequencies.
   */
  matchBiannuallyFrequency(date, frequency) {
    switch (frequency) {
    case standardizeFrequency(POINT_FREQUENCIES.BIANNUALLY.FIRST_DAY):
      return date.isSame(date.startOf('year').add(date.month() < 6 ? 0 : 6, 'month'), 'day');
    case standardizeFrequency(POINT_FREQUENCIES.BIANNUALLY.LAST_DAY):
      return date.isSame(
        date.startOf('year').add(date.month() < 6 ? 5 : 11, 'month').endOf('month'),
        'day'
      );
    case standardizeFrequency(POINT_FREQUENCIES.BIANNUALLY.FIRST_MONDAY):
      return date.date() <= 7 && date.day() === 1 && (date.month() % 6 === 0);
    case standardizeFrequency(POINT_FREQUENCIES.BIANNUALLY.LAST_FRIDAY):
      return date.day() === 5 && date.isSame(date.endOf('year').month(date.month() < 6 ? 5 : 11).day(5), 'day');
    default:
      return false;
    }
  }

  /**
   * Match yearly frequencies.
   */
  matchYearlyFrequency(date, frequency) {
    switch (frequency) {
    case standardizeFrequency(POINT_FREQUENCIES.YEARLY.FIRST_DAY):
      return date.isSame(date.startOf('year'), 'day');
    case standardizeFrequency(POINT_FREQUENCIES.YEARLY.LAST_DAY):
      return date.isSame(date.endOf('year'), 'day');
    case standardizeFrequency(POINT_FREQUENCIES.YEARLY.FIRST_MONDAY):
      return date.date() <= 7 && date.day() === 1;
    case standardizeFrequency(POINT_FREQUENCIES.YEARLY.LAST_FRIDAY):
      return date.day() === 5 && date.isSame(date.endOf('year').day(5), 'day');
    default:
      return false;
    }
  }

  /**
   * Generates a date for each data point at the given frequency.
   */
  defaultFrequencyHandler() {
    return false; // By default, matches no date.
  }
}

export default DateGenerator;
