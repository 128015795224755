<template>
  <div class="IobEditorAddRelations">
    <div style="height: fit-content;">
      <iob-dropdown-select-badge
        v-if="selectedDataTypeTypes && selectedDataTypeTypes.length"
        :id="typesSelectSectionId"
        :label="selectedType"
        title="Change type"
        :data="selectedDataTypeTypes"
        @onClickDropdownSelectBadge="(item) => getSelectedTypeOption(item)"
        @on-outside-click="({ isOpened })=> isTypeDropdownOpened = isOpened"
      />
    </div>
    <outside-click-listener
      style="width: 100%;"
      key-support="Escape"
      @outside-click="handleClickOutsideAddRelations"
    >
      <iob-dropdown-search-select
        :dropdown-menu-button-label="createRelationWithNewDatasetElementButtonLabel"
        :dropdown-menu-items="eligibleRelationsWithTitle"
        :sliced-data="true"
        :search-input="searchValue"
        :placeholder="relationsPlaceholder"
        @update:modelValue="(value) => emits('update:modelValue', value)"
        @onClickButton="handleCreateRelationWithNewDatasetElement"
        @onClickDropdownSearchSelectMenu="({ item }) => handleCreateRelationWithExistingDatasetElement(item)"
      />
    </outside-click-listener>
  </div>
</template>

<script setup>
import { computed, defineProps, ref, defineEmits } from 'vue';
import { RELATION_TYPES } from '../../../../../constants';
import IobDropdownSearchSelect from '../../../../Organisms/IobDropdownSearchSelect/IobDropdownSearchSelect.vue';
import IobDropdownSelectBadge from '../../../../Organisms/IobDropdownSelectBadge/IobDropdownSelectBadge.vue';
import OutsideClickListener from '../../../../OutsideClickListener/OutsideClickListener.vue';
import { generateUniqueId } from '../../../../../utils';
import { formatRelationsData, addTitleElementToRelationsData } from '../editor-utils-relations';

/** Data */

const relationTypes = ref(RELATION_TYPES);
const typesSelectSectionId = ref(generateUniqueId());
const isTypeDropdownOpened = ref(false);

/** Props */

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  searchValue: {
    type: String,
    default: '',
  },
  eligibleChildren: {
    type: [Array, String],
    default: () => [],
  },
  eligibleParents: {
    type: [Array, String],
    default: () => [],
  },
  eligibleDependencies: {
    type: [Array, String],
    default: () => [],
  },
  dataTypeName: {
    type: String,
    default: '',
  },
  selectedDataTypeTypes: {
    type: Array,
    default: () => [],
  },
  selectedType: {
    type: String, //yes
    default: null,
  },
  selectedDataTypeName: {
    type: String,
    default: null
  },
  isAddParentVisible: {
    type: Boolean,
    default: false,
  },
  isAddChildrenVisible: {
    type: Boolean,
    default: false,
  }
});

/** Emits */

const emits = defineEmits([
  'onClickCreateAsNewTypeName',
  'onClickAddRelationWithExistingDatasetElement',
  'onClickTypeOption',
  'onClickOutsideAddRelations',
  'update:modelValue'
]);

/** Computed */

const eligibleRelationsWithTitle = computed(() =>
  addTitleElementToRelationsData(formattedEligibleRelations.value, props.selectedType || props.selectedDataTypeName)
);

const formattedEligibleRelations = computed(() => {
  if (props.isAddChildrenVisible) {
    return formatRelationsData(props.eligibleChildren);
  } else if (props.isAddParentVisible) {
    return formatRelationsData(props.eligibleParents);
  }
  return formatRelationsData(props.eligibleDependencies);
});

const relationsPlaceholder = computed(() => {
  return `Search or create ${props.selectedType || props.selectedDataTypeName}`;
});

const relationType = computed(() => {
  if (props.isAddChildrenVisible) {
    return relationTypes.value[1];
  } else if (props.isAddParentVisible) {
    return relationTypes.value[0];
  }
  return relationTypes.value[2];
});

const createRelationWithNewDatasetElementButtonLabel = computed(() => {
  return props.selectedType
    ? `Create as a new ${props.selectedType}`
    : `Create as a new ${props.selectedDataTypeName}`;
});

/** Methods */

const handleClickOutsideAddRelations = (e) => {
  e.stopPropagation();
  e.preventDefault();
  const typesSelectSection = document.getElementById(typesSelectSectionId.value);

  if (isTypeDropdownOpened.value
    || (typesSelectSection && typesSelectSection.contains(e.target))
    || (typesSelectSection && typesSelectSection.contains(e.target) && e.key !== 'Escape')) {
    return;
  }
  emits('onClickOutsideAddRelations', e);
};

const getSelectedTypeOption = (item) => {
  emits('onClickTypeOption', {
    dataTypeName: props.selectedDataTypeName,
    relation: relationType.value,
    attributes: { type: item.value }
  });
};

const handleCreateRelationWithNewDatasetElement = (e) => {
  e.stopPropagation();
  emits('onClickCreateAsNewTypeName', {
    relation: relationType.value,
    body: { title: props.searchValue, dataTypeName: props.selectedDataTypeName },
  });
};

const handleCreateRelationWithExistingDatasetElement = (item) => {
  emits('onClickAddRelationWithExistingDatasetElement', {
    relation: relationType.value,
    datasetElement: props.id,
    eligibleDatasetElementId: item.id,
  });
};
</script>

<style lang="scss" src="./IobEditorAddRelations.scss" scoped />
