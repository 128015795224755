<template>
  <iob-action-button
    v-if="props.shape === 'icon'"
    type="ghost"
    color="secondary"
    :icon-name="iconName"
    :size="iconSize"
    class="IobSkeleton--icon IobSkeleton--animation"
  />
  <div
    v-else
    :class="classList"
    :style="style"
  />
</template>

<script setup>
import { computed, defineProps } from 'vue';

import IobActionButton from '../../Molecules/IobActionButton/IobActionButton.vue';
import { iconSizes } from '../../../constants';

const props = defineProps({
  width: {
    type: String,
    default: '100%'
  },
  height: {
    type: String,
    default: '12px'
  },
  shape: {
    type: String,
    default: 'default',
    validator: (value) => ['default', 'circle', 'rect', 'icon'].includes(value)
  },
  iconName: {
    type: String,
    default: ''
  },
  iconSize: {
    type: String,
    default: 'default',
    validator: (value) => iconSizes.includes(value)
  }
});

const classList = computed(() => {
  return {
    'IobSkeleton': true,
    'IobSkeleton--circle': props.shape === 'circle',
    'IobSkeleton--animation': true
  }
});

const style = computed(() => {
  return {
    'width': props.width === '100%' && ['circle', 'rect', 'icon'].includes(props.shape) ? props.height : props.width,
    'height': ['circle', 'rect', 'icon'].includes(props.shape) && props.width !== '100%' ? props.width : props.height
  }
});

</script>

<style lang='scss' scoped>
.IobSkeleton {
  border-radius: var(--radius-s, 2px);
  background: var(--color-tokens-color-skeleton-default, #e8e7ec);

  &--animation {
    animation: skeleton-loading 1.4s linear infinite;
    animation-delay: 0.2s;
    flex-shrink: 0;
  }

  &--circle {
    border-radius: 50%;
  }

  &--icon {
    pointer-events: none;

    ::v-deep(svg *) {
      fill: var(--color-tokens-color-skeleton-default, #e8e7ec);
    }
  }
}

@keyframes skeleton-loading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>
