<template>
  <span
    class="Text"
    :style="{
      textAlign: alignment
    }"
  >
    {{ value }}
  </span>  
</template>

<script>
export default {
  name: 'IobText',
  props: {
    value: {
      type: String,
      required: true
    },
    alignment: {
      type: String,
      default: 'left'
    }
  }
};
</script>

<style scoped src="./IobText.css"></style>
