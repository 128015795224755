import { get } from 'SRC/api/index.js';

const actions = {
  async fetchBoards({ commit, state }) {
    let results = {};
    if (!state.nextBoardsQuery) {
      return;
    }
    if (state.nextBoardsQuery === 'initial') {
      results = await get('/boards?page=1&size=25');
      if (!results) {
        return ;
      }
      commit('setBoards', results.data);
      commit('setNextBoardsQuery', results.next);
    } else {
      results = await get(state.nextBoardsQuery, null, true, false);
      if (!results) {
        return ;
      }
      commit('setBoards', results.data);
      commit('setNextBoardsQuery', results.next);
    }
  }
};

export default actions;
