<template>
  <div class="IobSidePanel level-container level2">
    <div
      v-for="(item, index) in itemsArray"
      :key="`${Date.now()}-${index}`"
    >
      <iob-button
        v-if="item && item.component==='button'"
        :size="item.size"
        :color="selectedItemKey === item.key ? selectedColor: item.color"
        :type="selectedItemKey === item.key ? selectedType: item.type"
        :rounded="item.rounded"
        :show-left-icon="item.leftIcon.length"
        :left-icon="item.leftIcon"
        @click="selectItem(item.key)"
      />
      <iob-separator
        v-if="item.component==='separator'"
        :color="item.color"
        :color-type="item.colorType"
        :state="item.state"
      />
    </div>
  </div>
</template>

<script>
import IobButton from '../IobButton/IobButton.vue';
import IobSeparator from '../../Atoms/IobSeparator/IobSeparator.vue';
import { parseIfJson } from '../../../utils';
export default {
  name: 'IobSidePanel',
  components: { IobButton, IobSeparator },
  props: {
    items: {
      type: Array,
      required: true,
    }
  },
  emits: ['select-item'],
  data() {
    return {
      selectedItemKey: null,
      selectedColor: 'primary',
      selectedType: 'filled',
    };
  },
  computed: {
    itemsArray() {
      return parseIfJson(this.items) || [];
    },
  },
  mounted() {
    const defaultItemKey = this.itemsArray.find((item) => item.isSelected)?.key;
    this.selectedItemKey = defaultItemKey;
  },
  methods: {
    selectItem(key) {
      this.selectedItemKey = key ;
      this.$emit('select-item', key);
    }
  },
};
</script>

<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-app-side-panel.scss" />
<style lang="scss" src="./IobSidePanel.scss" scoped></style>
