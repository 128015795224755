// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-12.use[1]!../../../node_modules/@vue/cli-service/node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/@vue/cli-service/node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-12.use[2]!../../../node_modules/@vue/cli-service/node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-12.use[3]!../../main.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".colorsPopover[data-v-64ac4d21]{padding:20px;width:336px;background:#fff;box-shadow:0 3px 6px -2px rgb(20 19 27/7%),0 8px 16px 1px rgb(20 19 27/5%),0 3px 8px rgb(20 19 27/4%);border-radius:8px}.colors-list[data-v-64ac4d21]{height:144px;justify-content:space-between}.colors-list[data-v-64ac4d21],.colors-list-second[data-v-64ac4d21]{display:flex;width:100%;flex-wrap:wrap}.colors-list-second[data-v-64ac4d21]{justify-content:flex-start}input[data-v-64ac4d21]{visibility:hidden}label[data-v-64ac4d21]{background-color:#000;height:32px;width:32px;position:fixed}input[type=color][data-v-64ac4d21]::-webkit-color-swatch-wrapper{padding:0}input[type=color][data-v-64ac4d21]::-webkit-color-swatch{border:none}.customColor-label[data-v-64ac4d21]{font-style:normal;font-weight:700;font-size:14px;line-height:19px;color:#000}.plus-container[data-v-64ac4d21]{background:hsla(240,3%,49%,.16);border-radius:15px;height:30px;width:30px;display:flex;justify-content:center}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
