<template>
  <div>
    <svg
      class="RedoIconButton-container"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      @click="() => $emit('redo-icon-click')"
    >
      <path
        d="M22 7.99072V13.9907H16"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.22578 17.997C1.79281 16.0944 1.98991 14.1026 2.78738
      12.3218C3.58484 10.5409 4.93947 9.06747 6.64717 8.12344C8.35486
      7.17941 10.3231 6.81593 12.2553 7.08779C14.1875 7.35964 15.9791 8.25209
      17.36 9.63067L22 13.9907"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>
<script>

export default ({
  name: 'RedoIconButton',
  emits: ['redo-icon-click']
});
</script>

<style scoped src='./RedoIconButton.css'></style>
