<template>
  <li
    :class="classList"
    tabindex="0"
  >
    <button
      v-bind="$attrs"
      :disabled="props.disabled"
      tabindex="-1"
      class="IobSubmenu-item-button"
    >
      <div
        v-if="selected"
        class="IobSubmenu-item-button-indicator"
      />
      <icon-loader
        class="IobSubmenu-item-button-leftIcon"
        :name="iconName"
        size="default"
      />
      <span class="IobSubmenu-item-button-label">
        {{ text }}
      </span>
    </button>
    <iob-action-button
      v-if="props.actionButton"
      :disabled="props.disabled"
      class="IobSubmenu-item-details"
      color="secondary"
      type="ghost"
      icon-name="MoreHorizontal"
    />
  </li>
</template>

<script setup>
import IconLoader from '../../../IconLoader/IconLoader.vue';
import { computed, defineProps } from 'vue';
import IobActionButton from '../../IobActionButton/IobActionButton.vue';

const props = defineProps({
  text: {
    type: String,
    default: 'menu'
  },
  iconName: {
    type: String,
    default: ''
  },
  selected: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  actionButton: {
    type: Boolean,
    default: true
  }
})

const classList = computed(() => {
  return {
    'IobSubmenu-item focus-inside': true,
    'IobSubmenu-item--default': !props.selected && !props.disabled,
    'IobSubmenu-item--selected': props.selected,
    'IobSubmenu-item--disabled': props.disabled
  }
})
</script>

<style lang="scss" src="./IobSubmenuItem.scss" scoped />
