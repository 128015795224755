<template>
  <div class="IobAvatarWithName">
    <div :class="avatarContentClass">
      <iob-avatar
        :picture="picture"
        :alt-text="avatarAltText"
        :alt-text-style="altTextStyle"
        :size="size"
        :custom-dimension="customDimension"
        :color-left="colorLeft"
        :color-bottom="colorBottom"
        :color="color"
        :deleted-user="deletedUser"
      />
      <div :class="avatarTitleSubtitleClass">
        <div
          :style="nameStyle"
          :class="avatarNameClass"
        >
          {{ name }}
        </div>
        <div
          v-if="subtitle !== ''"
          :class="avatarSubtitleClass"
        >
          {{ subtitle }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import IobAvatar from '../IobAvatar/IobAvatar.vue';
import { getAvatarAltText } from '../../../utils';

const props = defineProps({
  picture: {
    type: String,
    default: '',
  },
  deletedUser: {
    type: Boolean,
    default: false
  },
  altText: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: 'default',
  },
  color: {
    type: String,
    default: '',
  },
  colorBottom: {
    type: Boolean,
    default: false
  },
  colorLeft: {
    type: Boolean,
    default: false
  },
  name: {
    type: String,
    required: true
  },
  subtitle: {
    type: String,
    default: ''
  },
  customDimension: {
    type: String,
    default: ''
  },
  altTextStyle: {
    type: String,
    default: ''
  },
  nameStyle: {
    type: String,
    default: ''
  }
});

const avatarAltText = computed(() => {
  return props.altText || getAvatarAltText(props.name);
});
const avatarTitleSubtitleClass = computed(() => {
  return `IobAvatarWithName-content-description IobAvatarWithName-content-description-${props.size}`;
});

const avatarContentClass = computed(() => {
  return `IobAvatarWithName-content IobAvatarWithName-content-${props.size}`;
});

const avatarNameClass = computed(() => {
  return `IobAvatarWithName-content-description-${props.size}--name`;
});

const avatarSubtitleClass = computed(() => {
  return `IobAvatarWithName-content-description-${props.size}--subtitle`;
});
</script>

<style lang="scss" src="./IobAvatarWithName.scss" scoped />
