<template>
  <div class="ListBoardgetModal">
    <iob-modal
      show-modal
      modal-type="popup"
      size="small"
      :title="t('boardget.list.creation.label')"
      :description="description"
      should-close-on-outside-click
      @close="closeModal"
    >
      <template #popupContent>
        <div class="ListBoardgetModal-content">
          <div class="ListBoardgetModal-content-listName">
            <span class="ListBoardgetModal-content-listName--label">{{ t('boardget.list.creation.list-name.label') }}</span>
            <iob-label-field
              style="width: inherit"
              type="outlined"
              size="medium"
              :placeholder="t('boardget.list.creation.list-name.placeholder')"
              @update:modelValue="(value) => (boardgetListTitle = value)"
            />
          </div>
          <div class="ListBoardgetModal-content-wrapper">
            <div class="ListBoardgetModal-content-selection">
              <div class="ListBoardgetModal-content-selection-elements">
                <span
                  class="ListBoardgetModal-content-selection-elements-label"
                >{{ t('boardget.list.creation.listed-elements') }}</span>
                <iob-select
                  attach-to-body
                  color="secondary"
                  type="outlined"
                  is-scrollable
                  is-data-types-items
                  :model-value="defaultDataType"
                  :dropdown-style="datasetElementsDropdownStyle"
                  @update:modelValue="onDataTypeSelect"
                />
              </div>
              <div class="ListBoardgetModal-content-selection-elements">
                <span
                  class="ListBoardgetModal-content-selection-elements-label"
                > {{ dataTypeName + ' ' + t('boardget.list.creation.related-to.label') }}</span>
                <iob-dropdown-search-select
                  attach-to-body
                  :dropdown-menu-items="datasetElementsWithTitle"
                  :dropdown-style="datasetElementsDropdownStyle"
                  :sliced-data="true"
                  :default-field="false"
                  :search-input="relatedSearchQuery"
                  :is-dropdown-diplayed="isRelatedElementDropdownOpen"
                  @onClickDropdownSearchSelectMenu="
                    ({ item }) => getDatasetElement(item)
                  "
                >
                  <iob-label-field
                    container-styles="display: flex; flex-wrap: wrap; height: auto; min-height: 40px;"
                    :has-icon="isRelatedElementDropdownOpen"
                    :placeholder="t(('boardget.list.creation.related-to.placeholder'))"
                    :input-style="relatedElement ? 'cursor: not-allowed; width: 0;' : 'width: fit-content'"
                    @update:modelValue="searchDatasetElements"
                  >
                    <iob-badge
                      v-if="relatedElement"
                      :label="relatedElement.text"
                      show-close-button
                      @close="relatedElement = null;"
                    />
                  </iob-label-field>
                </iob-dropdown-search-select>
              </div>
            </div>
          </div>
          <iob-separator />
          <div class="ListBoardgetModal-content-container">
            <div class="ListBoardgetModal-content-filters">
              <span
                class="ListBoardgetModal-content-selection-elements-label"
              >
                Filters
              </span>
              <iob-dropdown-button
                size="medium"
                color="primary"
                button-type="ghost"
                icon-name="Plus"
                show-left-icon
                :disabled="filtersToAdd.length === 0"
                :show-right-icon="false"
                title="Add filter"
                attach-to-body
                :dynamic-title="false"
                :type-check="false"
                :items="filtersToAdd"
                :is-scrollable="true"
                dropdown-style="width: 230px; max-height: 500px; top:42px;left: -40px"
                @dropdown-element-click="handleDropdownItemClick"
              />
            </div>
            <div
              class="ListBoardgetModal-filterWrapper"
            >
              <div
                v-for="(item, index) in formData.fields"
                :key="item.label + index"
                style="width: 100%"
              >
                <div class="ListBoardgetModal-filterWrapper-filter">
                  <div class="ListBoardgetModal-filterWrapper-filter-name">
                    <icon-loader :name="item.name.iconName" />
                    <span class="ListBoardgetModal-filterWrapper-element-label">{{
                      item.label
                    }}</span>
                  </div>
                  <div class="ListBoardgetModal-filterWrapper-element">
                    <iob-combobox-multi-select
                      v-if="
                        item.componentType === 'avatarList' ||
                          item.componentType === 'inputDropdown' ||
                          item.componentType === 'badge'
                      "
                      attach-to-body
                      is-scrollable
                      :placeholder="item.value.length ? '' : inputPlaceholder(item.name.label)"
                      style="width: inherit"
                      :selected-items="item.value"
                      :dropdown-items="item.options"
                      dropdown-style="width:260;max-height:250px; z-index:1"
                      @onClickItem="
                        (value) => handleSelectedFilter(value, item.name.label)
                      "
                      @onDeleteBadge="
                        (value) => handleRemovedFilter(value, item.name.label)
                      "
                    />
                    <iob-switch-button
                      v-if="item.componentType === 'checkbox'"
                      @update:modelValue="(value) => getToggleSwitchValue(value, item.name.label)"
                    />
                    <div
                      v-if="
                        item.componentType === 'timeframe' ||
                          item.componentType === 'dateRange'
                      "
                      class="ListBoardgetModal-content-timeframe"
                    >
                      <iob-timeframe
                        style="width: auto;"
                        date-picker-styles="transform: translate(0);"
                        :default-field="false"
                        :types="timeframeTypes"
                        default-type="standard"
                        :name="name"
                        @handle-date-change="
                          (attributes) =>
                            getDateTag(item.name, attributes, formData)
                        "
                      >
                        <iob-label-field
                          container-styles="display: flex; flex-wrap: wrap; height: auto; min-height: 40px; padding: 6px 8px"
                          :placeholder="item.value.length ? '' : inputPlaceholder(item.name.label)"
                          @update:model-value="
                            (attributes) =>
                              removeDateTag(filter.name, attributes, formData)
                          "
                        >
                          <iob-badge
                            v-for="(timeSelected, badgeIndex) in item.value"
                            :key="badgeIndex"
                            :label="timeSelected.text"
                            color="secondary"
                            show-close-button
                            @close="handleRemovedFilter(item, item.name.label)"
                          />
                        </iob-label-field>
                      </iob-timeframe>
                    </div>
                    <iob-action-button
                      size="default"
                      color="secondary"
                      type="ghost"
                      icon-name="Minus"
                      @click="handleRemoveFilter(item.name.label)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #popupFooter>
        <div class="ListBoardgetModal-footer">
          <iob-button
            type="filled"
            color="secondary"
            size="medium"
            :label="t('common.cancel')"
            @click="closeModal"
          />
          <iob-button
            type="filled"
            color="primary"
            size="medium"
            :label="t('common.create')"
            @click="createList"
          />
        </div>
      </template>
    </iob-modal>
  </div>
</template>

<script setup>
import {
  computed,
  onMounted,
  reactive,
  ref,
  toRaw,
  watch,
  watchEffect,
  defineExpose
} from 'vue';
import { useStore } from 'vuex';
import { getUserInitials } from 'SRC/utils/collab-utils';
import {
  addOtherFilter,
  addTitleToDropdownData,
  formatDatasetElements,
  isFieldValueEmpty,
  getDateTag,
  removeDateTag,
  transformFieldValue
} from '../modals-utils';
import { debounce } from 'SRC/utils/utils.js';
import { DEFAULT_STATUS_BADGE_COLORS, dataTypesIcons } from 'GLOBALS/constants.js';
import { useI18n } from 'vue-i18n';

defineProps({
  description: {
    type: String,
    default: ''
  }
});

const { t } = useI18n();
const toggleFilterDropdown = ref(false);
const datasetElementsDropdownStyle = ref(
  'position: absolute; z-index: 1; width: 268px;max-height:250px;'
);
const timeframeTypes = [
  { id: 0, value: 'standard' },
  { id: 1, value: 'custom' }
];
const relatedSearchQuery = ref('');
const boardgetListTitle = ref('');
const removedFilters = ref([]);

const filtersToAdd = computed(() => getFilterData(allFilters.value).map((filter) => ({
  ...filter,
  showIcon: true,
  iconName: filter.name.iconName,
  text: filter.label,
  label: filter.label,
  name: filter.name,
  type: 'menu',
  state: 'default',
  iconSize: 'default'
}))
);

const filteredDatasetElements = ref([]);
const formData = reactive({
  name: '',
  dataType: '',
  fields: [],
  value: []
});
const relatedElement = ref(null);
const store = useStore();

const dataTypeId = computed(() => store.state.dock.dataTypeId);
const allUsers = computed(() => store.state.users.users);
const boardId = computed(() => store.state.board.id);
const viewTypeId = computed(() => store.state.dock.viewTypeId);
const datasetElementsWithTitle = computed(() =>
  addTitleToDropdownData(filteredDatasetElements.value, 'element')
);
const datasetElements = computed(() => store.state.dock.datasetElements);
const levels = computed(() => store.state.app.levels);
const formattedDatasetElements = computed(() =>
  formatDatasetElements(datasetElements.value)
);

const inputPlaceholder = (name) => `Add ${name} filter`;

const handleSelectedFilter = (item, fieldName) => {
  const index = formData.fields.findIndex((field) => field.name.label === fieldName);
  formData.fields[index].value.push(item);
};

const handleRemovedFilter = (selectedItem, fieldName) => {
  const index = formData.fields.findIndex((field) => field.name.label === fieldName);
  const indexItem = formData.fields[index].value.findIndex(
    (field) => field.id === selectedItem.id
  );
  formData.fields[index].value.splice(indexItem, 1);
};

const getToggleSwitchValue = (value, fieldName) => {
  const index = formData.fields.findIndex((field) => field.name.label === fieldName);
  formData.fields[index].value = [value];
};

const handleRemoveFilter = (label) => {
  let deletedFilter = formData.fields.find((field) => field.name.label === label);
  formData.fields = formData.fields.filter((item) => item.name.label !== label);
  deletedFilter = {
    showIcon: true,
    iconName: deletedFilter.name.iconName, label: deletedFilter.name.label,
    text: deletedFilter. label,
    name: deletedFilter.name,
    type: 'menu',
    state: 'default',
    iconSize: 'default'
  };
  removedFilters.value.push(deletedFilter);
};

const defaultDataType = computed(() => ({
  text: dataTypeName.value,
  type: 'icon-shape',
  title: dataTypeName.value,
  iconSize: 'xsmall',
  isClickable: true,
  iconName: dataTypesIcons[dataTypeName.value].icons.parent,
  iconColor: dataTypesIcons[dataTypeName.value].color
}));

const creationComponent = computed(() => store.state.dock.creationComponent);
const closeComponent = (value) => store.dispatch('board/closeComponent', value);

const filtersList = computed(() => {
  const attributes =
    dataTypes.value[selectedDatatypeId.value].editorConfig?.filterConfig.default
      .attributes;
  return getFilterData(attributes).map((filter) => ({
    ...filter,
    label: filter.label,
    type: 'menu',
    iconSize: 'default'
  }));
});

const getFilterData = (attributes, reset = false) => {
  const mainAttributesMap = dataTypes.value[
    selectedDatatypeId.value
  ].editorConfig.general.mainAttributesPanel.reduce((acc, attribute) => {
    acc[attribute.name] = attribute;
    return acc;
  }, {});
  const attrOptionsMap = dataTypes.value[
    selectedDatatypeId.value
  ].attributes.reduce((acc, attribute) => {
    acc[attribute.name] = attribute;
    return acc;
  }, {});
  for (const attr in attrOptionsMap) {
    if (attrOptionsMap[attr].type === 'user') {
      attrOptionsMap[attr].options =
        allUsers.value.map((user) => ({
          title: `${user.firstname} ${user.lastname}`,
          id: user.id,
          showIcon: false,
          colorBadge: 'secondary',
          avatar: {
            picture: user.picture,
            size: 'default',
            color: '#8AC3C5',
            altText: getUserInitials(user.firstname, user.lastname)
          },
          type: 'member'
        })) || [];
    } else if (attrOptionsMap[attr].type === 'level') {
      attrOptionsMap[attr].options = levels.value.map((level) => ({
        text: level.attributes['level-name'],
        id: level.id,
        iconName: '',
        type: 'badge',
        state: 'default',
        iconSize: 'default'
      }));
    } else {
      attrOptionsMap[attr].options =
        attrOptionsMap[attr].enum?.map((item) => ({
          text: item.value,
          colorBadge: DEFAULT_STATUS_BADGE_COLORS[item.value] || 'secondary',
          iconName: '',
          type: 'menu',
          state: 'default',
          iconSize: 'default'
        })) || [];
    }
  }
  return attributes
    .filter((attr) => attr.label in mainAttributesMap || attr.label === 'type')
    .map((attr, index) => ({
      name: attr,
      label: attrOptionsMap[attr.label].friendlyName,
      componentType:
        mainAttributesMap[attr.label]?.componentType ||
        attrOptionsMap[attr.label]?.componentType ||
        'inputDropdown',
      options: attrOptionsMap[attr.label]?.options || [],
      value: reset ? [] : formData.fields[index]?.value
    }));
};

const fetchDatasetElements = async () => {
  await store.dispatch('dock/fetchDatasetElements', { all: true });
  filteredDatasetElements.value = formatDatasetElements(datasetElements.value);
};

const allDataTypes = computed(() => store.state.app.dataTypes);

const dataTypes = computed(() => {
  const filteredDataTypesArr = Object.values(allDataTypes.value).filter(
    (dataType) => dataType.namespace === '/element-data-types/opex'
  );
  return filteredDataTypesArr.reduce((acc, dataType) => {
    acc[dataType.id] = dataType;
    return acc;
  }, {});
});

const dataTypeName = computed(() =>
  dataTypes.value[selectedDatatypeId.value].name
);

const selectedDatatypeId = ref(Object.keys(dataTypes.value)[0]);

const otherFilters = ref(
  ...dataTypes.value[
    selectedDatatypeId.value
  ].editorConfig?.filterConfig.other.
    attributes.map((filter) => filter.label) ||
    []
);

const searchDatasetElements = debounce(async (value) => {
  relatedSearchQuery.value = value;
  await store.dispatch('dock/searchDatasetElements', {
    query: relatedSearchQuery.value,
    withTypeId: false
  });
}, 500);

watch(
  selectedDatatypeId,
  async (newId) => {
    if (newId) {
      formData.fields = getFilterData(
        filtersList.value.map((filter) => filter.name),
        true
      );
      const Otherfilters = dataTypes.value[selectedDatatypeId.value]
        .editorConfig?.filterConfig
        .other.attributes;
      otherFilters.value = [...Otherfilters] || [];
    }
  },
  { immediate: true, deep: true }
);

const isRelatedElementDropdownOpen = computed(() => !relatedElement.value);

const getDatasetElement = (item) => {
  filteredDatasetElements.value = filteredDatasetElements.value.filter(
    (element) => element.id !== item.id
  );
  relatedElement.value = { id: item.id, text: item.title };
  relatedSearchQuery.value = '';
  searchDatasetElements('');

};

watchEffect(() => {
  filteredDatasetElements.value = formattedDatasetElements.value;
});

const closeModal = () => {
  closeComponent(creationComponent.value);
};
const allFilters = computed(() => [...otherFilters.value, ...removedFilters.value]
);
const handleDropdownItemClick = (item) => {
  addOtherFilter(item, formData.fields, otherFilters.value);
  const index = removedFilters.value.findIndex((filter) => filter.name.label === item.name.label);
  removedFilters.value.splice(index, 1);
  toggleFilterDropdown.value = false;
};

const getDataTypeIdByName = (name) =>
  Object.keys(dataTypes.value).find(
    (dataTypeId) => dataTypes.value[dataTypeId].name === name
  );

const onDataTypeSelect = (dataType) => {
  selectedDatatypeId.value = getDataTypeIdByName(dataType.text);
  removedFilters.value = [];
};

const createList = async () => {
  const filtersWithTheirValues = formData.fields
    .map((field) => {
      const fieldValue = transformFieldValue(field);
      if (isFieldValueEmpty(fieldValue)) {
        return null;
      }
      return { [field.name.label]: fieldValue };
    })
    .filter((item) => item !== null);
  const transformedAttributes = filtersWithTheirValues.reduce((acc, item) => {
    acc = { ...acc, ...item };
    return acc;
  }, {});
  const attributeSearches = {
    attributeSearches: transformedAttributes,
    relatedElementIds: relatedElement.value ? [toRaw(relatedElement.value).id] : [],
    dataTypeId: selectedDatatypeId.value
  };
  await store.dispatch('board/createBoardgetElement', {
    attributeSearches,
    boardId: boardId.value,
    viewTypeId: viewTypeId.value,
    dataTypeId: dataTypeId.value,
    dataTypeName: 'List',
    title: boardgetListTitle.value
  });
  store.dispatch('board/closeComponent', 'ListBoardgetModal');
};

onMounted(async () => {
  store.commit('dock/setDataTypeId', selectedDatatypeId.value);
  await fetchDatasetElements();
});

defineExpose({
  boardgetListTitle,
  getDatasetElement,
  relatedElement,
  searchDatasetElements,
  createList,
  formData,
  filtersToAdd
});
</script>

<style src="./ListBoardgetModal.scss" lang="scss" scoped />
