const mutations = {
  setUsers(state, users) {
    state.users = users;
  },
  setCurrentUser(state, user) {
    state.currentUser = user;
  },
  setCurrentUserColor(state, color) {
    state.currentUserColor = color;
  },
  setUsersOnBoard(state, users) {
    state.usersOnBoard = users;
  }
};

export default mutations;
