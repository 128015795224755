<template>
  <select
    :value="selectedUserId"
    @change="$emit('change', $event.target.value)"
  >
    <option
      v-for="(user, index) in usersArray"
      :key="index"
      :value="user.id"
    >
      {{ user.firstName + " " + user.lastName }}
    </option>
  </select>
</template>

<script>
import { parseIfJson } from '../../utils';

export default {
  name: 'IobUserSelector',
  props: {
    users: {
      type: [String, Array],
      required: true
    },
    selectedUserId: {
      type: String,
      required: true
    }
  },
  emits: ['change'],
  computed: {
    usersArray() {
      return parseIfJson(this.users) || [];
    },
    selectedUser() {
      return this.usersArray.find(user => {
        return user.id === this.selectedUserId;
      });
    }
  }
};
</script>
