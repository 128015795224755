<template>
  <svg
    class="UndoIconButton-container"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="() => $emit('undo-icon-click')"
  >
    <path
      d="M2 7.99072V13.9907H8"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.7742 17.997C22.2072 16.0944 22.0101
      14.1026 21.2126 12.3218C20.4152 10.5409 19.0605 9.06747 17.3528 8.12344C15.6451
      7.17941 13.6769 6.81593 11.7447 7.08779C9.81245 7.35964 8.02091 8.25209 6.64 9.63067L2 13.9907"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default ({
  name: 'UndoIconButton',
  emits: ['undo-icon-click']
});
</script>

<style scoped src='./UndoIconButton.css' />
