import { CHANGE_SOURCE } from 'GLOBALS/constants';
export const getChangeSource = (eventArray, clientId) => {
  let didOtherUserChange = false;
  for (const event of eventArray) {
    const keysChanged = event?.keysChanged;
    for (const keyValue of keysChanged) {
      const sourceId = event.target?.get(keyValue)?._item?.id?.client;
      if (sourceId !== clientId) {
        didOtherUserChange = true;
        break;
      }
    }
    if (didOtherUserChange) {
      return CHANGE_SOURCE.OTHER_USER;
    }
  }
  return CHANGE_SOURCE.CURRENT_USER;
};
export const extractChangedAttributesFromEvent = (acc, event) => {
  const changedAttribute = event?.target?._map?.get('attributes')?.content?.type?.toJSON();
  let newCount = acc.count;
  if (changedAttribute) {
    newCount++;
  }
  const datasetElementId = event?.target?.toJSON()?.id;
  const newAcc = {
    results: [...acc.results, {
      datasetElementId,
      changedAttribute
    }],
    count: newCount
  };
  return newAcc;
};
export const applyChange = (commit, yDatasetElements) => (change) => {
  const { datasetElementId, changedAttribute } = change;
  const datasetElement = yDatasetElements.get(datasetElementId);
  if (datasetElement) {
    commit('board/updateDatasetElementAttributes', {
      datasetElementId,
      updatedAttributes: changedAttribute
    });
  }
};

export const setDatasetElements = (commit, yDatasetElements, didRetrieveBoardElements) => {
  if (!didRetrieveBoardElements) {
    commit('board/setDidRetrieveDatasetElements', true);
  }
  const parsedyDataSetElements = JSON.parse(JSON.stringify(yDatasetElements));
  commit('board/setDatasetElements', parsedyDataSetElements);
};

