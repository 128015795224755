import sessionKeys from './sessionKeys';
import deleteFromLocal from './deleteFromLocal';
import getFromLocal from './getFromLocal';
import saveToLocal from './saveToLocal';

const storageService = {
  sessionKeys,
  deleteFromLocal,
  getFromLocal,
  saveToLocal
};

export default storageService;
