<template>
  <div class="IobMainNavHeader">
    <div class="IobMainNavHeader-top">
      <iobeya-logo />
      <iob-action-button
        v-if="hasCloseButton"
        size="large"
        color="secondary"
        type="ghost"
        @click="emit('close')"
      />
    </div>
    <div
      class="IobMainNavHeader-bottom"
    >
      <iob-alert
        v-if="shouldShowWarning"
        type="warning"
        icon="AlertTriangle"
        :title="warningTitle"
        :message="warningMessage"
        show-close
        @close="() => emit('warningClose')"
      />
      <iob-button
        v-if="hasAddButton"
        class="IobMainNavHeader-newButton"
        left-icon="Plus"
        :label="addButtonLabel"
        type="filled"
        size="medium"
        :disabled="isPlusButtonDisabled"
        show-left-icon
        @click="handleButtonClick"
      >
        <outside-click-listener
          v-if="toggleButtonDropdown"
          @outside-click="() => toggleButtonDropdown = false"
        >
          <iob-dropdown
            :style="buttonDropdownStyle"
            :items="buttonItems"
            @dropdown-element-item="handleDropdownItemClick"
          />
        </outside-click-listener>
      </iob-button>
      <iob-label-field
        v-if="hasSearchField"
        size="medium"
        placeholder="Search anything..."
        class="IobMainNavHeader-searchField"
        :disabled="isSearchDisabled"
        has-icon
        @update:modelValue="($event) => emit('update:searchValue', $event)"
      />
    </div>
  </div>
</template>

<script setup>
import IobButton from '../../../IobButton/IobButton.vue';
import IobeyaLogo from '../../../../Atoms/IobeyaLogo/IobeyaLogo.vue';
import IobActionButton from '../../../IobActionButton/IobActionButton.vue';
import IobAlert from '../../../../Atoms/IobAlert/IobAlert.vue';
import OutsideClickListener from '../../../../OutsideClickListener/OutsideClickListener.vue';
import IobDropdown from '../../../../Molecules/IobDropdown/IobDropdown.vue';
import {defineEmits, ref} from 'vue';

const emit = defineEmits(['update:searchValue', 'close', 'button-click', 'button-dropdown-click', 'dropdownElementClick','warningClose'])

const props = defineProps({
  hasAddButton: {
    type: Boolean,
    default: true
  },
  addButtonLabel: {
    type: String,
    default: 'New'
  },
  hasSearchField: {
    type: Boolean,
    default: false
  },
  isPlusButtonDisabled: {
    type: Boolean,
    default: false
  },
  hasCloseButton: {
    type: Boolean,
    default: false
  },
  warningTitle: {
    type: String,
    default: ''
  },
  warningMessage: {
    type: String,
    default: ''
  },
  shouldShowWarning: {
    type: Boolean,
    default: false
  },
  buttonItems: {
    type: Array,
    default: () => [],
  },
  buttonDropdownStyle: {
    type: Object,
    default: () => ({})
  }
})

const toggleButtonDropdown = ref(false);
const handleDropdownItemClick = ({item}) => {
  if (!item.action || item.action === '') return;
  emit('dropdownElementClick', item);
  emit('button-dropdown-click', item);
  toggleButtonDropdown.value = false;
};
const handleButtonClick = () => {
  if (props.buttonItems.length) {
    toggleButtonDropdown.value = !toggleButtonDropdown.value;
  }
  emit('button-click');
}
</script>

<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-app-sidebar.scss" />
<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-app-menu.scss" />
<style lang="scss" scoped src="./IobMainNavHeader.scss" />
