<template>
  <div
    :class="classList"
    tabindex="0"
    @click.prevent="handleDropdownElement"
  >
    <iob-avatar
      :picture="avatar.picture"
      :alt-text="avatar.altText"
      :size="avatar.size"
      :color-left="avatar.colorLeft"
      :color="avatar.color"
    />
    
    <div class="IobMemberElement-texts">
      <div :class="titleClassList">
        {{ title }}
      </div>
      <div
        v-if="subtitle.length"
        :class="subtitleClassList"
      >
        {{ subtitle }}
      </div>  
    </div> 
    <icon-loader
      v-if="showIcon"
      :name="iconName"
      :size="iconSize"
      :color="iconColor"
      :stroke="iconColor"
    />
  </div>
</template>

<script>
import IconLoader from '../../IconLoader/IconLoader.vue';
import IobAvatar from '../IobAvatar/IobAvatar.vue';

export default {
  name: 'IobMemberElement',
  components: { IobAvatar, IconLoader },
  props: {
    state: {
      type: String,
      default: 'default',
    },
    title: {
      type: String,
      default: 'title',
    },
    subtitle: {
      type: String,
      default: '',
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: 'MoreVertical',
    },
    avatar: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      iconSize: 'default',
    };
  },
  computed: {
    classList() {
      return `IobMemberElement focus-inside IobMemberElement--${this.state}`;
    },
    titleClassList() {
      return `IobMemberElement-texts-title IobMemberElement-texts-title--${this.state}`;
    },
    subtitleClassList() {
      return `IobMemberElement-texts-subtitle IobMemberElement-texts-subtitle--${this.state}`;
    },
    iconColor() {
      return `var(--list-elements-member-element-color-icons-secondary-ghost-${this.state})`;
    },
  },
  methods: {
    handleDropdownElement() {
      this.$emit('handle-dropdown-element');
    },
  }
};
</script>

<style lang="scss" src="./IobMemberElement.scss" scoped></style>