<template>
  <div
    ref="coverRef"
    class="IobCover"
    :class="coverClass"
    @mouseenter="handleShowPreview"
    @mouseleave="handleHidePreview"
  >
    <iob-image
      class="IobCover-asset"
      :src="src"
      :render-type="size === 's' ? '1:1' : '16:9'"
      :style="{backgroundColor: backgroundColor}"
    />
    <Transition>
      <div
        v-show="showPreview && preview"
        ref="previewRef"
        class="IobCover-preview"
      >
        <iob-card-preview
          :cover="src || preview?.image"
          cover-ratio="fit-to-box"
          :title="preview?.title"
          :elevation="3"
        />
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { computed, defineProps, ref } from 'vue';
import IobCardPreview from '../Card/IobCardPreview/IobCardPreview.vue';
import IobImage from '../../Atoms/IobImage/IobImage.vue';
import { getHiddenElementBoundingRect } from '../../../utils';

const props = defineProps({
  asset: {
    type: String,
    default: 'image'
  },
  description: {
    type: String,
    required: true
  },
  size: {
    type: String,
    default: 'default',
    validator: (value) => ['default', 's'].includes(value)
  },
  src: {
    type: String,
    required: true
  },
  preview: {
    type: Object,
    default: null
  },
  backgroundColor: {
    type: String,
    default: null
  }
});

const coverClass = computed(() => `IobCover--${props.size}`);
const showPreview = ref(false);
const enterTimeout = ref(null);
const leaveTimeout = ref(null);
const previewRef = ref(null);
const coverRef = ref(null);

const handleShowPreview = (event) => {
  if (!props.preview) {
    return;
  }
  if (enterTimeout.value) {
    clearTimeout(enterTimeout.value);
  }
  if (leaveTimeout.value) {
    clearTimeout(leaveTimeout.value);
  }
  enterTimeout.value = setTimeout(() => {
    showPreview.value = true;
    positionPreview(event);
  }, 300);
}

const handleHidePreview = () => {
  if (!props.preview) {
    return;
  }
  if (leaveTimeout.value) {
    clearTimeout(leaveTimeout.value);
  }
  if (enterTimeout.value) {
    clearTimeout(enterTimeout.value);
  }
  leaveTimeout.value = setTimeout(() => {
    showPreview.value = false;
  }, 150);
}

const positionPreview = (event) => {
  if (!previewRef.value) {
    return;
  }
  const coverRect = coverRef.value.getBoundingClientRect();
  // clone the preview element to get the correct size
  const { width: previewWidth, height: previewHeight } = getHiddenElementBoundingRect(previewRef.value);

  const { x, width, height } = coverRect;
  const posY = event.clientY - event.offsetY;
  previewRef.value.style.transform = 'translateY(-50%)';

  const hasReachedBottomBound = posY + previewHeight * .5 + height > window.innerHeight;
  const hasReachedTopBound = posY - previewHeight * .5 < 0;

  if (hasReachedBottomBound) {
    const marginBottom = -8;
    previewRef.value.style.top = `${posY + height + marginBottom}px`;
    previewRef.value.style.transform = 'translateY(-100%)';
  } else if (hasReachedTopBound) {
    previewRef.value.style.transform = 'translateY(0)';
    previewRef.value.style.top = `${posY}px`;
  } else {
    previewRef.value.style.top = `${posY + height * .5}px`;
  }
  const hasReachedRightBound = x + width + previewWidth > window.innerWidth && window.innerWidth > previewWidth;
  if (hasReachedRightBound) {
    previewRef.value.style.right = `${window.innerWidth - x}px`;
  } else {
    previewRef.value.style.left = `${x + width}px`;
  }
}
</script>

<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-illustration.scss" />
<style lang="scss" src="./IobCover.scss" scoped />
