<template>
  <div>
    <div class="InteractionModeSelector-container">
      <iob-side-panel
        v-bind="{items:newInteractionModalities}"
        @dblclick="onDoubleClick"
        @select-item="(key)=> changeInteractionMode(key)"
      />
    </div>
    <outside-click-listener
      v-if="isLinksMenuOpen"
      @outside-click="closeLinksMenu"
    >
      <div
        class="linksMenu-container"
      >
        <links-types-menu />
      </div>
    </outside-click-listener>
  </div>
</template>
<script>

import { INTERACTION_MODES } from 'GLOBALS/constants';
import { mapMutations, mapState } from 'vuex';
import LinksTypesMenu from './LinksTypesMenu.vue';

export default {
  name: 'InteractionModeSelector',
  components: { LinksTypesMenu },
  props: {
    onDoubleClickSelect: {
      type: Function,
      default: () => ({})
    },
    rollbackHotKeys: {
      type: Function,
      default: () => ({})
    }
  },
  data() {
    return {
      INTERACTION_MODES,
      interactionModalities: [
        {iconName: 'MousePointer', name: INTERACTION_MODES.SELECT, style: 'margin-bottom: 8px; height: 36px; width: 36px;' },
        {iconName: 'Hand', name: INTERACTION_MODES.PANNING, style: 'margin-bottom: 8px; height: 36px; width: 36px;'},
        {iconName: 'LinkMode', name: INTERACTION_MODES.LINKS, style: 'height: 36px; width: 36px;'}
      ],
      newInteractionModalities: [
        {component: 'button', size: 'medium', color: 'secondary', type: 'ghost',
          leftIcon: 'MousePointer', key: INTERACTION_MODES.SELECT, isSelected: true},
        {component: 'button', size: 'medium', color: 'secondary', type: 'ghost', leftIcon: 'Hand', key: INTERACTION_MODES.PANNING}
      ],
      componentName: ''
    };
  },
  computed: {
    ...mapState('board', [
      'selectedInteractionMode',
      'isLinksMenuOpen'
    ]),
    isPanning() {
      return this.selectedInteractionMode === INTERACTION_MODES.PANNING;
    },
    isSelect() {
      return this.selectedInteractionMode === INTERACTION_MODES.SELECT;
    },
    isLinks() {
      return this.selectedInteractionMode === INTERACTION_MODES.LINKS;
    }
  },
  mounted() {
    this.componentName = this.$options.name;
  },
  methods: {
    ...mapMutations('board', ['setSelectedInteractionMode', 'setIsLinksMenuOpen']),
    changeInteractionMode(value) {
      this.rollbackHotKeys();
      this.setSelectedInteractionMode({value});
    },
    onDoubleClick(event) {
      const target = event.target;
      const isOutside = target.classList?.value === 'IobSidePanel level-container level2';
      if (this.selectedInteractionMode !== INTERACTION_MODES.SELECT || isOutside) {
        return;
      }
      this.onDoubleClickSelect();
    },
    closeLinksMenu() {
      this.setIsLinksMenuOpen(false);
    }
  }
};

</script>
<style scoped>
.InteractionModeSelector-container {
  position: absolute;
  left: 20px;
  top: 489px;
}
.linksMenu-container {
  position: absolute;
  left: 95px;
  top: 540px;
  width: 200px;
  background: #fefefe;
  box-shadow:
    0 3px 6px rgb(20 19 27 / 7%),
    0 8px 16px rgb(20 19 27 / 5%),
    0 3px 8px rgb(20 19 27 / 4%);
  border-radius: 6px;
  padding: 12px;
  box-sizing: border-box;
}

.separator {
  height: 8px;
}

</style>
