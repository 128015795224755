<template>
  <button
    type="button"
    :class="classList"
    :style="buttonStyle"
    :disabled="disabled"
    @click="$emit('button-click')"
  >
    <icon-loader
      v-if="iconName"
      :key="iconName"
      class="mr-2"
      :name="iconName"
      :style="iconStyle"
      :stroke="iconStroke"
      :color="iconColor"
      :size="iconSize"
    />
    {{ label }}
  </button>
</template>

<script>
import IconLoader from '../IconLoader/IconLoader.vue';

export default {
  name: 'FlowBiteButton',
  components: { IconLoader },
  props: {
    shape: {
      type: String,
      default: 'standard'
    },
    mode: {
      type: String,
      default: 'dark'
    },
    size: {
      type: String,
      default: 'base'
    },
    borderColor: {
      type: String,
      default: 'border-black'
    },
    label: {
      type: String,
      default: ''
    },
    outlined: {
      type: Boolean,
      default: false
    },
    iconName: {
      type: String,
      default: ''
    },
    iconSize: {
      type: String,
      default: 'default'
    },
    iconColor: {
      type: String,
      default: '#FFFFFF'
    },
    iconStroke: {
      type: String,
      default: 'black'
    },
    isTransparent: {
      type: Boolean,
      default: false
    },
    buttonStyle: {
      type: String,
      default: ''
    },
    iconStyle: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    shapeClassMap: {
      standard: 'rounded-lg',
      rounded: 'rounded-full'
    },
    outlinedClasses: {
      'border-black': 'border-2 border-black',
      'border-gray': 'border-2 border-gray-300 focus:bg-gray-300 focus:border-gray-400'
    },
    modeClassMap: {
      dark: 'text-white bg-gray-800',
      light: 'bg-white',
      gray: 'bg-gray-100'
    },
    sizeClasses: {
      small: 'px-3 py-2',
      base: 'px-5 py-2.5',
      large: 'px-5 py-3'
    }
  }),
  computed: {
    classList() {
      if (this.isTransparent) {
        return `flex items-center ${this.sizeClasses[this.size]}`;
      }
      return `flex items-center ${this.modeClassMap[this.mode]} ${this.shapeClassMap[this.shape]} ${
        this.outlined && this.outlinedClasses[this.borderColor]} ${this.sizeClasses[this.size]}`;
    }
  }
};
</script>

<style scoped src="./CustomButton.css" />
