const utils = {
  getNextPage: async (response, data, fn, isRelative = false, circuitBreakerInfo = {}, body = null) => {
    while (response.next) {
      response = isRelative ? await fn(response.next, body, true, true, circuitBreakerInfo) :
        await fn(response.next, body, true, false, circuitBreakerInfo);
      const nextPageResponseData = response.data;
      if (response && nextPageResponseData) {
        data = data.concat(nextPageResponseData);
      }
    }
    return data;
  },
  createRequestBodyForNewRelation: (relation, eligibleDatasetElementId, id) => relation === 'parent'
    ? [{ datasetElementParentId: eligibleDatasetElementId, datasetElementChildId: id }]
    : [{ datasetElementParentId: id, datasetElementChildId: eligibleDatasetElementId }],
  formatDependencies: (createdDependencies, dataTypes) => {
    const data = {};
    const dependencies = createdDependencies;
    dependencies.forEach((element) => {
      const dataTypeName = dataTypes[element.typeId].name;
      if (dataTypes[element.typeId].editorConfig && dataTypes[element.typeId].editorConfig.relationConfig) {
        const relationConfigAttrs = dataTypes[element.typeId].editorConfig.relationConfig.attributes;
        let dependency = {};
        if (element) {
          dependency = element;
        }
        if (data[dataTypeName]) {
          data[dataTypeName].push({ dependency, relationConfigAttrs });
        } else {
          data[dataTypeName] = [{ dependency, relationConfigAttrs }];
        }
      }
    });
    return data;
  }
};

export default utils;
