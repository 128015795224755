<template>
  <div class="IobProgressBarWithLabel container level-2">
    <div class="IobProgressBarWithLabel-content">
      <iob-icon-shape
        v-if="iconName"
        :icon-name="iconName"
        size="xl"
      />
      <div class="IobProgressBarWithLabel-labelContainer">
        <icon-loader
          v-if="isCompleted"
          class="IobProgressBarWithLabel-checkContainer"
          name="Check"
          color="var(--color-tokens-color-text-base-content-on-container-default)"
        />
        <span
          v-else
          class="IobProgressBarWithLabel-percentage"
        >{{ progress }}%
        </span>
        <span class="IobProgressBarWithLabel-label">{{ label }}</span>
        <iob-progress-bar :progress="progress" />
      </div>
    </div>
    <iob-button
      left-icon="XCircle"
      show-left-icon="true"
      color="secondary"
      type="ghost"
      @click="$emit('onClickButton')"
    />
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import IconLoader from '../../IconLoader/IconLoader.vue';
import IobProgressBar from '../../Atoms/IobProgressBar/IobProgressBar.vue';
import IobButton from '../IobButton/IobButton.vue';
import IobIconShape from '../../Atoms/IobIconShape/IobIconShape.vue';

const props = defineProps({
  label: { type: String, default: 'Office_cover.png' },
  progress: { type: Number, default: 0 },
  iconName: { type: String, default: '' },
});

const isCompleted = computed(() => props.progress === 100);
</script>

<style lang="scss" src="./IobProgressBarWithLabel.scss" scoped />
