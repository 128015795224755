<template>
  <div>
    <label
      class="IobSwitchButton level-3 focus-outside"
      tabindex="0"
      data-test-id="IobSwitchButton"
    >
      <input
        type="checkbox"
        :checked="isChecked"
        :disabled="disabled"
        @change="handleClick"
      >
      <span class="IobSwitchButton-switcher" />
    </label>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

defineProps({
  isChecked: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['update:modelValue']);

const handleClick = (event) => {
  emit('update:modelValue', event.target.checked);
};
</script>

<style lang="scss" src="./IobSwitchButton.scss" scoped />
<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-switch.scss" />
