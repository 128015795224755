<template>
  <div
    class="relative mb-3 mr-4"
    @click="$event => $event.stopPropagation()"
  >    
    <label
      v-if="label"
      class="block mb-2 text-sm font-medium text-gray-900"
    >{{
      label
    }}</label>
    <div
      class="flex items-center relative"
      :style="inputStyle"
    >
      <checkbox
        v-if="hasCheckbox"
        :id="id"
        class="absolute left-4"
        :disabled="checkboxDisabled"
        :checked="checked" 
        outlined
        @checkbox-event="setCheckbox"
      />
      <icon-loader
        v-if="startIcon && startIcon.length"
        :id="`start-icon-${id}`"
        :class="startIconClassList"
        :name="startIcon"
        :size="startIconSize"
        :color="startIconColor"
        :stroke="startIconStroke"
        @click="$emit('start-icon-click')"
      />
      <input
        ref="labelInput"
        type="text"
        :value="modelValue"
        :class="classList"
        :placeholder="placeholder"
        :disabled="disabled"
        :valid="valid"
        :style="inputStyle.length ? inputStyle : ''"
        @keyup="(event) => $emit('update:modelValue', event.target.value)"
        @keyup.enter="(event) => $emit('press-enter', event)"
        @keyup.escape="(event) => $emit('press-escape', event)"
        @focus="(event) => $emit('focus-input', event)"
      >
      <icon-loader
        v-if="endIcon && endIcon.length"
        :id="`end-icon-${id}`"
        class="absolute right-4"
        :name="endIcon"
        :size="endIconSize"
        :color="endIconColor"
        :stroke="endIconStroke"
        @click="$emit('end-icon-click', id)"
      />
    </div>
    <p
      v-if="hasError"
      class="mt-2 text-sm text-red-600 dark:text-red-500"
    >
      <span class="font-medium">{{ errorMessage }}</span>
    </p>
  </div>
</template>

<script>
import IconLoader from '../IconLoader/IconLoader.vue';
import Checkbox from '../Checkbox/Checkbox.vue';

export default {
  name: 'LabelField',
  components: { IconLoader, Checkbox },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    shape: {
      type: String,
      default: 'standard'
    },
    mode: {
      type: String,
      default: 'light'
    },
    borderColor: {
      type: String,
      default: 'border-black'
    },
    outlined: {
      type: Boolean,
      required: true
    },
    modelValue: {
      type: String,
      default: ''
    },
    startIcon: {
      type: String,
      default: ''
    },
    startIconSize: {
      type: String,
      default: 'large'
    },
    startIconColor: {
      type: String,
      default: 'black'
    },
    startIconStroke: {
      type: String,
      default: 'black'
    },
    endIcon: {
      type: String,
      default: ''
    },
    endIconSize: {
      type: String,
      default: 'large'
    },
    endIconColor: {
      type: String,
      default: 'black'
    },
    endIconStroke: {
      type: String,
      default: 'black'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'base'
    },
    isClicked: {
      type: Boolean,
      default: false
    },
    isFocused: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    inputStyle: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    valid: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    hasError: {
      type: Boolean,
      default: false
    },
    hasCheckbox: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    },
    checkboxDisabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    shapeClassMap: {
      standard: 'text-base',
      rounded: 'rounded-lg'
    },
    outlinedClasses: {
      'border-black': 'border-2 border-black',
      'border-gray': 'border-2 border-gray-300 focus:ring-black focus:border-black',
      'border-red': 'border border-red-500 focus:ring-red-500 dark:bg-gray-700 focus:border-red-500'
    },
    modeClassMap: {
      gray: 'bg-gray-100',
      light: 'bg-white',
      darkGray: 'bg-gray-300',
      red: 'bg-red-50 placeholder-red-700'
    },
    positionClasses: {
      paddingLeft: 'pl-10',
      paddingRight: 'pr-12'
    },
    sizeClasses: {
      small: 'px-3 py-2',
      base: 'px-5 py-2.5',
      large: 'px-5 py-3'
    }
  }),
  computed: {
    classList() {
      let classList = `${this.modeClassMap[this.mode]} ${
        this.shapeClassMap[this.shape]
      } ${this.outlined && this.outlinedClasses[this.borderColor]} ${
        this.sizeClasses[this.size]
      } disabled:border-0 ml-1
       ${this.isClicked && this.modeClassMap.darkGray} ${this.hasError && this.modeClassMap.red} 
       ${this.hasError && this.outlinedClasses['border-red']}`;

      classList = this.startIcon.length ? `${classList} ${this.positionClasses.paddingLeft}` : `${classList}`;
      classList = this.endIcon.length ? `${classList} ${this.positionClasses.paddingRight}` : `${classList}`;
      classList = this.hasCheckbox ? `${classList} pl-20` : `${classList}`;

      if (this.isFocused && this.valid) {
        return `${classList} border-2`;
      }

      if (this.valid) {
        return `${classList} border-0`;
      }
      return classList;
    },
    startIconClassList() {
      const classList = 'absolute';
      return this.hasCheckbox ? `${classList} left-12` : `${classList} left-4`;
    }
  },
  mounted() {
    if (this.isFocused) {
      this.$refs.labelInput.focus();
    }
  },
  methods: {
    setCheckbox(data) {
      this.$emit('checked-item', data);
    }
  }
};
</script>

<style scoped src="./LabelField.css" />