<template>
  <div>
    <board-element-editor
      v-if="editorType === 'card'"
      :element-id="elementId"
      @close="emit('close')"
    />
    <indicator-editor
      v-else-if="editorType === 'indicator'"
      @close="emit('close')"
    />
    <embed-editor
      v-else-if="editorType === 'embed'"
      @close="emit('close')"
    />
    <note-editor
      v-else-if="editorType === 'note'"
      @close="emit('close')"
    />
    <image-editor
      v-else-if="editorType === 'image'"
      :element-id="elementId"
      @close="emit('close')"
    />
    <bookmark-editor
      v-else-if="editorType === 'bookmark'"
      @close="emit('close')"
    />
    <list-boardget-editor
      v-else-if="editorType === 'list'"
      @close="emit('close')"
    />
  </div>
</template>

<script setup>
import BoardElementEditor from './cardEditor/BoardElementEditor.vue';
import IndicatorEditor from './IndicatorEditor/IndicatorEditor.vue';
import EmbedEditor from './EmbedEditor/EmbedEditor.vue';
import NoteEditor from './NoteEditor/NoteEditor.vue';
import ImageEditor from './ImageEditor/ImageEditor.vue';
import BookmarkEditor from './BookmarkEditor/BookmarkEditor.vue';
import ListBoardgetEditor from './ListBoardgetEditor/ListBoardgetEditor.vue';
import { useStore } from 'vuex';
import { computed, defineEmits, defineProps } from 'vue';

defineProps({
  elementId: {
    type: String,
    required: false
  }
});

const store = useStore();
const editorType = computed(() => store.state.editor.editorType);
const emit = defineEmits(['close']);
</script>
