<template>
  <div
    class="IobFormGroup"
    :class="{
      'IobFormGroup--horizontal': !isVerticalLayout,
      'IobFormGroup--ghost': isGhost,
      'IobFormGroup--error': hasError
    }"
  >
    <div
      class="IobFormGroup-label"
    >
      <label :for="id">{{ label }}</label>
      <span
        v-if="isVerticalLayout && maxCharacters"
        class="IobFormGroup-label-characterLimit"
      >
        {{ inputValue.length }}/{{ maxCharacters }}
      </span>
    </div>
    <div class="IobFormGroup-content">
      <slot>
        <iob-label-field
          :id="id"
          :has-error="hasError"
          size="medium"
        />
      </slot>
      <div
        class="IobFormGroup-footer"
      >
        <div
          class="IobFormGroup-footer-message"
        >
          <div
            class="IobFormGroup-alertMessage"
          >
            <template v-if="message">
              <icon-loader
                class="IobFormGroup-alertMessage-icon"
                size="xsmall"
                name="Info"
              />
              <span class="IobFormGroup-alertMessage-text">
                {{ message }}
              </span>
            </template>
            <span
              v-if="!isVerticalLayout && maxCharacters"
              class="IobFormGroup-label-characterLimit"
            >
              {{ inputValue.length }}/{{ maxCharacters }}
            </span>
          </div>
        </div>
        <div
          v-if="description"
          class="IobFormGroup-description"
        >
          {{ description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, useSlots, computed } from 'vue';
import IconLoader from '../../IconLoader/IconLoader.vue';
import IobLabelField from '../IobLabelField/IobLabelField.vue';

const slots = useSlots();

const isGhost = computed(() => slots.default()[0]?.props?.type === 'ghost');
const inputValue = computed(() => slots.default()[0]?.props?.['model-value'] || slots.default()[0]?.el?.value || '');

defineProps({
  id: {
    type: String,
    default: undefined
  },
  label: {
    type: String,
    default: ''
  },
  maxCharacters: {
    type: Number,
    default: 0,
    validator: (value) => value >= 0
  },
  message: {
    type: String,
    default: ''
  },
  isVerticalLayout: {
    type: Boolean,
    default: true
  },
  hasError: {
    type: Boolean,
    default: false
  },
  description: {
    type: String,
    default: ''
  }
});
</script>
<style lang="scss" scoped src="./IobFormGroup.scss" />
<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-app-form-group.scss" />
<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-font.scss" />
