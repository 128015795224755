<template>
  <div>
    <slot
      :send="send"
      :state="state"
    />
  </div>
</template>

<script>

import { useMachine } from '@xstate/vue';
import panningMachine from 'SRC/machines/interactionModes/panning-machine';
import selectMachine from 'SRC/machines/interactionModes/select-machine';
import linksMachine from 'SRC/machines/interactionModes/links-machine';

const interactionModesConfig = {
  machines: {
    panning: panningMachine,
    select: selectMachine,
    links: linksMachine
  }
};

const allMachines = [interactionModesConfig];
export default {
  props: {
    machine: {
      type: String,
      required: true
    },
    activeMachine: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      send: () => {},
      state: {}
    };
  },
  watch: {
    activeMachine: {
      immediate: true,
      handler(val, oldVal) {
        if (val !== oldVal) {
          const machineContext = allMachines.find((m) => Object.keys(m.machines).indexOf(val) > -1);
          if (val === this.machine) {
            const {machine, options} = machineContext.machines[val];
            const {state, send} = useMachine(machine, options);
            this.state = state;
            this.send = send;
          } else {
            this.state = {};
            this.send = () => {};
          }
        }
      }
    }
  }
};
</script>
