<template>
  <IobModal
    id="BookmarkCreation-modal"
    modal-type="popup"
    size="xSmall"
    :show-modal="true"
    :has-sidebar-footer="true"
    :should-close-on-outside-click="false"
    @close="close"
  >
    <template #popupHeader>
      <div class="BookmarkCreation-header">
        <span class="BookmarkCreation-title">{{ $t('bookmark.button.create.title') }}</span>
      </div>
    </template>
    <template #popupContent>
      <form class="BookmarkCreation-form">
        <div class="BookmarkCreation-formGroup mb-4">
          <div class="pb-3">
            {{ $t('bookmark.button.link.label') }}
          </div>
          <iob-textarea
            :value="link"
            :is-error="error"
            :is-focused="true"
            :error-message="$t('bookmark.button.link.error')"
            :placeholder="$t('bookmark.button.link.placeholder')"
            max-caracters="2048"
            styles="min-height: 68px"
            type="outlined"
            size="default"
            @update:modelValue="validateLink"
          />
        </div>

        <div class="BookmarkCreation-formGroup flex flex-col gap-2">
          <div>
            {{ $t('bookmark.button.label.label') }}
          </div>
          <iob-label-field
            :model-value="label"
            :placeholder="$t('bookmark.button.label.placeholder')"
            has-clear-button
            @update:modelValue="(value) => label = value"
            @keydown.enter.prevent="initBoardElement"
          />
          <span class="empty-description">{{ $t('bookmark.button.label.description') }}</span>
        </div>
      </form>
    </template>
    <template #popupFooter>
      <div class="BookmarkCreation-buttonGroup">
        <IobButton
          :label="$t('btn-cancel')"
          color="secondarySoft"
          size="medium"
          type="filled"
          @click="close"
        />
        <IobButton
          :label="$t('bookmark.button.create.button.add')"
          :disabled="link.length === 0 || error"
          color="primary"
          size="medium"
          type="filled"
          @click="initBoardElement"
        />
      </div>
    </template>
  </IobModal>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex';
import {
  INTERACTION_MODES
} from 'GLOBALS/constants.js';
import parser from '../utils/parser';

export default {
  name: 'BookmarkButtonCreationModal',
  data: () => ({
    label: '',
    link: '',
    error: false
  }),
  computed: {
    ...mapState('app', ['dataTypes', 'viewTypes']),
    ...mapState('board', [
      'selectedInteractionMode'
    ]),
    ...mapGetters('app', ['getViewTypeSize']),
    ...mapGetters('board', ['getElementInitialPosition']),
    ...mapState({
      boardId: (state) => state.board.id,
      viewTypeId: (state) => state.dock.viewTypeId
    })
  },
  methods: {
    ...mapMutations('board', [
      'setSelectedInteractionMode'
    ]),
    ...mapActions('board', ['createBoardElement', 'selectBoardElements']),
    validateLink(link) {
      this.error = parser(link);
      this.link = link;
    },
    async initBoardElement() {
      this.validateLink(this.link);
      if (this.error) {
        return;
      }
      const size = this.getViewTypeSize(this.viewTypeId);
      const requestBody = {
        boardId: this.boardId,
        viewTypeId: this.viewTypeId,
        position: this.getElementInitialPosition(size),
        datasetElement: {
          attributes: {
            link: this.link,
            label: this.label
          }
        },
        width: size.width,
        height: size.height
      };
      this.elementId = await this.createBoardElement({
        requestBody,
        dataTypeName: 'Bookmark'
      });
      if (this.selectedInteractionMode === INTERACTION_MODES.PANNING) {
        this.setSelectedInteractionMode({
          value: INTERACTION_MODES.SELECT
        });
      }
      this.close();
      this.selectBoardElements({ boardElementsIds: [this.elementId] });
    },
    close() {
      this.$store.commit('dock/setCreationComponent', null);
    }
  }
};
</script>
<style src="./BookmarkButtonCreationModal.scss" lang="scss" />
