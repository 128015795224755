<template>
  <iob-spinner
    :color="loaderColor"
    :size="loaderSize"
  />
</template>

<script setup>
import { computed, defineProps } from 'vue';
import { iconSizesMap } from '../../../constants';
import IobSpinner from '../IobSpinner/IobSpinner.vue';

const props = defineProps({
  color: {
    type: String,
    default: 'primary',
  },
  size: {
    type: String,
    default: 'default',
  },
});

const colorClassMap = {
  primary: 'var(--loader-circle-color-primary)',
  secondary: 'var(--loader-circle-color-secondary)',
  alert: 'var(--loader-circle-color-alert)',
  black: 'var(--loader-circle-color-black)',
  white: 'var(--loader-circle-color-white)',
};

const loaderColor = computed(() => colorClassMap[props.color]);

const loaderSize = computed(() => iconSizesMap[props.size]);
</script>
