<template>
  <div class="IobBadgeDropdown">
    <iob-badge
      :color="color"
      secondary-icon="ChevronDown"
      :size="size"
      :selectable="true"
      :label="title"
      @click="toggleDropdownClick"
    />

    <outside-click-listener @outside-click="handleClickOutsideDropdown">
      <iob-dropdown
        v-if="toggleDropdown && items.length > 1"
        :items="itemsDropdown"
        class="IobBadgeDropdown-dropdown"
        @dropdown-element-item="handleDropdownItemClick"
      />
    </outside-click-listener>
    <div />
  </div>
</template>     
  
<script setup>
import { ref, defineProps, computed } from 'vue'; 
import IobDropdown from '../../Molecules/IobDropdown/IobDropdown.vue'; 
import IobBadge from '../../Atoms/IobBadge/IobBadge.vue';
import OutsideClickListener from '../../OutsideClickListener/OutsideClickListener.vue';

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  color: {
    type: String,
    default: 'secondary'
  },
  size: {
    type: String,
    default: 'default'
  },
  items: {
    type: Array,
    default: ()=>[]
  }
});
    
const toggleDropdown = ref(false);
const emits = defineEmits(['dropdownElementClick']);

const itemsDropdown = computed(() => {
  return props.items.map(item => {
    return {
      text: `${item.text}`,
      state: 'default',
      iconName: `${item.iconName}`,
      iconSize: 'default',
      type: 'menu',
      size:'xs',
    }
  });
});

const toggleDropdownClick = (e)=> {
  e.stopPropagation();
  toggleDropdown.value = !toggleDropdown.value;
};

const handleDropdownItemClick = ({item}) => {
  emits('dropdownElementClick', item);
  toggleDropdown.value = false;
};

const handleClickOutsideDropdown = () => {
  toggleDropdown.value = false;
};

</script>
  
  <style lang="scss" src="./IobBadgeDropdown.scss" scoped />