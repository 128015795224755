<template>
  <table class="IobTableMapInput IobTableMapInput-table">
    <thead>
      <tr>
        <th />
        <th class="IobTableMapInput-text">
          DATE
        </th>
        <th class="IobTableMapInput-text">
          TARGET
        </th>
        <th class="IobTableMapInput-text">
          ACTUAL VALUE
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="({ name, value, timeSeriesPoint}, index) in formattedMap"
        :key="name + value + timeSeriesPoint.value"
        class="IobTableMapInput IobTableMapInput-row"
      >
        <td class="IobTableMapInput IobTableMapInput-cellIndex IobTableMapInput-cell--index">
          {{ index + 1 }}
        </td>
        <td>
          {{ parseDate(parseDateByFrequency(name, timeSeriesFrequency)?.startDate) }}
        </td>
        <td class="IobTableMapInput IobTableMapInput-cell">
          <input
            :id="name + value + index"
            type="number"
            step="0.1"
            :value="value"
            class="IobTableMapInput-input"
            @keydown="(event) => handleKeyDown(event, index)"
            @change="(event) => handleInput({ event, name })"
          >
        </td>
        <td class="IobTableMapInput IobTableMapInput-cell">
          <input
            :id="name + actualValue + index"
            type="number"
            step="0.1"
            :value="timeSeriesPoint.value"
            class="IobTableMapInput-input"
            @keydown="(event) => handleKeyDown(event, index)"
            @change="(event) => handleTimeSeriesPoint({ event, timeSeriesPoint, name })"
          >
        </td>
      </tr>
      <tr>
        <td
          colspan="2"
          class="IobTableMapInput IobTableMapInput-cell IobTableMapInput-cell--disabled"
        >
          Final target
        </td>
        <td
          colspan="2"
          class="IobTableMapInput IobTableMapInput-cell IobTableMapInput-cell--disabled"
        >
          {{ String(props.finalTarget) }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import {computed, defineEmits, defineProps} from 'vue';
import {DateArrayGenerator} from '../IobTableMapDatesWrapper/DateArrayGenerator';
import {
  checkTimestampAgainstPointFrequency,
  parseDate,
  parseDateByFrequency,
  splitFrequency,
  utcDate
} from '../../../timeSeriesUtils';

const emit = defineEmits(['updateRow', 'handleTimeSeriesPoint']);

const props = defineProps({
  map: {
    type: Object,
    default: () => ({}),
  },
  initialValue: {
    type: String,
    default: ''
  },
  finalTarget: {
    type: String,
    default: ''
  },
  horizonValue: {
    type: String,
    default: ''
  },
  timeSeriesFrequency: {
    type: String,
    default: ''
  },
  timeSeriesData: {
    type: Array,
    default: () => []
  }
});

const formattedMap = computed(() => {
  return Object.entries(buildTableData.value).map(([name, values]) => ({
    name,
    value: values.targetValue,
    timeSeriesPoint: values.timeSeriesPoint
  }));
});

const handleInput = ({event, name}) => {
  const value = event.target.value;
  emit('updateRow', {name, value})
};

const parseTimeSeriesData = computed(() => {
  if (!props.timeSeriesData || Object.entries(props.timeSeriesData).length === 0) {
    return {};
  }
  const dateArrayGenerator = new DateArrayGenerator();
  const { frequency } = splitFrequency(props.timeSeriesFrequency);

  dateArrayGenerator.frequency = frequency;
  const parsedTimeSeriesData = {};
  props.timeSeriesData
    .forEach((item) => {
      const timestamp = item.timestamp;
      dateArrayGenerator.startDate = utcDate(timestamp);
      dateArrayGenerator.endDate = utcDate(timestamp, 23, 59, 59);
      const formattedDate = dateArrayGenerator.generate();
      if (checkTimestampAgainstPointFrequency(timestamp, props.timeSeriesFrequency, formattedDate[0])) {
        parsedTimeSeriesData[formattedDate[0]] = item;
      }
    });
  return parsedTimeSeriesData;
});

const buildTableData = computed(() => {
  return  Object.keys(props.map).reduce((acc, el) => {
    const targetValue = props.map[el] || '';
    const timeSeriesPoint = parseTimeSeriesData.value[el] || {id: '', timestamp: '', value: ''};
    acc[el] = {targetValue, timeSeriesPoint};
    return acc;
  }, {});
});

const handleTimeSeriesPoint = ({ event, timeSeriesPoint, name:date }) => {
  timeSeriesPoint.value = event.target.value;
  if (!timeSeriesPoint.timestamp) {
    timeSeriesPoint.timestamp = parseDateByFrequency(date, props.timeSeriesFrequency).startDate?.toISOString();
  }
  emit('handleTimeSeriesPoint', timeSeriesPoint);
};

const focusAdjacentRow = (adjacentCellIndex) => {
  const adjacentCellData = formattedMap.value[adjacentCellIndex];
  const adjacentCellId = `${adjacentCellData.name}${adjacentCellData.value}${adjacentCellIndex}`;
  const adjacentCell = document.getElementById(adjacentCellId);
  adjacentCell.focus();
};

const handleKeyDown = (event, index) => {
  const nextCellKeys = ['Tab', 'Enter'];
  const shiftKeyOn = event.shiftKey;
  const key = event.key;
  if (key === 'Tab') {
    event.preventDefault();
  }
  if (nextCellKeys.indexOf(key) < 0) {
    return;
  }
  const adjacentRowIndex = (index + (shiftKeyOn ? -1 : 1)) % formattedMap.value.length;
  focusAdjacentRow(adjacentRowIndex);
}
</script>
<style lang="scss" scoped src="./IobTimeSeriesTableMapInput.scss" />
