<template>
  <div
    class="BookmarkButton-wrapper"
    data-target="board-element"
    :data-link="link"
    :style="containerStyle"
  >
    <div
      class="BookmarkButton flex flex-row h-full justify-center items-center"
      data-target="board-element"
    >
      <div
        data-target="board-element"
        class="grow text-center BookmarkButton--text truncate"
      >
        {{ computedLabel }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { getContrastedColor, hextToInt } from 'BOARD/utils/utils';
import { DEFAULT_CARD_TEXT_COLOR } from 'GLOBALS/constants';

const props = defineProps({
  color: {
    type: String,
    required: false
  },
  label: {
    type: String,
    required: false
  },
  link: {
    type: String,
    required: true
  },
  width: {
    type: Number,
    required: true
  },
  height: {
    type: Number,
    required: true
  }
});

const computedLabel = computed(() => props.label?.length > 0 ? props.label : props.link);

const containerStyle = computed(() => ({
  width: `${props.width}px`,
  height: `${props.height}px`
}));

const contrastedColor = computed(() => getContrastedColor(hextToInt(props.color), DEFAULT_CARD_TEXT_COLOR.light, DEFAULT_CARD_TEXT_COLOR.dark));

const radius = computed(() => `${props.height / 6}px`);
</script>

<style scoped lang="scss">
.BookmarkButton-wrapper {
  position: relative;
  border-radius: v-bind(radius);
  background-color: v-bind(color);
  container-type: size;
  cursor: pointer;
}

.BookmarkButton {
  padding: 0 50cqh;

  &--text {
    font-size: 33cqh;
    color: v-bind(contrastedColor);
  }
}

</style>
