<template>
  <div
    class="IobListWithIdElement focus-inside"
    tabindex="0"
    @click.prevent="(event) => $emit('handle-dropdown-element', event)"
    @keyup.enter="() => $emit('handle-dropdown-element')"
  >
    <div class="IobListWithIdElement-indicator" />
    <iob-shape-color :color="shapeColor" />
    <div
      v-if="title && title.length"
      class="IobListWithIdElement-titleContainer"
    >
      <div class="IobListWithIdElement-title">
        {{ title }}
      </div>
    </div>
    <div
      v-if="text && text.length"
      class="IobListWithIdElement-textContainer"
    >
      <div class="IobListWithIdElement-text">
        {{ beforeSearchTerm }}<strong>{{ searchTerm }}</strong>{{ afterSearchTerm }}
      </div>
    </div>
  </div>
</template>

<script setup>
import IobShapeColor from '../../Atoms/IobShapeColor/IobShapeColor.vue';
import { defineProps, computed } from 'vue';

const props = defineProps({
  text: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  },
  shapeColor: {
    type: String,
    default: ''
  },
  searchValue: {
    type: String,
    default: ''
  }
});

const beforeSearchTerm = computed(() => {
  const index = props.text.toLowerCase().indexOf(props.searchValue.toLowerCase());
  return index >= 0 ? props.text.substring(0, index) : props.text;
});

const searchTerm = computed(() => {
  const index = props.text.toLowerCase().indexOf(props.searchValue.toLowerCase());
  return index >= 0 ? props.text.substring(index, index + props.searchValue.length) : '';
});

const afterSearchTerm = computed(() => {
  const index = props.text.toLowerCase().indexOf(props.searchValue.toLowerCase());
  return index >= 0 ? props.text.substring(index + props.searchValue.length) : '';
});
</script>

<style lang="scss" src="./IobListWithIdElement.scss" scoped />
