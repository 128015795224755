<template>
  <div
    :key="defaultXaxis.length"
    :style="containerStyle"
  >
    <apex-chart
      v-if="!isResizing"
      :id="elementId"
      data-target="board-element"
      :width="width - INDICATOR_CHART_PADDING"
      :height="height - INDICATOR_CHART_PADDING"
      type="line"
      :options="defaultXaxis"
      :series="defaultYaxis"
      :style="`min-height: ${height - 20}`"
    />
  </div>
</template>

<script setup>
import {useStore} from 'vuex';
import {computed, defineProps} from 'vue';
import {DatesSorter} from './DatesSorter';
import {FEATURES, GLIDEPATH_KEYS_LIMIT, INDICATOR_CHART_PADDING, OPTIONS, SERIES} from 'GLOBALS/constants';
import {filterDates, getGlidepathValuesFromCurrentDate, getKPIHorizonWindow} from './IndicatorChartUtils';
import {deepClone, isFeatureEnabled} from 'SRC/utils/utils';
import {splitFrequency} from 'BOARD/utils/timeSeriesUtils';

const store = useStore();

const props = defineProps({
  attributes: {
    type: Object,
    default: () => {}
  },
  elementId: {
    type: String,
    required: true
  },
  datasetElementId: {
    type: String,
    required: true
  },
  hasOutline: {
    type: Boolean,
    default: false
  },

  outlineColor: {
    type: String,
    required: true
  },
  height: {
    type: Number,
    required: true
  },
  width: {
    type: Number,
    required: true
  },
  canBeHighlighted: {
    type: Boolean,
    default: true
  },
  isResizing: {
    type: Boolean,
    required: true
  }
});

const data = computed(() => store.state.board.indicatorsRelations);
const zoomLevel = computed(() => store.state.board.zoomLevel);
const selectedDatasetElement = computed(() => store.state.board.datasetElements[props.datasetElementId]);
const timeWindow = computed(() => selectedDatasetElement.value?.attributes?.timeWindow ?? {});
const shouldUseTimeSeriesData = computed(() => isFeatureEnabled(store.state.app.featuresList, FEATURES.KPI_HISTORIZATION_FEATURE));
const containerStyle = computed(() => {
  const initialStyle = {
    height: `${props.height}px`,
    width: `${props.width}px`,
    cursor: 'default'
  };
  if (props.hasOutline) {
    return {
      ...initialStyle,
      borderRadius: '8px',
      outline: `${Math.max(2, 2 * (1 / zoomLevel.value))}px ${props.outlineColor} solid`
    };
  }
  return { ...initialStyle };
});
const relatedDatasetElements = computed(() => {
  if (!data.value) {
    return '';
  }
  const relatedDatasetElements = data.value[props.datasetElementId] || '';
  return relatedDatasetElements;
});
const graphData = computed(() => {
  if (!relatedDatasetElements.value) {
    return '';
  }
  const graphDatas = deepClone(relatedDatasetElements.value[0]);
  const {attributes} = graphDatas;
  if (attributes?.glidepathValues && Object.keys(attributes.glidepathValues).length) {
    const frequency = getFrequency(attributes);
    const dateRange = getIndicatorDateRange(graphDatas);
    attributes.glidepathValues = getGlidepathValuesFromCurrentDate(attributes.glidepathValues, frequency, GLIDEPATH_KEYS_LIMIT, dateRange);
  }
  return graphDatas;
});

const getIndicatorDateRange = (kpi) => {
  if (!shouldUseTimeSeriesData.value) {
    return {startDate: null, endDate: null};
  }
  const kpiTimeWindow = getKPIHorizonWindow(kpi);
  if (timeWindow.value?.['start-date']) {
    kpiTimeWindow.startDate = new Date(timeWindow.value['start-date']);
  }
  if (timeWindow.value?.['end-date']) {
    kpiTimeWindow.endDate = new Date(timeWindow.value['end-date']);
  }
  return kpiTimeWindow;
};

const getFrequency = (attributes) => {
  const {glidepathFrequency, timeSeriesFrequency} = attributes;
  return shouldUseTimeSeriesData.value ? splitFrequency(timeSeriesFrequency).frequency : glidepathFrequency;
};

const orderedDates = (graphAttributes) => {
  const {glidepathFrequency, timeSeriesFrequency, glidepathValues} = graphAttributes;
  const datesArray = Object.keys(glidepathValues);
  let frequency;
  let filteredDates;
  if (shouldUseTimeSeriesData.value) {
    const splitTimeSeriesFrequency = splitFrequency(timeSeriesFrequency);
    frequency = splitTimeSeriesFrequency.frequency;
    filteredDates = filterDates(datesArray, timeWindow.value, timeSeriesFrequency);
  } else {
    frequency = glidepathFrequency;
    filteredDates = datesArray;
  }
  const datesSorterInstance = new DatesSorter(frequency, filteredDates);
  return datesSorterInstance.generate();
};

const defaultXaxis = computed(() => {
  if (
    !graphData.value ||
    !graphData.value.attributes ||
    !graphData.value.attributes.glidepathValues
  ) {
    return OPTIONS;
  }
  const ordered = orderedDates(graphData.value.attributes);
  const attributes = selectedDatasetElement.value?.attributes;
  const title = attributes?.name || '';
  return {
    ...OPTIONS,
    stroke: {
      width: 5
    },
    title: {
      text: title,
      align: 'left',
      style: { fontSize: '28px' }
    },
    xaxis: {
      categories: ordered,
      labels: { style: { fontSize: '28px', rotate: -45 } }
    }
  };
});

const defaultYaxis = computed(() => {
  if (
    !graphData.value ||
    !graphData.value.attributes ||
    !graphData.value.attributes.glidepathValues
  ) {
    return SERIES;
  }

  const ordered = orderedDates(graphData.value.attributes);

  const glidepathValues = graphData.value.attributes.glidepathValues;
  const { targetValueComparator } = graphData.value.attributes;
  const targetAchievedData = [];
  const targetNotAchievedData = [];

  ordered.forEach((el) => {
    const traceValue = graphData.value.traces.get(el) || 0;
    const glidepathValueToday = glidepathValues[el] || 0;
    const targetAchievedCondition =
      (targetValueComparator === '≤' && traceValue <= glidepathValueToday) ||
      (targetValueComparator === '≥' && traceValue >= glidepathValueToday) ||
      (targetValueComparator === '<' && traceValue < glidepathValueToday) ||
      (targetValueComparator === '>' && traceValue > glidepathValueToday);

    const targetNotAchievedCondition =
      (targetValueComparator === '≤' && traceValue > glidepathValueToday) ||
      (targetValueComparator === '≥' && traceValue < glidepathValueToday) ||
      (targetValueComparator === '<' && traceValue >= glidepathValueToday) ||
      (targetValueComparator === '>' && traceValue <= glidepathValueToday);

    targetAchievedData.push(targetAchievedCondition ? traceValue : null);
    targetNotAchievedData.push(targetNotAchievedCondition ? traceValue : null);
  });

  return [
    {
      name: 'Target',
      type: 'line',
      data: ordered.map((el) => glidepathValues[el] || 0),
      color: '#2D6BFF'
    },
    {
      name: 'Target achieved',
      type: 'column',
      data: targetAchievedData,
      color: '#23C773'
    },
    {
      name: 'Target not achieved',
      type: 'column',
      data: targetNotAchievedData,
      color: '#E81B11'
    }
  ];
});

</script>
