<template>
  <div class="IobIconWithLabel">
    <iob-icon-shape
      :icon-name="icon"
      :size="getIconSize"
      :custom-dimension="customDimension"
      :custom-icon-dimension="customIconDimension"
      type="square"
      :color="color"
    />
    <span
      :style="labelStyle"
      class="IobIconWithLabel-label"
    >{{ label }}</span>
  </div>
</template>

<script>
import IobIconShape from '../Atoms/IobIconShape/IobIconShape.vue';

export default {
  name: 'IobIconWithLabel',
  components: { IobIconShape },
  props: {
    icon: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'small'
    },
    color: {
      type: String,
      default: 'secondary'
    },
    labelStyle: {
      type: String,
      default: ''
    },
    customDimension: {
      type: String,
      default: ''
    },
    customIconDimension: {
      type: String,
      default: ''
    }
  },
  computed: {
    getIconSize() {
      switch (this.size) {
      case 'xs':
        return 'xsmall';
      case 's':
        return 'small';
      default:
        return this.size;
      }
    }

  }
};
</script>

<style scoped src="./IobIconWithLabel.css"></style>
