import * as Vue from 'vue';
import App from 'SRC/App.vue';
import router from 'SRC/router';
import store from 'SRC/store';
import { enableVueBindings } from '@syncedstore/core';
import './index.css';
import 'flowbite';
import './assets/css/style.css';
import DesignSystemsComponents from '@iobeya/design-system';

// Global imports for design-tokens
import 'iobeya-design-tokens/scss/app/iobeya-app-form-group.scss';
import 'iobeya-design-tokens/scss/app/iobeya-list-elements.scss';
import 'iobeya-design-tokens/scss/app/iobeya-textInput.scss';

import { h, createApp } from 'vue';
import singleSpaVue from 'single-spa-vue';
import i18n from '@/plugins/locales/i18n';
import { EventEmitter } from 'events';
import VueApexCharts from 'vue3-apexcharts';

//This is added because more than 10 eventEmitters were used in circuitBreaker
const emitter = new EventEmitter();
emitter.setMaxListeners(50);

enableVueBindings(Vue);

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    Vue,
    render() {
      return h(App, {
        name: this.name,
        mountParcel: this.mountParcel,
        singleSpa: this.singleSpa
      });
    }
  },
  handleInstance: (app) => {
    app.use(router)
      .use(i18n)
      .use(DesignSystemsComponents)
      .use(store);
    app.use(VueApexCharts);
    app.component('ApexChart', VueApexCharts);
  }
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
