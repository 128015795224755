<template>
  <div
    class="IobInputAvatar"
  >
    <iob-label-field
      :placeholder="placeholder"
      has-clear-button
      type="ghost"
      :show-caracters-counter="false"
      :size="inputSize"
      :class="classList"
      :model-value="searchInput"
      @click="toggleDropdownClick"
      @update:modelValue="searchInput = $event"
      @focus="handleFocus"
    >
      <iob-avatar
        :key="selectedUser && selectedUser.picture"
        size="small"
        :picture="selectedUser ? selectedUser.picture : ''"
        :deleted-user="!selectedUser || !searchInput.length || !selectedUser.id"
        :name="getUsername"
        :alt-text="getInitials(selectedUser)"
      />
    </iob-label-field>
    <outside-click-listener
      v-if="toggleDropdown"
      :id="id"
      @outside-click="handleClickOutsideDropdown"
    >
      <iob-dropdown
        v-if="toggleDropdown && usersDropdown.length"
        :key="usersDropdown.map(el => el.id).join('-')"
        :items="usersDropdown"
        :search-value="searchInput"
        :is-scrollable="true"
        class="IobInputAvatar--dropdown"
        @dropdown-element-item="handleDropdownItemClick"
      />
    </outside-click-listener>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed, onMounted, watch  } from 'vue';
import IobDropdown from '../../Molecules/IobDropdown/IobDropdown.vue';
import IobLabelField from '../../Molecules/IobLabelField/IobLabelField.vue';
import IobAvatar from '../../Molecules/IobAvatar/IobAvatar.vue';
import OutsideClickListener from '../../OutsideClickListener/OutsideClickListener.vue';
// import { parseIfJson } from '../../../utils';

const props = defineProps({
  currentUser: {
    type: Object,
    default: () => { }
  },
  users: {
    type: Array,
    default: () => []
  },
  placeholder: {
    type: String,
    default: ''
  },
});
const emits = defineEmits(['dropdownElementClick']);
const selectedUser = ref(props.currentUser);
const toggleDropdown = ref(false);
const searchInput = ref('');
const isFocused = ref(false);

const classList = computed(() => {
  return {
    'IobInputAvatar--input': true,
    'IobInputAvatar--focus': toggleDropdown.value
  }
});

onMounted(() => {
  searchInput.value = props.currentUser ? `${props.currentUser.firstname} ${props.currentUser.lastname}` : ''
});

watch(() => props.currentUser, (value) => {
  if (!value) {
    selectedUser.value = {};
  }
  else {
    searchInput.value = props.currentUser ? `${props.currentUser.firstname} ${props.currentUser.lastname}` : '';
    selectedUser.value = value;
  }
}, {immediate: true});

const filteredUsers = computed(() => {
  const searchQuery = searchInput.value.trim().toLowerCase();

  if (!searchQuery) {
    return props.users;
  }

  const filtered =  props.users.filter((user) => {
    const firstname = user.firstname ? user.firstname.trim().toLowerCase() : ''
    const lastname = user.lastname ? user.lastname.trim().toLowerCase() : ''
    const fullName = `${firstname} ${lastname}`;
    
    return [firstname, lastname, fullName].some(field => field.includes(searchQuery));
  });
  if(filtered.length === 0) {
    return props.users;
  }
  else {
    return filtered;
  }
});

const usersDropdown = computed(() => {
  const filteredUserss = filteredUsers.value.map(item => {
    return {
      title: `${item.firstname} ${item.lastname}`,
      firstname: item.firstname,
      lastname: item.lastname,
      picture: item.picture,
      id: item.id,
      showIcon: true,
      iconName: `${item.id === selectedUser.value.id ? 'Check' : ''}`,
      avatar: {
        picture: item.picture,
        altText: getInitials(item),
        colorLeft: false,
        color: item.color,
        size: 'default',
      },
      type: 'member'
    }
  })
  return filteredUserss;
});
const getUsername = computed(() => {
  return `${selectedUser.value.firstname || ''} ${selectedUser.value.lastname || ''}`;
})
const getInitials = (item) => {
  return `${item.firstname ? item.firstname[0].toUpperCase() : 'U'}${item.lastname ? item.lastname[0].toUpperCase() : 'N'}`
}
const handleFocus = (focus) => {
  isFocused.value = focus;
};
const handleDropdownItemClick = ({ item }) => {
  emits('dropdownElementClick', item.id);
  selectedUser.value = item;
  searchInput.value = item.title;
  toggleDropdown.value = false;
};

const toggleDropdownClick = () => {
  toggleDropdown.value = !toggleDropdown.value;
};

const handleClickOutsideDropdown = () => {
  toggleDropdown.value = false;
};

</script>

<style lang="scss" src="./IobInputAvatar.scss" scoped />