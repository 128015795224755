<template>
  <div
    id="dropdownDelay"
    class="colorsPopover z-10"
  >
    <div
      aria-labelledby="dropdowButton"
      class="colors-list"
    >
      <colored-circle
        v-for="(color, index) of colorsList"
        :key="index"
        :color="color"
        :style="(index % 8 !== 7 || index === 0) && 'margin-right: 8px'"
        :is-selected="color === selectedColor"
        @select-color="$emit('select-color', color)"
      />
    </div>

    <div
      class="customColor-label"
      :style="'margin-top: 17px;'"
    >
      Custom color
    </div>
    <div
      aria-labelledby="dropdowButton"
      class="colors-list-second"
    >
      <colored-circle
        v-for="(color, index) of customColors"
        :key="index"
        :color="color"
        :style="`margin-bottom: 6px; ${(index % 8 !== 7 || index === 0) && 'margin-right: 8px'}`"
        :is-selected="color === selectedColor"
        @select-color="$emit('select-color', color)"
      />
      <div
        class="plus-container cursor-pointer"
        data-popover-target="popover-hover"
        data-popover-trigger="hover"
        @click="openColorPicker"
      >
        <icon-loader
          style="margin: auto;"
          name="Plus"
          color="#3C3C43"
          :size="iconSize"
        />
      </div>
    </div>
    <tailwind-color-picker
      v-if="isColorPicker"
      class="absolute"
      style="transform: translateY(-302px);"
      @close-picker="handleClosePicker"
    />
  </div>
</template>

<script>
import { COLOR_PALETTE } from '../../constants';
import ColoredCircle from '../ColoredCircle/ColoredCircle.vue';
import TailwindColorPicker from '../ColorPicker/ColorPicker.vue';
import IconLoader from '../IconLoader/IconLoader.vue';

export default {
  name: 'ColorsPopover',
  components: {
    ColoredCircle,
    TailwindColorPicker,
    IconLoader
  },
  props: {
    selectedColor: {
      type: String,
      default: ''
    },
    iconSize: {
      type: String,
      default: 'small'
    },
  },
  emits: ['select-color'],
  data: () => ({
    COLOR_PALETTE,
    colorsList: COLOR_PALETTE.slice(8, COLOR_PALETTE.length),
    isColorsPopup: false,
    isColorPicker: false,
    customColors: []
  }),
  methods: {
    openColorPicker(e) {
      this.isColorPicker = true;
      e.stopPropagation();
    },
    closePicker() {
      this.isColorPicker = false;
    },
    handleClosePicker(value) {
      if (value) {
        this.customColors = [...this.customColors, value];
      }
      this.closePicker();
    }
  }
};
</script>

<style scoped src="./ColorsPopover.css" />
