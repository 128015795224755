<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps({
  type: {
    type: String,
    default: 'square',
  },
  size: { type: String, default: 'default' },
  color: { type: String, default: '' },
});

const classList = computed(() => {
  return [
    'IobShapeColor',
    `IobShapeColor--${props.size}`,
    `IobShapeColor-${props.type}`,
  ];
});
</script>

<template>
  <div
    :class="classList"
    :style="`background: ${color}`"
  />
</template>

<style lang="scss" scoped src="./IobShapeColor.scss" />
