import { ZOOM, INTERACTION_MODES, BOARD_SIZE } from 'GLOBALS/constants.js';

const boardId = '00000000-0000-0000-0000-000000000000';

const
  initialState = () => ({
    id: boardId,
    boardSize: BOARD_SIZE,
    assets: {},
    selectedDatasetElements: [],
    dragging: false,
    canSelect: true,
    zoomLevel: ZOOM.DEFAULT_LEVEL,
    zoomWheelDeltaRatio: ZOOM.WHEEL_DELTA_RATIO,
    zoomMultiplier: ZOOM.STEP_MULTIPLIER,
    zoomLevelMin: ZOOM.LEVEL_MIN,
    viewportReferencePointOrigin: { x: 0, y: 0 },
    viewportReferencePoint: { x: 0, y: 0 },
    panX: 0,
    panY: 0,
    dx: 0,
    dy: 0,
    elements: {},
    datasetElements: {},
    filters: {},
    boardElementsInEdition: {},
    boardElementsBeingMoved: {},
    canUndo: false,
    canRedo: false,
    selectedInteractionMode: INTERACTION_MODES.SELECT,
    boardElementsBeingSelected: {},
    openedComponents: [],
    currentVersion: {},
    recentVersions: [],
    oldVersions: {},
    hotKeys: [],
    highlightedBoardElements: [],
    unHighlightedBoardElements: [],
    selectedVersion: null,
    mode: null,
    contextualMenuShown: true,
    userSelectedElements: [],
    lastBoardElementIdInEdition: null,
    isMultipleMove: false,
    translation: {
      x: 0,
      y: 0
    },
    formulaInEditionBoardElementId: null,
    isCursorLocked: false,
    dialogBoxFlags: {
      isCancelElementRelationsEditing: false,
      isAddElementToBoardgetListDialogOpened: false,
      isDeleteBoardDialogOpened: false,
      isDeleteRelationDialogOpened: false
    },
    dialogBoxInfo: null,
    specificDialogBoxFlags: {
      isHistoryDialogOpened: false
    },
    versionToBeDeletedId: null,
    notifications: {
      restoreVersion: { isNotificationDisplayed: false, message: '', actionName: '', callbackfn: () => { }, isCustomized: false },
      checkViewTypes: { isNotificationDisplayed: false, message: '', isCustomized: false },
      unmarkVersion: { isNotificationDisplayed: false, message: '', actionName: '', callbackfn: () => { }, isCustomized: false },
      addElementToListNotification: { isNotificationDisplayed: false, title: '', message: '', actionName: '',
        callbackfn: () => { }, isCustomized: true }
    },
    clickedElementId: null,
    history: [],
    nextPageBoardHistoryUrl: null,
    datasetElementHistory: [],
    nextPageDatasetElementHistoryUrl: null,
    fieldTypebeingManaged: null,
    fieldTypebeingAdded: null,
    selectedLinkType: null,
    isLinksMenuOpen: false,
    link: { source: null, target: null },
    warning: '',
    backgroundImage: {
      url: null,
      name: null
    },
    boardData: {},
    collabSessionReady: false,
    indicatorsRelations: [],
    didFetchIndicators: false,
    didRetrieveDatasetElements: false,
    didRetrieveBoardElements: false,
    userInteractionOnBoardElements: null,
    isSelectionRectangle: false
  }
  );
export default initialState;
