<template>
  <div
    class="ScrollBar"
    :style="scrollContainerStyle"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ScrollBar',
  props: {
    scrollContainerStyle: {
      type: String,
      default: ''
    }
  }
};
</script>

<style src="./ScrollBar.css" />
