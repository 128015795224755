<template>
  <div class="DropdownButtonFlowbite">
    <label
      v-if="title"
      class="block mb-2 text-sm font-medium text-gray-900"
    >{{ title }}
    </label>
    <outside-click-listener @outside-click="handleClickOutsideDropdown">
      <div
        class="DropdownButtonFlowbite-content"
        @click="toggleSearchMenu"
      >
        <custom-button
          :id="buttonId"
          :label="selectedItem.label"
          :mode="mode"
          :disabled="disabled"
          :border-color="borderColor"
          :button-style="buttonStyle"
          :icon-style="iconStyle"
          :icon-name="selectedItem.iconName || ''"
          :size="iconSize"
          :icon-color="iconColor"
          :icon-stroke="iconStroke"
          outlined
        />
        <icon-loader
          :key="iconName"
          :name="iconName"
          :stroke="chevronStroke"
          style="margin-left: -30px;"
        />
      </div>
      <search-menu
        v-if="isDropdownMenuOpen"
        v-model="selectedItem"
        :options="options"
        :input-data="searchInput"
        :search-field="searchField"
        :search-menu-style="searchMenuStyle"
        :options-container-height="optionsContainerHeight"
        @click-item="selectItem"
      />
    </outside-click-listener>
  </div>
</template>

<script>
import IconLoader from '../IconLoader/IconLoader.vue';
import SearchMenu from '../SearchMenu/SearchMenu.vue';
import CustomButton from '../CustomButton/CustomButton.vue';
import OutsideClickListener from '../OutsideClickListener/OutsideClickListener.vue';
import { parseIfJson } from '../../utils';

export default {
  name: 'DropdownButtonFlowbite',
  components: { IconLoader, SearchMenu, CustomButton, OutsideClickListener },
  props: {
    options: {
      type: Array,
      required: true
    },
    buttonStyle: {
      type: String,
      default: ''
    },
    defaultValue: {
      type: [String, Object],
      default: () => ({ label: 'button label' })
    },
    defaultOpen: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    searchField: {
      type: Boolean,
      default: true
    },
    searchMenuStyle: {
      type: String,
      default: ''
    },
    searchInput: {
      type: [String, Object],
      default: () => ({ placeholder: 'Search ...', startIcon: 'Search', value: '', style: 'width: 100%', startIconColor: 'gray'})
    },
    borderColor: {
      type: String,
      default: 'border-black'
    },
    mode: {
      type: String,
      default: 'dark'
    },
    buttonId: {
      type: String,
      default: ''
    },
    optionsContainerHeight: {
      type: String,
      default: 'fit-content'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    iconStyle: {
      type: String,
      default: ''
    },
    iconSize: {
      type: String,
      default: 'default'
    },
    iconColor: {
      type: String,
      default: 'black'
    },
    iconStroke: {
      type: String,
      default: '#454C50'
    },
    chevronStroke: {
      type: String,
      default: '#454C50'
    }
  },
  emits: ['click-item'],
  data() {
    return {
      isDropdownMenuOpen: this.defaultOpen || false,
      selectedItem: parseIfJson(this.defaultValue)
    };
  },
  computed: {
    iconName() {
      return this.isDropdownMenuOpen ? 'ChevronUp' : 'ChevronDown';
    }
  },
  methods: {
    toggleSearchMenu(e) {
      e.stopPropagation();
      this.isDropdownMenuOpen = !this.isDropdownMenuOpen;
    },
    handleClickOutsideDropdown() {
      this.isDropdownMenuOpen = false;
    },
    selectItem({item}) {
      this.selectedItem = item;
      this.$emit('click-item', item);
      this.isDropdownMenuOpen = false;
    }
  }
};
</script>

<style scoped src="./DropdownButtonFlowbite.css" />
