<template>
  <div class="PopOver">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'PopOver'
};
</script>

<style scoped src="./PopOver.css" />
