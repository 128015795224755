
<template>
  <div
    ref="dropdownBtnRef"
    class="IobDropdownButton"
  >
    <iob-button
      :size="size"
      :type="buttonType"
      :color="color"
      :selected="toggleDropdown"
      :label="dynamicTitle ? (typeCheck ? currentItem : title) : title"
      :left-icon="iconName ? iconNameItem : ''"
      :show-left-icon="true"
      :show-right-icon="showRightIcon"
      :disabled="disabled"
      :button-style="buttonStyle"
      :right-icon="toggleDropdown && items.length > 1 ? 'ChevronUp' : 'ChevronDown'"
      :right-icon-size="rightIconSize"
      :style="buttonCssStyle"
      class="IobDropdownButton-button"
      @click="toggleDropdownClick"
    />

    <template v-if="toggleDropdown && items.length">
      <teleport
        v-if="attachToBody"
        to="body"
      >
        <outside-click-listener @outside-click="handleClickOutsideDropdown">  
          <iob-dropdown
            class="IobDropdownButton-button-dropdown"
            :items="itemsDropdown"
            :is-scrollable="isScrollable"
            :dropdown-style="dropdownStyle"
            :dropdown-btn-ref="dropdownBtnRef"
            @dropdown-element-item="handleDropdownItemClick"
            @update:modelValue="(...args) => $emit('update:modelValue', ...args)"
          />
        </outside-click-listener>
      </teleport>

      <template v-else>
        <outside-click-listener @outside-click="handleClickOutsideDropdown">
          <iob-dropdown
            :items="itemsDropdown"
            :is-scrollable="isScrollable"
            :dropdown-style="dropdownStyle"
            class="IobDropdownButton-button-dropdown"
            @dropdown-element-item="handleDropdownItemClick"
            @update:modelValue="(...args) => $emit('update:modelValue', ...args)"
          />
        </outside-click-listener>
      </template>
    </template>

    <div />
  </div>
</template>

<script setup>
import {ref, defineProps, defineEmits, computed, watch } from 'vue';
import IobButton from '../../Molecules/IobButton/IobButton.vue';
import IobDropdown from '../../Molecules/IobDropdown/IobDropdown.vue';
import OutsideClickListener from '../../OutsideClickListener/OutsideClickListener.vue';

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  dynamicTitle: {
    type: Boolean,
    default: true
  },
  size: {
    type: String,
    default: 'default'
  },
  rightIconSize: {
    type: String,
    default: ''
  },
  iconName: {
    type: String,
    default: ''
  },
  items: {
    required: true,
    type: [Array, String]
  },
  typeCheck: {
    type: Boolean,
    default: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  buttonType: {
    type: String,
    default: 'filled'
  },
  color: {
    type: String,
    default: 'secondarySoft'
  },
  showRightIcon: {
    type: Boolean,
    default: true
  },
  buttonStyle:{
    type: String,
    default: ''
  },
  isScrollable:{
    type: Boolean,
    default: false
  },
  dropdownStyle: {
    type: String,
    default: ''
  },
  buttonCssStyle: {
    type: String,
    default: ''
  },
  attachToBody: {
    type: Boolean,
    default: false
  }
});

const currentItem = ref(props.title);
const iconNameItem= ref(props.iconName);
const dropdownBtnRef = ref(null);

const getIconName = (item) => {
  if (item.iconName) {
    return item.iconName;
  } else {
    return item === currentItem.value && props.typeCheck ? 'Check' : '';
  }
};
const isSelected = (item) => {
  return item.iconName === iconNameItem.value && props.typeCheck ? true : false;
};
const itemsDropdown = computed(() => {
  return props.items.map(item => {
    return {
      text: `${item.text ? item.text : item}`,
      state: item.state || 'default',
      iconName: getIconName(item),
      iconSize: 'default',
      type: item.type ||'menu',
      size:'xs',
      subItemslist: item.subItemslist ? item.subItemslist : [],
      ...item,
      selected: isSelected(item)
    }
  })
});

const toggleDropdown = ref(false);
const emits = defineEmits(['dropdownElementClick','dropdownButtonClosed']);

const toggleDropdownClick =() => {
  const currentToggleState = toggleDropdown.value;
  // using setTimeout with no delay to ensure the action occurs after the current execution context completes.
  setTimeout(() => {
    toggleDropdown.value = !currentToggleState
  });
  
};

const handleDropdownItemClick = ({item}, activateToggleDropdown = true) => {
  if(item.subItemslist?.length && !item.action){
    return;
  }
  emits('dropdownElementClick', item);
  currentItem.value = item.text;
  if (activateToggleDropdown) {
    toggleDropdown.value = false;
  }
  if (props.dynamicTitle) {
    iconNameItem.value = item.iconName;
  }
};

const handleClickOutsideDropdown = () => {
  toggleDropdown.value = false;
};

watch(toggleDropdown, (value) => {
  if (!value) {
    emits('dropdownButtonClosed');
  }

  if (props.attachToBody) {
    if (value) {
      window.addEventListener('scroll', forceCloseDropdown, true);
      window.addEventListener('resize', forceCloseDropdown, true);
    } else {
      window.removeEventListener('scroll', forceCloseDropdown, true);
      window.removeEventListener('resize', forceCloseDropdown, true);
    }
  }
});

const forceCloseDropdown = () => {
  if (toggleDropdown.value) {
    toggleDropdown.value = false;
  }
}
</script>
      
<style lang="scss" src="./IobDropdownButton.scss" scoped />
