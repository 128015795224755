<template>
  <div class="SearchSelect">
    <input 
      v-model="keyword" 
      class="SearchSelect-searchBox"
      type="text"
      @click="isDropDownOpen = true"
      @focus="isDropDownOpen = true"
      @blur="isDropDownOpen = false"  
    >
    <div 
      :class="[
        'SearchSelect-dropDown',
        isDropDownOpen ? 'SearchSelect-dropDown--visible' : ''
      ]"
      @mousedown.prevent
    >
      <div 
        v-for="(item, index) in searchResults"
        :key="index"
        class="SearchSelect-dropDown-item"
        @click.prevent="onItemClick(item)"
      >
        <span class="SearchSelect-dropDown-item-label">
          {{ item.label }}
        </span>
        <span class="SearchSelect-dropDown-item-description">
          {{ item.description }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IobSearchSelect',
  props: {
    items: {
      type: Array,
      required: true
    },
    matcher: {
      type: Function,
      required: true
    },
  },
  emits: ['select'],
  data() {
    return {
      keyword: '',
      isDropDownOpen: false,
    }
  },
  computed: {
    searchResults() {
      return this.items.filter(item => {
        return this.matcher(item, this.keyword);
      });
    }
  },
  methods: {
    onItemClick(item) {
      this.isDropDownOpen = false;
      this.keyword = item.label;
      this.$emit('select', item);
    }
  }
};
</script>

<style scoped src="./SearchSelect.css"></style>
