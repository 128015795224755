<template>
  <div>
    <label-field
      v-for="(item, index) of itemsArray"
      :id="item.id"
      :key="index"
      :item="item"
      :has-checkbox="hasCheckbox"
      :label="item.label"
      :valid="item.valid"
      :input-style="item.style"
      :start-icon="item.startIcon"
      :start-icon-height="item.startIconHeight"
      :start-icon-width="item.startIconWidth"
      :start-icon-color="item.startIconColor"
      :start-icon-stroke="item.startIconStroke"
      :start-icon-rect-color="item.startIconRectColor"
      :start-icon-rect-stroke="item.startIconRectStroke"
      :start-icon-circle-color="item.startIconCircleColor"
      :start-icon-circle-stroke="item.startIconCircleStroke"
      :end-icon="item.endIcon"
      :end-icon-height="item.endIconHeight"
      :end-icon-width="item.endIconWidth"
      :end-icon-color="item.endIconColor"
      :end-icon-stroke="item.endIconStroke"
      :end-icon-rect-color="item.endIconRectColor"
      :end-icon-rect-stroke="item.endIconRectStroke"
      :end-icon-circle-color="item.endIconCircleColor"
      :end-icon-circle-stroke="item.endIconCircleStroke"
      :model-value="item.value"
      :placeholder="item.placeholder"
      :checked="item.checked"
      :disabled="item.disabled"
      :checkbox-disabled="item.checkboxDisabled"
      :is-focused="item.isFocused"
      :border-color="borderColor"
      :shape="shape"
      :mode="mode"
      :size="size"
      outlined
      @focus-input="(event) => $emit('focus-input', event)"
      @press-enter="(event) => $emit('press-enter', event)"
      @press-escape="(event) => $emit('press-escape', event)"
      @update:modelValue="item.value = $event"
      @checked-item="(data) => $emit('checked-item', data)"
      @start-icon-click="$emit('start-icon-click')"
      @end-icon-click="(id) => $emit('end-icon-click', id)"
    />
  </div>
</template>

<script>
import LabelField from '../LabelField/LabelField.vue';
import { parseIfJson } from '../../utils';

export default {
  name: 'IobFieldset',
  components: { LabelField },
  props: {
    items: {
      type: [String, Array],
      required: true
    },
    hasCheckbox: {
      type: Boolean,
      default: false
    },
    shape: {
      type: String,
      default: 'standard'
    },
    mode: {
      type: String,
      default: 'light'
    },
    borderColor: {
      type: String,
      default: 'border-gray'
    },
    size: {
      type: String,
      default: 'base'
    },
    outlined: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['focus-input', 'press-enter', 'press-escape', 'checked-item', 'start-icon-click', 'end-icon-click'],
  computed: {
    itemsArray() {
      return parseIfJson(this.items) || [];
    }
  }
};
</script>
