<template>
  <div id="upload-wrapper">
    <div
      v-show="showBox"
      class="IobImageUpload"
      @click="openFileDialog"
      @drop.prevent="(event) => dropHandler(event)"
      @dragover.prevent
      @dragenter.prevent
    >
      <div
        class="IobImageUpload-input"
      >
        <div class="IobImageUpload-content">
          <icon-loader
            name="CloudUpload"
            size="large"
          />
          <div class="IobImageUpload-content-text">
            <slot>
              <div>Drag & Drop or <a class="link">choose an image</a> to upload</div>
              <span class="IobImageUpload-content-text--small">
                PNG or JPG, maximum dimensions 7560 x 7560px
              </span>
            </slot>
          </div>
        </div>
        <input
          ref="inputRef"
          type="file"
          style="display: none;"
          accept="image/png, image/jpeg"
          v-bind="$attrs"
          @change="onFileChange"
        >
      </div>
    </div>
    <div
      v-show="!showBox"
      class="IobImageUpload-menu"
    >
      <span class="IobImageUpload-menu-name">
        {{ imageName }}
      </span>
      <div class="IobImageUpload-menu-options">
        <iob-button
          size="medium"
          color="secondary"
          type="ghost"
          left-icon="MoreHorizontal"
          :show-left-icon="true"
          :selected="openOptions"
          @click="openOptions = !openOptions"
        />
        <outside-click-listener>
          <iob-dropdown
            v-if="openOptions"
            class="IobImageUpload-menu-options-dropdown"
            :items="imageActions"
            @dropdown-element-item="handleOptionSelect"
          />
        </outside-click-listener>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';

const inputRef = ref(null);
const imageName = ref(props.value?.name);
const openOptions = ref(false);

function openFileDialog() {
  inputRef.value.click();
}

const showBox = computed(() => !imageName.value || !props.canEditFile);

const emit = defineEmits(['change', 'reset']);

function dropHandler(ev) {
  ev.target.files = ev.dataTransfer.files;
  onFileChange(ev);
}

const props = defineProps({
  value: {
    type: Object,
    default: null
  },
  canEditFile: {
    type: Boolean,
    default: true
  }
});

watch(() => props.value, (value) => {
  imageName.value = value?.name || null;
});

function onFileChange(event) {
  const files = event.target.files;
  if (!files?.length) {
    return;
  }
  imageName.value = files[0].name;
  emit('change', event);
}

const imageActions = [
  { text: 'Replace', iconName: 'Repeat', type: 'menu'},
  { text: 'Delete', iconName: 'Trash', type: 'menu' }
];

const actionCallbacks = {
  'Replace': () => inputRef.value.click(),
  'Delete': () => {
    imageName.value = null;
    inputRef.value.value = '';
    // trigger reset event
    emit('reset');
  }
};

function handleOptionSelect({ item }) {
  const action = actionCallbacks[item.text];
  if (action) {
    action();
  }
  openOptions.value = false;
}
</script>

<style src="./IobImageUpload.scss" lang="scss" scoped />
