<template>
  <button
    :id="id"
    :class="globalStyles"
    :disabled="disabled"
    @click="onClickActionButton"
  >
    <icon-loader
      :name="iconName"
      :stroke="iconColor"
      :color="iconColor"
      :size="size"
      class="IobActionButton-icon-loader"
    />
  </button>
</template>

<script>
import IconLoader from '../../IconLoader/IconLoader.vue';

export default {
  name: 'IobActionButton',
  components: { IconLoader },
  props: {
    id: {
      type: String,
      default: '',
    },
    iconName: {
      type: String,
      default: 'X',
    },
    type: {
      type: String,
      default: 'soft',
    },
    color: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: 'default',
    },
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    sizeClassMap: {
      xsmall: 'IobActionButton-xsmall',
      small: 'IobActionButton-small',
      default: 'IobActionButton-default',
      large: 'IobActionButton-large'
    },
    colorClassMap: {
      primary: 'IobActionButton-primary',
      secondary: 'IobActionButton-secondary',
      black: 'IobActionButton-black',
      alert: 'IobActionButton-alert',
      warning: 'IobActionButton-warning',
      success: 'IobActionButton-success',
      info: 'IobActionButton-info',
      other: 'IobActionButton-other',
    },
    typeClassMap: {
      ghost: 'ghost',
      soft: 'soft',
      outlined: 'outlined',
    },
    roundedClassMap: {
      true: 'IobActionButton-rounded',
      false: '',
    }
  }),
  computed: {
    iconColor() {
      if(this.disabled)
        return `var(--action-button-color-icon-${this.color}-${this.type}-disabled)`;
      if(this.selected)
        return `var(--action-button-color-icon-${this.color}-${this.type}-selected)`;

      return `var(--action-button-color-icon-${this.color}-${this.type}-default)`;
    },
    formatState(){
      let typeClass;

      if(this.color === 'black' && this.type !== 'ghost'){
        typeClass = [this.colorClassMap[this.color], 'ghost'].join('-');
      }
      else{
        typeClass = [this.colorClassMap[this.color], this.typeClassMap[this.type]].join('-');
      }
      if(this.selected && !this.disabled)
        typeClass = [typeClass, 'selected'].join('-');
    
      return typeClass.endsWith('-') ? typeClass.slice(0, -1) : typeClass;
    },

    globalStyles() {
      return [
        'IobActionButton',
        'focus-outside',
        'IobActionButton--transition',
        this.sizeClassMap[this.size],
        this.formatState,
        this.roundedClassMap[this.rounded],
      ].join(' ');
    },
  },

  methods: {
    onClickActionButton(event) {
      event.preventDefault();
      event.stopPropagation();
    },
  },
};
</script>
<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-button.scss" />
<style lang="scss" src="./IobActionButton.scss" scoped></style>
