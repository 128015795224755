import actions from './actions';
import mutations from './mutations';

const AuthModule = {
  namespaced: true,
  state: {
    authenticated: null,
    token: null
  },
  getters: {
    isAuthenticated(state) {
      return state.authenticated;
    },
    getToken(state) {
      return state.token;
    }
  },
  mutations,
  actions
};

export default AuthModule;
