<template>
  <icon-loader
    :key="isFavorite ? 'StarFilled' : 'Star'"
    :name="isFavorite ? 'StarFilled' : 'Star'"
    :size="iconSize"
    :color="isFavorite ? iconColor: ''"
    :stroke="isFavorite ? iconStroke: ''"
  />
</template>

<script>
import IconLoader from '../IconLoader/IconLoader.vue';
export default {
  name: 'FavoriteComponent',
  components: { IconLoader },
  props: {
    isFavorite: {
      type: Boolean,
      required: true
    },
    iconSize: {
      type: String,
      default: 'default'
    },
    iconColor: {
      type: String,
      default: 'black'
    },
    iconStroke: {
      type: String,
      default: 'black'
    }
  }
};
</script>
