import { createMachine } from 'xstate';
import { transformCoordinates, transformElementCoordinates, rectangle } from 'SRC/utils/geometrics/transform';
import {INTERACTION_MODES, MUTATION_TYPES, USER_INTERACTIONS} from 'GLOBALS/constants.js';
import { computeFormulaInSelectionSize } from 'BOARD/utils/utils';
import { switchToPanMode } from '../../board/hot-keys-reducer';

const reCalc = (board) => {
  const div = board.$refs.selectionArea;
  const x3 = Math.min(board.x1, board.x2);
  const x4 = Math.max(board.x1, board.x2);
  const y3 = Math.min(board.y1, board.y2);
  const y4 = Math.max(board.y1, board.y2);
  div.style.left = `${x3}px`;
  div.style.top = `${y3}px`;
  div.style.width = `${x4 - x3}px`;
  div.style.height = `${y4 - y3}px`;
};
const hideSelection = (board) => {
  const div = board.$refs.selectionArea;
  div.style.left = '0px';
  div.style.top = '0px';
  div.style.width = '0px';
  div.style.height = '0px';
  div.hidden = 1; //Hide the div

  board.x1 = null;
  board.x2 = null;
  board.y1 = null;
  board.y2 = null;
};

export const selectedElements = (board, zoomLevel, transformedCoordinates) =>
  Object.values(board.elements)
    .map((e) => {
      const dataTypeName = board.getBoardElementDataType(e.id);
      let size;
      if (dataTypeName.includes('Formula')) {
        size = computeFormulaInSelectionSize(zoomLevel, e.id);
      } else {
        size = { width: e.width, height: e.height };
      }
      return transformElementCoordinates(zoomLevel, size.height, size.width)(e);
    })
    .filter((el) => rectangle.overlaps(transformedCoordinates)(el.coordinates));

export const getTransformedElements = (elementsInSelection) => (elementsInSelection.map((e) => e.id));

export const updateSelection = async (board, elementsInSelection, event) => {
  const transformedEls = getTransformedElements(elementsInSelection);

  const elementsExistInSelection = transformedEls.length > 0;

  const isDelete = (event.altKey);

  if (elementsExistInSelection) {
    if (isDelete) {
      await board.removeSelection(transformedEls);
    } else {
      const mutationType = (event.shiftKey || event.ctrlKey) ? MUTATION_TYPES.APPEND_TO_PREVIOUS : MUTATION_TYPES.ERASE_PREVIOUS;
      await board.selectBoardElements({ boardElementsIds: transformedEls, mutationType });
    }
  }
};

const machine = createMachine({
  id: 'selectMachine',
  initial: 'idle',
  predictableActionArguments: true,
  states: {
    idle: {
      on: {
        pressOnBoard: {
          target: 'mouseDown',
          actions: ['onmousedown']
        },
        selectAll: {
          target: 'multipleElementsSelected',
          actions: ['selectAllElements']
        },
        escape: {
          target: 'idle',
          actions: ['resetSelection']
        },
        rightMouseDown: {
          target: 'rightMouse'
        }
      }
    },
    rightMouse: {
      on: {
        mouseUp: {
          target: 'idle',
          actions: ['selectElement']
        },
        mouseDrag: {
          target: 'idle',
          actions: ['switchToPanning']
        }
      }
    },
    mouseDown: {
      on: {
        mouseDrag: {
          target: 'drawingSelectionRectangle',
          actions: ['onmousemove']
        },
        mouseUp: {
          target: 'idle',
          actions: ['resetSelection']
        },
        selectAll: {
          target: 'multipleElementsSelected',
          actions: ['selectAllElements']
        },
        doubleClickOnSelectionModeButton: {
          target: 'multipleElementsSelected',
          actions: ['selectAllElements']
        },
        escape: {
          target: 'idle',
          actions: ['resetSelection']
        }
      }
    },
    drawingSelectionRectangle: {
      on: {
        escape: {
          target: 'idle',
          actions: ['resetSelection']
        },
        mouseUp: {
          target: 'multipleElementsSelected',
          actions: ['onmouseup']
        },
        selectAll: {
          target: 'multipleElementsSelected',
          actions: ['selectAllElements']
        },
        mouseDrag: {
          target: 'drawingSelectionRectangle',
          actions: ['onmousemove']
        },
        elementClickedCtrlKey: {
          target: 'drawingSelectionRectangle',
          actions: ['onmousemove']
        },
        elementClickedShiftKey: {
          target: 'drawingSelectionRectangle',
          actions: ['onmousemove']
        },
        simpleElementClicked: {
          target: 'drawingSelectionRectangle',
          actions: ['onmousemove']
        }
      }
    },
    multipleElementsSelected: {
      on: {
        escape: {
          target: 'idle',
          actions: ['resetSelection']
        },
        selectAll: {
          target: 'multipleElementsSelected',
          actions: ['selectAllElements']
        },
        pressOnBoard: {
          target: 'mouseDown',
          actions: ['onmousedown']
        },
        elementClickedCtrlKey: {
          target: 'drawingSelectionRectangle',
          actions: ['onmousemove']
        },
        elementClickedShiftKey: {
          target: 'drawingSelectionRectangle',
          actions: ['onmousemove']
        },
        simpleElementClicked: {
          target: 'drawingSelectionRectangle',
          actions: ['onmousemove']
        },
        rightMouseDown: {
          target: 'rightMouse'
        }
      }
    },
    startAdditionalSelectionRectangle: {
      on: {
        loseFocus: {
          target: 'idle',
          actions: ['effect']
        },
        escape: {
          target: 'idle',
          actions: ['resetSelection']
        },
        selectAll: {
          target: 'multipleElementsSelected',
          actions: ['selectAllElements']
        },
        doubleClickOnSelectionModeButton: {
          target: 'multipleElementsSelected',
          actions: ['selectAllElements']
        }
      }
    }
  }
});
const options = {
  actions: {
    onmousedown(ev, { board, event }) {
      if ([3, 2].includes(event.which)) {
        return;
      }
      if (board.selectedInteractionMode === INTERACTION_MODES.SELECT) {
        board.isSelectionRectangle = true;
        board.updateUserInteractionOnBoardElements(USER_INTERACTIONS.RECTANGLE_SELECT);
        board.x1 = event.clientX; //Set the initial X
        board.y1 = event.clientY; //Set the initial Y
        board.x2 = event.clientX; //Set the initial X
        board.y2 = event.clientY; //Set the initial Y
        board.$refs.selectionArea.style.backgroundColor = 'rgba(51, 104, 246, 0.6)';
      }
    },
    addSimpleElementToSelection(ev, { board, elementId }) {
      return board.selectBoardElements({ boardElementsIds: [elementId], mutationType: MUTATION_TYPES.APPEND_TO_PREVIOUS });
    },
    removeSimpleElementFromSelection(ev, { board, elementId }) {
      return board.removeSelection({ boardElementsIds: [elementId] });
    },
    reset(ev, { board }) {
      board.isSelectionRectangle = false;
      board.resetUserInteractionOnBoardElements();
      hideSelection(board);
      board.resetSelectedElements();
    },
    onmousemove(ev, { board, event }) {
      if (board.isSelectionRectangle) {
        board.setCursor({ value: 'crosshair', lock: true });
        const div = board.$refs.selectionArea;
        div.hidden = 0; //Unhide the div
        board.x2 = event.clientX; //Update the current position X
        board.y2 = event.clientY; //Update the current position Y
        reCalc(board);
        const borders = document.getElementsByClassName('BoardView')[0].getBoundingClientRect();
        const transformedCoordinates = transformCoordinates(borders)(board);
        const { zoomLevel } = board;
        const highlightedElements = selectedElements(board, zoomLevel, transformedCoordinates).map((e) => e.id);
        if ((event.altKey)) {
          board.setUnHighlightedBoardElements(highlightedElements);
        } else {
          board.setHighlightedBoardElements(highlightedElements);
        }
      }
    },
    selectElement(ev, { board, event }) {
      const boardElementId = event.elementId;
      board.selectBoardElements({ boardElementsIds: [boardElementId], mutationType: MUTATION_TYPES.ERASE_PREVIOUS });
    },
    async switchToPanning(ev, { board, event }) {
      switchToPanMode(board, event, true);
    },
    async onmouseup(ev, { board, event }) {
      if (!board.isSelectionRectangle) {
        return;
      }
      board.unlockCursor();
      board.isSelectionRectangle = false;
      board.resetUserInteractionOnBoardElements();
      board.resetHighlightedBoardElements();
      board.resetUnHighlightedBoardElements();
      const borders = document.getElementsByClassName('BoardView')[0].getBoundingClientRect();
      const transformedCoordinates = transformCoordinates(borders)(board);
      const { zoomLevel } = board;
      const elementsInSelection = selectedElements(board, zoomLevel, transformedCoordinates);

      updateSelection(board, elementsInSelection, event);
      board.$refs.selectionArea.style.backgroundColor = '';
      board.isSelectionArea = true;
      hideSelection(board);
    },
    async resetSelection(ev, { board }) {
      if (board.formulaInEditionBoardElementId && board.boardElementsInEdition[board.formulaInEditionBoardElementId]) {
        board.removeFormulaFromSelection(board.formulaInEditionBoardElementId);
      }
      board.setFormulaInEditionBoardElementId(null);
      board.resetSelectedElements();
      hideSelection(board);
      board.isSelectionArea = false;
      board.selectionArea = {};
    },
    async selectAllElements(ev, { board }) {
      await board.selectAllBoardElements();
    }
  }
};

export default { machine, options };
