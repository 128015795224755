/** Class having datesArray generator functions */
export class DateArrayGenerator {
  /**
    * constructor description
    * @param  String startDate - Start date
    * @param  String endDate - End date
    * @param  String frequency - Frequency of the date array
    */
  constructor(newStartDate, newEndDate, frequency, canUseTimeSeriesData) {
    this._startDate = new Date(newStartDate);
    this._endDate = new Date(newEndDate);
    this._frequency = frequency;
    this._canUseTimeSeriesData = canUseTimeSeriesData;
  }
  get startDate() {
    return this._startDate;
  }
  /**
   * @param  String newStartDate - Start date
   * @description - parse to date then Set start date
   */
  set startDate(newStartDate) {
    this._startDate = new Date(newStartDate);
  }
  get endDate() {
    return this._endDate;
  }
  /** 
   * @param  String newEndDate - End date
   * @description - parse to date then Set end date
   */
  set endDate(newEndDate) {
    this._endDate = new Date(newEndDate);
  }
  /**
   * @description - Get frequency of the date array
   */
  get frequency() {
    return this._frequency;
  }
  /** 
   * @param  String frequency - Frequency of the date array
   */
  set frequency(frequency) {
    this._frequency = frequency;
  }
  /** 
   * @private
   * @description - map of [frequency, method] key value pairs
  */
  get frequencyMethodMap() {
    return {
      Monthly: 'generateMonths',
      Yearly: 'generateYears',
      Quarterly: 'generateQuarters',
      Semesterly: 'generateSemesters',
      Biannually: 'generateSemesters',
      Daily: 'generateDays',
      Weekly: 'generateWeeks',
    };
  }

  /**
   * 
   * @returns {Array} - Array of dates
   * @description - Generates an array of dates between startDate and endDate based on the frequency
   */
  generate() {
    if (!this.frequencyMethodMap[this.frequency]) return [];
    return this[this.frequencyMethodMap[this.frequency]]();
  }

  /**  generates an array of Months between two dates */
  generateMonths() {
    const diffMonths = (this.endDate.getFullYear() - this.startDate.getFullYear()) * 12 + this.endDate.getMonth() - this.startDate.getMonth() + 1;
    const months = Array.from({ length: diffMonths }, (_, index) => {
      const year = this.startDate.getFullYear() + Math.floor((this.startDate.getMonth() + index) / 12);
      const month = (this.startDate.getMonth() + index) % 12;
      const monthString = this.getMonth(month);
      return `${monthString}.${year}`;
    });
    return months;
  }

  generateYears() {
    const diffYears = this.endDate.getFullYear() - this.startDate.getFullYear() + 1;
    const yearsList = Array.from({ length: diffYears }, (_, index) => this.startDate.getFullYear() + index);
    return yearsList.map(String);
  }

  generateQuarters () {
    let quarters = [];
    let currentStartDate = new Date(this.startDate);
    let currentEndDate = new Date(this.endDate);

    while (currentStartDate <= currentEndDate) {
      let year = currentStartDate.getFullYear();
      let quarter = '';

      if (currentStartDate.getMonth() >= 0 && currentStartDate.getMonth() <= 2) {
        quarter = 'Q1';
      } else if (currentStartDate.getMonth() >= 3 && currentStartDate.getMonth() <= 5) {
        quarter = 'Q2';
      } else if (currentStartDate.getMonth() >= 6 && currentStartDate.getMonth() <= 8) {
        quarter = 'Q3';
      } else {
        quarter = 'Q4';
      }

      let quarterString = `${quarter} ${year}`;
      if (!quarters.includes(quarterString)) {
        quarters.push(quarterString);
      }

      if (currentStartDate.getMonth() >= 0 && currentStartDate.getMonth() <= 2) {
        currentStartDate = new Date(year, 3, 1);
      } else if (currentStartDate.getMonth() >= 3 && currentStartDate.getMonth() <= 5) {
        currentStartDate = new Date(year, 6, 1);
      } else if (currentStartDate.getMonth() >= 6 && currentStartDate.getMonth() <= 8) {
        currentStartDate = new Date(year, 9, 1);
      } else {
        currentStartDate = new Date(year + 1, 0, 1);
      }
    }

    return quarters;
  }

  /**
   * 
   * @param {int} month 
   * @returns string
   * @description recieves month index ([0..11]) and returns month Short
   */
  getMonth(month) {
    let date = new Date();
    date.setDate(1);
    date.setMonth(month);
    const monthString = date.toLocaleDateString('en-us', {month: 'short'});
    date = null;
    return monthString;
  }
  generateDays() {
    let days = [];
    let currentDate = new Date(this.startDate);
    let endDate = new Date(this.endDate);

    if (this._canUseTimeSeriesData) {
      currentDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);
    }

    while (currentDate <= endDate) {
      let day = String(currentDate.getDate()).padStart(2, '0');
      let month = currentDate.getMonth();
      let monthString = this.getMonth(month)
      let year = currentDate.getFullYear();
      let dateString =`${day} ${monthString}.${year}`;
      days.push(dateString);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return days;
  }

  generateSemesters() {
    let semesters = [];
    let currentStartDate = new Date(this.startDate);
    let currentEndDate = new Date(this.endDate);

    while (currentStartDate <= currentEndDate) {
      let year = currentStartDate.getFullYear();
      let semester = '';

      if (currentStartDate.getMonth() >= 0 && currentStartDate.getMonth() <= 5) {
        semester = 'S1';
      } else {
        semester = 'S2';
      }

      let semesterString = `${semester} ${year}`;
      if (!semesters.includes(semesterString)) {
        semesters.push(semesterString);
      }

      if (currentStartDate.getMonth() >= 0 && currentStartDate.getMonth() <= 3) {
        currentStartDate = new Date(year, 6, 1);
      } else if (currentStartDate.getMonth() >= 4 && currentStartDate.getMonth() <= 7) {
        currentStartDate = new Date(year, 8, 1);
      } else {
        currentStartDate = new Date(year + 1, 0, 1);
      }
    }

    return semesters;
  }

  generateWeeks() {
    let weeks = [];
    let currentDate = new Date(this.startDate);
    let currentEndDate = new Date(this.endDate);
    while (currentDate <= currentEndDate) {
      // Get ISO week for current date
      let isoWeek = this.getISOWeek(currentDate);
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth();
      let formattedWeek = `W${isoWeek} ${year}`
      if (isoWeek === 1 && month === 11) {
        formattedWeek = `W${isoWeek} ${year+1}`
      }
      // Add ISO week to array if not already present
      if (!weeks.includes(formattedWeek)) {
        weeks.push(formattedWeek);
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return weeks;
  }

  getISOWeek(date) {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    const yearStart = new Date(d.getFullYear(), 0, 1);
    return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
  }
}