<template>
  <div class="Chip">
    <div>
      {{ itemObject.label }}
    </div>
    <icon-loader
      v-if="deletable"
      style="margin-right:4px; transform: translateX(6px); cursor: pointer;"
      name="X"
      :color="iconColor"
      :size="iconSize"
      @icon-click="() => $emit('delete-icon-click', itemObject)"
    />
  </div>
</template>

<script>
import IconLoader from '../IconLoader/IconLoader.vue';
import { parseIfJson } from '../../utils';

export default {
  name: 'IobChip',
  components: { IconLoader },
  props: {
    item: {
      type: [String, Object],
      required: true
    },
    deletable: {
      type: Boolean,
      default: true
    },
    iconColor: {
      type: String,
      default: '#FFF'
    },
    iconSize: {
      type: String,
      default: 'default'
    },
  },
  emits: ['delete-icon-click'],
  computed: {
    itemObject() {
      return parseIfJson(this.item) || {};
    }
  }
};
</script>

<style scoped src="./IobChip.css" />
