<template>
  <div
    :class="classList"
    class="focus-inside"
    tabindex="0"
    @mouseenter.prevent="() => $emit('handle-submenu-hover', this)"
    @touchstart.prevent="() => $emit('handle-touch-start', this)"
    @click.prevent.stop="handleDropdownElement"
    @keyup.enter="() => $emit('handle-dropdown-element')"
  >
    <div class="IobMenuElement-left">
      <iobeya-logo
        v-if="iconName === 'iObeyaLogo'"
        :show-text="false"
        :size="iconSize"
      />
      <icon-loader
        v-else-if="iconName"
        :name="iconName"
        :size="iconSize"
        :color="iconColor"
        :stroke="iconColor"
      />
      <div v-if="showAsset">
        <iob-avatar
          v-if="showAvatar"
          :avatar-url="avatar.avatarUrl"
          :alt-text="avatar.altText"
          :name="avatar.name"
          :size="avatar.size"
          :color="avatar.colorLeft"
        />
        <iob-shape-color v-else-if="showColorAsset" />
      </div>
      <div
        class="IobMenuElement-text"
        data-test="IobMenuElement-text"
      >
        {{ text }}
      </div>
    </div>
    <div
      v-if="showRight"
      class="IobMenuElement-submenu"
    >
      <div
        v-if="subText && subText.length"
        class="IobMenuElement-subText"
      >
        {{ subText }}
      </div>
      <icon-loader
        v-if="rightIconName"
        :name="rightIconName"
        :color="iconColor"
        :stroke="iconColor"
      />
    </div>
    <slot />
  </div>
</template>

<script>
import IconLoader from '../../IconLoader/IconLoader.vue';
import IobAvatar from '../IobAvatar/IobAvatar.vue';
import IobShapeColor from '../../Atoms/IobShapeColor/IobShapeColor.vue';
import IobeyaLogo from '../../Atoms/IobeyaLogo/IobeyaLogo.vue';

const nonMonochromeIcons = new Set(['linkedin', 'powerbi']);

export default {
  name: 'IobMenuElement',
  components: { IconLoader, IobAvatar, IobShapeColor, IobeyaLogo },
  props: {
    state: {
      type: String,
      default: 'default',
    },
    text: {
      type: String,
      default: '',
    },
    showAsset: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: '',
    },
    showColorAsset: {
      type: Boolean,
      default: false,
    },
    showAvatar: {
      type: Boolean,
      default: false,
    },
    hasSubItems: {
      type: Boolean,
      default: false,
    },
    showRight: {
      type: Boolean,
      default: false,
    },
    displayRightOnHoverOnly: {
      type: Boolean,
      default: false,
    },
    rightIconName: {
      type: String,
      default: 'ChevronRight',
    },
    subText: {
      type: String,
      default: '',
    },
    iconSize: {
      type: String,
      default: 'default',
    },
    avatar: {
      type: Object,
      default: () => ({})
    },
  },
  data: () => ({
    isHovered: false,
  }),
  computed: {
    classList() {
      return ['IobMenuElement-item',
        `IobMenuElement-item--${this.state}`,
        {
          'IobMenuElement-item--displayRightOnHoverOnly': this.displayRightOnHoverOnly,
        }
      ];
    },
    iconColor() {
      return nonMonochromeIcons.has(this.iconName.toLowerCase()) ? null : `var(--list-elements-menu-element-color-icons-secondary-ghost-${this.state},#5D587B)`;
    },
  },
  methods: {
    handleDropdownElement(event) {
      this.$emit('handle-dropdown-element',event);
      this.hasSubItems && this.$emit('toggle-submenu-dropdown', {isHovered: this.isHovered});
    },
  },
};
</script>

<style lang="scss" src="./IobMenuElement.scss" scoped></style>
<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-list-elements.scss" />
