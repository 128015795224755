<template>
  <div id="multi-level-menu">
    <ul
      class="flex-col absolute MultiLevelMenu-box"
      :style="multiLevelmenuStyle"
    >
      <li
        v-for="(item, index) in itemsArray"
        :id="`multiLevel-Menu${origin}${index}`"
        :key="index"
        @mouseover="mouseOver($event, item)"
      >
        <div
          v-if="item.submenu"
          id="item"
          class="flex items-center"
          :style="getTextColor(item)"
          style="justify-content: space-between;"
        >
          <span>
            {{ item.title }}
          </span>
          <icon-loader
            v-show="item.submenu"
            name="ChevronRight"
            :color="defaultColor"
            class="MultiLevelMenu-chevronRight"
          />
        </div>
        <span
          v-else
          id="item"
          class="flex items-center"
          :style="getTextColor(item)"
          @mouseover="mouseOver($event, item)"
          @click="send(item)"
        >{{ item.title }}
        </span>
        <ul
          v-if="item.id === expandedSubMenu"
          id="sub-menu"
          class="flex-col absolute MultiLevelMenu-box"
          :style="getSubMenuStyle(index, `multiLevel-Menu${origin}${index}`)"
        >
          <li
            v-for="(subItem, id) in item.submenu"
            :key="id"
          >
            <span
              class="flex items-center"
              :style="getTextColor(item)"
              @mouseover="mouseOver($event, item)"
              @click="send(subItem)"
            >{{ subItem.title }}
            </span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import IconLoader from '../IconLoader/IconLoader.vue';
import { parseIfJson } from '../../utils';

export default {
  name: 'MultiLevelMenu',
  components: { IconLoader },
  props: {
    items: {
      type: [String, Array],
      required: true
    },
    multiLevelmenuStyle: {
      type: String,
      default: ''
    },
    origin: {
      type: String,
      default: 'multi-level-menu'
    },
    subMenuPosition: {
      type: String,
      default: 'right'
    }
  },
  emits: ['click'],
  data() {
    return {
      expandedSubMenu: -1,
      defaultColor: 'rgb(55 65 81)'
    };
  },
  computed: {
    itemsArray() {
      return parseIfJson(this.items) || [];
    },
  },
  methods: {
    mouseOver(event, item) {
      event.stopPropagation();
      if (item.submenu) {
        this.expandedSubMenu = item.id;
      } else {
        this.expandedSubMenu = -1;
      }
    },
    getSubMenuStyle(index, parentId) {
      const top = index * 32;
      const parent = document.getElementById(parentId);
      const offset = parent.getBoundingClientRect().width
      + 6;
      const direction = this.subMenuPosition === 'right' ? 'left' : 'right';
      return {
        top: `${top}px`,
        [direction]: offset
      };
    },
    send(item) {
      if (item.submenu) {
        return;
      }
      if (!item.submenu) {
        this.expandedSubMenu = -1;
      }
      this.$emit('click', {
        source: item.componentName,
        action: item.action
      });
    },
    getTextColor(item) {
      return item.textColor ? `color: ${item.textColor}` : `color: ${this.defaultColor}`;
    }
  }
};
</script>

<style scoped src="./MultiLevelMenu.css" />
