<template>
  <div>
    <slot v-if="isAuthenticated" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import store from 'SRC/store';
import {getToken, isAuthenticated} from '@iobeya/common-tools';

export default {
  name: 'FetchDataWrapper',
  data() {
    return { undoManager: {} };
  },
  computed: {
    ...mapGetters('auth', [
      'isAuthenticated'
    ]),
    ...mapState('users', [
      'currentUser',
      'currentUserColor'
    ]),
    ...mapState('app', [
      'featuresList'
    ])
  },
  watch: {
    async isAuthenticated(isAuthenticated) {
      if (isAuthenticated) {
        await Promise.all([
          this.fetchUsers(),
          this.fetchFeaturesList()
        ]);
      }
    }
  },
  methods: {
    ...mapActions('users', [
      'fetchAllUsers',
      'fetchCurrentUser'
    ]),
    ...mapActions('app', [
      'fetchFeaturesList'
    ]),
    async fetchUsers()  {
      const promise = (fn) =>
        new Promise((resolve, reject) => {
          fn().then((result) => {
            if (!result) {
              reject({ error: 'Error occured in fetch data' });
            }
            if ((result && result.error)) {
              reject(result);
            } else {
              resolve(result);
            }
          });
        });
      try {
        await Promise.all([
          promise(this.fetchCurrentUser),
          promise(this.fetchAllUsers)
        ]);
      } catch (error) {
        console.error(error);
      }
    },
    handleTokenRefresh() {
      console.log('Received iobeya-token-refreshed event in iobeya-nextgen-board');
      store.dispatch('auth/insertToken', {token: getToken(), authenticated: isAuthenticated()});
    }
  },
  mounted() {
    window.addEventListener('iobeya-token-refreshed', this.handleTokenRefresh);
  },
  beforeUnmount() {
    window.removeEventListener('iobeya-token-refreshed', this.handleTokenRefresh);
  }
};
</script>

