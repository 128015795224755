import { getUserInitials } from 'SRC/utils/collab-utils';

export function createRelationObject(datasetElement, titleAttributeName, user, dataTypeTypeName) {
  return {
    id: datasetElement.id,
    typeId: datasetElement.typeId,
    friendlyId: datasetElement.attributes['friendly-id'],
    title: datasetElement.attributes[titleAttributeName],
    attributes: datasetElement.attributes,
    ownerPicture: user ? user.picture : null,
    ownerName: user ? getUserInitials(user.firstname, user.lastname) : null,
    typeName: dataTypeTypeName
  };
}

const getters = {
  getDatasetElementInEdition: (state) => state.datasetElementId,
  getRelationFromDatasetElementIds: (rootState) => (datasetElementParentId, datasetElementChildId) =>
    Object.values(rootState.relations.hierarchicalRelations)
      .filter((hierarchicalRelation) => !hierarchicalRelation.deleted)
      .find((hierarchicalRelation) => hierarchicalRelation.datasetElementParentId === datasetElementParentId
        && hierarchicalRelation.datasetElementChildId === datasetElementChildId),
  getRelationObject:
    (rootGetters) => (datasetElement) =>
      createRelationObject(
        datasetElement,
        rootGetters['app/getTitleAttribute'](datasetElement.typeId)?.name,
        rootGetters['users/getUserInfo'](datasetElement.attributes?.owner) || null,
        rootGetters['app/getDataTypeTypeName'](datasetElement.typeId)
      )
};

export default getters;
