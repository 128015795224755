const mutations = {
  setBoards(state, boards) {
    state.boards = [...state.boards, ...boards];
  },
  setNextBoardsQuery(state, query) {
    state.nextBoardsQuery = query;
  },
  setBoardParams(state, params) {
    state.params = {...state.params, ...params};
  },
  setRooms(state, rooms) {
    state.rooms = [...state.rooms, ...rooms];
  },
  resetBoards(state) {
    state.boards = [];
  },
  resetRooms(state) {
    state.rooms = [];
  }
};
export default mutations;
