import store from '@/store';
import { get } from 'SRC/api/index.js';

let settings = null;
let accessTokenRetrieval = null;

const actions = {
  authorize(state, boardId) {
    if (!boardId || store.getters['powerbi/getSettings'] === null) {
      console.error('Cannot authorize without boardId or settings');
      return;
    }
    window.location.href = store.getters['powerbi/getAuthorizeUrl'](boardId);
  },
  async retrieveSettings({ commit }) {
    if (settings) {
      return;
    }

    settings = get('/oauth/settings?type=powerbi')
      .then((response) => {
        if (response) {
          commit('setSettings', response.data[0]);
        }
      });

    await settings;
    settings = null;
  },
  async retrieveAccessToken({ commit }, { code, infos }) {
    if (!infos.settingsId || accessTokenRetrieval) {
      return;
    }
    let tokenUri = `/oauth/settings/${infos.settingsId}/my-token`;
    if (code) {
      tokenUri += `?code=${code}`;
    }
    accessTokenRetrieval = get(tokenUri)
      .then((response) => {
        if (response) {
          commit('setAccessToken', response.accessToken);
        }
      });
    await accessTokenRetrieval;
    accessTokenRetrieval = null;
  }
};

export default actions;
