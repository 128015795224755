<template>
  <thead class="IobTableHeader">
    <tr class="IobTableHeader-row">
      <IobTableHeaderCell
        v-for="(column, index) in columns"
        :key="index"
        :label="column.label"
        :width="isFullWidth ? '100%' : column.width"
        :class="column.class"
        :sortable="column.sortable"
        :disabled="column.disabled"
        :is-checked="column.isChecked"
        :sort-direction="sortDirection"
        :is-sorted="sortField === column.name"
        :selected="selectedCell === column.name"
        :check-all="checkAll"
        @clickCell="handleCellClick(column.name)"
        @toggleCheckAll="(item) => emits('toggleCheckAll', item)"
      />
      <div class="IobTableHeader-box-shadow">
        <IobSeparator class="IobTableHeader-separator" />
      </div>
    </tr>
  </thead>
</template>

<script setup>
import IobTableHeaderCell from '../IobTableHeaderCell/IobTableHeaderCell.vue';
import IobSeparator from '../../../Atoms/IobSeparator/IobSeparator.vue';
import { defineProps, defineEmits, ref } from 'vue';

defineProps({
  columns: {
    type: Array,
    default: () => []
  },
  sortField: {
    type: String,
    default: ''
  },
  sortDirection: {
    type: String,
    default: 'asc',
    validator: (value) => ['asc', 'desc'].includes(value)
  },
  checkAll: {
    type: Boolean,
    default: false
  },
  isFullWidth: {
    type: Boolean,
    default: false
  }
});
const emits = defineEmits(['onSelectCell', 'toggleCheckAll']);
const selectedCell = ref(-1);
const handleCellClick = (id) => {
  selectedCell.value = id;
  emits('onSelectCell', id);
};
</script>

<style lang="scss" scoped src="./IobTableHeader.scss" />
