<template>
  <div
    class="IobCheckbox"
    :class="{ 'IobCheckbox--rounded': type === 'rounded' }"
  >
    <template
      v-if="type === 'rounded'"
    >
      <icon-loader
        :name="iconName"
        :color="iconColor"
        @click.stop="changeAction"
      />
      <input
        :id="id"
        :checked="checked"
        :disabled="disabled"
        type="checkbox"
        class="IobCheckbox-input--hidden"
        @click.stop
        @change="changeAction"
      >
    </template>
    <input
      v-else
      :id="id"
      class="IobCheckbox-input focus-inside"
      :checked="checked"
      :disabled="disabled"
      type="checkbox"
      @click.stop
      @change="changeAction"
    >
    <span
      v-if="label && label.length"
      class="IobCheckbox-label"
    >
      {{ label }}
    </span>
  </div>
</template>

<script>
import IconLoader from '../../IconLoader/IconLoader.vue';

export default {
  name: 'IobCheckbox',
  components: { IconLoader },
  props: {
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'default',
      validator: (value) => ['default', 'rounded'].includes(value),
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
  emits: ['changeCheckbox'],
  computed: {
    iconColor() {
      return this.checked
        ? 'var(--app-card-selection-color-checkbox-selectd, #2D6BFF)'
        : 'var(--app-card-selection-color-checkbox, #5D587B)';
    },
    iconName() {
      return this.checked ? 'CheckCircle-2-Filled' : 'Circle';
    },
  },
  methods: {
    changeAction() {
      this.$emit('changeCheckbox', {
        name: 'checkbox',
        isChecked: !this.checked,
        id: this.id
      });
    }
  }
};
</script>

<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-checkboxes.scss" />
<style lang="scss" scoped src="./IobCheckbox.scss" />
