export function getUserClickedToolEvent({viewType, datasetElementId = null, attributes = {}}) {
  return getCustomEvent('userClickedTool', { viewType, datasetElementId, attributes });
}

export function getUserClickedElementEvent({ elementId, datasetElementId = null, hasBoardElement = true }) {
  return getCustomEvent('userClickedElement', { elementId, datasetElementId, hasBoardElement });
}

export function getAddElementToBoardEvent(element) {
  return getCustomEvent('addElementToBoard', {element});
}

export function getUserEditedElementEvent(element) {
  return getCustomEvent('userEditedElement', {element});
}

function getCustomEvent(type, detail) {
  return new CustomEvent(type, {detail});
}

export default {
  getUserClickedToolEvent,
  getUserClickedElementEvent,
  getAddElementToBoardEvent,
  getUserEditedElementEvent
};
