<template>
  <div class="VersionHistory">
    <div class="VersionHistory-header">
      <button
        class="VersionHistory-closeButton"
        @click="closeVersionHistory"
      >
        <img
          src="https://raw.githubusercontent.com/feathericons/feather/master/icons/x.svg"
          alt="close"
        >
      </button>
      <span class="VersionHistory-title"> Version history </span>
    </div>
    <scroll-bar :scroll-container-style="scrollContainerStyle">
      <div class="VersionHistory-body">
        <version-item
          :class="{ 'VersionHistory-selectedVersion': isSelected(currentVersion), 'VersionHistory-versionItem': true }"
          :version="currentVersion"
          @version-click="selectedVersionChange(currentVersion)"
        />
        <div
          v-if="recentVersions.length || monthlyVersions.length"
          class="VersionHistory-verticalLine"
        />
        <div
          v-if="recentVersions && recentVersions.length !== 0"
          id="recent-versions"
        >
          <div class="VersionHistory-sectionTitle">
            <icon-loader
              class="VersionHistory-circleIcon"
              name="Chip"
              size="default"
            />
            <span class="VersionHistory-name">
              Recent ({{ recentVersions.length }})
            </span>
            <icon-loader
              v-if="selectedSections.includes(versionCategories[0])"
              class="VersionHistory-closeSection"
              name="ChevronUp"
              color="black"
              @click="toggleVersions(versionCategories[0])"
            />
            <icon-loader
              v-else
              class="VersionHistory-openSection"
              name="ChevronDown"
              color="black"
              @click="toggleVersions(versionCategories[0])"
            />
          </div>
          <div v-if="selectedSections.includes(versionCategories[0])">
            <div
              v-for="(version, index) of recentVersions"
              :key="version"
              class="VersionHistory-version"
            >
              <version-item
                :class="{
                  'VersionHistory-selectedVersion': isSelected(version),
                  'VersionHistory-versionItem': true,
                  'VersionHistory-markedVersion': version.isMarked
                }"
                :version="version"
                @version-click="selectedVersionChange(version)"
              />
              <div
                v-if="selectedSections.includes(versionCategories[0])"
                class="VersionHistory-verticalLineSeparation"
              />
              <div
                v-if="index === recentVersions.length-1 && !selectedSections.includes(versionCategories[0])"
                class="VersionHistory-verticalLineEndRecentSection"
              />
              <icon-loader
                v-if="(index === recentVersions.length-1) && !monthlyVersions.length"
                class="VersionHistory-closingCircleIcon"
                name="Chip"
                size="default"
              />
            </div>
          </div>
        </div>
        <div
          v-if="recentVersions.length && monthlyVersions.length"
          class="VersionHistory-verticalLineSeparation"
        />
        <div
          v-for="(month, index) of monthlyVersions"
          :key="month"
        >
          <div id="old-versions">
            <div class="VersionHistory-sectionTitle">
              <icon-loader
                class="VersionHistory-circleIcon"
                name="Chip"
                size="default"
              />
              <span class="VersionHistory-name">{{ month }} ({{ getVersionsByMonth(month).length }})
              </span>
              <icon-loader
                v-if="selectedSections.includes(month)"
                class="VersionHistory-closeSection"
                name="ChevronUp"
                size="default"
                color="black"
                @click="toggleVersions(month)"
              />
              <icon-loader
                v-else
                class="VersionHistory-openSection"
                name="ChevronDown"
                size="default"
                color="black"
                @click="toggleVersions(month)"
              />
            </div>
            <div
              v-if="index !== monthlyVersions.length-1 && !selectedSections.includes(month)"
              class="VersionHistory-verticalLineOldSection"
            />
          </div>
          <div v-if="selectedSections.includes(month)">
            <div
              v-for="version of getVersionsByMonth(month)"
              :key="version.id"
              class="VersionHistory-version"
            >
              <version-item
                :version="version"
                :class="{
                  'VersionHistory-selectedVersion': isSelected(version),
                  'VersionHistory-versionItem': true,
                  'VersionHistory-markedVersion': version.isMarked
                }"
                @version-click="selectedVersionChange(version)"
              />
            </div>
          </div>
          <div
            v-if="selectedSections.includes(month)"
            class="VersionHistory-verticalLineOldSection"
          />
          <icon-loader
            v-if="index === monthlyVersions.length-1 && selectedSections.includes(month)"
            class="VersionHistory-closingCircleIcon"
            name="Chip"
            size="default"
          />
        </div>
      </div>
    </scroll-bar>
  </div>
</template>

<script>
import VersionItem from '../Version/Version.vue';
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import { VERSION_MODES, VERSION_CATEGORIES } from 'GLOBALS/constants.js';

export default {
  name: 'VersionHistory',
  components: { VersionItem },
  data() {
    return {
      selectedSections: [],
      monthlyVersions: [],
      currentBoardVersion: {
        id: 'currentVersionId',
        name: 'Current board version',
        description: '',
        isMarked: false,
        isSelected: true
      },
      isLoaded: false,
      versionCategories: VERSION_CATEGORIES,
      scrollContainerStyle: 'position: absolute; top: 80px; left: 20px;'
    };
  },
  computed: {
    ...mapState('board', ['recentVersions', 'oldVersions', 'openedComponents', 'selectedVersion', 'currentVersion', 'mode'])
  },
  watch: {
    selectedVersion() {
      this.updateSelection();
    },
    mode: {
      immediate: true,
      async handler(value) {
        if (value === VERSION_MODES[3]) {
          this.isLoaded = true;
          await this.loadVersions(this.mode);
          this.monthlyVersions = Object.keys(this.oldVersions);
          this.updateSelection();
          this.selectedSections.push(this.versionCategories[0]);
        }
      }
    }
  },
  async created() {
    if (!this.isLoaded) {
      this.setCurrentVersion(this.currentBoardVersion);
      this.setSelectedVersion(this.currentVersion);
      await this.loadVersions();
      this.monthlyVersions = Object.keys(this.oldVersions);
    }
  },
  unmounted() {
    this.setMode(null);
    this.setSelectedVersion(this.currentVersion);
    this.updateSelection();
  },
  methods: {
    ...mapMutations('board', ['setOpenedComponents', 'setSelectedVersion', 'addBoardRecentVersions', 'addBoardOldVersions', 'setCurrentVersion',
      'setMode']),
    ...mapGetters('board', ['getBoardVersionsByMonth']),
    ...mapActions('board', ['addBoardVersionsToStore']),
    toggleVersions(section) {
      if (!this.selectedSections.includes(section)) {
        this.selectedSections.push(section);
      } else {
        this.selectedSections = this.selectedSections.filter(
          (element) => element !== section
        );
      }

    },
    getVersionsByMonth(section) {
      return this.getBoardVersionsByMonth()(section);
    },
    selectedVersionChange(item) {
      this.setSelectedVersion(item);
      this.updateSelection();
    },
    closeVersionHistory() {
      const openedComponents = this.openedComponents.filter((componentName) => componentName !== 'VersionHistory');
      this.setOpenedComponents(openedComponents);
    },
    isSelected(version) {
      return version.id === this.selectedVersion.id;
    },
    async loadVersions(loadVersionsPrams) {
      await this.addBoardVersionsToStore(loadVersionsPrams);
    },
    updateSelection() {
      const isCurrentVersionSelected = this.currentBoardVersion.id === this.selectedVersion.id;
      this.currentBoardVersion.isSelected = isCurrentVersionSelected;

      this.setCurrentVersion(this.currentBoardVersion);
      const recentVersions = this.recentVersions.map((recentVersion) => {
        const isSelected = recentVersion.id === this.selectedVersion.id;
        return { ...recentVersion, isSelected };
      });
      this.addBoardRecentVersions(recentVersions);

      const oldVersions = this.oldVersions;
      for (const i in oldVersions) {
        oldVersions[i].forEach((oldVersion) => {
          const isSelected = oldVersion.id === this.selectedVersion.id;
          oldVersion.isSelected = isSelected;
        });
      }
      this.addBoardOldVersions(oldVersions);
    }
  }
};
</script>
<style src="./VersionHistory.css">

</style>
