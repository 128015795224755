import {get, post, put} from '@/api';
import {utcDate} from 'BOARD/components/IndicatorChart/IndicatorChartUtils';
import {PAGE_SIZE} from 'GLOBALS/constants';
import {API_URL} from '../../config/api';
import {getAllItemsFromAllPages} from '../editor/hierarchy/actions';
import {getKPIHorizonWindow} from '../../board/components/IndicatorChart/IndicatorChartUtils';
import DateGenerator from './DateGenerator';

const actions = {
  resetTimeSeriesData({commit}, timeSeriesId) {
    commit('resetTimeSeriesById', timeSeriesId);
  },
  async fetchTimeSeries({commit}, {id, startDate, endDate}) {
    if (!id) {
      console.warn('Time series id is required');
      return [];
    }

    startDate = utcDate(startDate);
    endDate = utcDate(endDate, 23, 59, 59, 999);
    const start = startDate ? `start=${startDate.toISOString()}&` : '';
    const end = endDate ? `end=${endDate.toISOString()}&` : '';
    try {
      const url = `${API_URL}/timeseries/${id}?${start}${end}page=1&size=${PAGE_SIZE}`;
      const points = await getAllItemsFromAllPages(url, false);
      commit('setTimeSeriesPoint', {id, points});
    } catch (error) {
      console.error('Error fetching time series points:', error);
    }
  },

  async createTimeSeriesPoint({dispatch, commit}, {timestamp, valueType, value, kpi}) {
    const timeSeriesData = {
      timestamp,
      valueType,
      value,
      kpi
    };
    try {
      const createdTimeSeries = await post('/timeseries', timeSeriesData);
      if (!createdTimeSeries) {
        return null;
      }
      commit('setTimeSeriesPoint', {id: createdTimeSeries.id, points: [createdTimeSeries]});
      commit('setValueType', {id: createdTimeSeries.id, valueType: timeSeriesData.valueType});
      dispatch('fetchTimeSeriesActualValue', {timeSeriesId: createdTimeSeries.id, kpi});
      return createdTimeSeries;
    } catch (error) {
      console.error('Error creating time series:', error);
    }
  },

  async addTimeSeriesPoint({dispatch, commit}, {timeSeriesId, timestamp, valueType, value, kpi}) {
    try {
      const timeSeriesData = {
        timestamp,
        valueType,
        value,
        kpi
      };

      const addedPoint = await post(`/timeseries/${timeSeriesId}/add`, timeSeriesData);
      if (addedPoint) {
        commit('addTimeSeriesPoint', addedPoint);
        dispatch('fetchTimeSeriesActualValue', {timeSeriesId, kpi});
      }
      return addedPoint;
    } catch (error) {
      console.error('Error adding time series point:', error);
    }
  },

  async updateTimeSeriesPoint({dispatch, commit}, {timeSeriesId, timestamp, valueType, value, kpi}) {
    try {
      const timeSeriesData = {
        valueType,
        value,
        kpi
      };
      const updatedPoint = await put(`/timeseries/${timeSeriesId}/${timestamp}`, timeSeriesData);
      if (updatedPoint) {
        commit('updateTimeSeriesPoint', updatedPoint);
        dispatch('fetchTimeSeriesActualValue', {timeSeriesId, kpi});
      }
      return updatedPoint;
    } catch (error) {
      console.error('Error updating time series point:', error);
    }
  },

  async addOrUpdateTimeSeriesPoint({ dispatch }, {timeSeriesId, timestamp, value, valueType, timeSeriesPoints, kpi}) {
    const existingPoint = timeSeriesPoints.find((point) => point.timestamp === timestamp);

    if (existingPoint) {
      await dispatch('updateTimeSeriesPoint', {timeSeriesId, timestamp, valueType, value, kpi});
    } else {
      await dispatch('addTimeSeriesPoint', {timeSeriesId, timestamp, valueType, value, kpi});
    }
  },

  async fetchTimeSeriesActualValue({ commit }, {timeSeriesId, kpi}) {
    if (!timeSeriesId || !kpi) {
      return {};
    }
    const timeWindow = getKPIHorizonWindow(kpi);
    const frequency = kpi?.attributes?.timeSeriesFrequency;
    const end = new Date().toISOString();
    const dateGenerator = new DateGenerator(frequency, timeWindow.startDate, end);
    const query = dateGenerator.generateDates().map((id) => `dates=${id}`).join('&');

    try {
      const response = await get(`/timeseries/${timeSeriesId}?${query}&size=1`);
      const data = response?.data ?? [];
      if (data?.length) {
        commit('setTimeSeriesActualValue', data[0]);
      }
    } catch (error) {
      console.error('Error fetching the actual timeseries value:', error);
      throw error;
    }
  }

};

export default actions;
