/* eslint-disable max-lines */
import { get, post, put, del } from 'SRC/api/index.js';
import {
  BOARD_SIZE,
  VIEW_MARGIN,
  MAIN_MENU_HEIGHT,
  MUTATION_TYPES,
  VERSION_MODES,
  COMPARAISON_DATE_OPTIONS,
  PAGE_SIZE,
  HISTORY_PAGE_SIZE,
  INTERACTION_MODES,
  LINKS_TYPES,
  assetTypes,
  NO_EDITOR_ON_CREATION,
  RELATION_TYPES
} from 'GLOBALS/constants.js';
import utils from './utils';
import {
  getSyncedDoc,
  initWebsocketProvider,
  getSyncedStore,
  getUserStateId
} from 'SRC/utils/collab';
import apiUtils from 'SRC/store/utils';
import router from '@/router';

const currentUserRoute = 'users/getCurrentUser';

const actions = {
  async deleteSelectedDatasetElement({ state, commit }, id) {
    try {
      const friendlyId = state.datasetElements[id].attributes['friendly-id'];
      const selectedDatasetElements = state.selectedDatasetElements.filter((el) => el !== friendlyId);
      const boardElements = Object.values(state.elements).filter((el) => el.datasetElement.id === id);
      if (boardElements.length) {
        boardElements.forEach(async (el) => {
          await commit('updateBoardElement', {
            elementId: el.id,
            updates: {
              deleted: true
            }
          });
        });
      }
      await commit('setSelectedDatasetElements', selectedDatasetElements);
      await commit('updateDatasetElement', {
        datasetElementId: id,
        updates: {
          deleted: true
        }
      });
      const result = await del(`/dataset-elements/${id}`);
      return !!result;
    } catch (error) {
      return false;
    }
  },
  async updateDatasetElementFromAPI({commit}, {datasetElementId, attributes}) {
    const response = await put(`/dataset-elements/${datasetElementId}`, {attributes});
    if (response) {
      commit('updateDatasetElement', {
        datasetElementId,
        updates: {
          attributes: response.attributes
        }
      });
    }
  },
  async fetchBoardData({ commit, state, dispatch }) {
    const boardData = await get(`/boards/${state.id}`);
    commit('setBoardData', boardData);
    dispatch('downloadBackgroundImage');
  },
  async updateBoard({ commit, state }, payload) {
    const boardData = await put(`/boards/${state.id}`, payload);
    if (boardData) {
      commit('setBoardData', boardData);
      return true;
    }
    return false;
  },
  updateBoardElementPosition({ commit }, { elementId, position }) {
    commit('updateBoardElement', {
      elementId,
      updates: {
        position
      }
    });
  },
  updateBoardElementSize({ commit }, { elementId, size }) {
    commit('updateBoardElement', {
      elementId,
      updates: {
        width: size.width,
        height: size.height
      }
    });
  },
  computeZoomToFitLevel({ commit }) {
    const viewHeight = document.documentElement.clientHeight - MAIN_MENU_HEIGHT;
    const viewWidth = document.documentElement.clientWidth;
    const zoomLevel = Math.min(
      (viewWidth - 2 * VIEW_MARGIN.HORIZONTAL) / BOARD_SIZE.width,
      (viewHeight - 2 * VIEW_MARGIN.VERTICAL) / BOARD_SIZE.height
    );
    commit('setZoomLevelMin', zoomLevel);
    commit('setZoomToFitLevel', zoomLevel);
  },
  initViewportReferencePointOrigin({ commit, state }) {
    const viewSize = {
      height: document.documentElement.clientHeight - MAIN_MENU_HEIGHT,
      width: document.documentElement.clientWidth
    };
    const renderedBoardSize = {
      width: BOARD_SIZE.width * state.zoomLevel,
      height: BOARD_SIZE.height * state.zoomLevel
    };

    const x = (renderedBoardSize.width - viewSize.width) * 0.5;
    const y = (renderedBoardSize.height - viewSize.height) * 0.5;

    commit('setViewportReferencePointOrigin', {
      x: x / state.zoomLevel,
      y: y / state.zoomLevel
    });
    commit('setViewportReferencePoint', {
      x: x / state.zoomLevel,
      y: y / state.zoomLevel
    });
  },
  updateViewportReferencePoint({ commit, state }) {
    const dx =
      (BOARD_SIZE.width * state.zoomLevel -
        document.documentElement.clientWidth) *
      0.5;
    const dy =
      (BOARD_SIZE.height * state.zoomLevel -
        document.documentElement.clientHeight) *
      0.5;
    commit('setDx', dx);
    commit('setDy', dy);

    const x =
      state.viewportReferencePointOrigin.x +
      -1 * state.panX +
      state.dx / state.zoomLevel;
    const y =
      state.viewportReferencePointOrigin.y +
      -1 * state.panY +
      state.dy / state.zoomLevel;

    commit('setViewportReferencePoint', { x, y });
  },
  zoomToFit({ commit, state }) {
    commit('setZoomLevel', state.zoomToFitLevel);
  },
  zoomIn({ commit, state }) {
    commit('zoomIn');
    actions.updateViewportReferencePoint({ commit, state });
  },
  zoomOut({ commit, state }) {
    commit('zoomOut');
    actions.updateViewportReferencePoint({ commit, state });
  },
  adjustPanAfterZoom({ commit, state }, oldZoomLevel, event) {
    if (state.zoomLevel === state.zoomLevelMin) {
      commit('setPanX', 0);
      commit('setPanY', 0);
      return;
    }
    const deltaZoom = state.zoomLevel - oldZoomLevel;
    const viewport = {
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight - MAIN_MENU_HEIGHT * 0.5
    };
    const dx =
      (((event.clientX - viewport.width * 0.5) / oldZoomLevel) * deltaZoom) /
      oldZoomLevel;
    const dy =
      (((event.clientY - viewport.height * 0.5) / oldZoomLevel) * deltaZoom) /
      oldZoomLevel;
    commit('setPanX', state.panX - dx);
    commit('setPanY', state.panY - dy);
  },
  zoomInWithWheel({ commit, state }, event) {
    const ratio = 1 + Math.abs(event.deltaY) * state.zoomWheelDeltaRatio;
    const oldZoomLevel = state.zoomLevel;
    const newZoomLevel = state.zoomLevel * ratio;
    commit('setZoomLevel', newZoomLevel);
    actions.adjustPanAfterZoom({ commit, state }, oldZoomLevel, event);
    actions.updateViewportReferencePoint({ commit, state });
  },
  zoomOutWithWheel({ commit, state }, event) {
    const ratio = 1 + event.deltaY * state.zoomWheelDeltaRatio;
    const oldZoomLevel = state.zoomLevel;
    const newZoomLevel = state.zoomLevel / ratio;
    if (newZoomLevel <= state.zoomLevelMin) {
      commit('setZoomLevel', state.zoomLevelMin);
    } else {
      commit('setZoomLevel', newZoomLevel);
    }
    actions.adjustPanAfterZoom({ commit, state }, oldZoomLevel, event);
    actions.updateViewportReferencePoint({ commit, state });
  },
  initWebSocketProvider({ state, rootGetters }) {
    const boardStore = getSyncedStore();
    const doc = getSyncedDoc(boardStore);
    const token = rootGetters['auth/getToken'];
    initWebsocketProvider(state.id, doc, token);
  },
  async addDatasetElementToStore({ state, commit }, datasetElementId) {
    if (!state.datasetElements[datasetElementId]) {
      const datasetElement = await get(`/dataset-elements/${datasetElementId}`);
      commit('addDatasetElement', {
        datasetElementId,
        datasetElement
      });
    }
    return state.datasetElements[datasetElementId];
  },
  async addDatasetElementsToStore({ state, commit }, datasetElementIds) {
    const filteredIds = datasetElementIds.filter(
      (id) => !state.datasetElements[id]
    );
    if (!filteredIds?.length) {
      return;
    }
    const query = filteredIds.map((id) => `ids=${id}`).join('&');
    const response = await get(`/dataset-elements?${query}`);
    const datasetElements = response.data;
    if (datasetElements && datasetElements.length > 0) {
      datasetElements.forEach((element) => {
        commit('addDatasetElement', {
          datasetElementId: element.id,
          datasetElement: element
        });
      });
    }
  },
  updateDatasetElementAttributes({ commit }, { datasetElementId, attributes }) {
    commit('updateDatasetElement', {
      datasetElementId,
      updates: {
        attributes
      }
    });
  },
  notifyEditorOpen({ state, commit, rootGetters }, boardElementId) {
    const currentUser = rootGetters[`${currentUserRoute}`];
    if (currentUser && currentUser.id) {
      const currentUserClientId = getUserStateId(currentUser.id);
      if (
        state.boardElementsInEdition[boardElementId] &&
        Array.isArray(state.boardElementsInEdition[boardElementId])
      ) {
        if (
          !state.boardElementsInEdition[boardElementId].some(
            (each) =>
              each.userId === currentUser.id &&
              each.awarenessStateId === currentUserClientId
          )
        ) {
          commit('setUsersForBoardElementInEdition', {
            boardElementId,
            users: [
              ...state.boardElementsInEdition[boardElementId],
              { userId: currentUser.id, awarenessStateId: currentUserClientId }
            ]
          });
        }
      } else {
        commit('setUsersForBoardElementInEdition', {
          boardElementId,
          users: [
            { userId: currentUser.id, awarenessStateId: currentUserClientId }
          ]
        });
      }
    }
  },
  async notifyEditorClosed({ state, commit, rootGetters }, boardElementId) {
    const currentUser = rootGetters[`${currentUserRoute}`];
    const currentUserClientId = getUserStateId(currentUser.id);
    if (
      currentUser &&
      state.boardElementsInEdition[boardElementId] &&
      state.boardElementsInEdition[boardElementId].length
    ) {
      if (
        state.boardElementsInEdition[boardElementId].some(
          (each) =>
            each.userId === currentUser.id &&
            each.awarenessStateId === currentUserClientId
        )
      ) {
        const otherModifyingSessions = state.boardElementsInEdition[
          boardElementId
        ].filter((each) => each.awarenessStateId !== currentUserClientId);
        if (!otherModifyingSessions.length) {
          await commit('deleteBoardElementInEdition', { boardElementId });
          return;
        }
        commit('setUsersForBoardElementInEdition', {
          boardElementId,
          users: otherModifyingSessions
        });
      }
    }
  },
  notifyBoardElementMoveStarted(
    { state, commit, rootGetters },
    boardElementId
  ) {
    const currentUser = rootGetters[`${currentUserRoute}`];
    if (
      state.boardElementsBeingMoved[boardElementId] &&
      Array.isArray(state.boardElementsBeingMoved[boardElementId])
    ) {
      if (
        !state.boardElementsBeingMoved[boardElementId].some(
          (each) => each === currentUser.id
        )
      ) {
        commit('setUsersForBoardElementBeingMoved', {
          boardElementId,
          users: [
            ...state.boardElementsBeingMoved[boardElementId],
            currentUser.id
          ]
        });
      }
    } else {
      commit('setUsersForBoardElementBeingMoved', {
        boardElementId,
        users: [currentUser.id]
      });
    }
  },
  notifyBoardElementMoveFinished(
    { state, commit, rootGetters },
    boardElementId
  ) {
    const currentUser = rootGetters[`${currentUserRoute}`];
    if (
      currentUser &&
      state.boardElementsBeingMoved[boardElementId] &&
      state.boardElementsBeingMoved[boardElementId].length
    ) {
      commit('setUsersForBoardElementBeingMoved', {
        boardElementId,
        users: state.boardElementsBeingMoved[boardElementId].filter(
          (eachUserId) => eachUserId !== currentUser.id
        )
      });
    }
  },
  selectBoardElements(
    { state, commit, rootGetters },
    { boardElementsIds, mutationType }
  ) {
    const currentUser = rootGetters[`${currentUserRoute}`];
    const currentUserClientId = getUserStateId(currentUser.id);
    const userObject = {
      userId: currentUser.id,
      awarenessStateId: currentUserClientId
    };
    const data = utils.fillDataObject(
      state,
      boardElementsIds,
      userObject,
      currentUser.id
    );

    if (mutationType === MUTATION_TYPES.APPEND_TO_PREVIOUS) {
      commit('setUsersForBoardElementBeingSelected', {
        ...state.boardElementsBeingSelected,
        ...data
      });
    } else {
      const filteredData = Object.entries(
        state.boardElementsBeingSelected
      ).reduce((acc, [id, usersArray]) => {
        if (boardElementsIds.indexOf(id) > -1) {
          return acc;
        } else {
          acc[id] = usersArray.filter((e) => e.userId !== currentUser.id);
          return acc;
        }
      }, {});
      commit('setUsersForBoardElementBeingSelected', {
        ...filteredData,
        ...data
      });
    }
    commit('setUsersForBoardElementBeingSelected', data);
  },
  removeSelection({ state, commit, rootGetters }, elementsIds) {
    const currentUser = rootGetters[`${currentUserRoute}`];
    const selectedEls = Object.entries(state.boardElementsBeingSelected).reduce(
      (acc, [elementId, usersList]) => {
        if (!elementsIds.includes(elementId)) {
          acc[elementId] = usersList;
          return acc;
        }
        acc[elementId] = usersList.filter((e) => e.userId !== currentUser.id);
        return acc;
      },
      {}
    );
    commit('setUsersForBoardElementBeingSelected', selectedEls);
  },
  removeGlobalSelection({ state, commit }, elementsIds) {
    const selectedEls = Object.entries(state.boardElementsBeingSelected).reduce(
      (acc, [elementId, usersList]) => {
        acc[elementId] = !elementsIds.includes(elementId) ? usersList : [];
        return acc;
      },
      {}
    );
    commit('setUsersForBoardElementBeingSelected', selectedEls);
  },
  resetSelectedElements({ state, commit, rootGetters }) {
    const currentUser = rootGetters[`${currentUserRoute}`];
    const data = Object.entries(state.boardElementsBeingSelected).reduce(
      (acc, [boardElementId, usersArray]) => {
        if (usersArray?.length > 0) {
          acc[boardElementId] = usersArray.filter(
            (e) => e.userId !== currentUser.id
          );
        }
        return acc;
      },
      {}
    );
    commit('setUsersForBoardElementBeingSelected', data);
  },
  deleteUserSelectedElements({ state, commit, rootGetters, dispatch }) {
    const currentUser = rootGetters[`${currentUserRoute}`];
    const userSelectedElements = state.userSelectedElements;
    const userEditedElements = Object.entries(state.boardElementsInEdition)
      .filter(([, usersList]) =>
        usersList.find(({ userId }) => userId === currentUser.id)
      )
      .map((e) => e[0]);
    if (
      userSelectedElements &&
      userSelectedElements.find(
        (el) => userEditedElements && userEditedElements.includes(el)
      )
    ) {
      dispatch('editor/closeEditor', '', { root: true });
    }
    userSelectedElements?.forEach(async (elementId) => {
      await commit('updateBoardElement', {
        elementId,
        updates: {
          deleted: true
        }
      });
      if (state.boardElementsInEdition[elementId]) {
        const allUsers = state.boardElementsInEdition[elementId];
        const otherUsers = allUsers.filter(
          (each) => each.userId !== currentUser.id
        );
        if (!allUsers.length || !otherUsers.length) {
          await commit('deleteBoardElementInEdition', {
            boardElementId: elementId
          });
          return;
        }

        await commit('setUsersForBoardElementInEdition', {
          boardElementId: elementId,
          users: otherUsers
        });
      }
    });
    dispatch('removeGlobalSelection', userSelectedElements);
  },
  toggleSelectedElementsLockState({ state, commit }) {
    const userSelectedElements = state.userSelectedElements;
    const selectedElements = Object.values(state.elements).filter((element) => userSelectedElements.includes(element.id));
    const locked = !selectedElements?.some((element) => element.locked);
    userSelectedElements?.forEach(async (elementId) => {
      await commit('updateBoardElement', {
        elementId,
        updates: {
          locked
        }
      });
    });
  },
  setUserSelectedElements({ commit, rootGetters }, data) {
    const currentUser = rootGetters[`${currentUserRoute}`];
    const userSelectedElements = Object.entries(data).reduce(
      (acc, [elementId, usersList]) => {
        const isSelectedBycurrentUser = usersList.find(
          (e) => e.userId === currentUser.id
        );
        if (isSelectedBycurrentUser) {
          return [...acc, elementId];
        }
        return acc;
      },
      []
    );
    commit('setUserSelectedElements', userSelectedElements);
  },
  selectAllBoardElements({ state, commit, rootGetters }) {
    const currentUser = rootGetters[`${currentUserRoute}`];
    const currentUserClientId = getUserStateId(currentUser.id);
    const userObject = {
      userId: currentUser.id,
      awarenessStateId: currentUserClientId
    };
    const data = utils.fillDataObject(
      state,
      Object.keys(state.elements),
      userObject,
      currentUser.id
    );
    commit('setUsersForBoardElementBeingSelected', data);
  },
  async addBoardVersionsToStore({ state, commit }, mode) {
    const boardVersions = await get(
      `/boards/${state.id}/versions?page=1&size=${PAGE_SIZE}`
    );
    if (boardVersions && boardVersions.data) {
      let boardVersionsData = boardVersions.data;
      boardVersionsData = await apiUtils.getNextPage(
        boardVersions,
        boardVersionsData,
        get
      );
      const today = new Intl.DateTimeFormat(
        'en-EU',
        COMPARAISON_DATE_OPTIONS
      ).format(new Date());
      const otherVersions = [];
      const recentVersions = [];
      boardVersionsData.forEach((boardVersion) => {
        const creationDate = new Intl.DateTimeFormat(
          'en-EU',
          COMPARAISON_DATE_OPTIONS
        ).format(new Date(boardVersion.creationTimestamp));
        utils.formatVersion(boardVersion, false, today, creationDate);
        if (creationDate === today) {
          recentVersions.push(boardVersion);
        } else {
          otherVersions.push(boardVersion);
        }
      });
      if (mode === VERSION_MODES[0]) {
        commit('setSelectedVersion', recentVersions[0]);
      }
      const oldVersions = {};
      otherVersions.forEach((version) => {
        if (oldVersions[version.date]) {
          otherVersions[version.date] = oldVersions[version.date].push(version);
        } else {
          oldVersions[version.date] = [version];
        }
      });

      commit('addBoardRecentVersions', recentVersions);
      commit('addBoardOldVersions', oldVersions);
    }
  },
  async addBoardVersion({ state, commit }, version) {
    const markedVersion = await post(`/boards/${state.id}/versions`, version);
    if (markedVersion) {
      const today = new Intl.DateTimeFormat(
        'en-EU',
        COMPARAISON_DATE_OPTIONS
      ).format(new Date());
      const creationDate = new Intl.DateTimeFormat(
        'en-EU',
        COMPARAISON_DATE_OPTIONS
      ).format(new Date(markedVersion.creationTimestamp));
      utils.formatVersion(markedVersion, true, today, creationDate);
      commit('setSelectedVersion', markedVersion);
      commit('addMarkedVersion', markedVersion);
    }
  },
  async editVersion({ state, commit }, { version, versionId }) {
    const editedVersion = await put(`/board-versions/${versionId}`, version);
    if (editedVersion) {
      const today = new Intl.DateTimeFormat(
        'en-EU',
        COMPARAISON_DATE_OPTIONS
      ).format(new Date());
      const creationDate = new Intl.DateTimeFormat(
        'en-EU',
        COMPARAISON_DATE_OPTIONS
      ).format(new Date(editedVersion.creationTimestamp));
      if (!editedVersion.marked) {
        if (state.selectedVersion.id !== editedVersion.id) {
          utils.formatVersion(editedVersion, false, today, creationDate);
          commit('setEditedVersion', editedVersion);
          return;
        }
        utils.formatVersion(editedVersion, true, today, creationDate);
        commit('setEditedVersion', editedVersion);
        return;
      }

      utils.formatVersion(editedVersion, true, today, creationDate);
      commit('setSelectedVersion', editedVersion);
      commit('setEditedVersion', editedVersion);
    }
  },
  async restoreVersion({ state, dispatch }, versionId) {
    dispatch('removeSelection', state.userSelectedElements);
    dispatch('editor/resetEditor', undefined, { root: true });
    if (state.formulaInEditionBoardElementId) {
      dispatch(
        'removeFormulaFromSelection',
        state.formulaInEditionBoardElementId
      );
    }
    await put(`/board-versions/${versionId}/restore`, null, false);
  },
  async deleteVersion(_empty, versionId) {
    await del(`/board-versions/${versionId}`, null, false);
  },
  async createBoardElement(
    { state, rootState, commit, dispatch },
    { requestBody, datasetElementId, dataTypeName, attributes }
  ) {
    if (attributes) {
      requestBody.datasetElement = {
        typeId: rootState.dock.dataTypeId,
        attributes
      };
    }
    const endpoint = datasetElementId ? `/dataset-elements/${datasetElementId}/board-elements` : '/board-elements';
    const response = await post(endpoint, requestBody);
    if (!response) {
      return;
    }

    const newElement = {
      id: response.id,
      datasetElement: response.datasetElement,
      position: response.position,
      viewTypeId: response.viewTypeId,
      width: response.width,
      height: response.height,
      attributes: response.attributes,
      scale: response.scale
    };

    if (state.datasetElements[newElement.datasetElement.id]) {
      await dispatch('updateDatasetElementAttributes', {
        datasetElementId,
        attributes: {
          ...newElement.datasetElement.attributes
        }
      });
    } else {
      await dispatch('addDatasetElementToStore', newElement.datasetElement.id);
    }

    commit('addBoardElement', {
      elementId: newElement.id,
      element: newElement
    });

    if (dataTypeName === 'Formula') {
      dispatch(
        'handleFormulaDataType', newElement
      );
    } else if (!NO_EDITOR_ON_CREATION.includes(dataTypeName) && !rootState.dock.isImporting) {
      dispatch('editor/openEditor', {
        datasetElementId: newElement.datasetElement.id,
        boardElementId: newElement.id
      }, { root: true });
    }
    if (rootState.dock.isImporting) {
      commit('dock/setIsImporting', false, { root: true });
    }
    return newElement.id;
  },
  async getAsset({commit}, {assetId}) {
    if (!assetId) {
      return;
    }
    try {
      const response = await get(`/assets/${assetId}`, null, true, true);
      if (response) {
        const asset = {
          id: response.id,
          contentUrl: response.contentUrl,
          type: response.type
        };
        commit('setAsset', {assetId: asset.id, asset});
        return asset;
      }
    } catch (e) {
      console.error(e);
    }
  },
  async createAsset({ commit }, { asset, type }) {
    try {
      const formData = new FormData();
      formData.append('file', asset);
      formData.append('type', type);
      const response = await post('/assets', formData, true, true, false, true);
      if (response) {
        const newAsset = {
          id: response.id,
          contentUrl: response.contentUrl,
          type: response.type
        };
        commit('setAsset', { assetId: newAsset.id, asset: newAsset });
        return newAsset;
      }
    } catch (e) {
      console.error(e);
      return;
    }
  },
  async createBoardImage({ dispatch }, { requestBody, file }) {
    try {
      const createdAsset = await dispatch('createAsset', {
        asset: file,
        type: assetTypes.BOARD_IMAGE
      }
      );
      if (createdAsset) {
        const elementId = await dispatch('createBoardElement', {
          requestBody,
          attributes: {
            asset: createdAsset.id
          },
          dataTypeName: 'Image'
        });
        return elementId;
      }
    } catch (e) {
      console.error(e);
      return;
    }
  },
  async updateBoardImage({dispatch}, {datasetElementId, datasetElement, file, size, elementId}) {
    try {
      const createdAsset = await dispatch('createAsset', {
        asset: file,
        type: assetTypes.BOARD_IMAGE
      }
      );
      await dispatch('updateDatasetElementAttributes', {
        datasetElementId,
        attributes: {
          ...datasetElement.value.attributes,
          asset: createdAsset.id
        }
      });
      await dispatch('updateBoardElementSize', {
        elementId,
        size
      });
    } catch (e) {
      console.error(e);
      return;
    }
  },
  handleFormulaDataType({ commit, rootGetters, dispatch }, newElement) {
    const currentUser = rootGetters[`${currentUserRoute}`];
    const currentUserClientId = getUserStateId(currentUser.id);
    commit('setUsersForBoardElementInEdition', {
      boardElementId: newElement.id,
      users: [
        { userId: currentUser.id, awarenessStateId: currentUserClientId }
      ]
    });
    dispatch('editor/closeEditor', undefined, { root: true });
    commit('setLastBoardElementIdInEdition', newElement.id);
    commit('setFormulaInEditionBoardElementId', newElement.id);
  },
  openFormulaEditor(
    { commit, state, dispatch, rootGetters },
    { boardElementId }
  ) {
    if (state.formulaInEditionBoardElementId) {
      dispatch(
        'removeFormulaFromSelection',
        state.formulaInEditionBoardElementId
      );
    }
    dispatch('editor/closeEditor', undefined, { root: true });
    const currentUser = rootGetters[`${currentUserRoute}`];
    const currentUserClientId = getUserStateId(currentUser.id);
    const previousElementEditedByUser = Object.entries(
      state.boardElementsInEdition
    ).filter(([, usersList]) =>
      usersList.find((each) => each.userId === currentUser.id)
    );
    if (previousElementEditedByUser && previousElementEditedByUser.length > 0) {
      previousElementEditedByUser
        .map((e) => e[0])
        .forEach(async (elementId) => {
          if (state.boardElementsInEdition[elementId]) {
            const otherModifiers = state.boardElementsInEdition[
              elementId
            ].filter((e) => e.userId !== currentUser.id);
            if (!otherModifiers.length) {
              await commit('deleteBoardElementInEdition', {
                boardElementId: elementId
              });
              return;
            }
            commit('setUsersForBoardElementInEdition', {
              boardElementId: elementId,
              users: otherModifiers
            });
          }
        });
    }
    commit('setUsersForBoardElementInEdition', {
      boardElementId,
      users: [
        ...(state.boardElementsInEdition[boardElementId] || []),
        { userId: currentUser.id, awarenessStateId: currentUserClientId }
      ]
    });
    commit('setLastBoardElementIdInEdition', boardElementId);
    commit('setFormulaInEditionBoardElementId', boardElementId);
  },
  async removeFormulaFromSelection(
    { commit, state, rootGetters, dispatch },
    boardElementId
  ) {
    const currentUser = rootGetters[`${currentUserRoute}`];
    dispatch('removeSelection', [boardElementId]);
    if (state.boardElementsInEdition[boardElementId]) {
      const otherModifiers = state.boardElementsInEdition[
        boardElementId
      ].filter((e) => e.userId !== currentUser.id);
      if (!otherModifiers.length) {
        await commit('deleteBoardElementInEdition', { boardElementId });
        return;
      }
      commit('setUsersForBoardElementInEdition', {
        boardElementId,
        users: otherModifiers
      });
    }
    commit('setFormulaInEditionBoardElementId', null);
  },
  setCursor({ state, commit }, { value, lock }) {
    if (state.isCursorLocked) {
      return;
    }
    document.body.style.cursor = value;
    if (lock) {
      commit('lockCursor');
    }
  },
  async getHistory({ state, commit }, page) {
    let history;
    if (page !== 1) {
      if (state.nextPageBoardHistoryUrl) {
        history = await get(state.nextPageBoardHistoryUrl, null, true, false);
      }
    } else {
      history = await get(
        `/history/traces?boardId=${state.id}&page=${page}&size=${HISTORY_PAGE_SIZE}`
      );
    }

    if (!history) {
      return;
    }

    if (history.next) {
      commit('setNextPageBoardHistoryUrl', history.next);
    } else {
      commit('setNextPageBoardHistoryUrl', null);
    }
    if (history.data) {
      const boardHistory = utils.addUserToHistoryData(history.data);
      commit('addHistory', utils.addUserPictureCheck(boardHistory));
    }
  },
  async getDatasetElementHistory(
    { state, commit },
    { page, datasetElementId }
  ) {
    let history;
    if (page !== 1) {
      if (state.nextPageDatasetElementHistoryUrl) {
        history = await get(
          state.nextPageDatasetElementHistoryUrl,
          null,
          true,
          false
        );
      }
    } else {
      history = await get(
        `/dataset-elements/${datasetElementId}/history/traces?page=${page}&size=${HISTORY_PAGE_SIZE}`
      );
    }

    if (!history) {
      return;
    }

    if (history.next) {
      commit('setNextPageDatasetElementHistoryUrl', history.next);
    } else {
      commit('setNextPageDatasetElementHistoryUrl', null);
    }
    if (history.data) {
      const boardHistory = utils.addUserToHistoryData(history.data);
      commit(
        'addDatasetElementHistory',
        utils.addUserPictureCheck(boardHistory)
      );
    }
  },
  async createVisualLink({state, commit}, link) {
    const hierarchicalRelationId = link?.hierarchicalRelationId ;
    let relationKind = state.selectedLinkType?.type;
    if (hierarchicalRelationId) {
      relationKind =  LINKS_TYPES.HierarchicalRelationType ;
    }
    const sourceElement = state.elements[state.link.source];
    const targetElement = state.elements[state.link.target];
    const sourceId = sourceElement?.id || link.source;
    const targetId = targetElement?.id || link.target;
    const relationTypeId = state.selectedLinkType?.id;
    const relationId = null;
    if (sourceId === targetId) {
      return ;
    }
    const visualLink = await post('/visual-links', {
      sourceBoardElementId: sourceId,
      targetBoardElementId: targetId,
      ...(relationTypeId && { relationTypeId }),
      ...(relationKind && { relationKind }),
      ...(relationId && { relationId }),
      ...(hierarchicalRelationId && { hierarchicalRelationId }),
      strokeStyle: 'solid',
      thickness: 1
    }).catch((error) => {
      const message = utils.getWarningMessage(state, error, relationKind, sourceElement, targetElement);
      if (message) {
        commit('setWarning', message);
      }
    });
    if (!visualLink) {
      commit('resetLink');
      return;
    }
    return visualLink;
  },

  async processRelation({ state, commit, dispatch }) {
    if (!(state.link.target && state.link.source)) {
      return;
    }
    const line = document.getElementById('mouseFollower');
    if (line) {
      line.remove();
    }
    commit('relations/removeLine', 'mouseFollower', { root: true });
    const visualLink = await dispatch('createVisualLink');
    const {id, relationId, dependency, hierarchicalRelationId, hierarchicalRelation } = visualLink;
    commit(
      'relations/addVisualLink',
      {
        visualLinkId: id,
        visualLink
      },
      { root: true }
    );
    if (hierarchicalRelationId) {
      commit(
        'relations/addHierarchicalRelation',
        {
          hierarchicalRelationId,
          hierarchicalRelation
        },
        { root: true }
      );
    } else {
      commit(
        'relations/addDependency',
        {
          dependencyId: relationId,
          dependency
        },
        { root: true }
      );
    }
    commit('relations/setSelectedVisualLink', { id }, { root: true });
    commit('resetLink');
    commit('setSelectedInteractionMode', { value: INTERACTION_MODES.SELECT });
  },
  closeComponent({ commit, state}, component) {
    const filteredComponents = state.openedComponents.filter((c) => c !== component);
    commit('setOpenedComponents', filteredComponents);
  },
  async createDatasetElementInBoardget({ dispatch, commit, rootState }, { requestBody, filterId, relatedElementId }) {
    commit('dock/setIsCreating', true, { root: true});
    const newElement = await post('/dataset-elements', requestBody);
    if (!newElement) {
      return;
    }
    await dispatch('board/addDatasetElementToStore', newElement.id, { root: true });
    const dataTypeName = rootState.app.dataTypes[requestBody.typeId].name;
    if (!['List', 'Image'].includes(dataTypeName)) {
      commit('editor/setFilterId', filterId, { root: true });
      if (relatedElementId) {
        await dispatch('createRelationForNewElementInBoardgetList',
          { newElementId: newElement.id, sourceTypeId: requestBody.typeId, relatedElementId });
      }
      dispatch('editor/openEditor', {
        datasetElementId: newElement.id,
        boardElementId: null
      }, { root: true });
    }
    return {id: newElement.id };
  },
  async createRelationForNewElementInBoardgetList(
    { state, dispatch },
    { newElementId, sourceTypeId, relatedElementId }) {
    const relatedDatasetElement = state.datasetElements[relatedElementId] || await get(`/dataset-elements/${relatedElementId}`);
    const hasSameDataType = sourceTypeId === relatedDatasetElement.typeId;
    await dispatch('hierarchy/createRelationWithExistingElement', {
      relation: hasSameDataType ? RELATION_TYPES[1] : RELATION_TYPES[2],
      sourceDatasetElementId: relatedElementId,
      targetDatasetElementId: newElementId
    }, { root: true });
  },
  async getFilterDataType(_, {filterId}
  ) {
    const filter = await get(`/dataset-elements/filters/${filterId}`);
    if (filter) {
      return filter;
    }
    return null;
  },
  async fetchFilterResults({ commit, state }, { id, pageSize = PAGE_SIZE, page = 1 }) {
    if (!id) {
      return;
    }
    commit('setFilter', { id, elements: [], totalCount: 0, isLoading: true, currentPage: page });
    const response = await get(`/dataset-elements/filters/${id}/results?page=${page}&size=${pageSize}`);
    const list = response?.data || [];
    if (list.length) {
      const elements = [];
      list.forEach((element) => {
        if (!state.datasetElements[element.id]) {
          commit('addDatasetElement', {
            datasetElementId: element.id,
            datasetElement: element});
        }
        elements.push(state.datasetElements[element.id]);
      });
      commit('setFilter', { id, elements, totalCount: response.totalCount, isLoading: false, currentPage: page });
    } else {
      commit('setFilter', { id, elements: [], totalCount: 0, isLoading: false, currentPage: page });
    }
    return { totalCount: response.totalCount };
  },
  async updateFilterResult({state, dispatch}, { id, attributeSearches, dataTypeId, relatedElementIds }) {
    const body = {
      dataTypeId,
      attributeSearches,
      relatedElementIds
    };
    const filter = await put(`/dataset-elements/filters/${id}`, body);
    const filterDatasetElementId = Object.values(state.datasetElements).find((element) => element.attributes?.filter === id);
    const element = Object.values(state.elements).find((element) => element.datasetElement.id === filterDatasetElementId.id);
    if (filter) {
      await dispatch('fetchFilterResults', {id, pageSize: element?.attributes?.rowsPerPage, page: 1});
    }
  },
  async createBoardgetElement({ dispatch, rootGetters }, { attributeSearches, boardId, viewTypeId, dataTypeId, dataTypeName, title }) {
    const response = await post('/dataset-elements/filters', attributeSearches);
    if (response) {
      const size = rootGetters['app/getViewTypeSize'](viewTypeId);
      const { id } = response;
      const datasetElement = {
        typeId: dataTypeId,
        attributes: {
          filter: id,
          title
        }
      };
      const requestBody = {
        boardId,
        viewTypeId,
        position: {
          'x': 0,
          'y': 0
        },
        datasetElement,
        height: size.height,
        width: size.width,
        scale: 1,
        attributes: {
          rowsPerPage: 5
        }
      };
      await dispatch('createBoardElement', { requestBody, dataTypeName, attributes: { filter: id, title } });
    }
  },
  async fetchIndicatorsRelations({ commit, state }, {shouldUseTimeSeriesData}) {
    const allIndicators = Object.values(state.datasetElements).filter((el) => el.typeName === '/boardget-data-types/opex/Indicator');
    const indicatorPromises = allIndicators.map((indicator) => ({
      indicator: indicator.id,
      promise: get(`/dataset-elements/${indicator.id}/relations`)
    }));

    // Wait for all promises to resolve
    const resolvedPromises = await Promise.all(indicatorPromises.map((obj) => obj.promise));

    // Construct the result object using the resolved promises
    const indicatorsRelations = indicatorPromises.reduce((acc, { indicator }, index) => {
      acc[indicator] = resolvedPromises[index];
      return acc;
    }, {});
    const formattedIndicatorsRelationsEntries = Object
      .entries(indicatorsRelations)
      .filter(([, el]) => el.data && el.data.length > 0)
      .map(([indicatorId, relations]) => ([indicatorId, relations.data.map((el) => el.relatedDatasetElementId)]));
    const formattedIndicatorsRelations = Object.fromEntries(formattedIndicatorsRelationsEntries);
    function onlyUnique(value, index, array) {
      return array.indexOf(value) === index;
    }
    const distinctKPISIds = Object.values(formattedIndicatorsRelations).flat().filter(onlyUnique);
    const query = `ids=${distinctKPISIds.join('&ids=')}`;
    const relations = await get(`/dataset-elements?${query}`);
    const traces = await Promise
      .all(distinctKPISIds
        .map((kpiId) => ({kpiId, kpiData: relations.data.find((kpi) => kpi.id === kpiId)}))
        .map(({kpiId, kpiData}) =>
          utils.constructIndicatorValues({kpiId, kpiData, shouldUseTimeSeriesData}))
      );
    const cumulatedWeeklyValuesMap = traces.map(([id, values]) => {
      const cumulatedValues = utils.getLatestValuesByPeriod(values);
      return [id, cumulatedValues];
    });
    const tracesMap = Object.fromEntries(cumulatedWeeklyValuesMap);/*getLastValuePerDayForAllKeys(*/
    if (relations && relations.data) {
      const formattedData = Object.entries(formattedIndicatorsRelations).reduce((acc, [indicatorId, kpisList]) => {
        acc[indicatorId] = relations.data.filter((element) => kpisList.includes(element.id)).map((el) => ({...el, traces: tracesMap[el.id]}));
        return acc;
      }, {});
      commit('setIndicatorsRelations', formattedData);
    }
  },
  async deleteBoard({ state }) {
    const query = `ids=${state.id}`;
    const res = await del(`/boards?${query}`);
    if (res && res[0] && res[0].id) {
      router.push('/boards');
    }
  },
  async uploadBackgroundImage({ commit }, { image }) {
    const formData = new FormData();
    formData.append('file', image);
    formData.append('type', assetTypes.BACKGROUND_IMAGE);
    const result = await post('/assets', formData, true, true, false, true);
    if (result) {
      const backgroundImage = {
        id: result.id,
        name: image.name,
        url: result.contentUrl
      };
      commit('setBackgroundImage', backgroundImage);
    }
  },
  async downloadBackgroundImage({ commit, state }) {
    const backgroundImageId = state.boardData.backgroundImageId;
    if (!backgroundImageId) {
      return;
    }
    const result = await get(`/assets/${backgroundImageId}`, null, true, true);
    if (result) {
      const backgroundImage = {
        id: result.id,
        name: 'background.jpg',
        url: result.contentUrl
      };
      commit('setBackgroundImage', backgroundImage);
    }
  },
  async removeBackgroundImage({ commit, state, dispatch }) {
    const payload = { ...state.boardData, backgroundImageId: null };
    const res = await dispatch('updateBoard', payload);
    if (res) {
      commit('setBackgroundImage', { id: null, name: null, url: null });
    }
  },
  updateDatasetElements({ rootState, commit }, newDatasetElement) {
    const { id, attributes } = newDatasetElement;
    const datasetElement = rootState.board.datasetElements[id];
    const datasetElements = { ...rootState.board.datasetElements,
      [id]: {
        ...datasetElement,
        attributes: {...newDatasetElement.attributes, ...attributes}
      }};
    commit('setDatasetElements', datasetElements);
  },
  updateAttributeInRelatedDatasetElements({ state, commit }, elementId, attributes) {
    const relatedDatasetElement = Object.values(state.datasetElements).find(
      (dsValue) =>
        dsValue.dependencies &&
        dsValue.dependencies.length > 0 &&
        dsValue.dependencies.find((dependency) => dependency.id === elementId)
    );
    if (!relatedDatasetElement) {
      return;
    }
    const datasetElements = {
      ...state.datasetElements,
      [relatedDatasetElement.id]: {
        ...relatedDatasetElement,
        dependencies: relatedDatasetElement.dependencies.map((element) => {
          if (element.id !== elementId) {
            return element;
          }
          return {
            ...element,
            attributes: { ...element.attributes, ...attributes }
          };
        })
      }
    };
    commit('setDatasetElements', datasetElements);
  },
  async synchronizeIndicatorRelations({ rootGetters, state, commit }, {kpiId, shouldUseTimeSeriesData}) {
    const datasetElement = state.datasetElements[kpiId];
    const isKpi = rootGetters['app/getDataTypeName'](datasetElement?.typeId) === 'KPI';
    const isRelatedToIndicator = Object
      .values(state.indicatorsRelations)
      ?.some((relations) => relations.find((el) => el.id === kpiId));
    if (!isKpi || !isRelatedToIndicator) {
      return;
    }
    const indicatorsRelations = { ...state.indicatorsRelations };
    const kpiData = state.datasetElements[kpiId];
    let traces = [];
    const rawTraces = await utils.constructIndicatorValues({kpiId, kpiData, shouldUseTimeSeriesData});
    if (rawTraces && rawTraces.length) {
      traces = utils.getLatestValuesByPeriod(rawTraces[1]);
    }
    const allIndicators = Object.keys(indicatorsRelations);
    if (!allIndicators || !allIndicators.length) {
      return;
    }
    const updatedIndicatorsRelations = allIndicators.reduce((acc, indicatorId) => {
      const isIndicatorRelated = indicatorsRelations[indicatorId]?.find((kpi) => kpi.id === kpiId);
      if (!isIndicatorRelated) {
        if (!indicatorsRelations[indicatorId]) {
          return {...acc};
        }
        return {...acc, [indicatorId]: [...indicatorsRelations[indicatorId]]};
      }
      return {...acc, [indicatorId]: [...indicatorsRelations[indicatorId].map((kpi) => {
        if (kpi.id !== kpiId) {
          return kpi;
        }
        return {...kpi, attributes: kpiData?.attributes, traces};
      })]
      };
    }, {});
    commit('setIndicatorsRelations', updatedIndicatorsRelations);
  }
};

export default actions;
