<template>
  <div class="IobStandardPicker">
    <div
      v-if="mode !== 'Y'"
      class="IobStandardPicker-standard-yearsSlider"
    >
      <iob-button
        size="small"
        color="secondary"
        type="ghost"
        left-icon="ArrowLeft"
        :show-left-icon="true"
        @click="changeYear('prev')"
      />
      <p>{{ currentYear }}</p>
      <iob-button
        size="small"
        color="secondary"
        type="ghost"
        left-icon="ArrowRight"
        :show-left-icon="true"
        @click="changeYear('next')"
      />
    </div>
    <div
      :class="{
        'IobStandardPicker-standard-mode': true,
        'IobStandardPicker-standard-mode-4-3-grid': mode === 'M' || mode === 'Y',
        'IobStandardPicker-standard-mode-1-2-grid': mode === 'S',
        'IobStandardPicker-standard-mode-2-2-grid': mode === 'Q',
      }"
    >
      <template v-if="mode === 'M'">
        <iob-button
          v-for="(month, index) in MONTHS"
          :key="month"
          size="medium"
          color="secondarySoft"
          type="ghostAlternative"
          :label="month"
          :selected="selectedPeriod === index + 1 && currentMode === 'M'"
          @click="setPeriod(index)"
        />
      </template>
      <template v-if="mode === 'Q'">
        <iob-button
          v-for="(quarter, index) in quarters"
          :key="quarter"
          size="medium"
          color="secondarySoft"
          type="ghostAlternative"
          :label="quarter"
          :timeframe="true"
          :selected="selectedPeriod === index + 1 && currentMode === 'Q'"
          @click="setPeriod(index)"
        >
          <span style="font-size: 12px">{{ `${MONTHS[index * 3].substring(0, 3)}-${MONTHS[(index * 3) + 2].substring(0, 3)}` }}</span>
        </iob-button>
      </template>
      <template v-if="mode === 'S'">
        <iob-button
          v-for="(semester, index) in semesters"
          :key="semester"
          size="medium"
          color="secondarySoft"
          type="ghostAlternative"
          :label="semester"
          :selected="selectedPeriod === index + 1 && currentMode === 'S'"
          :timeframe="true"
          @click="setPeriod(index)"
        >
          <span style="font-size: 12px">{{ `${MONTHS[(index * 6) % 12].substring(0, 3)}-${MONTHS[((index * 6) + 5) % 12].substring(0, 3)}` }}</span>
        </iob-button>
      </template>
      <template v-if="mode === 'Y'">
        <iob-button
          v-for="(Year, index) in years"
          :key="Year"
          size="medium"
          color="secondarySoft"
          type="ghostAlternative"
          :label="`${Year}`"
          :selected="years.indexOf(currentYear) === index && currentMode === 'Y'"
          @click="setPeriod(index)"
        />
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { MONTHS } from '../../../../constants';
import IobButton from '../../IobButton/IobButton.vue';

const props = defineProps({
  mode: {
    type: String,
    default: 'M'
  },
  year: {
    type: Number,
    default: new Date().getFullYear()
  },
  period: {
    type: Number,
    default: 0
  }
});

const emit = defineEmits(['changePeriod']);

const selectedPeriod = ref(props.period || 0);
const currentYear = ref(props.year || new Date().getFullYear());
const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
const years = ref(Array.from({ length: 12 }, (_, i) => 2021 + i));
const semesters = ['Semester 1', 'Semester 2'];
const currentMode = ref(props.mode?.length > 0 ? props.mode : 'M');

const changeYear = (direction) => {
  if (direction === 'prev') {
    currentYear.value -= 1;
  } else {
    currentYear.value += 1;
  }
  if (selectedPeriod.value === 0) {
    emit('changePeriod', { year: currentYear.value, period: selectedPeriod.value });
  }
};

const setPeriod = (period) => {
  selectedPeriod.value = period + 1;
  currentMode.value = props.mode;
  if (props.mode === 'Y') {
    currentYear.value = years.value[period];
    selectedPeriod.value = years.value.indexOf(currentYear.value) + 1; 
  }
  emit('changePeriod', { year: currentYear.value, period: selectedPeriod.value });
};

watch(() => props.period, (newValue) => {
  selectedPeriod.value = newValue;
});
watch(() => props.year, (newValue) => {
  currentYear.value = newValue;
});

</script>

<style lang="scss" src="./IobStandardPicker.scss" scoped />
