import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const UsersModule = {
  namespaced: true,
  state: {
    users: [],
    currentUser: null,
    currentUserColor: null,
    usersOnBoard: []
  },
  getters,
  mutations,
  actions
};

export default UsersModule;
