<template>
  <div
    class="IobTextElement"
    :class="textClass"
  >
    {{ text }}
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps({
  type: {
    type: String,
    default: 'text',
    validator: (value) => ['subtitle', 'text'].includes(value)
  },
  text: {
    type: String,
    default: ''
  },
});

const textClass = computed(() => `IobTextElement--${props.type}`);
</script>

<style lang="scss" sscoped>
.IobTextElement {
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.14px;

  &--subtitle {
    color: var(--list-elements-text-element-color-text-secondary-ghost-subtitle);
    font-weight: 500;
  }

  &--text {
    background: var(--list-elements-title-element-color-background-secondary-ghost-default);
    color: var(--list-elements-text-element-color-text-secondary-ghost-text);
    font-weight: 400;
  }

}
</style>
<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-list-elements.scss" />
