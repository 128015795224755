<template>
  <div
    class="IobSeparator"
    :class="[alignClass, colorClass]"
  />
</template>

<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps({
  isVertical: {
    type: Boolean,
    default: false
  },
  color: {
    type: String,
    default: 'base'
  },
  colorType: {
    type: String,
    default: 'onDefault'
  },
  state: {
    type: String,
    default: 'default'
  }
});

const alignClass = computed(() => {
  return props.isVertical ? 'IobSeparator-vertical' : 'IobSeparator-horizontal'
});

const colorClass = computed(() => {
  const modifier = props.color === 'base' ? 'base-onDefault-default' : `${props.color}-${props.colorType}-${props.state}`;
  return `IobSeparator-${modifier}`;
});

</script>

<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-separator.scss" />
<style lang='scss' scoped src='./IobSeparator.scss' />
