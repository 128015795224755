<template>
  <div />
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'OAuthCallbackView',
  data() {
    return {
      code: '',
      infos: {}
    };
  },
  computed: {
    oAuthType() {
      return this.$route.params.type;
    },
    ...mapState({
      currentUser: (state) => state.users.currentUser
    }),
    accessToken() {
      return this.$store.state[this.oAuthType]?.accessToken;
    }
  },
  watch: {
    currentUser(user) {
      if (user && this.oAuthType) {
        this.$store.dispatch(`${this.oAuthType}/retrieveAccessToken`, { code: this.code, infos: this.infos });
      }
    },
    accessToken(token) {
      if (token) {
        this.$router.push({ path: `/board/${this.infos.boardId}`});
      }
    }
  },
  mounted() {
    const params = new URLSearchParams(window.location.search);
    this.code = params.get('code');
    this.infos = JSON.parse(Buffer.from(params.get('state'), 'base64'));
  }
};
</script>
