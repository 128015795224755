const getters = {
  getAuthorizeUrl: (state) => (boardId) => {
    if (!boardId || !state.settings) {
      return null;
    }
    const info = {
      settingsId: state.settings.id,
      boardId
    };
    const buf = Buffer.from(JSON.stringify(info));
    const args = new URLSearchParams({
      // eslint-disable-next-line camelcase
      client_id: state.settings.clientId,
      // eslint-disable-next-line camelcase
      response_type: 'code',
      // eslint-disable-next-line camelcase
      redirect_uri: state.settings.redirectUri,
      scope: state.settings.scope,
      state: buf.toString('base64')
    });
    return `${state.settings.authorizeUri}?${args}`;
  },
  getSettings: (state) => state.settings
};

export default getters;
