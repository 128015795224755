<template>
  <div class="IconWithLabel-Container">
    <icon-loader
      :key="iconName"
      :name="iconName"
      :color="iconColor"
      :stroke="iconStroke"
      :size="iconSize"
    />
    <div
      :style="labelStyle"
      class="IconWithLabel-separation"
    >
      {{ label }}
    </div>
  </div>
</template>

<script> 
import IconLoader from '../IconLoader/IconLoader.vue';

export default {
  name: 'IconWithLabel',
  components: { IconLoader },
  props: {
    iconName: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    labelStyle: {
      type: String,
      default: ''
    },
    iconColor: {
      type: String,
      default: 'black'
    },
    iconStroke: {
      type: String,
      default: 'black'
    },
    iconSize: {
      type: String,
      default: 'default'
    },
  }
};
</script>

<style scoped src="./IconWithLabel.css" />
