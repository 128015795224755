<template>
  <div class="User">
    <iob-avatar
      class="User-avatar"
      :avatar-url="image"
      size="default"
      color-bottom="color"
    />
    <span class="User-name">{{ name }}</span>
  </div>
</template>

<script>
import IobAvatar from '../Molecules/IobAvatar/IobAvatar.vue';

export default {
  name: 'IobUser',
  components: { IobAvatar },
  props: {
    name: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    textColor: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped src="./IobUser.css"></style>
