export const getViewTypeId = (viewTypes, toolType) => {
  let embedViewTypeId = '';
  for (const [key, value] of Object.entries(viewTypes)) {
    if (value.toolType === toolType) {
      embedViewTypeId = key;
      break;
    }
  }
  return embedViewTypeId;
};
