<template>
  <div
    class="DropdownButton"
    @click.stop="toggleDropdown"
  >
    <slot />
    <dropdown-menu
      v-if="isDropdownOpen"
      class="DropdownButton-menu"
      :items="items"
      :top="dropdownTop"
      :left="dropdownLeft"
      :style="dropdownStyle"
      :full-width="fullWidth"
      @click-item="onItemClick"
    />
  </div>
</template>

<script>
import DropdownMenu from '../DropdownMenu/DropdownMenu.vue';

export default {
  name: 'DropdownButton',
  components: {
    DropdownMenu
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    dropdownAtMousePosition: {
      type: Boolean,
      default: false // If set to true, dropdown will be opened on mouse position
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    defaultOpen: {
      type: Boolean,
      default: false
    },
    dropdownStyle: {
      type: String,
      default: ''
    },
    menuStyle: {
      type: String,
      default: ''
    }
  },
  emits: ['on-click-function'],
  data() {
    return {
      isDropdownOpen: this.defaultOpen || false,
      dropdownTop: 0,
      dropdownLeft: 0
    };
  },
  methods: {
    toggleDropdown(e) {
      if (this.isDropdownOpen) {
        this.closeDropdown();
      } else {
        this.openDropdown(e);
      }
    },
    openDropdown(event) {
      if (this.dropdownAtMousePosition) {
        // Calculate the position of the dropdown
        this.calculateDropdownPosition(event);
      }
      this.isDropdownOpen = true;
    },
    closeDropdown() {
      this.isDropdownOpen = false;
    },
    calculateDropdownPosition(event) {
      this.dropdownTop = event.offsetY;
      this.dropdownLeft = event.offsetX;
    },
    onItemClick(functionName) {
      this.isDropdownOpen = false;
      this.$emit('on-click-function', functionName);
    }
  }
};
</script>

<style scoped src='./DropdownButton.css' />
