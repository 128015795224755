<template>
  <div
    class="IobListItem focus-inside"
    tabindex="0"
  >
    <div :class="['IobListItem-left', `IobListItem-left-${size}`]">
      <iob-list-item-attributes
        v-for="(item, index) in leftItems"
        :key="index"
        :field-type="item.fieldType"
        :value="item.value"
        :size="size"
      />
    </div>
    <div
      v-if="!hideRightSection"
      class="IobListItem-right"
      :style="titleRightStyle"
      @click.stop
    >
      <iob-list-item-attributes
        v-for="(item, index) in rightItems"
        :key="index"
        :field-type="item.fieldType"
        :value="item.value"
        :size="size"
      />
      <icon-loader
        v-if="hasDetails"
        id="detailsIcon"
        name="MoreHorizontal"
        color="#787391"
        v-bind="additionalProps"
        @icon-click="emits('onClickDetailsIcon')"
      />
    </div>
  </div>
</template>

<script setup>
import IobListItemAttributes from './IobListItemAttributes/IobListItemAttributes.vue';
import IconLoader from '../../IconLoader/IconLoader.vue';
import { defineProps, defineEmits, computed } from 'vue';

const emits = defineEmits([
  'onClickDetailsIcon'
]);

const props = defineProps({
  leftItems: {
    type: Array,
    default: () => [],
  },
  rightItems: {
    type: Array,
    default: () => [],
  },
  hasDetails: {
    type: Boolean,
    default: true
  },
  size: {
    type: String,
    default: 'small'
  },
  hideRightSection: {
    type: Boolean,
    default: false
  },
});

const titleRightStyle = computed(() => {
  return rightItemsWithValue.value.length > 0 ? 
    {
      display: 'grid',
      'grid-template-columns': 'repeat(' + rightItemsWithValue.value.length + ', minmax(40px, 1fr))',
    } : {}
});

const rightItemsWithValue = computed(() => {
  return props.rightItems.filter(item => item.fieldType === 'title');
});

const additionalProps = computed(() => {
  if (props.size === 'medium') {
    return {
      customDimension: 57.8
    }
  }
  return {
    size: 'large',
  };
});
</script>

<style lang="scss" src="./IobListItem.scss" scoped />
