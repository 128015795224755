/**
 * Check if the given string is a valid URL
 * @param url
 * @returns {boolean} true if the given string is not a valid URL
 */
export default (url) => {
  try {
    // eslint-disable-next-line no-new
    new URL(url);
    return false;
  } catch (_) {
    return true;
  }
};
