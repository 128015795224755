import { get } from 'SRC/api/index.js';
/**
 * Compares the given dates with the current week's dates.
 *
 * @param {Array} dates - An array of date objects to compare.
 * @param {Date} currentDate - The current date to calculate the week from.
 * @returns {boolean} - Returns true if the dates match the current week's dates, otherwise false.
 */

export const compareDates = (dates, currentDate) => {
  const datesValues = getNext7Days(currentDate);
  return datesValues.every((obj) =>  dates.some((date) => date['end-date'] === obj['end-date']));
};

/**
 * Gets the next 7 days based on the given date.
 *
 * @param {Date} currentDate - The current date to calculate the next 7 days from.
 * @returns {Array} - An array of date objects representing the next 7 days.
 *                    Each date object contains the type 'custom' and the 'end-date' in ISO format.
 */
export const getNext7Days = (currentDate) => {

  const startOfWeek = currentDate;
  const dates = [];

  for (let i = 0; i < 7; i++) {
    const endDate = new Date(startOfWeek);
    endDate.setDate(startOfWeek.getDate() + i);
    const date = {
      type: 'custom',
      'end-date': endDate.toISOString().split('T')[0]
    };
    dates.push(date);
  }
  return dates ;
};
export const fetchRelatedElements = async (datasetElementIds) => {
  if (!datasetElementIds || !datasetElementIds.length) {
    return [];
  }
  try {
    const query = datasetElementIds.map((id) => `ids=${id}`).join('&');
    const response = await get(`/dataset-elements?${query}&page=1&size=25`);
    if (response && response.data) {
      return response.data.slice();
    }
    return [];
  } catch (error) {
    console.error('Error in fetchRelations', error);
  }
};

/**
 * Filters the given dates based on the current date.
 *
 * @param {Array} dates - An array of date objects to filter.
 * @param {Date} currentDate - The current date to use for filtering.
 * @returns {Array} - An array of filtered date objects.
 */

export const getOtherFiltersDates = (dates, currentDate) => {
  const datesValues = getNext7Days(currentDate);
  const extraDates = dates.filter((obj) => !datesValues.some((date) => date['end-date'] === obj['end-date']));
  return extraDates;
};

const currentDate = new Date();
export const defaultQuickFilters = [
  { name: 'owner', label: 'assigned to me',
    icon: 'User', color: 'secondarySoft',
    type: 'filled', isFocused: false, size: 'medium', values: []},
  { name: 'due-date', label: 'Due next 7 days',
    icon: 'CalendarClock', color: 'secondarySoft',
    type: 'filled', isFocused: false, size: 'medium', values: getNext7Days(currentDate)}
];
