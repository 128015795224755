<template>
  <div
    :style="containerStyle"
    class="IobContextualMenu level-3"
    @pointerdown.stop
    @dblclick.stop
  >
    <div v-if="!locked">
      <div
        v-if="noEdit"
        class="IobContextualMenu-container"
      >
        <iob-contextual-menu-map
          v-for="(item, index) in SELECTION_AREA_CONTEXTUAL_MENU"
          :key="index"
          :component-type="item.componentType"
          :icon-name="item.iconName"
          :text="item.text"
          :has-separator="item.hasSeparator"
          v-bind="buildProps(item)"
          @item-click="$emit('item-click', { eventName: item.action })"
        />
      </div>
      <div
        v-else
        class="IobContextualMenu-container"
      >
        <iob-contextual-menu-map
          v-for="(item, index) in contextualMenuItems"
          :key="index"
          v-bind="buildProps(item)"
          :bg-color="bgColor"
          :is-free-size="isFreeSize"
          :element-size="elementSize"
          :element-type="elementType"
          @setViewType="(value) => $emit('setViewType', value)"
          @item-click="$emit('item-click', { eventName: item.action })"
          @dropdown-click="
            (value) =>
              $emit('dropdown-click', { eventName: item.action, item: value })"
        />
      </div>
    </div>
    <div
      v-else
      class="IobContextualMenu-container"
    >
      <iob-button
        color="secondary"
        type="ghost"
        size="medium"
        label="Unlock"
        show-left-icon
        left-icon="Unlock"
        @click="$emit('item-click', { eventName: 'onLockToggle' })"
      />
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import IobContextualMenuMap from './IobContextualMenuMap.vue';
import { getDimensionFromSize } from '../../../utils';
import { SELECTION_AREA_CONTEXTUAL_MENU } from '../../../constants';

const props = defineProps({
  isInEdition: {
    type: Boolean,
    default: false
  },
  elementType: {
    type: String,
    default: 'card'
  },
  containerStyle: {
    type: String,
    default: ''
  },
  contextualMenuItems: {
    type: Array,
    required: true
  },
  noEdit: {
    type: Boolean,
    default: false
  },
  selectedElementsCount: {
    type: String,
    default: ''
  },
  bgColor: {
    type: String,
    default: ''
  },
  layout: {
    type: String,
    default: ''
  },
  isColorsPopup: {
    type: Boolean,
    default: false
  },
  multipleElementsMenu: {
    type: Boolean,
    default: false
  },
  locked: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: ''
  },
  isFreeSize: {
    type: Boolean,
    default: false
  },
  sizeLayoutMap: {
    type: Array,
    default: () => []
  },
  zoomValue: {
    type: Number,
    default: 1
  }
});

const zoomDropdownTitle = computed(() => `${props.zoomValue}%`);

const sizeDropdownTitle = computed(() => {
  const layoutItems = props.sizeLayoutMap?.find((item) => item[props.layout]);
  return getDimensionFromSize(
    props.size.width,
    props.size.height,
    layoutItems && layoutItems[props.layout]
  ) || 'M';
});

const iconLayoutName = computed(() => {
  const listOfLayouts = props.contextualMenuItems?.find(
    (item) => item.dropdownTitle === 'layoutDropdownTitle'
  );
  return listOfLayouts?.list?.find((item) => item.value === props.layout)
    ?.iconName || '';
});

const computedMap = {
  zoomDropdownTitle: zoomDropdownTitle.value,
  sizeDropdownTitle: sizeDropdownTitle.value,
  iconLayoutName: iconLayoutName.value
};

const buildProps = (item) => {
  const items = {};
  for (const [propName, propValue] of Object.entries(item)) {
    if (computedMap[propValue] !== undefined) {
      items[propName] = computedMap[propValue];
    } else {
      items[propName] =
        props[propValue] !== undefined ? props[propValue] : propValue;
    }
  }
  return items;
};
</script>

<style lang="scss" scoped src="./IobContextualMenu.scss" />
<style
  lang="scss"
  src="iobeya-design-tokens/scss/app/iobeya-app-contextual-menu.scss"
/>
