<template>
  <div
    ref="image"
    class="Image"
    data-target="board-element"
  >
    <img
      v-if="contentUrl"
      data-target="board-element"
      :src="contentUrl"
      alt=""
      :height="height"
      :width="width"
      @drag.prevent
      @pointerdown.prevent
      @click.prevent
    >
    <div
      v-else
      class="Image-placeholder"
      :style="{height: `${height}px`, width: `${width}px`}"
    />
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import {defineProps, onMounted, computed} from 'vue';

const store = useStore();
const props = defineProps({
  attributes: {
    type: Object,
    required: true
  },
  width: {
    type: Number,
    required: true
  },
  height: {
    type: Number,
    required: true
  }
});

const assetId = computed(() => props.attributes.asset);
const contentUrl = computed(() => store.state.board.assets[assetId.value]?.contentUrl);

onMounted(() => {
  store.dispatch('board/getAsset', {assetId: assetId.value});
});

</script>

<style scoped>
.Image-placeholder {
  background-color: #dcdcdc;
  opacity: 0.5;
}
</style>
