<template>
  <div :class="containerClasses">
    <logo-type :class="logoTypeClass" />
    <div :class="logosClasses">
      <logo-font
        class="IobResourceCenter-logoFont"
        color="var(--logo-color-text)"
      />
      <resource-center
        class="IobResourceCenter-logoFont"
        color="var(--logo-color-text)"
      />
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import LogoFont from 'iobeya-vue-icons/vueComponents/logo-font.vue';
import LogoType from 'iobeya-vue-icons/vueComponents/logo-type.vue';
import ResourceCenter from 'iobeya-vue-icons/vueComponents/resource-center.vue';

const props = defineProps({
  size: { type: String, default: 'default' },
});

const gapClassMap = {
  default: 'IobResourceCenter-gap-default',
  xl: 'IobResourceCenter-gap-xl',
  '2xl': 'IobResourceCenter-gap-2xl',
  '3xl': 'IobResourceCenter-gap-3xl',
  '4xl': 'IobResourceCenter-gap-4xl',
};

const logoTypeSizesMap = {
  default: 'IobResourceCenter-logoType-default',
  xl: 'IobResourceCenter-logoType-xl',
  '2xl': 'IobResourceCenter-logoType-2xl',
  '3xl': 'IobResourceCenter-logoType-3xl',
  '4xl': 'IobResourceCenter-logoType-4xl',
};

const containerClasses = computed(() => {
  return `IobResourceCenter ${gapClassMap[props.size]}`;
});

const logosClasses = computed(() => {
  return `IobResourceCenter-logos IobResourceCenter-logos-${props.size}`;
});

const logoTypeClass = computed(() => {
  return logoTypeSizesMap[props.size];
});
</script>

<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-logo.scss" />
<style lang="scss" scoped src="./IobResourceCenter.scss" />
