<template>
  <pop-over
    class="UserListPopOver"
    :style="usersHiddenListHeight"
  >
    <scroll-bar :style="scrollContainerStyle">
      <iob-user
        v-for="(user, index) in usersArray"
        :key="index"
        :name="user.name"
        :color="user.color"
        :image="user.image"
        :text="getUserInitials(user.firstname, user.lastname)"
        text-color="#fff"
        :background-color="user.color"
        :style="'margin-bottom: 15px'"
      />
    </scroll-bar>
  </pop-over>
</template>

<script>
import PopOver from '../Atoms/popover/PopOver.vue';
import IobUser from '../IobUser/IobUser.vue';
import ScrollBar from '../ScrollBar/ScrollBar.vue';
import { MAX_HEIGHT_HIDDEN_AVATARS, MAX_ELEMENTS_IN_DROPDOWN_LIST } from '../../constants.js';
import { parseIfJson } from '../../utils';

export default {
  name: 'UserListPopOver',
  components: { PopOver, IobUser, ScrollBar },
  props: {
    users: {
      type: [String, Array],
      required: true
    }
  },
  computed: {
    usersHiddenListHeight() {
      if (this.usersArray.length < MAX_ELEMENTS_IN_DROPDOWN_LIST) {
        return 'height: auto;';
      }
      return `height: ${MAX_HEIGHT_HIDDEN_AVATARS}px`;

    },
    scrollContainerStyle() {
      if (this.usersArray && this.usersArray.length <= MAX_ELEMENTS_IN_DROPDOWN_LIST) {
        return 'overflow-y: hidden;';
      }
      return '';
    },
    usersArray() {
      return parseIfJson(this.users) || [];
    }
  },
  methods: {
    getUserInitials(firstname, lastname) {
      return `${(firstname || '').charAt(0).toUpperCase()}${(lastname || '').charAt(0).toUpperCase()}`;
    }
  }
};
</script>

<style scoped src="./UserListPopOver.css" />
