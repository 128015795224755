<template>
  <tree-view
    :tree-data="options"
    :placeholder="placeholder"
    :label-field="labelField"
    :status="status"
    :show-left-icon="showLeftIcon"
    :is-multiple-select="isMultipleSelect"
    :show-right-icon="showRightIcon"
    :is-editor="isEditor"
    :is-dropdown-opened="isDropdownOpened"
    :show-input="showInput"
    :dropdown-style="dropdownStyle"
    @select-item="handleDropdownItemClick"
    @toggle-dropdown="emit('toggleDropdown')"
  />
</template>

<script setup>
import {ref, defineProps, defineEmits, watch} from 'vue';
import TreeView from './TreeView/TreeView.vue';
import { UNASSIGNED } from '../../../constants';

const props = defineProps({
  options: {
    type: Array,
    required: true
  },
  status: {
    type: Object,
    default: () => ({})
  },
  labelField: {
    type: String,
    default: 'label'
  },
  placeholder: {
    type: String,
    default: UNASSIGNED
  },
  showRightIcon: {
    type: Boolean,
    default: false
  },
  showLeftIcon: {
    type: Boolean,
    default: true
  },
  showInput: {
    type: Boolean,
    default: true
  },
  isMultipleSelect: {
    type: Boolean,
    default: false
  },
  isEditor: {
    type: Boolean,
    default: false,
  },
  dropdownStyle: {
    type: Object,
    default: () => ({})
  }
});

const emit = defineEmits(['dropdownElementClick', 'toggleDropdown']);
const status = ref(props.status);

function handleDropdownItemClick(selectedItem) {
  emit('dropdownElementClick', selectedItem);

  const { id, attributes } = selectedItem;
  status.value = {
    id,
    [props.labelField]: attributes?.[props.labelField] || '',
    colors: attributes?.colors || {}
  };
}

watch(() => props.status, (newStatus) => {
  status.value = newStatus;
}, { deep: true });
</script>
