import {utcDate} from 'BOARD/components/IndicatorChart/IndicatorChartUtils';

export const TIME_SERIES_FREQUENCIES = {
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  SEMESTERLY: 'Semesterly',
  BIANNUAL: 'Biannual',
  YEARLY: 'Yearly'
};

export const POINT_FREQUENCIES = {
  WEEKLY: {
    EVERY_MONDAY: 'Every Monday',
    EVERY_TUESDAY: 'Every Tuesday',
    EVERY_WEDNESDAY: 'Every Wednesday',
    EVERY_THURSDAY: 'Every Thursday',
    EVERY_FRIDAY: 'Every Friday',
    EVERY_SATURDAY: 'Every Saturday',
    EVERY_SUNDAY: 'Every Sunday'
  },
  MONTHLY: {
    FIRST_DAY: 'First day of the month',
    MID_MONTH: '15th of the month',
    LAST_DAY: 'Last day of the month',
    FIRST_MONDAY: 'First Monday of the month',
    LAST_FRIDAY: 'Last Friday of the month'
  },
  QUARTERLY: {
    FIRST_DAY: 'First day of the quarter',
    LAST_DAY: 'Last day of the quarter',
    FIRST_MONDAY: 'First Monday of the quarter',
    LAST_FRIDAY: 'Last Friday of the quarter'
  },
  BIANNUAL: {
    FIRST_DAY: 'First day of the semester',
    LAST_DAY: 'Last day of the semester',
    FIRST_MONDAY: 'First Monday of the semester',
    LAST_FRIDAY: 'Last Friday of the semester'
  },
  YEARLY: {
    FIRST_DAY: 'First day of the year',
    LAST_DAY: 'Last day of the year',
    FIRST_MONDAY: 'First Monday of the year',
    LAST_FRIDAY: 'Last Friday of the year'
  }
};

/**
 * Parses a date string based on the specified frequency.
 *
 * @param {string} date - The date string to parse.
 * @param timeSeriesFrequency - The complete frequency string (e.g., "Monthly:First day of the month").
 * @returns {Object|null} - The parsed date as an object with 'startDate' and 'endDate' properties for certain frequencies,
 * or null if the frequency is not recognized.
 */
export const parseDateByFrequency = (date, timeSeriesFrequency) => {
  const {frequency, pointFrequency} = splitFrequency(timeSeriesFrequency);
  switch (standardizeFrequency(frequency)) {
  case standardizeFrequency(TIME_SERIES_FREQUENCIES.DAILY):
    // Parse daily dates in UTC (e.g., "19 Nov.2024")
    return parseDailyDate(date);
  case standardizeFrequency(TIME_SERIES_FREQUENCIES.WEEKLY):
    // Parse weekly dates in UTC (e.g., "W15 2024")
    return parseWeeklyDate(date, pointFrequency);
  case standardizeFrequency(TIME_SERIES_FREQUENCIES.MONTHLY):
    // Parse monthly dates in UTC (e.g., "Nov.2024")
    return parseMonthlyDate(date, pointFrequency);
  case standardizeFrequency(TIME_SERIES_FREQUENCIES.QUARTERLY):
    // Parse quarterly dates in UTC (e.g., "Q1 2024")
    return parseQuarterlyDate(date, pointFrequency);
  case standardizeFrequency(TIME_SERIES_FREQUENCIES.SEMESTERLY):
  case standardizeFrequency(TIME_SERIES_FREQUENCIES.BIANNUAL):
    // Parse biannual dates in UTC (e.g., "S1 2024")
    return parseBiannualDate(date, pointFrequency);
  case standardizeFrequency(TIME_SERIES_FREQUENCIES.YEARLY):
    // Parse yearly dates in UTC (e.g., "2024")
    return parseYearlyDate(date, pointFrequency);
  default:
    return { startDate: null, endDate: null};
  }
};

export const parseDailyDate = (date) => {
  // Match the input date string for the pattern "<day> <month>.<year>" (e.g., "19 Nov.2024")
  const match = /^([0-2]?\d|3[01]) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\.(\d{4})$/.exec(date);
  if (!match) {
    return { startDate: null, endDate: null };
  }
  const [, day, monthName, year] = match;
  const monthIndex = new Date(`${monthName} 1`).getMonth();
  const startDate = new Date(Date.UTC(Number(year), monthIndex, Number(day)));
  const endDate = new Date(startDate);
  return { startDate, endDate };
};

export const parseWeeklyDate = (date, pointFrequency = POINT_FREQUENCIES.WEEKLY.EVERY_MONDAY) => {
  // Match the input date string for the pattern "W<week> <year>" (e.g., "W15 2024")
  const match = /^W(\d{1,2}|5[0-3]) (\d{4})$/.exec(date);
  if (!match) {
    return { startDate: null, endDate: null };
  }

  const [, week, year] = match.map(Number);
  const firstDayOfYear = new Date(Date.UTC(year, 0, 1));
  const dayOfWeek = firstDayOfYear.getUTCDay(); // Get the day of the week (0 = Sunday)
  // Determine the offset to the first Monday of the year
  const offsetToMonday = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek;
  const weekStartDate = new Date(
    Date.UTC(year, 0, 1 + offsetToMonday + (week - 1) * 7)
  );

  const weekEndDate = new Date(weekStartDate);
  weekEndDate.setUTCDate(weekStartDate.getUTCDate() + 6);

  // If a specific frequency is provided, adjust the date accordingly
  if (standardizeFrequency(pointFrequency)) {
    // Map the pointFrequency string to a specific day of the week (0 = Monday, 6 = Sunday)
    const frequencyMapping = {
      [POINT_FREQUENCIES.WEEKLY.EVERY_MONDAY]: 0,
      [POINT_FREQUENCIES.WEEKLY.EVERY_TUESDAY]: 1,
      [POINT_FREQUENCIES.WEEKLY.EVERY_WEDNESDAY]: 2,
      [POINT_FREQUENCIES.WEEKLY.EVERY_THURSDAY]: 3,
      [POINT_FREQUENCIES.WEEKLY.EVERY_FRIDAY]: 4,
      [POINT_FREQUENCIES.WEEKLY.EVERY_SATURDAY]: 5,
      [POINT_FREQUENCIES.WEEKLY.EVERY_SUNDAY]: 6
    };

    // Retrieve the target day based on the mapping
    const targetDay = frequencyMapping[pointFrequency];
    if (targetDay === undefined) {
      throw new Error(`Invalid pointFrequency: ${pointFrequency}`);
    }

    // Adjust the start date to match the target day of the week
    const adjustedDate = new Date(weekStartDate);
    adjustedDate.setUTCDate(weekStartDate.getUTCDate() + targetDay);

    return { startDate: utcDate(adjustedDate), endDate: lastHoursOfDay(adjustedDate) };
  }

  // Return the full week range if no specific frequency is provided
  return { startDate: utcDate(weekStartDate), endDate: lastHoursOfDay(weekEndDate) };
};

export const parseMonthlyDate = (date, pointFrequency = POINT_FREQUENCIES.MONTHLY.FIRST_DAY) => {
  // Match the input date string for the pattern "<Month>.<Year>" (e.g., "Nov.2024")
  const match = /^([A-Za-z]{3})\.(\d{4})$/.exec(date);
  if (!match) {
    return { startDate: null, endDate: null };
  }

  const [, monthName, year] = match;
  const monthIndex = new Date(`${monthName} 1`).getMonth();
  const startDate = new Date(Date.UTC(Number(year), monthIndex, 1));
  const endDate = new Date(Date.UTC(Number(year), monthIndex + 1, 0));

  // Adjust the date based on the provided pointFrequency
  if (pointFrequency) {
    switch (standardizeFrequency(pointFrequency)) {
    case standardizeFrequency(POINT_FREQUENCIES.MONTHLY.FIRST_DAY):
      return { startDate: utcDate(startDate), endDate: lastHoursOfDay(startDate) };

    case standardizeFrequency(POINT_FREQUENCIES.MONTHLY.MID_MONTH): {
      const midMonthDate = new Date(Date.UTC(Number(year), monthIndex, 15));
      return {startDate: utcDate(midMonthDate), endDate: lastHoursOfDay(midMonthDate)};
    }

    case standardizeFrequency(POINT_FREQUENCIES.MONTHLY.LAST_DAY):
      return { startDate: utcDate(endDate), endDate: lastHoursOfDay(endDate) };

    case standardizeFrequency(POINT_FREQUENCIES.MONTHLY.FIRST_MONDAY): {
      // Find the first Monday of the month
      const firstMonday = new Date(startDate);
      while (firstMonday.getUTCDay() !== 1) {
        firstMonday.setUTCDate(firstMonday.getUTCDate() + 1);
      }
      return { startDate: utcDate(firstMonday), endDate: lastHoursOfDay(firstMonday) };
    }

    case standardizeFrequency(POINT_FREQUENCIES.MONTHLY.LAST_FRIDAY): {
      // Find the last Friday of the month
      const lastFriday = new Date(endDate);
      while (lastFriday.getUTCDay() !== 5) {
        lastFriday.setUTCDate(lastFriday.getUTCDate() - 1);
      }
      return { startDate: utcDate(lastFriday), endDate: lastHoursOfDay(lastFriday) };
    }

    default:
      throw new Error(`Invalid pointFrequency: ${pointFrequency}`);
    }
  }

  // Default: return the full month range
  return { startDate, endDate };
};

export const parseQuarterlyDate = (date, pointFrequency = POINT_FREQUENCIES.QUARTERLY.FIRST_DAY) => {
  // Match the input date string for the pattern "Q<quarter> <year>" (e.g., "Q1 2024")
  const match = /^Q([1-4]) (\d{4})$/.exec(date);
  if (!match) {
    return { startDate: null, endDate: null }; // Return null if the input format doesn't match
  }

  // Extract the quarter and year values as numbers
  const [, quarter, year] = match.map(Number);
  if (quarter < 1 || quarter > 4) {
    throw new Error(`Invalid quarter: ${quarter}`); // Validate quarter range
  }

  // Calculate the start and end dates of the quarter
  const quarterStartMonth = (quarter - 1) * 3;
  const quarterStartDate = new Date(Date.UTC(year, quarterStartMonth, 1));
  const quarterEndDate = new Date(Date.UTC(year, quarterStartMonth + 3, 0)); // Last day of the quarter

  // Adjust the date based on the provided pointFrequency
  switch (standardizeFrequency(pointFrequency)) {
  case standardizeFrequency(POINT_FREQUENCIES.QUARTERLY.FIRST_DAY):
    return { startDate: utcDate(quarterStartDate), endDate: lastHoursOfDay(quarterStartDate) };

  case standardizeFrequency(POINT_FREQUENCIES.QUARTERLY.LAST_DAY):
    return { startDate: utcDate(quarterEndDate), endDate: lastHoursOfDay(quarterEndDate) };

  case standardizeFrequency(POINT_FREQUENCIES.QUARTERLY.FIRST_MONDAY): {
    // Find the first Monday of the quarter
    const firstMonday = new Date(quarterStartDate);
    while (firstMonday.getUTCDay() !== 1) {
      firstMonday.setUTCDate(firstMonday.getUTCDate() + 1);
    }
    return { startDate: utcDate(firstMonday), endDate: lastHoursOfDay(firstMonday) };
  }

  case standardizeFrequency(POINT_FREQUENCIES.QUARTERLY.LAST_FRIDAY): {
    // Find the last Friday of the quarter
    const lastFriday = new Date(quarterEndDate);
    while (lastFriday.getUTCDay() !== 5) {
      lastFriday.setUTCDate(lastFriday.getUTCDate() - 1);
    }
    return { startDate: utcDate(lastFriday), endDate: lastHoursOfDay(lastFriday) };
  }

  default:
    throw new Error(`Invalid pointFrequency: ${pointFrequency}`); // Handle invalid frequencies
  }
};

export const parseBiannualDate = (date, pointFrequency = POINT_FREQUENCIES.BIANNUAL.FIRST_DAY) => {
  // Match the input date string for the pattern "S<semester> <year>" (e.g., "S1 2024")
  const match = /^S([1-2]) (\d{4})$/.exec(date);
  if (!match) {
    return { startDate: null, endDate: null }; // Return null if the input format doesn't match
  }

  // Extract the semester and year values as numbers
  const [, semester, year] = match.map(Number);
  if (semester < 1 || semester > 2) {
    throw new Error(`Invalid semester: ${semester}`); // Validate semester range
  }

  // Calculate the start and end dates of the semester
  const semesterStartMonth = semester === 1 ? 0 : 6; // First semester starts in January, second in July
  const semesterStartDate = new Date(Date.UTC(year, semesterStartMonth, 1)); // First day of the semester
  const semesterEndDate = new Date(Date.UTC(year, semesterStartMonth + 6, 0)); // Last day of the semester

  // Adjust the date based on the provided pointFrequency
  switch (standardizeFrequency(pointFrequency)) {
  case standardizeFrequency(POINT_FREQUENCIES.BIANNUAL.FIRST_DAY):
    return { startDate: utcDate(semesterStartDate), endDate: lastHoursOfDay(semesterStartDate) };

  case standardizeFrequency(POINT_FREQUENCIES.BIANNUAL.LAST_DAY):
    return { startDate: utcDate(semesterEndDate), endDate: lastHoursOfDay(semesterEndDate) };

  case standardizeFrequency(POINT_FREQUENCIES.BIANNUAL.FIRST_MONDAY): {
    // Find the first Monday of the semester
    const firstMonday = new Date(semesterStartDate);
    while (firstMonday.getUTCDay() !== 1) {
      firstMonday.setUTCDate(firstMonday.getUTCDate() + 1);
    }
    return { startDate: utcDate(firstMonday), endDate: lastHoursOfDay(firstMonday) };
  }

  case standardizeFrequency(POINT_FREQUENCIES.BIANNUAL.LAST_FRIDAY): {
    // Find the last Friday of the semester
    const lastFriday = new Date(semesterEndDate);
    while (lastFriday.getUTCDay() !== 5) {
      lastFriday.setUTCDate(lastFriday.getUTCDate() - 1);
    }
    return { startDate: utcDate(lastFriday), endDate: lastHoursOfDay(lastFriday) };
  }

  default:
    throw new Error(`Invalid pointFrequency: ${pointFrequency}`); // Handle invalid frequencies
  }
};

export const parseYearlyDate = (date, pointFrequency = POINT_FREQUENCIES.YEARLY.FIRST_DAY) => {
  // Match the input date string for the pattern "<year>" (e.g., "2024")
  const match = /(\d{4})/.exec(date);
  if (!match) {
    return null; // Return null if the input format doesn't match
  }

  // Extract the year value as a number
  const year = Number(match[1]);

  // Define the start and end dates of the year
  const yearStartDate = new Date(Date.UTC(year, 0, 1)); // January 1
  const yearEndDate = new Date(Date.UTC(year, 11, 31)); // December 31

  // Adjust the date based on the provided pointFrequency
  switch (standardizeFrequency(pointFrequency)) {
  case standardizeFrequency(POINT_FREQUENCIES.YEARLY.FIRST_DAY):
    return { startDate: utcDate(yearStartDate), endDate: lastHoursOfDay(yearStartDate) };

  case standardizeFrequency(POINT_FREQUENCIES.YEARLY.LAST_DAY):
    return { startDate: utcDate(yearEndDate), endDate: lastHoursOfDay(yearEndDate) };

  case standardizeFrequency(POINT_FREQUENCIES.YEARLY.FIRST_MONDAY): {
    // Find the first Monday of the year
    const firstMonday = new Date(yearStartDate);
    while (firstMonday.getUTCDay() !== 1) {
      firstMonday.setUTCDate(firstMonday.getUTCDate() + 1);
    }
    return { startDate: utcDate(firstMonday), endDate: lastHoursOfDay(firstMonday) };
  }

  case standardizeFrequency(POINT_FREQUENCIES.YEARLY.LAST_FRIDAY): {
    // Find the last Friday of the year
    const lastFriday = new Date(yearEndDate);
    while (lastFriday.getUTCDay() !== 5) {
      lastFriday.setUTCDate(lastFriday.getUTCDate() - 1);
    }
    return { startDate: utcDate(lastFriday), endDate: lastHoursOfDay(lastFriday) };
  }

  default:
    throw new Error(`Invalid pointFrequency: ${pointFrequency}`); // Handle invalid frequencies
  }
};

const lastHoursOfDay = (date) => utcDate(date, 23, 59, 59);

export const standardizeFrequency = (frequency) => frequency.trim().toLowerCase();

export const splitFrequency = (frequency) => {
  const match = /^([^:]+):([^:]+)$/.exec(frequency);
  if (!match) {
    return { frequency, pointFrequency: null };
  }
  return { frequency: match[1], pointFrequency: match[2] };
};
