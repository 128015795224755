<template>
  <div>
    <button
      :data-tooltip-target="`tooltip-default${color}`"
      class="ColoredCircle"
      :class="{
        'border-blue-700 border-2': isSelected,
        'ColoredCircle-border': !isSelected,
      }"
      type="button"
      :style="`background-color: ${color}; height: ${size}; width: ${size};`"
      @mouseover="showTooltip"
      @mouseleave="hideTooltip"
      @click="$emit('select-color', color)"
    />
    <div
      v-if="isTooltipShown && colorName && withTooltip"
      :id="`tooltip-default${color}`"
      role="tooltip"
      class="absolute z-10 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg
      shadow-sm tooltip dark:bg-gray-700"
      :style="'transform: translate(-30px, -62px);'"
    >
      {{ colorName }}
      <div
        class="tooltip-arrow"
        data-popper-arrow
      />
    </div>
  </div>
</template>

<script>
import ntc from 'color-namer';

export default {
  name: 'ColoredCircle',
  props: {
    color: {
      type: String,
      required: true
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: '30px'
    },
    withTooltip: {
      type: Boolean,
      default: true
    }
  },
  emits: ['select-color'],
  data: () => ({
    isTooltipShown: false
  }),
  computed: {
    colorName() {
      if (!this.color) {
        return '';
      }
      const cr = ntc(this.color, { pick: ['ntc'] });
      const min = cr.ntc.reduce((prev, curr) =>
        prev.distance < curr.distance ? prev : curr
      );
      return min.name;
    }
  },
  methods: {
    showTooltip() {
      this.isTooltipShown = true;
    },
    hideTooltip() {
      this.isTooltipShown = false;
    }
  }
};
</script>

<style scoped src="./ColoredCircle.css" />
