import { INTERACTION_MODES } from 'GLOBALS/constants';

const eventsHandlers = {
  [INTERACTION_MODES.SELECT]: {
    click: (board, { event }) => {
      const visualLink = event.target.ownerSVGElement;
      board.setIsVisualLinkBeingEditedPosition(visualLink);
      board.setVisualLinkBeingEditedType(visualLink.id);
      board.setIsVisualLinkContextualMenuOpen(true);
      board.closeEditor();
    },
    pointermove: (board) => {
      board.setCursor({ value: 'pointer', lock: false });
    },
    mouseleave: (board) => {
      board.setCursor({ value: 'default', lock: false });
    }
  }
};
export default eventsHandlers;
