/**
 * Formats a string from Camel or Pascal case to an attribute name: a string format of [A-Z]* [a-z]*.
 * Example: createdOn to Created on
 * @param {string} name - String to be formatted
 */
export const transformNameToFriendly = (name) => {
  return  name.replace(/"/g, '')
    .replace(/([A-Z])/g, ' $1')
    .trim()
    .toLowerCase()
    .split(' ')
    .map((word, index) => index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word)
    .join(' ')
};

/**
 * Formats a date to the following format: Month Day, Year .
 * Example: createdOn to Created on
 * @param {Date} name - Date to be formatted
 */
export const generateDate = (date)=> {
  const d = new Date(date);
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  return d.toLocaleDateString('en-US', options);
};

/**
 * This function verifies if attribute has a source of 'attributes', if so it returns the name which already has a friendlyName. 
 * Otherwise, it returns the attribute name with a friendlyName.
 * @param {String} attribute - Attribute to find
 * @param {Array} dataTypeAttributes - The array of attributes to search in
 */
export const findMainPanelAttribute = (attribute, dataTypeAttributes) => {
  if(attribute.source === 'attributes'){
    return dataTypeAttributes.find((item) => attribute.name === item.name);
  }
  else {
    return {... attribute, friendlyName: transformNameToFriendly(attribute.name)};
  }
};

/**
 * This function finds the driver attribute and returns the driver title and an enum.
 * Returns the existent driver or the default driver if it exists.
 * Returns the existent enum and an empty array if no driver is found.
 * @param {Array} dataTypeAttributes - Array of attributes to search in
 * @param {Object} datasetElement - The Object in which the attribute driver exists
 */
export const getProgressAttributes = (dataTypeAttributes, datasetElement) => {
  const defaultAttribute = dataTypeAttributes.find((attribute) => attribute.name === 'driver');
  return {
    title: datasetElement.attributes.driver || defaultAttribute?.defaultValue || '',
    enum: defaultAttribute?.enum || []
  }
};

export const showOnFns = (value, secondOperand) => {
  return {
    'string': {
      '>': () => !!(value && value.length > secondOperand.length),
      '<': () => !!(value && value.length < secondOperand.length),
      '=': () => !!(value && value === secondOperand),
      '!=': () => !!(value && value !== secondOperand),
      '>=': () => !!(value && value.length >= secondOperand.length),
      '<=': () => !!(value && value.length <= secondOperand.length),
    },
    'number': {
      '>': () => !!(value && value > secondOperand),
      '<': () => !!(value && value < secondOperand),
      '=': () => !!(value && value === secondOperand),
      '!=': () => !!(value && value !== secondOperand),
      '>=': () => !!(value && value >= secondOperand),
      '<=': () => !!(value && value <= secondOperand),
    },
  }
}
