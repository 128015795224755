<template>
  <div :class="classList">
    <div
      class="IobTitleElement-title"
      :style="color ? `color: ${color}` : ''"
    >
      {{ title }}
    </div>
    <iob-link
      v-if="hasLink"
      :text="linkText"
      :url="linkUrl"
      :link-icon="linkIcon"
      @click="emit('click')"
    />
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';
import IobLink from '../IobLink/IobLink.vue';

const props = defineProps({
  state: {
    type: String,
    default: 'default',
  },
  title: {
    type: String,
    default: ''
  },
  hasPadding: {
    type: Boolean,
    default: false
  },
  isCentred: {
    type: Boolean,
    default: false
  },
  linkText: {
    type: String,
    default: ''
  },
  linkIcon: {
    type: String,
    default: ''
  },
  linkUrl: {
    type: String,
    default: ''
  },
  color: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['click']);

const hasLink = computed(() => props.linkText || props.linkIcon);
const classList = computed(() => [
  `IobTitleElement-title--${props.state}`,
  'IobTitleElement-title',
  {
    'IobTitleElement-title--padding': props.hasPadding,
    'IobTitleElement-title--center': props.isCentred,
    'IobTitleElement-title--with-link': hasLink
  }
]);

</script>

<style lang="scss" src="./IobTitleElement.scss" scoped></style>
