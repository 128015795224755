<template>
  <input
    ref="fileInput"
    type="file"
    accept=".jpeg, .jpg, .png, .bmp, .gif, .svg"
    multiple
    style="display: none;"
    @change="onFileChange"
    @cancel="handleCancel"
  >
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { getImageSize, random } from 'SRC/board/utils/utils';

const fileInput = ref(null);
const store = useStore();

const viewTypeId = computed(() => store.state.dock.viewTypeId);
const boardId = computed(() => store.state.board.id);
const getElementInitialPosition = (size) => store.getters['board/getElementInitialPosition'](size);

onMounted(() => {
  fileInput.value.click();
});

async function onFileChange(ev) {
  const files = ev.target.files;
  const dimensions = await Promise.all(Object.values(files)?.map(getImageSize));
  await Promise.all(Object.values(files)?.map((file, index) =>
    store.dispatch('board/createBoardImage', {
      requestBody: {
        position: getRandomOffsetWithinMargin(getElementInitialPosition(dimensions[index])),
        viewTypeId: viewTypeId.value,
        boardId: boardId.value,
        width: dimensions[index].width,
        height: dimensions[index].height
      },
      file
    })
  ));

  store.commit('dock/setCreationComponent', null);
}

function handleCancel() {
  store.commit('dock/setCreationComponent', null);
  store.dispatch('board/closeComponent', 'ImageCreation');
}

function getRandomOffsetWithinMargin(item, margin = 50) {
  const rx = Math.floor(random() * Math.floor(margin));
  const ry = Math.floor(random() * Math.floor(margin));
  return {x: item.x - (margin / 2) + rx, y: item.y - (margin / 2) + ry};
}
</script>
