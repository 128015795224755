<template>
  <div class="IobLoadingBar">
    <div
      :style="`width:${progress}%`"
      :class="loaderClasses"
    />
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps({
  color: {
    type: String,
    default: 'primary',
  },
  progress: { type: Number, default: 0 },
});

const colorClassMap = {
  primary: 'IobLoadingBar-loader-primary',
  secondary: 'IobLoadingBar-loader-secondary',
  alert: 'IobLoadingBar-loader-alert',
  black: 'IobLoadingBar-loader-black',
  white: 'IobLoadingBar-loader-white',
};

const loaderClasses = computed(() => {
  return `IobLoadingBar-loader ${colorClassMap[props.color]}`;
});
</script>

<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-loaders.scss" />
<style lang="scss" src="./IobLoadingBar.scss" scoped />
