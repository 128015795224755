<template>
  <div
    class="IobAlert"
    :class="`IobAlert--${type}`"
  >
    <div class="IobAlert-content">
      <div class="IobAlert-content-left">
        <icon-loader
          v-if="showIcon"
          :name="icon"
          size="default"
          :color="title ? `var(--alert-color-text-title-${type})` :
            `var(--alert-color-text-message-${type})`"
        />
        <div
          v-if="title"
          class="IobAlert-content-title"
        >
          {{ title }}
        </div>
        <div
          v-else
          class="IobAlert-content-text"
        >
          {{ message }}
        </div>
      </div>
      <iob-action-button
        v-if="showClose"
        size="small"
        :color="type === 'error' ? 'alert' : type"
        type="ghost"
        @click="emit('close')"
      />
    </div>
    <div
      v-if="title"
      class="IobAlert-content-text"
    >
      {{ message }}
    </div>
  </div>
</template>

<script setup>
import {computed, defineProps} from 'vue';
import IconLoader from '../../IconLoader/IconLoader.vue';
import IobActionButton from '../../Molecules/IobActionButton/IobActionButton.vue';

const props = defineProps({
  type: {
    type: String,
    default: 'success',
    validator: (value) => ['error', 'warning', 'success', 'info'].includes(value)
  },
  title: {
    type: String,
    default: '',
  },
  message: {
    type: String,
    required: true,
  },
  showClose: {
    type: Boolean,
    default: false,
  },
  showIcon: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['close']);

const icon = computed(() => {
  const iconMap = {
    'success': 'CheckCircle-2',
    'warning': 'AlertTriangle',
    'error': 'Info',
    'info': 'Info'
  };
  return iconMap[props.type] || 'Info';
});
</script>

<style lang="scss" scoped src="./IobAlert.scss" />
<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-alert.scss" />
