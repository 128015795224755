<template>
  <div class="AvatarWithPopOver">
    <iob-avatar
      class="AvatarWithPopOver-avatar"
      :avatar-url="image"
      :color-left="color"
      :alt-text="text"
    />
    <user-pop-over
      class="AvatarWithPopOver-popover"
      :image="image"
      :color="color"
      :name="name"
      :text="text"
      :text-color="textColor"
      :background-color="backgroundColor"
    />
  </div>
</template>

<script>
import IobAvatar from '../Molecules/IobAvatar/IobAvatar.vue';
import UserPopOver from '../UserPopOver/UserPopOver.vue';

export default {
  name: 'AvatarWithPopOver',
  components: { IobAvatar, UserPopOver },
  props: {
    name: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    text: {
      default: '',
      type: String
    },
    textColor: {
      default: '',
      type: String
    },
    backgroundColor: {
      default: '',
      type: String
    }
  }
};
</script>

<style scoped src="./AvatarWithPopOver.css" />
