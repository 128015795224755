<template>
  <div class="IobSubmenu">
    <iob-submenu-category
      v-if="categoryName"
      :icon-name="categoryIcon"
      @category-select="handleCategorySelect"
    >
      {{ categoryName }}
    </iob-submenu-category>
    <ul class="IobSubmenu-list">
      <slot />
    </ul>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import IobSubmenuCategory from './Category/IobSubmenuCategory.vue';

const props = defineProps({
  categoryName: {
    type: String,
    default: ''
  },
  categoryIcon: {
    type: String,
    default: ''
  }
})

const emits = defineEmits(['category-select']);

const handleCategorySelect = () => {
  emits('category-select', {categoryName: props.categoryName});
}
</script>

<style lang="scss" src="./IobSubmenu.scss" scoped />
