const mutations = {
  setDependencies(state, dependencies) {
    state.dependencies = dependencies;
  },
  addDependency(state, {dependencyId, dependency}) {
    state.dependencies[dependencyId] = dependency;
  },
  updateHierarchicalRelation(state, {
    hierarchicalRelationId,
    updates
  }) {
    Object.assign(state.hierarchicalRelations[hierarchicalRelationId], updates);
  },
  addHierarchicalRelation(state, {hierarchicalRelationId, hierarchicalRelation}) {
    state.hierarchicalRelations[hierarchicalRelationId] = hierarchicalRelation;
  },
  setHierarchicalRelations(state, hierarchicalRelations) {
    state.hierarchicalRelations = hierarchicalRelations;
  },
  setLines(state, line) {
    state.lines = [...state.lines, line];
  },
  updateLine(state, line) {
    state.lines = state.lines.map((element) => element.id === line.id ? line : element);
  },
  removeLine(state, lineId) {
    state.lines = state.lines.filter((line) => line.id !== lineId);
  },
  setIsVisualLinkContextualMenuOpen(state, value) {
    state.isVisualLinkContextualMenuOpen = value;
  },
  setIsVisualLinkBeingEdited(state, value) {
    state.visualLinkBeingEdited = value;
  },
  setSelectedVisualLinkAction(state, value) {
    state.selectedVisualLinkAction = value;
  },
  setVisualLinks(state, visualLinks) {
    state.visualLinks = visualLinks;
  },
  addVisualLink(state, {visualLinkId, visualLink}) {
    state.visualLinks[visualLinkId] = visualLink;
  },
  setCreatedDependencies(state, createdDependencies) {
    state.createdDependencies = createdDependencies;
  },
  setRelationType(state, relationType) {
    state.relationType = relationType;
  },
  setSelectedVisualLinkType(state, value) {
    state.selectedVisualLinkType = value;
  },
  updateVisualLink(state, {
    visualLinkId,
    updates
  }) {
    Object.assign(state.visualLinks[visualLinkId], updates);
  },
  updateDependency(state, {
    dependencyId,
    updates
  }) {
    Object.assign(state.dependencies[dependencyId], updates);
  },
  setSelectedVisualLink(state, visualLink) {
    state.selectedVisualLink = visualLink;
  },
  setIsSelectedVisualLink(state, value) {
    state.isSelectedVisualLink = value;
  },
  setSearchResults(state, searchResults) {
    state.searchResults = searchResults;
  }
};
export default mutations;
