<template>
  <div
    class="Card"
    @mouseleave="setIsHovered(false)"
    @mouseover="setIsHovered(true)"
  >
    <div
      v-if="isHighlighted"
      data-target="board-element"
      class="Card-highlighted"
    />
    <card-compartment
      id="upperPart"
      :has-grid="false"
      :attributes="topAttributes"
      :fields="upperFields"
      :friendly-id-text-style="friendlyIdTextStyle"
      :bg-color="bgColor"
      :has-color="hasColor"
      :grid-size="upperGridSize"
      :style="upperPartStyle"
      :text-style="upperPartTextStyle"
      :size-attributes="CARD_LOWER_PART_SIZE_TO_STYLE_MAP[size]"
      :contrasted-color="contrastedColor"
      :upper-part-height="upperPartHeight"
      part="upper"
    />
    <card-compartment
      v-if="lowerFields && lowerFields.length > 0 && size !== 'S'"
      id="lowerPart"
      :attributes="bottomAttributes"
      :has-grid="false"
      :fields="lowerFields"
      :height="200"
      :width="width"
      :bg-color="bgColor"
      :has-color="hasColor"
      :grid-size="lowerGridSize"
      :text-style="lowerPartTextStyle"
      :field-style="lowerPartFieldStyle"
      :lower-part-fields-size="lowerPartFieldsSize"
      :lower-part-icon-size="lowerPartIconSize"
      :size-attributes="CARD_LOWER_PART_SIZE_TO_STYLE_MAP[size]"
      part="lower"
      :style="lowerPartStyle"
    />
    <div
      v-for="index in additionalDots"
      :id="`${elementId}-p-${index}insider`"
      :key="index"
      :style="getDotPlacement(index, additionalDots ? additionalDots.length : 0)"
    />
  </div>
</template>

<script>
import CardCompartment from 'BOARD/components/CardCompartment/CardCompartment.vue';
import { calculateScaledValue } from 'BOARD/utils/utils';
import {
  CARD_SIZE_MAP,
  CARD_LOWER_PART_SIZE_TO_STYLE_MAP
} from 'GLOBALS/constants';

export default {
  name: 'IOBCard',
  components: {
    CardCompartment
  },
  props: {
    fields: {
      type: Array,
      default: () => ([{ originRef: '/vms/title' }])
    },
    canBeHighlighted: {
      type: Boolean,
      default: true
    },
    bgColor: {
      type: String,
      default: ''
    },
    hasColor: {
      type: Object,
      required: true
    },
    additionalDots: {
      type: Array,
      required: true
    },
    elementId: {
      type: String,
      required: true
    },
    attributes: {
      type: Object,
      default: () => { }
    },
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: true
    },
    contrastedColor: {
      type: String,
      required: true
    }
  },
  data: () => ({
    isHovered: false,
    CARD_LOWER_PART_SIZE_TO_STYLE_MAP
  }),
  computed: {
    upperPartHeight() {
      return this.height - parseInt(CARD_LOWER_PART_SIZE_TO_STYLE_MAP[this.size].lowerPartHeight);
    },
    size() {
      if (this.height > 650) {
        return 'XXXL';
      }
      if (this.height > 431) {
        return 'XXL';
      }
      const nearestBiggerSize = Math.min(...Object
        .values(CARD_SIZE_MAP)
        .map((el) => el.height)
        .filter((height) => this.height < height));
      if (nearestBiggerSize === Infinity) {
        return 'XXXL';
      }
      const size = Object.entries(CARD_SIZE_MAP).find((el) => el[1].height === nearestBiggerSize)[0];
      return size;
    },
    sizeInPx() {
      return CARD_LOWER_PART_SIZE_TO_STYLE_MAP[this.size].lowerPartHeight;
    },
    upperPartStyle() {
      return `height: calc(${this.height}px - ${this.sizeInPx}); padding-bottom: 8px !important; width: ${this.width}px;
      padding: ${calculateScaledValue(this.width, this.height, 8)}px ${calculateScaledValue(this.width, this.height, 16)}px
    `;
    },
    lowerPartStyle() {
      return `background-color: #FFF !important; height: ${this.sizeInPx} ; width: ${this.width}px;
      padding: ${calculateScaledValue(this.width, this.height, 4)}px ${calculateScaledValue(this.width, this.height, 8)}px
    `;
    },
    lowerFields() {
      return this.fields.find((f) => f && f.position === 'bottom')?.fields;
    },
    upperFields() {
      return this.fields.filter((f) => f.position !== 'bottom');
    },
    isHighlighted() {
      return this.isHovered && this.canBeHighlighted;
    }
  },
  methods: {
    setIsHovered(value) {
      this.isHovered = value;
    },
    getDotPlacement(index, additionalDots) {
      const offset = additionalDots < 10 ? 10 : 100 / additionalDots;
      let top = 0;
      const i = Math.floor(index / 2) + 1;
      if (index % 2 === 0) {
        top = (50 + (offset / 2)) - Number(i) * offset;
      } else {
        top = (50 - (offset / 2)) + Number(i) * offset;
      }
      return `position: absolute; left: 0%; top:${top}%; width:100%`;
    }
  }
};
</script>

<style scoped src='./FreeSizeCard.css' />
