<template>
  <div
    :class="{
      'DropdownMenu': true,
      'DropdownMenu--fullWidth': fullWidth
    }"
    :style="dropdownStyle"
  >
    <button
      v-for="(item, index) in itemsArray"
      :key="index"
      class="DropdownMenu-item"
      :disabled="item.disabled"
      @click.stop="handleItemClick(item)"
    >
      <span
        v-if="item.title"
        class="DropdownMenu-itemTitle"
      >
        {{ item.title }}
      </span>
      <span class="DropdownMenu-itemLabel">
        {{ item.label }}
      </span>
    </button>
  </div>
</template>

<script>
import { parseIfJson } from '../../utils';

export default {
  name: 'DropdownMenu',
  props: {
    top: {
      type: Number,
      default: 0
    },
    left: {
      type: Number,
      default: 0
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    items: {
      type: [String, Array],
      required: true
    }
  },
  emits: ['click-outside', 'click-item'],
  computed: {
    dropdownStyle() {
      return {
        top: this.top ? `${this.top}px` : 'auto',
        left: this.left ? `${this.left}px` : 'auto'
      };
    },
    itemsArray() {
      return parseIfJson(this.items) || [];
    }
  },
  methods: {
    handleItemClick(item) {
      if (item.onClick) {
        this.$emit('click-item', item.onClick);
      }
    }
  }
};
</script>

<style scoped src='./DropdownMenu.css' />
