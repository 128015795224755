import {SimpleBoardContext, SimpleDatasetElementContext} from '@iobeya/vms-formula-evaluator/evaluator';
import store from 'SRC/store';
class BoardContext extends SimpleBoardContext {
  boardElementsNew = [];
  constructor(id, superContext) {
    super({id}, superContext);
  }

  async ownBoardElements() {
    const elements = store.state.board.elements;
    const datasetElementsIds = Object.values(elements)
      .map(({datasetElement}) => datasetElement.id);
    return Object.entries(store.state.board.datasetElements)
      .filter(([id]) => datasetElementsIds.includes(id))
      .map(([, elem]) => new SimpleDatasetElementContext(elem, this));
  }
}
export default BoardContext;
