<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M10 1.66667C7.78986 1.66667 5.67025 2.54464 4.10744 4.10744C2.54464 5.67025 1.66667 7.78986 1.66667 10H0C4.96705e-08 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10H18.3333C18.3333 7.78986 17.4554 5.67025 15.8926 4.10744C14.3298 2.54464 12.2101 1.66667 10 1.66667Z"
        :fill="`url(#${gradientTopId})`"
      />
      <path
        d="M0.833333 9.16666C1.29357 9.16666 1.66667 9.53975 1.66667 9.99999C1.66667 12.2101 2.54464 14.3297 4.10744 15.8925C5.67025 17.4553 7.78986 18.3333 10 18.3333C12.2101 18.3333 14.3298 17.4553 15.8926 15.8925C17.4554 14.3297 18.3333 12.2101 18.3333 9.99999H20C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 4.96705e-08 12.6522 0 9.99999C0 9.53975 0.373096 9.16666 0.833333 9.16666Z"
        :fill="`url(#${gradientBottomId})`"
      />
    </g>
    <defs>
      <linearGradient
        :id="gradientTopId"
        x1="0"
        x2="18"
        y1="10"
        y2="10"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0%"
          stop-opacity="0"
          :stop-color="color"
        />
        <stop
          offset="100%"
          stop-opacity="0.5"
          :stop-color="color"
        />
      </linearGradient>
      <linearGradient
        :id="gradientBottomId"
        x1="0"
        x2="18"
        y1="10"
        y2="10"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0%"
          stop-opacity="1"
          :stop-color="color || 'black'"
        />
        <stop
          offset="100%"
          stop-opacity="0.5"
          :stop-color="color || 'black'"
        />
      </linearGradient>
    </defs>
    <animateTransform
      from="0 0 0"
      to="360 0 0"
      attributeName="transform"
      type="rotate"
      repeatCount="indefinite"
      dur="1500ms"
    />
  </svg>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { generateUniqueId } from '../../../utils';

defineProps({
  size: {
    type: String,
    default: '20',
  },
  color: {
    type: String,
    default: 'var(--loader-circle-color-primary)',
  },
});

const gradientTopId = ref('');
const gradientBottomId = ref('');

onMounted(() => {
  gradientTopId.value = generateUniqueId();
  gradientBottomId.value = generateUniqueId();
});
</script>

<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-loaders.scss" />
