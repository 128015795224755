<!-- eslint-disable max-len -->
<template>
  <simple-popup
    content="Please verify that the URL is valid, or directly access the following pages:"
    title="Sorry, this page cannot be found"
    button-label="Default board page"
    @button-click="navigateToDefaultBoard"
  />
</template>

<script>
export default {
  name: 'PageNotFound',
  methods: {
    navigateToDefaultBoard() {
      this.$router.push('/board/00000000-0000-0000-0000-000000000000');
    }
  }
};
</script>
