import mutations from './mutations';
import actions from './actions';
import getInitialState from './initialState';

const ToolsConfigModule = {
  namespaced: true,
  state: getInitialState(),
  mutations,
  actions
};

export default ToolsConfigModule;
