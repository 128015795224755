<template>
  <div :class="containerClasses">
    <div
      :style="loaderStyles"
      :class="loaderClasses"
    />
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps({
  color: {
    type: String,
    default: 'primary',
  },
  progress: { type: Number, default: 0 },
  animationDuration: { type: Number, default: 1000 }
});

const colorClassMap = {
  primary: 'IobProgressBar-loader-primary',
  secondary: 'IobProgressBar-loader-secondary',
  alert: 'IobProgressBar-loader-alert',
  black: 'IobProgressBar-loader-black',
  white: 'IobProgressBar-loader-white',
};
const loaderClasses = computed(() => {
  return `IobProgressBar-loader ${colorClassMap[props.color]}`;
});

const containerClasses = computed(() => {
  return props.color === 'white' ? 'IobProgressBar-white' : 'IobProgressBar';
});

const loaderStyles = computed(() => {
  return {
    width: `${props.progress}%`,
    transition: `width ${props.animationDuration}ms linear`,
  };
});
</script>

<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-loaders.scss" />
<style lang="scss" scoped src="./IobProgressBar.scss" />
