<template>
  <dialog-box
    class="z-50"
    :dialog-box-specific-style="dialogBoxStyle"
  >
    <div class="relativemax-w-2xl max-h-full">
      <div class="relative bg-white rounded-lg">
        <!-- Modal header -->
        <div class="flex items-center justify-between mb-5">
          <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
            {{ title }}
          </h3>
          <icon-loader
            v-if="hasCloseIcon"
            name="X"
            :stroke="iconStroke"
            :size="small"
            style="cursor: pointer"
            @icon-click="(event) => $emit('close-icon-click', event)"
          />
        </div>
        <!-- Modal body -->
        <div class="max-h-60 relative bg-white rounded-lg dark:bg-gray-700 overflow-x-hidden">
          <div>
            <p
              class="text-base leading-relaxed text-gray-500 dark:text-gray-400 space-y-6"
            >
              <span
                class="text-lg"
              >{{ content }}
              </span>
            </p>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="flex float-right items-center pt-4 space-x-2 border-gray-200 rounded-b dark:border-gray-600">
          <custom-button
            :label="buttonLabel"
            outlined
            button-style="background-color: rgba(0, 77, 219, 1); border: none"
            @click="(event) => $emit('button-click', event)"
          />
        </div>
      </div>
    </div>
  </dialog-box>
</template>

<script>
import CustomButton from '../CustomButton/CustomButton.vue';
import DialogBox from '../DialogBox/DialogBox.vue';
import IconLoader from '../IconLoader/IconLoader.vue';

export default {
  name: 'SimplePopUp',
  components: {
    CustomButton,
    DialogBox,
    IconLoader
  },
  props: {
    hasCloseIcon: {
      type: Boolean,
      default: true
    },
    buttonLabel: {
      type: String,
      default: 'OK'
    },
    content: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    iconStroke: {
      type: String,
      default: 'rgba(107, 114, 128, 1)'
    }
  },
  emits: ['button-click'],
  data() {
    return {
      dialogBoxStyle: {
        position: 'fixed',
        left: '35%',
        top: '35%',
        width: '420px'
      }
    };
  }
};
</script>

<style scoped src="./SimplePopup.css" />
