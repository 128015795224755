<template>
  <input
    ref="fileInput"
    type="file"
    accept=".jpeg, .jpg, .png, .bmp, .gif, .svg"
    style="display: none;"
    @change="onFileChange"
    @cancel="closeEditor"
  >
</template>

<script setup>
import {computed, defineEmits, onMounted, ref, defineProps} from 'vue';
import { useStore } from 'vuex';
import { getImageSize } from 'SRC/board/utils/utils';

const fileInput = ref(null);
const store = useStore();

const datasetElementId = computed(() => store.state.editor.datasetElementId);
const datasetElement = computed(() => store.state.board.datasetElements[datasetElementId.value]);
onMounted(() => {
  fileInput.value.click();
});

const props = defineProps({
  elementId: {
    type: String,
    required: true
  }
});

async function onFileChange(ev) {
  const file = ev.target.files[0];
  const dimension = await getImageSize(file);
  await store.dispatch('board/updateBoardImage', {
    elementId: props.elementId,
    size: {
      width: dimension.width,
      height: dimension.height
    },
    datasetElementId: datasetElementId.value,
    datasetElement,
    file
  });
  closeEditor();
}

const emit = defineEmits(['close']);
const closeEditor = () => emit('close');
</script>
