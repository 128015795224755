<template>
  <th
    class="IobTableHeaderCell"
    :class="[stateClass]"
    :tabindex="tabindex"
    @click="handleCellClick"
  >
    <div class="IobTableHeaderCell-container">
      <div
        v-if="isChecked" 
        class="IobTableHeaderCell-container-checkbox"
      >
        <iob-checkbox
          id="checkAll"
          :checked="checkAll"
          @change-checkbox="(item) => emits('toggleCheckAll', item)"
        />
      </div>
      <span
        class="IobTableHeaderCell-columnLabel"
        :class="stateClass"
      >{{ label }}</span>
      <IconLoader
        v-if="sortable"
        color="black"
        :name="sortIcon"
        size="small"
        :disabled="disabled"
        class="IobTableHeaderCell-sortIcon"
        :class="stateClass"
      />
    </div>
  </th>
</template>

<script setup>
import IobCheckbox from '../../../Atoms/IobCheckbox/IobCheckbox.vue';
import IconLoader from '../../../IconLoader/IconLoader.vue';
import { computed, defineEmits, defineProps } from 'vue';

const emits = defineEmits(['clickCell', 'toggleCheckAll']);
const handleCellClick = () => {
  emits('clickCell');
};
const props = defineProps({
  label: { type: String, default: '' },
  sortable: { type: Boolean, default: true },
  disabled: { type: Boolean, default: false },
  selected: { type: Boolean, default: false },
  isSorted: { type: Boolean, default: false },
  isChecked: { type: Boolean, default: false },
  checkAll: { type: Boolean, default: false },
  sortDirection: { String, default: 'asc', validator: (value) => ['asc', 'desc'].includes(value)}
});

const stateClass = computed(() => {
  return {
    'IobTableHeaderCell--selected' : props.selected || props.isSorted,
    'IobTableHeaderCell--disabled': props.disabled || !props.label,
    'focus-inside': !props.disabled
  }
});

const sortIcon = computed(() => {
  if (props.isSorted) {
    return props.sortDirection === 'asc' ? 'ArrowUp' : 'ArrowDown';
  } else {
    return 'ChvevronsUpDown';
  }
});

const tabindex = computed(() => {
  return (!props.sortable || !props.label || props.disabled) ? -1 : 0;
});
</script>

<style lang="scss" scoped src="./IobTableHeaderCell.scss" />
