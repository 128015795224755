export const formatChildren = (boardId,
  viewTypes,
  elementsToInstanciate,
  parent,
  boardSize,
  elementSize
) => {
  const requests = elementsToInstanciate.reduce((acc, child, index) => {
    const viewTypeId = Object.entries(viewTypes).find(([, { dataTypeId }]) => child.typeId === dataTypeId)[0];
    const previousElement = index === 0 ? {...parent, body: { position: parent.position}} : acc[index - 1];
    const isFirstInTheList = index === 0;
    const position = calculateChildPosition(previousElement, boardSize, isFirstInTheList, elementSize);
    const item = {
      elementId: child.id,
      body: {
        boardId,
        viewTypeId,
        position
      }
    };
    return [...acc, item];
  }, []);
  return requests;
};

export const calculateChildPosition = (previousElement, boardSize, isFirstInTheList, elementSize) => {
  const offset = isFirstInTheList ? {x: Number(elementSize.width) + 40, y: 0} : {x: 10, y: 10};
  const didReachObstacle =
    (previousElement.body.position.x + offset.x + Number(elementSize.width)) > boardSize.width
    || (previousElement.body.position.y + offset.y + Number(elementSize.height)) > boardSize.height;
  return didReachObstacle ? {
    x: previousElement.body.position.x,
    y: previousElement.body.position.y
  } : {
    x: previousElement.body.position.x + offset.x,
    y: previousElement.body.position.y + offset.y
  };
};
