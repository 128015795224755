import {
  BOARDGET_LIST_ITEM_HEIGHT,
  BOARDGET_LIST_ADDITIONAL_HEIGHT
} from 'SRC/globals/constants';
export const targetMap = {
  'knob-tl': (translation, position, height, width, onlyHomotheticResize) => (shiftkey) => {
    let newElementX = Math.round(translation.x + position.x);
    let newElementY = Math.round(translation.y + position.y);
    let newWidth = Math.round(width - translation.x);
    let newHeight = Math.round(height - translation.y);

    const isOnlyHomotheticResize = onlyHomotheticResize || shiftkey;
    let ratio;
    if (isOnlyHomotheticResize) {
      if (translation.x < translation.y) {
        ratio = newWidth / width;
        newHeight = Math.trunc(height * ratio);
        newElementY = Math.trunc(position.y - (ratio - 1) * height);
      } else {
        ratio = newHeight / height;
        newWidth = Math.trunc(width * ratio);
        newElementX = Math.trunc(position.x - (ratio - 1) * width);
      }
    }

    return { newElementX, newElementY, newWidth, newHeight, ratio };
  },
  'knob-tr': (translation, position, height, width, onlyHomotheticResize) => (shiftkey) => {
    const newElementX = position.x;
    let newElementY = Math.round(translation.y + position.y);
    let newWidth = Math.round(width + translation.x);
    let newHeight = Math.round(height - translation.y);

    const isOnlyHomotheticResize = onlyHomotheticResize || (!onlyHomotheticResize && shiftkey);
    let ratio;
    if (isOnlyHomotheticResize) {
      if (translation.x > translation.y) {
        ratio = newWidth / width;
        newHeight = Math.trunc(height * ratio);
        newElementY = Math.trunc(position.y - (ratio - 1) * height);
      } else {
        ratio = newHeight / height;
        newWidth = Math.trunc(width * ratio);
      }
    }
    return { newElementX, newElementY, newWidth, newHeight, ratio };
  },
  'knob-bl': (translation, position, height, width, onlyHomotheticResize) => (shiftkey) => {
    let newElementX = Math.round(translation.x + position.x);
    const newElementY = position.y;
    let newWidth = Math.round(width - translation.x);
    let newHeight = Math.round(height + translation.y);

    const isOnlyHomotheticResize = onlyHomotheticResize || (!onlyHomotheticResize && shiftkey);
    let ratio;
    if (isOnlyHomotheticResize) {
      if (translation.x < translation.y) {
        ratio = newWidth / width;
        newHeight = Math.trunc(height * ratio);
      } else {
        ratio = newHeight / height;
        newWidth = Math.trunc(width * ratio);
        newElementX = Math.trunc(position.x - (ratio - 1) * width);
      }
    }
    return { newElementX, newElementY, newWidth, newHeight, ratio };
  },
  'knob-br': (translation, position, height, width, onlyHomotheticResize) => (shiftkey) => {
    const newElementX = position.x;
    const newElementY = position.y;
    let newWidth = Math.round(width + translation.x);
    let newHeight = Math.round(height + translation.y);

    const isOnlyHomotheticResize = onlyHomotheticResize || (!onlyHomotheticResize && shiftkey);
    let ratio;
    if (isOnlyHomotheticResize) {
      if (translation.x > translation.y) {
        ratio = newWidth / width;
        newHeight = Math.trunc(height * ratio);
      } else {
        ratio = newHeight / height;
        newWidth = Math.trunc(width * ratio);
      }
    }
    return { newElementX, newElementY, newWidth, newHeight, ratio };
  },
  'knob-tm': (translation, position, height, width, hr, scale) => () => {
    const newElementX = position.x;
    const newElementY = Math.round(translation.y + position.y);
    const newWidth = width;
    const newHeight = Math.round(height - translation.y);
    let rowsPerPage = Math.trunc(((height - (BOARDGET_LIST_ADDITIONAL_HEIGHT * scale)) / ((BOARDGET_LIST_ITEM_HEIGHT * scale))));
    rowsPerPage = rowsPerPage < 1 ? 1 : rowsPerPage;
    return { newElementX, newElementY, newWidth, newHeight, rowsPerPage };
  },
  'knob-lm': (translation, position, height, width) => () => {
    const newElementX = Math.round(translation.x + position.x);
    const newElementY = position.y;
    const newWidth = Math.round(width - translation.x);
    const newHeight = height;
    return { newElementX, newElementY, newWidth, newHeight };
  },
  'knob-bm': (translation, position, height, width, hr, scale) => () => {
    const newElementX = position.x;
    const newElementY = position.y;
    const newWidth = width;
    const newHeight = Math.round(height + translation.y);
    let rowsPerPage = Math.trunc(((height - (BOARDGET_LIST_ADDITIONAL_HEIGHT * scale)) / ((BOARDGET_LIST_ITEM_HEIGHT * scale))));
    rowsPerPage = rowsPerPage < 1 ? 1 : rowsPerPage;
    return { newElementX, newElementY, newWidth, newHeight, rowsPerPage };
  },
  'knob-rm': (translation, position, height, width) => () => {
    const newElementX = position.x;
    const newElementY = position.y;
    const newWidth = Math.round(width + translation.x);
    const newHeight = height;
    return { newElementX, newElementY, newWidth, newHeight };
  }
};
