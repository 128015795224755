<template>
  <div class="IobDateRangePicker">
    <div class="IobDateRangePicker-inputs">
      <iob-label-field
        class="IobDateRangePicker-inputs-labelField"
        :model-value="displayDateFormat(startDate)"
        :size="labelSize"
        icon-name="Calendar"
        type="ghost"
        placeholder="DD/MM/YYYY"
        has-icon
        has-clear-button
        :is-focused="isStartFocused"
        @click="showDatepicker('start')"
        @update:modelValue="(value) => handleDateChange(value, 'start')"
        @focus="showDatepicker('start')"
      />
      <icon-loader
        name="Minus"
        size="default"
        color="#787391"
      />
      <iob-label-field
        class="IobDateRangePicker-inputs-labelField"
        :model-value="displayDateFormat(endDate)"
        :size="labelSize"
        type="ghost"
        placeholder="DD/MM/YYYY"
        icon-name="CalendarCheck"
        has-icon
        has-clear-button
        :is-focused="isEndFocused"
        @click="showDatepicker('end')"
        @update:modelValue="(value) => handleDateChange(value, 'end')"
        @focus="showDatepicker('end')"
      />
    </div>
    <iob-separator
      class="IobDateRangePicker-seperator"
      type="vertical"
    />
    <outside-click-listener
      v-if="isDatepickerVisible"
      @outside-click="handleClickOutside"
    >
      <div
        v-if="isDatepickerVisible"
        class="IobDateRangePicker-datepicker"
      >
        <div class="IobDateRangePicker-datepicker-header">
          <select
            ref="monthInput"
            v-model="month"
            class="IobDateRangePicker-datepicker-header--months"
            @change="handleMonthChange"
          >
            <option
              v-for="(monthName, index) in months"
              :key="index"
              :value="index"
            >
              {{ monthName }}
            </option>
          </select>
          <select
            ref="yearInput"
            v-model="year"
            class="IobDateRangePicker-datepicker-header--years"
            @change="handleYearChange"
          >
            <option
              v-for="(yearNumber, index) in years"
              :key="index"
              :value="yearNumber"
            >
              {{ yearNumber }}
            </option>
          </select>
          <iob-button
            class="IobDateRangePicker-datepicker-header--prev"
            size="small"
            color="secondary"
            type="ghost"
            left-icon="ArrowLeft"
            :show-left-icon="true"
            @click="prevMonth"
          />
          <iob-button
            class="IobDateRangePicker-datepicker-header--next"
            size="small"
            color="secondary"
            type="ghost"
            left-icon="RightArrow"
            :show-left-icon="true"
            @click="nextMonth"
          />
        </div>

        <div class="IobDateRangePicker-datepicker-days">
          <span
            v-for="day in days"
            :key="day"
          >{{ day }}</span>
        </div>

        <div
          class="IobDateRangePicker-datepicker-dates"
        >
          <iob-button
            v-for="(dateButton, index) in dateButtons"
            :key="index"
            size="medium"
            color="secondarySoft"
            type="ghostAlternative"
            :selected="dateButton.selected"
            :disabled="dateButton.isDisabled"
            style="border-radius: none"
            :class="{
              'IobDateRangePicker-datepicker-dates-date': dateButton.withinRange && !dateButton.selected && !dateButton.isDisabled,
              'within-range': dateButton.withinRange && !dateButton.selected && !dateButton.isDisabled,
              'first-in-range': dateButton.withinRange && (index === 0 || !dateButtons[index - 1].withinRange || dateButtons[index - 1].isDisabled) && !dateButton.selected && !dateButton.isDisabled,
              'last-in-range': dateButton.withinRange && (index === dateButtons.length - 1 || !dateButtons[index + 1].withinRange || dateButtons[index + 1].isDisabled) && !dateButton.selected && !dateButton.isDisabled,
            }"
            :label="`${dateButton.text}`"
            @click="handleDateClick(dateButton)"
          />
        </div>
      </div>
    </outside-click-listener>
  </div>
</template>

<script setup>
import { ref, defineProps, onMounted, watch } from 'vue';
import { DAYS, MONTHS } from '../../../constants';
import IobLabelField from '../../Molecules/IobLabelField/IobLabelField.vue';
import IconLoader from '../../IconLoader/IconLoader.vue';
import IobSeparator from '../../Atoms/IobSeparator/IobSeparator.vue';
import IobButton from '../IobButton/IobButton.vue';
import OutsideClickListener from '../../OutsideClickListener/OutsideClickListener.vue';

const props = defineProps({
  start: {
    type: String,
    default: ''
  },
  end: {
    type: String,
    default: ''
  },
  labelSize: {
    type: String,
    default: 'medium'
  }
});

const isEndFocused = ref(false);
const isStartFocused = ref(false);
const dateInputValue = ref('');
const isDatepickerVisible = ref(true);
const selectedDate = ref(new Date());
const year = ref(new Date().getFullYear());
const month = ref(new Date().getMonth());
const dateButtons = ref([]);
const monthInput = ref(null);
const yearInput = ref(null);
const days = ref(DAYS);
const months = ref(MONTHS);
const years = ref(Array.from({ length: 10 }, (_, i) => ((year.value) - 2 + i)));
const startDate = ref(props.start ? props.start : '');
const endDate = ref(props.end ? props.end : '');
const selectedBound = ref('start');

const emit = defineEmits(['onDateChange']);

const getDateFromString = (dateString) => {
  const date = dateString.split('-');
  return {
    year: parseInt(date[0], 10),
    month: parseInt(date[1], 10) - 1,
    day: parseInt(date[2], 10)
  };
};

const displayDateFormat = (date) => {
  return date.split('-').reverse().join('/');
};
const updateFromProps = () => {
  if (startDate.value) {
    const { year: startYear, month: startMonth, day: startDay } = getDateFromString(startDate.value);
    year.value = startYear;
    month.value = startMonth;
    selectedDate.value = new Date(startYear, startMonth, startDay);
  } else if (endDate.value) {
    const { year: endYear, month: endMonth, day: endDay } = getDateFromString(endDate.value);
    year.value = endYear;
    month.value = endMonth;
    selectedDate.value = new Date(endYear, endMonth, endDay);
  } else {
    selectedDate.value = new Date();
  }
  updateYearMonth();
  displayDates();
};

const updateFocus = () => {
  if (startDate.value.length !== 0 && endDate.value.length !== 0) {
    isStartFocused.value = true;
  } else {
    isEndFocused.value = true;
    selectedBound.value = 'end';
  }
};

const emitDateChange = () => {
  emit('onDateChange', { startDate: startDate.value, endDate: endDate.value });
};

const handleDateChange = (value, type) => {
  if (value === '') {
    if (type === 'start') {
      startDate.value = '';
    } else {
      endDate.value = '';
    }
    displayDates();
  }
  emitDateChange();
};

const showDatepicker = (datepicker) => {
  if (datepicker === 'start') {
    if (startDate.value) {
      const { year, month, day } = getDateFromString(startDate.value);
      selectedDate.value = new Date(year, month, day);
    } else {
      selectedDate.value = new Date();
    }
  } else {
    if (endDate.value) {
      const { year, month, day } = getDateFromString(endDate.value);
      selectedDate.value = new Date(year, month, day);
    } else {
      selectedDate.value = new Date();
    }
  }
  selectedBound.value = datepicker;
  year.value = selectedDate.value.getFullYear();
  month.value = selectedDate.value.getMonth();
  displayDates();
  // isDatepickerVisible.value = !isDatepickerVisible.value;
};

const formatDate = (dateString) => {
  const [year, month, day] = dateString.split('-').map(Number);
  return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
};

const saveDate = () => {
  // isDatepickerVisible.value = false;
  const formattedDate = `${selectedDate.value.getFullYear()}-${selectedDate.value.getMonth() + 1}-${selectedDate.value.getDate()}`;
  dateInputValue.value = formatDate(formattedDate);

  if (selectedBound.value === 'start') {
    startDate.value = formatDate(formattedDate);
  } else {
    endDate.value = formatDate(formattedDate);
  }
  displayDates();
  emitDateChange();
};

const handleDateClick = (dateButton) => {
  selectedDate.value = new Date(year.value, month.value, dateButton.text);
  displayDates();
  saveDate();
};

const handleMonthChange = () => {
  month.value = monthInput.value.selectedIndex;
  displayDates();
};

const handleYearChange = () => {
  year.value = years.value[yearInput.value.selectedIndex];
  displayDates();
};

const isDateWithinRange = (date) => {
  if (!startDate.value || !endDate.value) return false;
  const start = new Date(getDateFromString(startDate.value).year, getDateFromString(startDate.value).month, getDateFromString(startDate.value).day);
  const end = new Date(getDateFromString(endDate.value).year, getDateFromString(endDate.value).month, getDateFromString(endDate.value).day);
  return date >= start && date <= end;
};

const createButton = (text, isDisabled = false, type = 0) => {
  const currentDate = new Date();
  const comparisonDate = new Date(year.value, month.value + type, text);
  const isToday =
    currentDate.getDate() === text &&
    currentDate.getFullYear() === year.value &&
    currentDate.getMonth() === month.value;

  const isStartDateSelected = startDate.value && comparisonDate.getTime() === new Date(getDateFromString(startDate.value).year, getDateFromString(startDate.value).month, getDateFromString(startDate.value).day).getTime();
  const isEndDateSelected = endDate.value && comparisonDate.getTime() === new Date(getDateFromString(endDate.value).year, getDateFromString(endDate.value).month, getDateFromString(endDate.value).day).getTime();

  const selected = isStartDateSelected || isEndDateSelected;

  return {
    text,
    isDisabled,
    isToday,
    selected,
    withinRange: isDateWithinRange(comparisonDate)
  };
};

const nextMonth = () => {
  if (month.value === 11) {
    year.value++;
  }
  month.value = (month.value + 1) % 12;
  displayDates();
};

const prevMonth = () => {
  if (month.value === 0) {
    year.value--;
  }
  month.value = (month.value - 1 + 12) % 12;
  displayDates();
};

const updateYearMonth = () => {
  if (!monthInput.value && !yearInput.value) return;
  monthInput.value.selectedIndex = month.value;
  yearInput.value.selectedIndex = years.value.indexOf(year.value);
};

const getDayIndex = (date) => (date.getDay() === 0 ? 6 : date.getDay() - 1);

const displayDates = () => {
  updateYearMonth();
  dateButtons.value = [];
  
  const lastOfPrevMonth = new Date(year.value, month.value, 0);
  for (let i = 0; i < getDayIndex(lastOfPrevMonth) + 1; i++) {
    const text = lastOfPrevMonth.getDate() - getDayIndex(lastOfPrevMonth) + i;
    dateButtons.value.push(createButton(text, true, -1));
  }

  const lastOfMonth = new Date(year.value, month.value + 1, 0);
  for (let i = 1; i <= lastOfMonth.getDate(); i++) {
    dateButtons.value.push(createButton(i, false));
  }

  const firstOfNextMonth = new Date(year.value, month.value + 1, 1);
  for (let i = getDayIndex(firstOfNextMonth); i < 6; i++) {
    const text = firstOfNextMonth.getDate() - getDayIndex(firstOfNextMonth) + i;
    dateButtons.value.push(createButton(text, true, 1));
  }

  if (!startDate.value && !endDate.value) {
    dateButtons.value.forEach((dateButton) => {
      dateButton.withinRange = false;
      dateButton.selected = false;
    });
  } else {
    dateButtons.value.forEach((dateButton) => {
      const comparisonDate = new Date(year.value, month.value, dateButton.text);
      dateButton.withinRange = isDateWithinRange(comparisonDate);
      const start = startDate.value ? new Date(getDateFromString(startDate.value).year, getDateFromString(startDate.value).month, getDateFromString(startDate.value).day) : null;
      const end = endDate.value ? new Date(getDateFromString(endDate.value).year, getDateFromString(endDate.value).month, getDateFromString(endDate.value).day) : null;
      dateButton.selected = (start && comparisonDate.getTime() === start.getTime()) || (end && comparisonDate.getTime() === end.getTime());
    });
  }
};

onMounted(() => {
  updateFromProps();
  displayDates();
  updateFocus();
});

watch(()=> props.start, () => {
  startDate.value = props.start;
  updateFocus();
  displayDates();
});
watch(()=> props.end, () => {
  endDate.value = props.end;
  updateFocus();
  displayDates();
});

</script>
<style lang="scss" src="./IobDateRangePicker.scss" scoped />
