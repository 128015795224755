<template>
  <div class="CheckButton">
    <input 
      type="checkbox"
      class="CheckButton-checkbox"
      :checked="checked"
      @change="$emit('change', $event.target.checked)"
      @click="$emit('click')"
    >
    <span
      v-if="enhancedLabel"
      class="CheckButton-enhancedLabel"
    >{{ enhancedLabel }}</span>
    <span class="CheckButton-label">{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: 'IobCheckButton',
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    enhancedLabel: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      required: true
    },
  },
  emits: ['change', 'click']
};
</script>

<style scoped src="./CheckButton.css" />
