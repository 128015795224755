<template>
  <div class="IobIconShapeDropdown">
    <div :class="{'IobIconShapeDropdown-withTitle IobIconShapeDropdown-withTitle-content': title}">
      <iob-icon-shape
        :size="size"
        :color="color"
        :icon-name="iconName"
        @click="openDropdown"
      />
      <span
        v-if="title"
        :class="{'IobIconShapeDropdown-withTitle-content-text': title}"
      >{{ title }}</span>
    </div>
    <outside-click-listener @outside-click="handleClickOutsideDropdown">
      <iob-dropdown
        v-if="toggleDropdown && items.length > 1"
        :items="items"
        class="IobIconShapeDropdown-dropdown"
        @dropdown-element-item="handleDropdownItemClick"
      />
    </outside-click-listener>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';
import IobIconShape from '../../Atoms/IobIconShape/IobIconShape.vue';
import IobDropdown from '../IobDropdown/IobDropdown.vue';
import OutsideClickListener from '../../OutsideClickListener/OutsideClickListener.vue';

defineProps({
  title: {
    type: String,
    default: ''
  },
  size: {
    type: String,
    default: 'large'
  },
  iconName: {
    type: String,
    default: ''
  },
  color: {
    type: String,
    default: 'other'
  },
  items: {
    required: true,
    type: Array,
    default: () => {
      return []
    },
  },
  selectedItem: {
    type: String,
    default: ''
  }
});

const toggleDropdown = ref(false);
const emits = defineEmits(['dropdownElementClick']);
const handleDropdownItemClick = ({item}) => {
  emits('dropdownElementClick', item);
  toggleDropdown.value = false;
};

const openDropdown = (e) => {
  e.stopPropagation();
  toggleDropdown.value = !toggleDropdown.value;
}

const handleClickOutsideDropdown = () => {
  toggleDropdown.value = false;
};

</script>

<style lang="scss" src="./IobIconShapeDropdown.scss" scoped />
