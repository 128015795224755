import {logout as signOut} from '@iobeya/common-tools';

const actions = {
  async insertToken({ commit }, {token, authenticated}) {
    commit('setToken', token);
    commit('setAuthenticated', authenticated);
  },
  logout({ commit }) {
    signOut();
    commit('setToken', null);
    commit('setAuthenticated', false);
  }
};

export default actions;
