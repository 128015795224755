import { get, post, del } from 'SRC/api/index.js';
import i18n from 'PLUGINS/locales/i18n';
import { formatDateFromI18n } from 'SRC/utils/utils';

export default {
  async fetchAttachments(ids) {
    if (!ids?.length) {
      return [];
    }
    const response = await get(`/attachments?ids=${ids.join(',')}`);
    if (response) {
      const attachments = response.data;
      if (attachments?.length) {
        return attachments.map((attachment) => ({
          ...attachment,
          createdOn: formatDateFromI18n(new Date(attachment.createdOn), i18n.global.locale.value, 'full.date.time.format')
        }));
      }
    } else {
      return [];
    }
  },
  async createUrlAttachment(url, title) {
    try {
      const formData = new FormData();
      formData.append('url', url);
      formData.append('title', title);
      formData.append('type', 'url');
      const response = await post('/attachments', formData, true, true, false, true);
      if (response) {
        const newAttachment = {
          ...response,
          createdOn: formatDateFromI18n(new Date(response.createdOn), i18n.global.locale.value, 'full.date.time.format')
        };
        return newAttachment;
      }
    } catch (error) {
      console.error(error);
    }
  },
  async deleteAttachment(attachmentId) {
    try {
      await del(`/attachments/${attachmentId}`);
    } catch (error) {
      console.error(error);
    }
  }
};
