import { CARD_SIZE_MAP, MONTHS } from './constants';

export const getInitials = (item) => {
  if (item.length < 2) {
    return item.toUpperCase();
  }
  return item.substring(0, 2).toUpperCase();
};

export const isJSON = (text) => {
  if (typeof text !== 'string') {
    return false;
  }
  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    return false;
  }
};

export const parseIfJson = (data) => {
  if (isJSON(data)) {
    return JSON.parse(data);
  }
  return data;
};

export const getAvatarAltText = (displayName) => {
  const names = displayName.split(' ');
  const firstInitial = names[0].charAt(0).toUpperCase();
  const lastInitial = names[names.length - 1].charAt(0).toUpperCase();
  return firstInitial + lastInitial;
}

export const onKeyDownEvent = (event, fn) => {
  event.stopPropagation();
  fn(event);
};

export const generateUniqueId = () => Math.random().toString(36).substring(2);

/**
 * 
 * @param {HTMLElement} element A HTMLElement to get the Bounding Client Rect that can be hidden(CSS `display: none` or `visibility: hidden`)
 * @returns {DOMRect} A DOMRect object that contains the size of an element and its position relative to the viewport.
 */
export const getHiddenElementBoundingRect = (element) => {
  /**
   * @type {HTMLElement}
   */
  const clone = element.cloneNode(true);
  clone.style.position = 'fixed';
  clone.style.zIndex = -9999;
  clone.style.display = 'block';
  document.body.appendChild(clone);
  /**@type {DOMRect} */
  const rect = clone.getBoundingClientRect();
  document.body.removeChild(clone);
  return rect;
};

/* Date manipulation utils */

/**
 * Formats a date to the format "month day".
 * Example: "Jun 12"
 * @param {string} date - The date to format.
 * @returns {string} The formatted date.
 */
export const formatDate = (date) => {
  const d = new Date(date);
  const day = d.getDate();
  const month = d.toLocaleString('en-US', { month: 'short' });
  return `${day} ${month}`;
};
/**
 * Formats a date to the format "month day, year".
 * Example: "Apr 5, 2022"
 * @param {string} date - The date to format.
 * @returns {string} The formatted date.
 */
export const formatDateWithYear = (date) => {
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  return new Intl.DateTimeFormat('en-US', options).format(new Date(date));
};

/**
 * Formats a date to the format "weekday".
 * Example: "Staurday, Tuesday etc"
 * @param {string} date - The date to format.
 * @returns {string} The formatted date.
 */
export const formatDayOfWeek = (date) => {
  const options = { weekday: 'long' };
  return new Intl.DateTimeFormat('en-US', options).format(new Date(date));
};

/**
 * Checks if two dates are on the same day.
 * @param {Date} date1 - The first date.
 * @param {Date} date2 - The second date.
 * @returns {boolean} True if the dates are on the same day, false otherwise.
 */
export const isSameDay = (date1, date2) => {
  return date1.getFullYear() === date2.getFullYear() &&
           date1.getMonth() === date2.getMonth() &&
           date1.getDate() === date2.getDate();
};

/**
 * Checks if two dates are in the same year.
 * @param {Date} date1 - The first date.
 * @param {Date} date2 - The second date.
 * @returns {boolean} True if the dates are in the same year, false otherwise.
 */
export const isSameYear = (date1, date2) => {
  return date1.getFullYear() === date2.getFullYear();
};

/**
 * Checks if two dates are in the same week.
 * @param {Date} date1 - The first date.
 * @param {Date} date2 - The second date.
 * @returns {boolean} True if the dates are in the same week, false otherwise.
 */
export const isSameWeek = (date1, date2) => {
  const startOfWeek = (date) => {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    const startDate = new Date(date);
    startDate.setDate(diff);
    startDate.setHours(0, 0, 0, 0); 
    return startDate;
  };
  
  return startOfWeek(new Date(date1)).getTime() === startOfWeek(new Date(date2)).getTime();
};

export const formatDateToLabel = (isActiveTab, attributes) => {
  const today = new Date();

  if (isActiveTab.value === 'custom') {
    const startDate = attributes['start-date'] || '';
    const endDate = attributes['end-date'] || '';
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (startDate.length > 0 && endDate.length > 0) {
      if (
        start.getMonth() === end.getMonth() &&
        start.getFullYear() === end.getFullYear() &&
        start.getFullYear() === today.getFullYear()
      ) {
        return `${start.getDate()} - ${formatDate(endDate)}.`;
      } else if (
        start.getMonth() === end.getMonth() &&
        start.getFullYear() === end.getFullYear()
      ) {
        return `${start.getDate()} - ${formatDate(
          endDate
        )}. ${end.getFullYear()} `;
      } else if (
        start.getFullYear() === end.getFullYear() &&
        isSameYear(start, today)
      ) {
        return `${formatDate(startDate)}. - ${formatDate(
          endDate
        )}. ${start.getFullYear()}`;
      } else {
        return `${formatDate(
          startDate
        )}. ${start.getFullYear()}  - ${formatDate(
          endDate
        )}. ${end.getFullYear()}`;
      }
    } else if (endDate.length > 0) {
      const end = new Date(endDate);

      if (isSameDay(end, today)) {
        return 'Today';
      } else if (
        isSameDay(
          end,
          new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
        )
      ) {
        return 'Tomorrow';
      } else if (
        isSameDay(
          end,
          new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
        )
      ) {
        return 'Yesterday';
      } else if (isSameWeek(end, today)) {
        return formatDayOfWeek(endDate);
      } else if (isSameYear(end, today)) {
        return `${formatDate(endDate)}.`;
      } else {
        return `${formatDate(endDate)}. ${end.getFullYear()}`;
      }
    } else if (startDate.length > 0 && isSameYear(start, today)) {
      return `${formatDate(startDate)}.`;
    } else if (startDate.length > 0) {
      return `${formatDate(startDate)}. ${start.getFullYear()}`;
    } else {
      return 'None';
    }
  } else {
    if (
      today.getFullYear() !== attributes.year &&
      attributes.period &&
      attributes['frame'] === 'M'
    ) {
      return `${MONTHS[attributes.period - 1]} ${attributes.year}`;
    }
    if (
      today.getFullYear() === attributes.year &&
      attributes.period &&
      attributes['frame'] === 'M'
    ) {
      return `${MONTHS[attributes.period - 1]}`;
    } else if (
      today.getFullYear() !== attributes.year &&
      attributes.period &&
      attributes.frame !== 'Y'
    ) {
      return `${attributes.frame}${attributes.period} ${attributes.year}`;
    } else if (
      today.getFullYear() === attributes.year &&
      attributes.period &&
      attributes.frame !== 'Y'
    ) {
      return `${attributes.frame}${attributes.period}`;
    } else if (attributes.frame === 'Y' && attributes.year) {
      return `${attributes.year}`;
    } else {
      return 'None';
    }
  }
};

export const getDimensionFromSize = (
  width,
  height,
  sizeMap = CARD_SIZE_MAP
) => {
  for (const key in sizeMap) {
    const size = sizeMap[key];
    if (size.width === width && size.height === height) {
      return key;
    }
  }
  return '';
};