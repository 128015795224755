import {POWERBI_URL_PATTERNS, URL_PATTERN} from 'GLOBALS/constants';
import parse from 'BOARD/components/Embed/powerbi/utils/parser';
import i18n from 'PLUGINS/locales/i18n';

export const deepClone = (obj) => {
  if (obj instanceof Map) {
    return new Map(Array.from(obj, ([key, value]) => [key, deepClone(value)]));
  }
  if (Array.isArray(obj)) {
    return obj.map(deepClone);
  }
  if (obj && typeof obj === 'object') {
    return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, deepClone(value)]));
  }
  return obj;
};

export const pick = (obj, keys) => keys.reduce((acc, change) => {
  acc[change] = obj[change];
  return acc;
}, {});
export const isFeatureEnabled = (featuresList, featureName) => {
  const feature = featuresList.find((feature) => feature.name === featureName);
  return feature && feature.enabled;
};
export const isValidUrl = (url) => {
  // Test if is powerBi report or dashboard or visual or app
  if (POWERBI_URL_PATTERNS.basePattern.test(url) || POWERBI_URL_PATTERNS.appPattern.test(url)) {
    return parse(url) != null;
  }
  return URL_PATTERN.test(url);
};

export function useDebounce(fn, delay) {
  let cancelFn;
  const cancelPromise = new Promise((resolve) => {
    cancelFn = resolve;
  });

  return { debounce: debounce(fn, delay, cancelPromise), cancelFn };
}

export const debounce = (fn, delay, cancelPromise = null) => {
  let debounceTimer;
  cancelPromise?.then(() => {
    clearTimeout(debounceTimer);
  });

  return function (...args) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
};
const findLevelInTree = (levels, id) => {
  for (const level of levels) {
    if (level.id === id) {
      return level;
    }
    if (level.children) {
      const found = findLevelInTree(level.children, id);
      if (found) {
        return found;
      }
    }
  }
  return null;
};
export const getLevelAttributeValue = (levels, id, defaultLevelName) => {
  const levelName = 'level-name';
  const level = levels?.length && findLevelInTree(levels, id);
  return level
    ? { id: level.id, [levelName]: level.attributes[levelName], colors: level.attributes.colors || {} }
    : { [levelName]: defaultLevelName };
};

export const formatDateFromI18n = (date, locale, formatKey) => {
  if (!(date instanceof Date) || isNaN(date)) {
    throw new Error('Invalid date object.');
  }
  // Get the format string from i18n, or fall back to "en" format
  const dateFormat =
    i18n.global.t(formatKey, { locale }) // Check if the key exists
      ?? i18n.global.t(formatKey, { locale: 'en' }); // Default to "en"
  // Define parts of the date
  const formatter = new Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: locale !== 'en-US' ? 'h23' : 'h12' // 24-hour format for other locales
  });

  // Extract date parts
  const parts = formatter.formatToParts(date);
  const partsMap = Object.fromEntries(parts.map((part) => [part.type, part.value]));

  // Replace placeholders in the format string
  const formattedDate = dateFormat
    .replace('a', partsMap.dayPeriod ?? '')
    .replace('dd', partsMap.day)
    .replace('MM', partsMap.month)
    .replace('YYYY', partsMap.year)
    .replace('HH', partsMap.hour)
    .replace('mm', partsMap.minute);

  return formattedDate;
};
