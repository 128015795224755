import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const PowerBiOAuthModule = {
  namespaced: true,
  state: {
    accessToken: null,
    settings: null
  },
  getters,
  mutations,
  actions
};

export default PowerBiOAuthModule;
