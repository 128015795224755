<template>
  <div
    class="actions-container"
    @action="(event)=> $emit('action',event)"
  >
    <icon-loader
      name="MoreHorizontal"
      :color="color"
      :size="iconSize"
    />
  </div>
</template>

<script>
import IconLoader from '../IconLoader/IconLoader.vue';

export default {
  name: 'ActionsComponent',
  components: { IconLoader },
  props: {
    color: {
      type: String,
      default: '#6B7280'
    },
    iconSize: {
      type: String,
      default: 'small'
    },
  },
  emits: ['action']
};
</script>

<style scoped src="./Actions.css" />
