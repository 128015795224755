import { DEFAULT_WIDGET_SIZE, LEADER_LINE_OPTIONS, STROKESTYLE_VALUES, STROKESTYLE_MENU,
  CARD_SIZE_MAP, boardElementsSizes, MONTHS } from 'GLOBALS/constants.js';
import LeaderLine from '@iobeya/leader-line';
import _ from 'lodash';

export const buildHotKeyCombinationCode = (event) => {
  if (['Shift', 'Control'].includes(event.key)) {
    return event.key;
  }
  return `${event.ctrlKey ? 'ctrl+' : ''}${event.shiftKey ? 'shift+' : ''}${event.key}`;
};

export const computeFormulaInSelectionSize = (zoomLevel, id) => {
  const formulaElement = document.getElementById(`formula-${id}`);
  const formulaWidth = formulaElement ? (formulaElement.getBoundingClientRect().width / zoomLevel).toString()
    : DEFAULT_WIDGET_SIZE.width;
  return { height: DEFAULT_WIDGET_SIZE.height, width: formulaWidth };
};

export const drawLink = (link, linkId, color) => {
  const start = document.getElementById(`${link.sourceBoardElementId}insider`);
  const end = document.getElementById(`${link.targetBoardElementId}insider`);
  if (!(start && end && start.id !== end.id)) {
    return null;
  }
  const dash = STROKESTYLE_VALUES[link.strokeStyle];
  const size = link.thickness;
  const  thikness = STROKESTYLE_MENU.Thickness.filter((element) => element.options.size === size)[0];
  const endPlugSize = thikness?.options.endPlugSize;
  const leaderLine = new LeaderLine({
    start,
    end,
    allowedSockets: ['right', 'left']
  }).setOptions({
    ...LEADER_LINE_OPTIONS,
    ...(size && { size }),
    ...(dash && { dash }),
    ...(endPlugSize && { endPlugSize }),
    color
  });
  leaderLine.id = linkId;
  return leaderLine;
};

export const removeLink = (link) => {
  link.remove();
};

export const moveLinktoBoardView = (linkId, selectedVisualLink, setIsSelectedVisualLink) => {
  const leaderLineElement = document.querySelector('body>svg.leader-line:last-of-type');
  leaderLineElement.id = `${linkId}`;
  if (linkId !== 'mouseFollower') {
    // Add data-target attribute to be able to detect events emitted by the svg on the board
    const leaderLineTarget = document.querySelector('body>svg.leader-line:last-of-type>g>use:last-of-type');
    leaderLineTarget.setAttribute('data-target', 'visual-link');
    if (selectedVisualLink) {
      setIsSelectedVisualLink(true);
    }
  }
  // Move the svg to the Board
  const boardView = document.getElementById('boardViewRef');
  leaderLineElement.style.pointerEvents = 'none !important';
  boardView.appendChild(leaderLineElement);
};

export const updateLeaderLinesPosition = (lines) => {
  lines.forEach((line) => {
    line.position();
  });
};

export const compareProxies = (obj1, obj2) => _.isEqual(obj1, obj2);

export const colorDiff = (c1, c2) => {
  // See http://stackoverflow.com/questions/596216/formula-to-determine-brightness-of-rgb-color
  const r1 = ((c1 >> 16) & 0xFF),
    g1 = ((c1 >> 8) & 0xFF),
    b1 = (c1 & 0xFF),
    r2 = ((c2 >> 16) & 0xFF),
    g2 = ((c2 >> 8) & 0xFF),
    b2 = (c2 & 0xFF),
    d = (Math.max(r1, r2) - Math.min(r1, r2)) +
      (Math.max(g1, g2) - Math.min(g1, g2)) +
      (Math.max(b1, b2) - Math.min(b1, b2));
  return d;
};

export const hextToInt = (hex) => parseInt(hex.replace('#', ''), 16);

export const toHexa = (intColor, length = 6) => `#${('0'.repeat(length) + Number(intColor).toString(16)).substr(-length)}`;

export const getContrastedColor = (color, lightColor, darkColor) => {
  // Implement using method from W3C for accessibility http://www.w3.org/WAI/ER/WD-AERT/#color-contrast
  const aD = colorBrightness(darkColor), aL = colorBrightness(lightColor), a = colorBrightness(color), daL = Math.abs(aL - a), daD = Math.abs(aD - a);

  if (daD > 125 && daL < 125) {
    // If difference in brightness between color and dark is enough and between color and light is not, use dark.
    return toHexa(darkColor);
  } else if (Math.abs(aD - a) < 125 && Math.abs(aL - a) > 125) {
    // If difference in brightness between color and light is enough and between color and dark is not, use light.
    return toHexa(lightColor);
  } else {
    // In other cases for brightness, use color difference.
    const dcD = colorDiff(color, darkColor),
      dcL = colorDiff(color, lightColor),
      // We use round for average to favor a little bit light colors.
      aM = Math.round((aD + aL) / 2);
    // If color difference with light is higher  use light.
    // Also if brightness is lower then average  and dark is not a great choice, use light.
    // We round average and check lower or equal to favor light color, which is better for bad display that reduce brightness.
    if (dcL > dcD || (a <= aM && dcD < 500)) {
      return toHexa(lightColor);
    }
  }
  // In other cases use dark.
  return toHexa(darkColor);
};

export const colorBrightness = (c) => {
  // See http://stackoverflow.com/questions/596216/formula-to-determine-brightness-of-rgb-color
  const r = ((c > 16) & 0xFF), g = ((c > 8) & 0xFF), b = (c & 0xFF), a = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return a;
};

/**
 * Calculate value based on container width and height.
 * @param {number} width - The width of the container.
 * @param {number} height - The height of the container.
 * @returns {number} - The calculated value.
 */

export const calculateScaledValue = (width, height, defaultValue, toolType = 'card') => {
  if (!boardElementsSizes[toolType]) {
    return 0;
  }
  // Define the target dimensions and the desired font size
  const targetWidth = parseInt(boardElementsSizes[toolType].width);
  const targetHeight = parseInt(boardElementsSizes[toolType].height);

  // Calculate the scaling factor based on the target dimensions
  const widthScale = width / targetWidth;
  const heightScale = height / targetHeight;

  // Use the smaller scaling factor to maintain aspect ratio
  const scale = Math.min(widthScale, heightScale);

  // Calculate the style based on the scaling factor
  const sacledValue = defaultValue * scale;
  return sacledValue;
};

export const getDimensionFromSize = (width, height, sizeMap = CARD_SIZE_MAP) => {
  for (const key in sizeMap) {
    const size = sizeMap[key];
    if (size.width === width && size.height === height) {
      return key;
    }
  }
  return ''; // return empty string if no matching size is found
};

/* Date manipulation utils */

/**
 * Formats a date to the format "month day".
 * Example: "Jun 12"
 * @param {string} date - The date to format.
 * @returns {string} The formatted date.
 */
export const formatDate = (date) => {
  const d = new Date(date);
  const day = d.getDate();
  const month = d.toLocaleString('en-US', { month: 'short' });
  return `${day} ${month}`;
};
/**
 * Formats a date to the format "month day, year".
 * Example: "Apr 5, 2022"
 * @param {string} date - The date to format.
 * @returns {string} The formatted date.
 */
export const formatDateWithYear = (date) => {
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  return new Intl.DateTimeFormat('en-US', options).format(new Date(date));
};

/**
 * Formats a date to the format "weekday".
 * Example: "Staurday, Tuesday etc"
 * @param {string} date - The date to format.
 * @returns {string} The formatted date.
 */
export const formatDayOfWeek = (date) => {
  const options = { weekday: 'long' };
  return new Intl.DateTimeFormat('en-US', options).format(new Date(date));
};

/**
 * Checks if two dates are on the same day.
 * @param {Date} date1 - The first date.
 * @param {Date} date2 - The second date.
 * @returns {boolean} True if the dates are on the same day, false otherwise.
 */
export const isSameDay = (date1, date2) => date1.getFullYear() === date2.getFullYear() &&
           date1.getMonth() === date2.getMonth() &&
           date1.getDate() === date2.getDate();

/**
 * Checks if two dates are in the same year.
 * @param {Date} date1 - The first date.
 * @param {Date} date2 - The second date.
 * @returns {boolean} True if the dates are in the same year, false otherwise.
 */
export const isSameYear = (date1, date2) => date1.getFullYear() === date2.getFullYear();

/**
 * Checks if two dates are in the same week.
 * @param {Date} date1 - The first date.
 * @param {Date} date2 - The second date.
 * @returns {boolean} True if the dates are in the same week, false otherwise.
 */
export const isSameWeek = (date1, date2) => {
  const startOfWeek = (date) => {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    const startDate = new Date(date);
    startDate.setDate(diff);
    startDate.setHours(0, 0, 0, 0);
    return startDate;
  };

  return startOfWeek(new Date(date1)).getTime() === startOfWeek(new Date(date2)).getTime();
};

export const formatDateToLabel = (type, attributes) => {
  const today = new Date();

  if (type === 'custom') {
    const startDate = attributes['start-date'] || '';
    const endDate = attributes['end-date'] || '';
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (startDate.length > 0 && endDate.length > 0) {
      if (
        start.getMonth() === end.getMonth() &&
        start.getFullYear() === end.getFullYear() &&
        start.getFullYear() === today.getFullYear()
      ) {
        return `${start.getDate()} - ${formatDate(endDate)}.`;
      } else if (
        start.getMonth() === end.getMonth() &&
        start.getFullYear() === end.getFullYear()
      ) {
        return `${start.getDate()} - ${formatDate(
          endDate
        )}. ${end.getFullYear()} `;
      } else if (
        start.getFullYear() === end.getFullYear() &&
        isSameYear(start, today)
      ) {
        return `${formatDate(startDate)}. - ${formatDate(
          endDate
        )}. ${start.getFullYear()}`;
      } else {
        return `${formatDate(
          startDate
        )}. ${start.getFullYear()}  - ${formatDate(
          endDate
        )}. ${end.getFullYear()}`;
      }
    } else if (endDate.length > 0) {
      const end = new Date(endDate);

      if (isSameDay(end, today)) {
        return 'Today';
      } else if (
        isSameDay(
          end,
          new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
        )
      ) {
        return 'Tomorrow';
      } else if (
        isSameDay(
          end,
          new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
        )
      ) {
        return 'Yesterday';
      } else if (isSameWeek(end, today)) {
        return formatDayOfWeek(endDate);
      } else if (isSameYear(end, today)) {
        return `${formatDate(endDate)}.`;
      } else {
        return `${formatDate(endDate)}. ${end.getFullYear()}`;
      }
    } else if (startDate.length > 0 && isSameYear(start, today)) {
      return `${formatDate(startDate)}.`;
    } else if (startDate.length > 0) {
      return `${formatDate(startDate)}. ${start.getFullYear()}`;
    } else {
      return 'None';
    }
  } else {
    if (
      today.getFullYear() !== attributes.year &&
      attributes.period &&
      attributes.frame === 'M'
    ) {
      return `${MONTHS[attributes.period - 1]} ${attributes.year}`;
    }
    if (
      today.getFullYear() === attributes.year &&
      attributes.period &&
      attributes.frame === 'M'
    ) {
      return `${MONTHS[attributes.period - 1]}`;
    } else if (
      today.getFullYear() !== attributes.year &&
      attributes.period &&
      attributes.frame !== 'Y'
    ) {
      return `${attributes.frame}${attributes.period} ${attributes.year}`;
    } else if (
      today.getFullYear() === attributes.year &&
      attributes.period &&
      attributes.frame !== 'Y'
    ) {
      return `${attributes.frame}${attributes.period}`;
    } else if (attributes.frame === 'Y' && attributes.year) {
      return `${attributes.year}`;
    } else {
      return 'None';
    }
  }
};

/**
 * Retrieves the size of an image asynchronously.
 * @param {File} image - The image file.
 * @returns {Promise<{width: number, height: number}>} - A promise that resolves with an object containing the width and height of the image.
 */

export const getImageSize = async (image) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height
      });
    };
    img.onerror = (ev) => {
      reject(ev);
    };
    img.src = e.target.result;
  };

  reader.onerror = (ev) => {
    reject(ev);
  };

  reader.readAsDataURL(image);
});

/**
 * Generates a random number between 0 and 1 using the crypto.getRandomValues method.
 * @returns {number} A random number between 0 (inclusive) and 1 (exclusive).
 */
export const random = () => crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32;

