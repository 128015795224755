const getters = {
  getAllUsers(state) {
    return state.users;
  },
  getUsersOnBoard(state) {
    return state.usersOnBoard;
  },
  getUserById(state) {
    return (userId) => state.users.find((user) => user.id === userId);
  },
  getCurrentUser(state) {
    return state.currentUser;
  },
  getCurrentUserColor(state) {
    return state.currentUserColor;
  },
  getUserInfo(state) {
    return (userId) => state.users.find((user) => user.id === userId);
  }
};

export default getters;
