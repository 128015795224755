<template>
  <div class="IobCarousel">
    <IobImage
      :src="imageUrl"
      render-type="fit-to-box"
    />
    <div class="IobCarousel-container">
      <div class="IobCarousel-container-title">
        {{ title }}
      </div>
      <div class="IobCarousel-container-content">
        {{ content }}
      </div>
      <div class="IobCarousel-container-actions">
        <iob-button
          v-for="(action,index) in actions"
          :key="index"
          v-bind="action"
          @click="handleActionClicked(index)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import IobImage from '../../Atoms/IobImage/IobImage.vue';
import IobButton from '../IobButton/IobButton.vue';
import { defineProps, defineEmits } from 'vue';

defineProps({
  imageUrl: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  },
  content: {
    type: String,
    default: ''
  },
  actions: {
    type: Array,
    default: () => ([])
  }
});

const emit = defineEmits(['onActionCicked']);

const handleActionClicked = (index) => {
  emit('onActionCicked', index);
}

</script>

<style lang="scss" scoped>
.IobCarousel{
  width: 100%;
  min-height: var(--app-carousel-size-height);
  border-radius: var(--app-carousel-radius-default);
  overflow: hidden;
  position: relative;
  display: flex;

  &-container{
    display: flex;
    padding: 24px var(--app-carousel-padding-content);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--app-carousel-gap-content);
    position: relative;
    flex-grow: 1;
    z-index: 2;

    &-title{
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      max-width: var(--app-carousel-size-text-max-width);
      overflow: hidden;
      color: var(--app-carousel-color-title);
      text-overflow: ellipsis;
      font-family: 'Markazi Text', sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0.32px;
    }

    &-content {
      display: flex;
      padding-bottom: var(--app-carousel-padding-description);
      justify-content: center;
      align-items: flex-end;
      gap: var(--app-card-selection-gap-large);
      max-width: var(--app-carousel-size-text-max-width);

      color: var(--app-carousel-color-content);
      font-family: 'Noto Sans', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    &-actions {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      gap: var(--app-carousel-gap-actions);
      align-self: stretch;
    }
  }

  .IobImage {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

}
</style>

<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-app-carousel.scss" />
