<template>
  <div class="IobDropdownSelectBadge">
    <outside-click-listener
      key-support="Escape"
      @outside-click="handleClickOutsideDropdown"
    >
      <iob-select-badge
        :shape-color="typeColor"
        :label="labelValue"
        :chevron-icon="chevronIcon"
        @click="toggleDropdown"
      />
      <iob-dropdown
        v-show="isDropdownOpened"
        :items="formatData(dataArray)"
        class="IobDropdownSelectBadge-dropdown"
        @DropdownElementItem="handleDropdownClick"
      />
    </outside-click-listener>
  </div>
</template>

<script setup>
import IobSelectBadge from '../../Molecules/IobSelectBadge/IobSelectBadge.vue';
import IobDropdown from '../../Molecules/IobDropdown/IobDropdown.vue';
import { defineProps, computed, ref, defineEmits, watch, nextTick, toRef } from 'vue';
import OutsideClickListener from '../../OutsideClickListener/OutsideClickListener.vue';
import { parseIfJson } from '../../../utils';

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  typeColor: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  data: {
    required: true,
    type: [Array, String],
  },
});

const emit = defineEmits(['onClickDropdownSelectBadge', 'onOutsideClick']);
const isDropdownOpened = ref(false);
const label = toRef(props, 'label');
const labelValue = ref('');

const dataArray = computed(() => {
  return parseIfJson(props.data);
});

const chevronIcon = computed(() => {
  return isDropdownOpened.value ? 'ChevronUp' : 'ChevronDown';
});

watch(label, async (newLabel) => {
  await nextTick();
  labelValue.value = newLabel;
}, { immediate: true });

const toggleDropdown = (e) => {
  e.stopPropagation();
  isDropdownOpened.value = !isDropdownOpened.value;
};

const formatData = (data) => {
  let formattedData = [...data];
  formattedData.forEach((element) => {
    element.type = 'menu';
    element.text = element.value;
  });

  if (props.title && props.title.length) {
    const title = {
      type: 'title',
      title: props.title,
    };
    formattedData.unshift(title);
  }
  return formattedData;
};

const handleDropdownClick = ({ item, event }) => {
  if (event) {
    event.stopPropagation();
    event.preventDefault();
  }
  isDropdownOpened.value = false;
  emit('onClickDropdownSelectBadge', item);
  labelValue.value = item.text;
};

const handleClickOutsideDropdown = () => {
  emit('onOutsideClick', { isOpened: isDropdownOpened.value });
  isDropdownOpened.value = false;
};

</script>

<style lang="scss" src="./IobDropdownSelectBadge.scss" scoped />
