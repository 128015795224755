import BoardMachineWrapper from 'BOARD/BoardMachineWrapper.vue';
import PageNotFound from './PageNotFound/PageNotFound.vue';
import OAuthCallbackView from 'HOME/views/OAuthCallback/OAuthCallback.vue';

const routes = [
  { path: '/board/:type/callback', component: OAuthCallbackView },
  { path: '/board/:boardId?', component: BoardMachineWrapper },
  {
    path: '/:catchAll(.*)',
    component: PageNotFound
  }
];

export default routes;
