const mutations = {
  setAuthenticated(state, authenticated) {
    state.authenticated = authenticated;
  },
  setToken(state, token) {
    state.token = token;
  }
};

export default mutations;
