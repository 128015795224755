<template>
  <pop-over class="UserPopOver">
    <iob-user
      class="UserPopOver-user"
      :image="image"
      :color="color"
      :name="name"
      :text="text"
      :text-color="textColor"
      :background-color="backgroundColor"
    />
  </pop-over>
</template>

<script>
import PopOver from '../Atoms/popover/PopOver.vue';
import IobUser from '../IobUser/IobUser.vue';

export default {
  name: 'UserPopOver',
  components: { PopOver, IobUser },
  props: {
    name: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    text: {
      default: '',
      type: String
    },
    textColor: {
      default: '',
      type: String
    },
    backgroundColor: {
      default: '',
      type: String
    }
  }
};
</script>

<style scoped src="./UserPopOver.css" />
