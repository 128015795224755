<template>
  <div
    class="IobHiddenAvatarList"
    @click="handleClick"
  >
    <iob-avatar
      :size="size"
      :alt-text="usersNumber"
    />
    <outside-click-listener
      v-if="toggleUsersDropdown"
      @outside-click="handleOutsideClick"
    >
      <iob-dropdown
        v-if="toggleUsersDropdown"
        class="IobHiddenAvatarList--dropdown"
        :dropdown-style="dropdownStyle"
        :items="parseUsersWithTitle"
        :is-scrollable="true"
      />
    </outside-click-listener>
  </div>
</template>

<script setup>
import IobAvatar from '../IobAvatar/IobAvatar.vue';
import IobDropdown from '../IobDropdown/IobDropdown.vue';
import OutsideClickListener from '../../OutsideClickListener/OutsideClickListener.vue';
import { defineProps, ref, computed } from 'vue';

const props = defineProps({
  users: {
    type: [String, Array],
    required: true,
  },
  size: {
    type: String,
    default: 'large',
  },
  altText: {
    type: String,
    default: '',
  },
  dropdownStyle: {
    type: String,
    default: ''
  },
});

const toggleUsersDropdown = ref(false);

const parseUsersWithTitle = computed(() => {
  return [
    { title: `${usersNumber.value} members on this board`, type: 'title' },
    ...props.users,
  ];
});

const usersNumber = computed(() => `+${props.users.length}`);
const handleClick = () => {
  toggleUsersDropdown.value = true;
};

const handleOutsideClick = () => {
  toggleUsersDropdown.value = false;
};
</script>

<style lang="scss" src="./IobHiddenAvatarList.scss" scoped />
