<template>
  <free-size-card
    v-if="isFreeSize"
    v-bind="$props"
    :contrasted-color="contrastedColor"
  />
  <predefined-sizes-card
    v-else
    v-bind="$props"
    :contrasted-color="contrastedColor"
  />
</template>

<script>
import PredefinedSizesCard from './PredefinedSizesCard/PredefinedSizesCard.vue';
import FreeSizeCard from './freeSizeCard/FreeSizeCard.vue';
import { getContrastedColor, hextToInt } from 'BOARD/utils/utils';
import {
  DEFAULT_CARD_TEXT_COLOR
} from 'GLOBALS/constants';

export default {
  name: 'IOBCard',
  components: {
    PredefinedSizesCard,
    FreeSizeCard
  },
  props: {
    isFreeSize: {
      type: Boolean,
      default: false
    },
    fields: {
      type: Array,
      default: () => ([{ originRef: '/vms/title' }])
    },
    canBeHighlighted: {
      type: Boolean,
      default: true
    },
    bgColor: {
      type: String,
      default: ''
    },
    hasColor: {
      type: Object,
      required: true
    },
    additionalDots: {
      type: Array,
      required: true
    },
    elementId: {
      type: String,
      required: true
    },
    attributes: {
      type: Object,
      default: () => { }
    },
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: true
    }
  },
  computed: {
    contrastedColor() {
      if (!this.hasColor || !this.bgColor) {
        return '';
      }
      const bgColorInt = hextToInt(this.bgColor);
      return getContrastedColor(bgColorInt, DEFAULT_CARD_TEXT_COLOR.light, DEFAULT_CARD_TEXT_COLOR.dark);
    }
  }
};
</script>
