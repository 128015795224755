<template>
  <div>
    <div
      v-for="(section, index) in Object.keys(strokeStyleOptions)"
      :key="index"
    >
      <span class="StrokeStyle-optionName">
        {{ section }}
      </span>
      <div class="StrokeStyle-options">
        <div
          v-for="(action, i) in strokeStyleOptions[section]"
          :key="i"
          :class="{'StrokeStyle-option': true, 'StrokeStyle-selectedOption': action.isSeleted}"
          @click="clickAction(section, action)"
        >
          <icon-loader
            :name="action.iconName"
            color="black"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { STROKESTYLE_MENU, STROKESTYLE_VALUES } from 'GLOBALS/constants';
import { mapState, mapMutations } from 'vuex';
import { compareProxies } from 'BOARD/utils/utils';

export default {
  name: 'StrokeStyle',
  data() {
    return {
      styleOptions: [],
      strokeStyleOptions: STROKESTYLE_MENU,
      line: null
    };
  },
  computed: {
    ...mapState('relations', ['lines', 'visualLinkBeingEdited', 'visualLinks'])
  },
  mounted() {
    this.setDefaultStrokeStyleValues();
  },
  methods: {
    ...mapMutations('relations', [
      'updateLine',
      'setIsVisualLinkContextualMenuOpen',
      'updateVisualLink'
    ]),
    setDefaultStrokeStyleValues() {
      const line = this.lines.find(
        (line) => line.id === this.visualLinkBeingEdited.id
      );
      Object.keys(this.strokeStyleOptions).forEach((section) => {
        this.strokeStyleOptions[section].forEach((element) => {
          if (element.options.size === line.size || compareProxies(element.options.dash, line.dash)) {
            element.isSeleted = true;
            return;
          }
          element.isSeleted = false;
        });
      });
    },
    updateStrokeStyleOptions(section, action) {
      this.strokeStyleOptions[section].forEach((element) => {
        if (element.options === action.options) {
          element.isSeleted = true;
          return;
        }
        element.isSeleted = false;
      });
    },
    clickAction(section, action) {
      this.updateStrokeStyleOptions(section, action);
      const line = this.lines.find(
        (line) => line.id === this.visualLinkBeingEdited.id
      );

      line.setOptions({
        allowedSockets: ['right', 'left'],
        ...action.options
      });
      this.updateLine(line);
      const strokeStyle = Object.keys(STROKESTYLE_VALUES).find(
        (key) => compareProxies(STROKESTYLE_VALUES[key], line.dash)
      );
      const thickness = line.size;
      this.updateVisualLink({
        visualLinkId: this.visualLinkBeingEdited.id,
        updates: {
          ...(thickness && { thickness }),
          ...(strokeStyle && { strokeStyle })
        }
      });
      this.setIsVisualLinkContextualMenuOpen(true);
      this.$emit('stroke-style-menu-click');
    }
  }
};
</script>

<style src="./StrokeStyle.css" />
