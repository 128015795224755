// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-12.use[1]!../../../node_modules/@vue/cli-service/node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/@vue/cli-service/node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-12.use[2]!../../../node_modules/@vue/cli-service/node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-12.use[3]!../../main.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MultiLevelMenu-box[data-v-fbcdd442]{padding:4px 0;background:#fff;border-radius:8px;box-shadow:0 4px 12px rgb(20 19 27/5%),0 1px 10px rgb(20 19 27/4%),0 2px 4px -1px rgb(20 19 27/7%)}.MultiLevelMenu-submenu[data-v-fbcdd442]{top:0;margin-left:288px;width:100%}.MultiLevelMenu-chevronRight[data-v-fbcdd442]{margin-top:14px;margin-right:9px}.MultiLevelMenu-icon[data-v-fbcdd442]{margin-top:14px;margin-left:5px}span[data-v-fbcdd442]{padding:18px 16px;font-family:PT Sans,sans-serif;min-width:130px;max-width:100%;height:45px;font-size:14px;border-radius:4px}li[data-v-fbcdd442]{width:100%;white-space:nowrap}li[data-v-fbcdd442]:hover{background:#efefef}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
