<template>
  <div
    class="BoardView"
    :style="{backgroundImage: `url(${backgroundImage?.url})`}"
  >
    <selection-area
      v-if="userSelectedElements.length > 1 && !isMultipleMove"
      :position="selectionPosition"
    />
    <div
      v-for="(element) in elements"
      :key="element.id"
    >
      <board-element-machine-wrapper
        v-if="datasetElements[element.datasetElement.id]"
        :key="element.id"
        :state="state"
        :element-id="element.id"
        :element="element"
        :layout-tiles="getLayoutTiles(element.viewTypeId)"
        :position="element.position"
        :locked="element.locked"
        :type="getToolType(element.viewTypeId)"
        :type-id="element.viewTypeId"
        :height="element.height"
        :width="element.width"
        :scale="element.scale"
        :rows-per-page="element.rowsPerPage"
        :data-type-id="datasetElements[element.datasetElement.id].typeId"
        :dataset-element-id="element.datasetElement.id"
        @edit-click="() => onEditClick(element.id)"
        @open-click="onOpenClick"
        @element-drag="(position) => onUserDraggingElement(element, position)"
      />
    </div>
    <visual-links v-if="boardData.displayLinks" />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import BoardElementMachineWrapper from 'BOARD/components/BoardElement/BoardElementMachineWrapper.vue';
import { getUserClickedElementEvent } from 'GLOBALS/events.js';
import { MUTATION_TYPES, ZOOM, RESIZE_TYPE } from 'GLOBALS/constants.js';
import SelectionArea from '../SelectionArea/SelectionArea.vue';
import { getYDoc } from 'SRC/utils/collab';
import VisualLinks from '../VisualLinks/VisualLinks.vue';

export default {
  name: 'BoardView',
  components: { BoardElementMachineWrapper, SelectionArea, VisualLinks },
  props: {
    state: {
      type: Object,
      default: () => ({})
    },
    selectionArea: {
      type: Boolean,
      default: false
    },
    selectionPosition: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState('app', [
      'viewTypes'
    ]),
    ...mapState('board', [
      'elements',
      'zoomLevel',
      'userSelectedElements',
      'isMultipleMove',
      'link',
      'relationSource',
      'sourceCoordinates',
      'backgroundImage',
      'boardData',
      'selectedInteractionMode',
      'datasetElements'
    ]),
    ...mapState('relations', [
      'dependencies'
    ]),
    ...mapGetters('board', [
      'getFullAttributes'
    ]),
    creatingLink() {
      return this.link.source && !this.link.target;
    },
    relationSource() {
      if (this.link.source) {
        return {
          position: {
            x: this.sourceCoordinates.x,
            y: this.sourceCoordinates.y
          }
        };
      }
      return {position: {x: 0, y: 0}};
    }
  },
  mounted() {
    this.computeZoomToFitLevel();
    this.zoomToFit();
    this.initViewportReferencePointOrigin();
  },
  methods: {
    ...mapActions('board', [
      'updateBoardElementPosition',
      'computeZoomToFitLevel',
      'zoomToFit',
      'initViewportReferencePointOrigin',
      'initWebSocketProvider',
      'selectBoardElements',
      'removeSelection',
      'addDatasetElementToStore'
    ]),
    ...mapMutations('board', [
      'setBoardElementsBeingSelected',
      'setTranslation'
    ]),
    setIsMouseFollower() {
      this.isMouseFollower = true;
    },
    resetIsMouseFollower() {
      this.isMouseFollower = false;
    },
    onUserClickedElement(elementId, event) {
      const isDelete = event.ctrlKey;
      if (isDelete) {
        return this.removeSelection([elementId]);
      }
      const mutationType = event.shiftKey ? MUTATION_TYPES.APPEND_TO_PREVIOUS : MUTATION_TYPES.ERASE_PREVIOUS;
      this.selectBoardElements({boardElementsIds: [elementId], mutationType});
    },
    onEditClick(elementId) {
      document.dispatchEvent(getUserClickedElementEvent({ elementId }));
    },
    async onOpenClick(datasetElementId) {
      const elements =  Object.values(this.elements);
      const hasBoardElement = elements.includes((element) => element.datasetElement.id === datasetElementId);
      if (!this.datasetElements[datasetElementId]) {
        await this.addDatasetElementToStore(datasetElementId);
      }
      document.dispatchEvent(getUserClickedElementEvent({ hasBoardElement, datasetElementId }));
    },
    onUserDraggingElement(element, position) {
      const { id } = element;
      const { left: x, top: y, translation } = position;
      if (this.isMultipleMove) {
        const ydoc = getYDoc();
        const updateElementsPositions = (ctx) => () => {
          ctx.userSelectedElements.forEach((id_) => {
            const element = this.elements[id_];
            const newElementX = Math.round(translation.x + element.position.x);
            const newElementY = Math.round(translation.y + element.position.y);
            ctx.updateBoardElementPosition({
              elementId: id_,
              position: {
                x: newElementX,
                y: newElementY
              }
            });
          });
        };
        ydoc.transact(updateElementsPositions(this));
        this.setTranslation({
          x: 0,
          y: 0
        });
      } else {
        const formattedCoordinates = {x: Math.round(x), y: Math.round(y)};
        this.updateBoardElementPosition({
          elementId: id,
          position: {...formattedCoordinates}
        });
      }
    },
    getLayoutTiles(viewTypeId) {
      if (this.viewTypes && this.viewTypes[viewTypeId] && this.viewTypes[viewTypeId].resizable === RESIZE_TYPE.FREE) {
        return this.viewTypes[viewTypeId].layouts[0].tiles;
      }
      if (this.viewTypes && viewTypeId) {
        const levelOfDetailSecondary = this.viewTypes[viewTypeId].layouts.find((layout) => layout.levelOfDetail === -1);

        if (this.zoomLevel < ZOOM.LEVEL_OF_DETAIL_THRESHOLD && levelOfDetailSecondary) {
          return levelOfDetailSecondary.tiles;
        }

        return this.viewTypes[viewTypeId].layouts.find((layout) => layout.levelOfDetail === 0).tiles;
      }
    },
    getToolType(viewTypeId) {
      return this.viewTypes[viewTypeId].toolType;
    }
  }
};
</script>

<style scoped>
.BoardView {
  background-color: white;
  box-shadow: rgb(149 157 165 / 20%) 0 8px 24px;
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style>
