<template>
  <div data-target="board" />
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { drawLink, moveLinktoBoardView } from 'BOARD/utils/utils';

export default {
  name: 'VisualLink',
  props: {
    link: {
      type: Object,
      required: true
    },
    linkId: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: undefined
    }
  },
  data: () => ({
    leaderLine: {}
  }),
  computed: {
    ...mapState('relations', ['lines', 'selectedVisualLink']),
    ...mapState('board', ['elements'])
  },
  mounted() {
    this.manageLink();
  },
  async unmounted() {
    const line = this.leaderLine;
    if (line && line.id) {
      this.removeLine(line.id);
      const el = document.getElementById(line.id);
      if (el) {
        el.remove();
      }
    }
  },
  methods: {
    ...mapMutations('relations', ['setLines', 'removeLine', 'setIsSelectedVisualLink']),
    manageLink() {
      this.leaderLine = drawLink(this.link, this.linkId, this.color);
      if (this.leaderLine === null) {
        return;
      }
      this.setLines(this.leaderLine);
      // Move the svg to the Board
      moveLinktoBoardView(this.linkId, this.selectedVisualLink, this.setIsSelectedVisualLink);
    }
  }
};
</script>
