const mutations = {
  setViewTypes(state, viewTypes) {
    state.viewTypes = viewTypes;
  },
  setDataTypes(state, dataTypes) {
    state.dataTypes = dataTypes;
  },
  setFormulas(state, formulas) {
    state.formulas = formulas;
  },
  setErrors(state, errors) {
    state.errors = errors;
  },
  setLayouts(state, layouts) {
    state.layouts = layouts;
  },
  setExceptions(state, exceptions) {
    state.exceptions = exceptions;
  },
  setRelationError(state, errorObject) {
    state.relationError = errorObject;
  },
  setSystemDataTypes(state, systemDataTypes) {
    state.systemDatatypes = systemDataTypes;
  },
  resetRelationError(state) {
    state.relationError = null;
  },
  setRelationTypes(state, relationTypes) {
    state.relationTypes = relationTypes;
  },
  setCircuitOpened(state, open) {
    state.circuitOpened = open;
  },
  setLevels(state, levels) {
    state.levels = levels;
  },
  setLevelsTree(state, levelsTree) {
    state.levelsTree = levelsTree;
  },
  setFeaturesList(state, featuresList) {
    state.featuresList = featuresList;
  }
};

export default mutations;
