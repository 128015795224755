import {getYjsValue, syncedStore} from '@syncedstore/core';
import {WebsocketProvider} from 'y-websocket';
import * as awarenessProtocol from 'y-protocols/awareness.js';
import { UndoManager } from 'yjs';

export const WSSCHEME = process.env.VUE_APP_COLLABORATIVE_SVC_SCHEME;
export const WSURL = process.env.VUE_APP_COLLABORATIVE_SVC_URL;
export const WSPORT = process.env.VUE_APP_COLLABORATIVE_SVC_PORT;
let webSocket = null;
let yDoc = null;
let collabInterval = null;
let store = null;
let undoManager = null;
let boardElementsMap = null;
let datasetElementsMap = null;

export function initWebsocketProvider(id, doc, authToken, wsSheme = WSSCHEME, wsUrl = WSURL, wsPort = WSPORT) {
  yDoc = doc;
  boardElementsMap = doc.getMap('boardElements');
  datasetElementsMap = doc.getMap('datasetElements');
  const scopedTypes = [
    boardElementsMap,
    datasetElementsMap
  ];
  undoManager = new UndoManager(scopedTypes, {ignoreRemoteMapChanges: true});
  if (webSocket) {
    const splittedWebSocket = webSocket.url.split('?token=');
    webSocket.url = `${splittedWebSocket[0]}?token=${authToken}`;
  } else {
    webSocket = new WebsocketProvider(`${wsSheme}://${wsUrl}:${wsPort}`, id, doc,
      { params: { token: authToken } });
  }
}

export function getWebsocketProvider() {
  return webSocket;
}
export function getUndoManager() {
  return undoManager;
}
export function undo() {
  const undoManager = getUndoManager();
  undoManager.undo();
}

export function redo() {
  const undoManager = getUndoManager();
  undoManager.redo();
}

export function getYDoc() {
  return yDoc;
}

export function createSyncedStore(shape) {
  store = syncedStore(shape);
  return store;
}

export function getSyncedStore() {
  return store;
}

export function getSyncedDoc(store) {
  return getYjsValue(store);
}

export function getCollabInterval() {
  return collabInterval;
}

export function getMap(name) {
  const mapsKeys = {
    'boardElements': boardElementsMap,
    'datasetElements': datasetElementsMap
  };
  return mapsKeys[name];
}

export function initCollabAwareness(firstname, lastname, id, image, color) {
  const setLocalState = () => {
    const provider  = getWebsocketProvider();
    provider.awareness.setLocalStateField('user', {
      // Define a print name that should be displayed
      name: `${firstname} ${lastname}`,
      firstname,
      lastname,
      // Define a color that should be associated to the user:
      color,
      id: `${id}`,
      image
    });
  };
  setLocalState();
  collabInterval = setInterval(setLocalState, 20000);
}

export function removeCollabAwareness() {
  const interval = getCollabInterval();
  const provider = getWebsocketProvider();
  const yDoc = getYDoc();
  clearInterval(interval);
  if (provider) {
    awarenessProtocol.removeAwarenessStates(
      provider.awareness, [yDoc.clientID], 'window unload'
    );
  }
  webSocket?.destroy();
  webSocket = null;
  store = null;
  undoManager = null;
  boardElementsMap = null;
  datasetElementsMap = null;
}

export function getUserStateId(userId) {
  const provider = getWebsocketProvider();
  const foundStates = [...provider.awareness.getStates()].filter((each) => each[1] && each[1].user && each[1].user.id === userId);
  return foundStates.length ? foundStates[0][0] : null;
}
