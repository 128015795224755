import boardElementHandlers from 'BOARD/eventsHandlers/board-element';
import boardHandlers from 'BOARD/eventsHandlers/board';
import visualLinksHandlers from 'BOARD/eventsHandlers/visual-link';

const handlers = {
  board: boardHandlers,
  'board-element': boardElementHandlers,
  'visual-link': visualLinksHandlers
};

export default handlers;
