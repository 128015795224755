import {UNKNOWN_USER, CARD_SIZE_MAP} from 'GLOBALS/constants.js';

export const CURRENT_USER_FEEDBACK_COLOR = '#3368F6';
export const DEFAULT_MOVE_FEEDBACK_COLOR = '#3368F6';
export const DEFAULT_EDITION_FEEDBACK_COLOR = '#3368F6';

export function getToolTipTextForEdition(modifiers, currentUserIsAModifier) {
  if (!modifiers) {
    return '';
  }
  if (!currentUserIsAModifier) {
    if (modifiers.length === 1) {
      return `${modifiers[0].firstname} ${modifiers[0].lastname}`;
    }
    return `${modifiers.length} members`;
  }
  if (!modifiers.length) {
    return 'You are editing';
  }
  if (modifiers.length !== 1) {
    return `You and ${modifiers.length} members`;
  }
  return `You and ${modifiers[0].firstname} ${modifiers[0].lastname}`;
}

export function getToolTipTextForMove(moverId, allUsers, currentUserId) {
  if (!allUsers || !moverId) {
    return '';
  }
  if (moverId === currentUserId) {
    return '';
  }
  const boardElementMover = allUsers.filter((each) => each.id === moverId)[0];
  if (!boardElementMover) {
    return `${UNKNOWN_USER.firstname} ${UNKNOWN_USER.lastname}`;
  }
  return `${boardElementMover.firstname} ${boardElementMover.lastname}`;
}

export function getFeedbackColorForEdition(modifiersIds, users, currentUserId) {
  if (modifiersIds.length) {
    const currentUserIsEditing = modifiersIds.some((each) => each === currentUserId);
    if (currentUserIsEditing) {
      return CURRENT_USER_FEEDBACK_COLOR;
    } else {
      const boardElementModifiers = users
        .filter((each) => each.id !== currentUserId)
        .filter((each) => modifiersIds.includes(each.id));
      return boardElementModifiers.length ? boardElementModifiers[0].color : '#ff00ff';
    }
  } else {
    return DEFAULT_EDITION_FEEDBACK_COLOR;
  }
}

export function getFeedbackColorForMove(moversIds, allUsers) {
  if (moversIds && moversIds.length > 0) {
    const boardElementMover = allUsers.find((each) => moversIds.includes(each.id));
    if (boardElementMover && boardElementMover.color) {
      return boardElementMover.color;
    }
    return DEFAULT_MOVE_FEEDBACK_COLOR;
  } else {
    return DEFAULT_MOVE_FEEDBACK_COLOR;
  }
}
/**
 * Checks if a specified property exists within the attributes of a given data type.
 *
 * @param {string} propretyName - The name of the property to check for.
 * @param {Object} dataTypesList - The list of data types, where each key is a data type ID.
 * @param {string} dataTypeId - The ID of the data type to search within.
 * @returns {boolean} - Returns `true` if the property exists, otherwise `false`.
 */
export function hasProprety(propretyName, dataTypesList, dataTypeId)  {
  const a = dataTypesList[dataTypeId]?.attributes.find(
    (attr) => attr.name === propretyName
  );
  return !!a;
}
/**
 * Retrieves the value of a specified property from the given attributes or return the default value in the data type.
 *
 * @param {string} propretyName - The name of the property to retrieve the value for.
 * @param {Object} dataTypesList - The list of data types, where each key is a data type ID.
 * @param {Object} attributes - The attributes object containing property names and their values.
 * @param {string} dataTypeId - The ID of the data type to search within.
 * @returns {*} - Returns the value of the property if found, otherwise returns the default value from the data type or an empty string.
 */
export function getPropertyValue(propretyName, dataTypesList, attributes, dataTypeId) {
  const propretyEntry = Object.entries(attributes).find(
    ([attrName]) => attrName === propretyName
  );
  const propretyInDataType = dataTypesList[dataTypeId]?.attributes.find(
    (attr) => attr.name === propretyName
  );
  const defaultValue = propretyInDataType && propretyInDataType.defaultValue ? propretyInDataType.defaultValue : '';
  return propretyEntry ? propretyEntry[1] : defaultValue;
}
export function getSizeFromDimensions(dimension, sizes = CARD_SIZE_MAP) {
  return  sizes[dimension];
}

export function openLink(link) {
  if (link?.length > 0) {
    window.open(link, '_blank');
  }
}
