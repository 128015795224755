<template>
  <div
    class="IobTableCellSkeleton"
  >
    <template v-if="type === 'titleWithIconShape'">
      <iob-skeleton 
        shape="rect" 
        width="15px"
      />
      <iob-skeleton
        width="64px"
        height="24px"
      />
      <iob-skeleton />
    </template>
    <template v-else-if="['title', 'user'].includes(type)">
      <iob-skeleton
        :width="customSkeletonWidth"
        shape="rect"
      />
      <div style="flex-grow: 1;">
        <iob-skeleton />
      </div>
    </template>
    <iob-skeleton
      v-else-if="type === 'badge'"
      width="64px"
      height="24px"
    />
    <template v-else-if="type === 'icons' && icons?.length">
      <iob-skeleton 
        v-for="(icon) in icons"
        :key="icon"
        shape="icon"
        :icon-name="icon"
        :icon-size="iconSize"
      />
    </template>
    <iob-skeleton
      v-else-if="type === 'icons'"
      width="40px"
      shape="rect"
    />
    <iob-skeleton v-else />
  </div>
</template>
  
<script setup>
import IobSkeleton from '../../../Atoms/IobSkeleton/IobSkeleton.vue';
import { computed, defineProps } from 'vue';
const props = defineProps({
  type: {
    type: String,
    default: 'default',
    validator: (value) => ['default', 'title', 'badge', 'icons', 'user', 'titleWithIconShape'].includes(value)
  },
  icons: {
    type: Array,
    default: () => []
  },
  iconSize: {
    type: String,
    default: 'default'
  }
});

const customSkeletonWidth = computed(() => props.type === 'title' ? 'var(--illustration-cover-size-small)': 'var(--avatar-sizing-l)');
</script>
  
  <style lang="scss" scoped>
  .IobTableCellSkeleton {
    display: flex;
    align-items: center;
    gap: var(--table-row-gap-large, 16px);
    width: 100%;
  }
  </style>
  