<template>
  <div @click.prevent="handleClick">
    <label class="switch">
      <input 
        :id="id"
        type="checkbox"
        :checked="checkedSwitch"
        :disabled="props.disabled"
      >
      <span class="slider round" />
    </label>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue';

const props = defineProps({
  id: {
    type: String,
    default: 'checkbox'
  },
  checked: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
});
const checkedSwitch = ref(props.checked);

watch(() => props.checked, (value) => {
  checkedSwitch.value = value;
}, {immediate: true});

const emit = defineEmits(['change']);
const handleClick = () => {
  emit('change', !checkedSwitch.value);
  checkedSwitch.value = !checkedSwitch.value;
}

</script>

<style lang="scss" src="./IobToggleSwitch.scss" scoped />