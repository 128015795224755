<template>
  <div>
    <ul class="IobTabs">
      <li
        v-for="(tab, index) in tabs"
        :id="`tab-header-${index}`"
        :key="index"
        class="IobTab"
        :class="{ active: selectedTab === index}"
        @click="() => emit('tab-select', index)"
      >
        <div
          v-if="tab?.icon"
          class="IobTab-icon"
        >
          <icon-loader
            :name="tab.icon"
            size="large"
          />
        </div>
        <div class="IobTab-label">
          {{ tab?.label || tab }}
        </div>
      </li>
    </ul>
    <div class="IobTab-content">
      <slot
        :id="`tab-content-${activeTab}`"
        :name="activeTabName"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const emit = defineEmits(['tab-select']);

const props = defineProps({
  tabs: {
    type: Array,
    required: true
  },
  selectedTab: {
    type: Number,
    default: null
  }
});

const activeTabName = computed(() => {
  const label = props.tabs[props.selectedTab]?.label || props.tabs[props.selectedTab];
  return label?.toLowerCase();
});
</script>

<style lang="scss" scoped>
.IobTabs {
  display: flex;
}

.IobTab {
  display: flex;
  height: var(--tab-underline-sizing-tab-vertical-default, 48px);
  padding: 0px var(--tab-underline-padding-tab-underline-horizontal-default, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--tab-underline-gap-icon-label-default, 8px);
  flex: 1 0 0;
  position: relative;
  cursor: pointer;

  &::after {
    position: absolute;
    content: "";
    height: var(--nav-tab-underline-sizing-underline-vertical-small, 1px);
    bottom: 0;
    border-radius: var(--nav-tab-underline-radius-underline-small, 9999px);
    background: var(--nav-tab-underline-color-underline, #CCCBD6);
    width: 100%;
  }

  &-label {
    color: var(--tab-underline-color-text-primary-default, #5D587B);
    /* text-s/font-regular */
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.14px;
  }

  ::v-deep(svg *) {
    fill: var(--tab-underline-color-icon-primary-default, #5D587B);
  }

  &-content {
    margin-top: 24px;
  }

  &.active {

    .IobTab-label {
      color: var(--tab-underline-color-text-primary-selected, #1D51CE);
    }

    ::v-deep(svg *) {
      fill: var(--tab-underline-color-icon-primary-selected, #2D6BFF);
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      border-bottom: 2px solid var(--tab-underline-color-underline-primary-selected, #2D6BFF);
      width: 100%;
    }
  }
}
</style>
