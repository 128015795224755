// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-12.use[1]!../../../node_modules/@vue/cli-service/node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/@vue/cli-service/node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-12.use[2]!../../../node_modules/@vue/cli-service/node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-12.use[3]!../../main.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DialogBox-padding[data-v-82040508]{padding:25px}.DialogBox-content[data-v-82040508]{box-sizing:content-box;background:#fff;box-shadow:0 2px 4px -1px rgb(20 19 27/7%),0 4px 12px .5px rgb(20 19 27/5%),0 2px 4px -2px rgb(20 19 27/4%);border-radius:16px}.DialogBox-closeIcon[data-v-82040508]{position:absolute;top:36px;right:36px;cursor:pointer}.button-group[data-v-82040508]{position:absolute;display:flex;bottom:28px;right:28px;width:191px;justify-content:space-between}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
