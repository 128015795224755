<template>
  <div class="HistoryLine">
    <div class="HistoryLine-layout">
      <avatar-with-popover
        v-if="!!userPicture"
        :name="getUserInitials(data.user.firstname, data.user.lastname)"
        :color="data.user.color || defaultUserColor"
        :image="data.user.picture"
        :text="getUserInitials(data.user.firstname, data.user.lastname)"
        :text-color="data.user.textColor || defaultAvatarTextColor"
        :background-color="data.user.color || defaultUserColor"
        :style="{ top: 0, width: '60px' }"
      />
      <div :style="detailsStyle">
        <div :style="'margin-top: 2px'">
          <span
            v-for="word of generateSentence"
            :key="word.text"
            :class="word.class"
          >
            {{ word.text }}
          </span>
        </div>
        <div class="HistoryLine-date">
          {{ generateDateString(data.dateTime) }}
        </div>
      </div>
    </div>
    <div
      v-if="componentName === 'DatasetElementHistory' && data.action.kind === 'updated'"
      class="HistoryLine-actions"
    >
      <div class="HistoryLine-action">
        <span
          v-if="data.action.oldValue"
          class="HistoryLine-actionValue"
        > {{ data.action.oldValue }} </span>
        <span
          v-else
          class="HistoryLine-actionValue"
        > None </span>
      </div>
      <div class="HistoryLine-VerticalLineIcon">
        <icon-loader
          name="VerticalLine"
          stroke="gray"
        />
        <icon-loader
          name="ChevronDown"
          color="gray"
          style="margin-top: -5px; margin-left: 5px;"
        />
      </div>
      <div class="HistoryLine-action">
        <span
          v-if="data.action.newValue"
          class="HistoryLine-actionValue"
        > {{ data.action.newValue }} </span>
        <span
          v-else
          class="HistoryLine-actionValue"
        > None </span>
      </div>
    </div>
    <div
      v-if="isEndOfSection"
      class="HistoryLine-separator"
    />
  </div>
</template>

<script>
import { DEFAULT_USER_COLOR, DEFAULT_AVATAR_TEXT_COLOR, VERSION_DATETIME_OPTIONS } from 'GLOBALS/constants.js';
import { getUserInitials } from 'SRC/utils/collab-utils';

export default {
  name: 'HistoryLine',
  props: {
    data: {
      type: Object,
      required: true
    },
    isEndOfSection: {
      type: Boolean,
      required: false,
      default: false
    },
    componentName: {
      type: String,
      required: true
    }
  },
  data: () => ({
    userName: null,
    userPicture: null,
    defaultAvatarTextColor: DEFAULT_AVATAR_TEXT_COLOR,
    defaultUserColor: DEFAULT_USER_COLOR
  }),
  computed: {
    datasetElementName() {
      return this.data.datasetElement.title ? `${this.data.datasetElement.friendlyId} ${this.data.datasetElement.title} `
        : `${this.data.datasetElement.friendlyId} `;
    },
    generateSentence() {
      if (this.data.action.kind === 'board_visit' && this.componentName === 'BoardHistory') {
        return [{
          text: `${this.userName} `,
          class: 'HistoryLine-userName'
        },
        {
          text: 'consulted the ',
          class: ''
        },
        {
          text: `${this.data.boardId} `,
          class: 'HistoryLine-boardElementName'
        }
        ];
      }
      if (this.data.action.kind === 'updated') {
        if (this.componentName === 'BoardHistory') {
          return [
            {
              text: `${this.userName} `,
              class: 'HistoryLine-userName'
            },
            {
              text: 'modified the ',
              class: ''
            },
            {
              text: this.datasetElementName,
              class: 'HistoryLine-boardElementName'
            }
          ];
        }
        return [
          {
            text: `${this.userName} `,
            class: 'HistoryLine-userName'
          },
          {
            text: 'modified the ',
            class: ''
          },
          {
            text: `${this.data.action.field.friendlyName} `,
            class: 'HistoryLine-boardElementName'
          }
        ];
      }

      if (this.data.action.kind === 'added') {
        if (this.componentName === 'DatasetElementHistory') {
          return [
            {
              text: `${this.userName} `,
              class: 'HistoryLine-userName'
            },
            {
              text: `${this.data.action.kind} `,
              class: ''
            },
            {
              text: this.datasetElementName,
              class: 'HistoryLine-boardElementName'
            },
            {
              text: 'on ',
              class: ''
            },
            {
              text: `${this.data.boardId} `,
              class: 'HistoryLine-boardElementName'
            }
          ];
        }
      }

      if (this.data.action.kind === 'deleted') {
        if (this.componentName === 'DatasetElementHistory') {
          return [
            {
              text: `${this.userName} `,
              class: 'HistoryLine-userName'
            },
            {
              text: 'removed ',
              class: ''
            },
            {
              text: this.datasetElementName,
              class: 'HistoryLine-boardElementName'
            },
            {
              text: 'from ',
              class: ''
            },
            {
              text: `${this.data.boardId} `,
              class: 'HistoryLine-boardElementName'
            }
          ];
        }
      }

      return [
        {
          text: `${this.userName} `,
          class: 'HistoryLine-userName'
        },
        {
          text: `${this.data.action.kind} `,
          class: ''
        },
        {
          text: this.datasetElementName,
          class: 'HistoryLine-boardElementName'
        }
      ];
    },
    detailsStyle() {
      if (this.userPicture) {
        return '';
      }
      return 'margin-left: 60px;';
    }
  },
  mounted() {
    if (!this.data && !this.data.user) {
      return;
    }
    this.getUserInformartions();
  },
  methods: {
    generateDateString(dateTime) {
      const dateObject = new Date(dateTime);
      const date = dateObject.toLocaleDateString('en-EU', {...VERSION_DATETIME_OPTIONS, month: 'long'}).replace('at', ',');
      return date;
    },
    getUserInitials(firstname, lastname) {
      return getUserInitials(firstname, lastname) || 'UU';
    },
    getUserInformartions() {
      if (this.data.user) {
        this.userName =
          this.data.user.firstname && this.data.user.lastname ? `${this.data.user.firstname} ${this.data.user.lastname}` : this.data.user.username;
      }
      if (this.data.displayPicture === false) {
        return;
      }
      if (!this.data.user.picture) {
        this.userPicture = true;
        return;
      }
      this.userPicture = this.data.user.picture;
    }
  }
};
</script>
<style src="./HistoryLine.css" />
