<template>
  <outside-click-listener
    @outside-click="closeVisualLinkContextualMenu"
  >
    <div
      class="VisualLinkContextualMenu"
      :style="`top: ${linksMenuTopPosition};
        left: ${linksMenuLeftPosition};
        height: ${DEFAULT_VISUAL_LINK_MENU_SIZE.height};
        width: ${DEFAULT_VISUAL_LINK_MENU_SIZE.width}`"
    >
      <interaction-mode-icon
        v-for="action in visualLinksActions"
        :key="action.name"
        :icon-name="action.iconName"
        :color="action.iconColor"
        :stroke="action.iconStroke"
        :is-item-hovered="selectedVisualLinkAction === action.name"
        :is-item-disabled="action.isDisabled"
        :is-item-clickable="action.isClickable"
        :label="action.label"
        :component-name="componentName"
        @mouseover="setAction(action.name)"
        @mouseleave="setAction(null)"
        @click="executeAction(action.name)"
      />
    </div>
    <outside-click-listener
      v-if="isLinkTypesMenuOpen"
      @outside-click="closeLinkTypesMenu"
    >
      <div
        class="VisualLinkContextualMenu-linksMenu"
        :style="`
          top: ${linksMenuTopPosition + DEFAULT_VISUAL_LINK_MENU_SIZE.height + 10};
                    left: ${linksMenuLeftPosition};`"
      >
        <links-types-menu
          :display-show-hide-label="false"
          :component-name="componentName"
          @link-type-change="manageVisualLinkTypeSelection"
        />
      </div>
    </outside-click-listener>
    <outside-click-listener
      v-if="isThincknessAndStyleOpen"
      @outside-click="closeThincknessAndStyleMenu"
    >
      <div
        class="VisualLinkContextualMenu-strokeStyleMenu"
        :style="`top: ${linksMenuTopPosition + DEFAULT_VISUAL_LINK_MENU_SIZE.height + 10};
        left: ${linksMenuLeftPosition + 50};`"
      >
        <stroke-style @stroke-style-menu-click="closeThincknessAndStyleMenu" />
      </div>
    </outside-click-listener>
  </outside-click-listener>
</template>
<script>
import { VISUAL_LINKS_ACTIONS, DEFAULT_VISUAL_LINK_MENU_SIZE, LINKS_TYPES} from 'GLOBALS/constants';
import { mapMutations, mapState } from 'vuex';
import InteractionModeIcon from 'BOARD/components/InteractionModeSelector/InteractionModeIcon.vue';
import LinksTypesMenu from 'BOARD/components/InteractionModeSelector/LinksTypesMenu.vue';
import StrokeStyle from 'BOARD/components/VisualLinkContextualMenu/StrokeStyle/StrokeStyle.vue';

export default {
  name: 'VisualLinkContextualMenu',
  components: { InteractionModeIcon, LinksTypesMenu, StrokeStyle },
  data() {
    return {
      VISUAL_LINKS_ACTIONS,
      isLinkTypesMenuOpen: false,
      DEFAULT_VISUAL_LINK_MENU_SIZE,
      componentName: null,
      isThincknessAndStyleOpen: false
    };
  },
  computed: {
    ...mapState('relations', [
      'visualLinkBeingEdited',
      'selectedVisualLinkAction',
      'lines',
      'selectedVisualLinkType',
      'visualLinks',
      'dependencies'
    ]),
    visualLinksActions() {
      return [
        { iconName: 'ColoredChip', name: VISUAL_LINKS_ACTIONS.TYPEANDCOLOR, iconColor: `#${this.selectedVisualLinkType.color}`,
          label: this.selectedVisualLinkType.label,
          isClickable: this.selectedVisualLinkType.type !== LINKS_TYPES.HierarchicalRelationType },
        { iconName: 'StrokeStyle', name: VISUAL_LINKS_ACTIONS.THICKNESSANDSTYLE, iconColor: 'black' },
        { iconName: 'Repeat', name: VISUAL_LINKS_ACTIONS.DIRECTION, iconColor: 'black',
          isDisabled: this.selectedVisualLinkType.type === LINKS_TYPES.HierarchicalRelationType,
          isClickable: this.selectedVisualLinkType.type !== LINKS_TYPES.HierarchicalRelationType
        },
        { iconName: 'Trash', name: VISUAL_LINKS_ACTIONS.DELETE, iconStroke: 'black' }
      ];
    },
    linksMenuTopPosition() {
      return (
        this.visualLinkBeingEdited.top + this.visualLinkBeingEdited.height * 0.5
      );
    },
    linksMenuLeftPosition() {
      return (
        this.visualLinkBeingEdited.left + this.visualLinkBeingEdited.width * 0.5
      );
    }
  },
  async mounted() {
    this.componentName = this.$options.name;
  },
  methods: {
    ...mapMutations('relations', [
      'setSelectedVisualLinkAction',
      'setIsVisualLinkContextualMenuOpen',
      'removeRelation',
      'setIsVisualLinkBeingEdited',
      'updateVisualLink',
      'updateDependency',
      'updateHierarchicalRelation',
      'updateLine',
      'setSelectedVisualLinkType'
    ]),
    setAction(value) {
      this.setSelectedVisualLinkAction(value);
    },
    closeVisualLinkContextualMenu() {
      this.setIsVisualLinkContextualMenuOpen(false);
    },
    executeAction(name) {
      if (name === VISUAL_LINKS_ACTIONS.DELETE) {
        this.updateVisualLink({
          visualLinkId: this.visualLinkBeingEdited.id,
          updates: { deleted: true }
        });
        const dependencyId =  this.visualLinks[this.visualLinkBeingEdited.id].relationId;
        const hierarchicalRelationId = this.visualLinks[this.visualLinkBeingEdited.id].hierarchicalRelationId;
        if (dependencyId) {
          this.updateDependency({
            dependencyId,
            updates: { deleted: true }
          });
        }
        if (hierarchicalRelationId) {
          this.updateHierarchicalRelation({
            hierarchicalRelationId,
            updates: { deleted: true }
          });
        }
        this.setIsVisualLinkContextualMenuOpen(false);
        return;
      }

      if (name === VISUAL_LINKS_ACTIONS.THICKNESSANDSTYLE) {
        this.isThincknessAndStyleOpen = true;
      }

      if (name === VISUAL_LINKS_ACTIONS.TYPEANDCOLOR && this.selectedVisualLinkType.type === LINKS_TYPES.DependencyType) {
        this.isLinkTypesMenuOpen = true;
      }

      if (name === VISUAL_LINKS_ACTIONS.DIRECTION && this.selectedVisualLinkType.type === LINKS_TYPES.DependencyType) {
        this.updateVisualLink({
          visualLinkId: this.visualLinkBeingEdited.id,
          updates: {
            sourceBoardElementId: this.visualLinks[this.visualLinkBeingEdited.id].targetBoardElementId,
            targetBoardElementId: this.visualLinks[this.visualLinkBeingEdited.id].sourceBoardElementId
          }
        });
        const dependencyId =  this.visualLinks[this.visualLinkBeingEdited.id].relationId;
        this.updateDependency({
          dependencyId,
          updates: {
            sourceDatasetElementId: this.dependencies[dependencyId].targetDatasetElementId,
            targetDatasetElementId: this.dependencies[dependencyId].sourceDatasetElementId
          }
        });
        const line = this.lines.find(
          (line) => line.id === this.visualLinkBeingEdited.id
        );
        const newLine = line.setOptions({
          allowedSockets: ['right', 'left'],
          start: line.end,
          end: line.start
        });
        this.updateLine(newLine);
        this.setIsVisualLinkContextualMenuOpen(false);
      }
    },
    closeLinkTypesMenu() {
      this.isLinkTypesMenuOpen = false;
    },
    closeThincknessAndStyleMenu() {
      this.isThincknessAndStyleOpen = false;
    },
    manageVisualLinkTypeSelection(linkType) {
      this.setSelectedVisualLinkType(linkType);
      const line = this.lines.find(
        (line) => line.id === this.visualLinkBeingEdited.id
      );
      line.setOptions({
        allowedSockets: ['right', 'left'],
        color: `#${linkType.color}`
      });
      this.updateLine(line);
      const dependencyId = this.visualLinks[this.visualLinkBeingEdited.id].relationId;
      if (dependencyId) {
        this.updateDependency({
          dependencyId,
          updates: { relationTypeId: linkType.id }
        });
      }
      this.closeLinkTypesMenu();
    }
  }
};
</script>

<style src="./VisualLinkContextualMenu.css" />
