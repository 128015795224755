<template>
  <div
    class="IobNotification level-container level-0"
  >
    <div class="IobNotification-content">
      <div class="IobNotification-content-left">
        <iob-icon-shape
          v-if="iconName"
          :icon-name="iconName"
          :color="iconColor"
          size="large"
        />
      </div>
      <div class="IobNotification-content-right">
        <div class="IobNotification-content-right-wrapper">
          <div
            class="IobNotification-content-right-header"
          >
            <h3
              v-if="title.length"
              class="IobNotification-content-right-header-title"
            >
              {{ title }}
            </h3>
            <iob-button
              class="IobNotification-content-right-header-close"
              size="medium"
              color="secondary"
              type="ghost"
              :show-left-icon="true"
              left-icon="X"
              @click="emit('close')"
            />
          </div>
          <p
            v-if="description.length"
            class="IobNotification-content-right-header-description"
          >
            {{ description }}
          </p>
        </div>
        <div
          v-if="secondaryButton.length || primaryButton.length"
          class="IobNotification-content-right-header-buttons"
        >
          <iob-button
            v-if="secondaryButton.length"
            size="medium"
            color="secondarySoft"
            type="filled"
            :show-left-icon="false"
            :label="secondaryButton"
            @click="emit('secondaryButtonClick')"
          />
          <iob-button
            v-if="primaryButton.length"
            size="medium"
            color="primary"
            type="filled"
            :show-left-icon="false"
            :label="primaryButton"
            @click="emit('primaryButtonClick')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import {defineEmits, defineProps} from 'vue';
import IobIconShape from '../../Atoms/IobIconShape/IobIconShape.vue';
import IobButton from '../../Molecules/IobButton/IobButton.vue';

defineProps({
  title: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  primaryButton: {
    type: Boolean,
    default: false,
  },
  secondaryButton: {
    type: Boolean,
    default: true,
  },
  iconName: {
    type: String,
    default: 'Bell',
  },
  iconColor: {
    type: String,
    default: 'primary',
  }
});
  
const emit = defineEmits(['close', 'primaryButtonClick', 'secondaryButtonClick']);

</script>
  
  <style lang="scss" scoped src="./IobNotification.scss" />
  <style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-pop-up.scss" />