<template>
  <div>
    <svg
      v-if="props.toolType === 'rectangle'"
      :width="props.width"
      :height="props.height"
    >
      <rect

        :style="styleObject"
      />

    </svg>
    <svg
      v-else-if="props.toolType === 'arrowBigRight'"

      :width="props.width"
      :height="props.height"
    >
      <polygon
        points="10,35 90,35 90,10 170,50 90,90 90,65 10,65"
        fill="none"
        stroke="black"
        stroke-width="3"
      />
    </svg>
  </div>
</template>
<script setup>
import { computed } from 'vue';

const props = defineProps({
  height: {
    type: String,
    default: '100'
  },
  width: {
    type: String,
    default: '100'
  },
  strokeColor: {
    type: String,
    default: '#000000'
  },
  toolType: {
    type: String,
    default: 'rectangle'
  }
});
const styleObject = computed(() =>
  `
  fill: none;
  stroke: ${props.strokeColor};
  stroke-width: 3;
  width: ${props.width}px;
  height: ${props.height}px;
  stroke-width: 0.25rem;
  border-radius: 2px;`
) ;
</script>
