import { get } from 'SRC/api';
import { isFeatureEnabled } from 'SRC/utils/utils.js';

export const getChildrenIds = async (datasetElementId) => {
  const hierarchicalRelationsData = await get(`/hierarchical-relations?datasetElementIds=${datasetElementId}`);
  if (hierarchicalRelationsData && hierarchicalRelationsData.data && hierarchicalRelationsData.data.length) {
    const { datasetElementChildrenIds }  = hierarchicalRelationsData.data[0];
    return datasetElementChildrenIds;
  }
  return [];
};

export const getHiddenItems = (featureGroup, featureTogglesList) => Object.entries(featureGroup).flatMap(([key, value]) =>
  isFeatureEnabled(featureTogglesList, key) ? value.olds : value.news
);

