import { LINKS_TYPES } from 'GLOBALS/constants';

export const transformToObjectFromEntries = (arr) =>
  arr && arr.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

export const formatVisualLinks = ({
  visualLinks,
  dependencies,
  hierarchicalRelations,
  relationTypes,
  elements
}) => {
  let visualLinksList = [];
  const links = Object.values(visualLinks);
  const elementsCount = Object.keys(elements).length;
  if (links && links.length && elementsCount) {
    visualLinksList = links
      .filter((el) => !el.deleted)
      .filter((el) => elements[el.sourceBoardElementId] && !elements[el.sourceBoardElementId].deleted &&
        elements[el.targetBoardElementId] && !elements[el.targetBoardElementId].deleted
      ).map((el) => {
        if (dependencies[el.relationId]) {
          const relationTypeId = dependencies[el.relationId].relationTypeId;
          const relationTypeData = relationTypes.find(
            (rt) => rt.id === relationTypeId
          );
          return { ...el, relationTypeData };
        }
        if (!el.relationId && hierarchicalRelations[el.hierarchicalRelationId]) {
          const relationTypeData = relationTypes.find(
            (rt) => rt.type === LINKS_TYPES.HierarchicalRelationType
          );
          return { ...el, relationTypeData };
        }
      })
      .filter((el) => !!el);
  }
  if (visualLinksList && visualLinksList.length > 0) {
    const duplicates = getDuplicates(visualLinksList);
    let multiples = Object.entries(duplicates).filter(
      ([, value]) => value.length > 1
    );
    const singles = Object.entries(duplicates).filter(
      ([, value]) => value.length === 1
    );
    multiples =
      multiples && multiples.length > 0
        ? multiples.reduce((acc, curr) => ({ ...acc, [curr[0]]: curr[1] }), {})
        : {};
    return {
      relations: Object.values(transformToObjectFromEntries(singles)).flat(),
      multiples
    };
  }
  return {};
};

const getDuplicates = (visualLinksList) => visualLinksList.reduce((acc, curr) => {
  const value =
      acc[`${curr.sourceBoardElementId}|${curr.targetBoardElementId}`] ||
      acc[`${curr.targetBoardElementId}|${curr.sourceBoardElementId}`];
  if (!value) {
    acc[`${curr.sourceBoardElementId}|${curr.targetBoardElementId}`] = [
      curr
    ];
  } else if (
    acc[`${curr.sourceBoardElementId}|${curr.targetBoardElementId}`]
  ) {
    acc[`${curr.sourceBoardElementId}|${curr.targetBoardElementId}`] = [
      ...value,
      curr
    ];
  } else if (
    acc[`${curr.targetBoardElementId}|${curr.sourceBoardElementId}`]
  ) {
    acc[`${curr.targetBoardElementId}|${curr.sourceBoardElementId}`] = [
      ...value,
      curr
    ];
  }
  return acc;
}, {});
