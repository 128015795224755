<template>
  <div
    :style="containerStyle"
    @click="$emit('icon-click')"
  >
    <component
      :is="iconComp"
      :color="color"
      :stroke="stroke"
      :height="customDimension || getIconSize"
      :width="customDimension || getIconSize"
    />
  </div>
</template>

<script>
import LayoutTemplateIcon from 'iobeya-vue-icons/vueComponents/layout-template.vue';
import MenuIcon from 'iobeya-vue-icons/vueComponents/menu.vue';
import UndoIcon from 'iobeya-vue-icons/vueComponents/undo-2.vue';
import RedoIcon from 'iobeya-vue-icons/vueComponents/redo-2.vue'
import ArrowLeftIcon from 'iobeya-vue-icons/vueComponents/arrow-left.vue'
import CalendarDaysIcon from 'iobeya-vue-icons/vueComponents/calendar-days.vue'
import CalendarIcon from 'iobeya-vue-icons/vueComponents/calendar.vue'
import MoreHorizontalIcon from 'iobeya-vue-icons/vueComponents/more-horizontal.vue'
import WatchIcon from 'iobeya-vue-icons/vueComponents/watch.vue'
import ArrowUpIcon from 'iobeya-vue-icons/vueComponents/arrow-up.vue'
import ArrowDownIcon from 'iobeya-vue-icons/vueComponents/arrow-down.vue'
import BookMarkIcon from 'iobeya-vue-icons/vueComponents/bookmark.vue'
import ChevronDownIcon from 'iobeya-vue-icons/vueComponents/chevron-down.vue'
import CardFieldsIcon from 'iobeya-vue-icons/vueComponents/card-fields.vue'
import HomeIcon from 'iobeya-vue-icons/vueComponents/home.vue'
import HistoryIcon from 'iobeya-vue-icons/vueComponents/history.vue'
import RoomIcon from 'iobeya-vue-icons/vueComponents/room.vue'
import SmileIcon from 'iobeya-vue-icons/vueComponents/smile.vue'
import StarFilledIcon from 'iobeya-vue-icons/vueComponents/star--filled.vue'
import VerticalLineIcon from 'iobeya-vue-icons/vueComponents/VerticalLine.vue'
import BubbleIcon from 'iobeya-vue-icons/vueComponents/Bubble.vue'
import ChevronRightIcon from 'iobeya-vue-icons/vueComponents/chevron-right.vue'
import ChevronLeftIcon from 'iobeya-vue-icons/vueComponents/chevron-left.vue'
import FunctionIcon from 'iobeya-vue-icons/vueComponents/function.vue'
import LinkModeIcon from 'iobeya-vue-icons/vueComponents/link-mode.vue'
import ChvevronsUpDownIcon from 'iobeya-vue-icons/vueComponents/chevrons-up-down.vue'
import TextIcon from 'iobeya-vue-icons/vueComponents/text.vue'
import PlusCircleIcon from 'iobeya-vue-icons/vueComponents/plus-circle.vue'
import ChevronUpIcon from 'iobeya-vue-icons/vueComponents/chevron-up.vue'
import MoreVerticalIcon from 'iobeya-vue-icons/vueComponents/more-vertical.vue'
import HandIcon from 'iobeya-vue-icons/vueComponents/hand.vue'
import LockIcon from 'iobeya-vue-icons/vueComponents/lock.vue'
import ALargeSmallIcon from 'iobeya-vue-icons/vueComponents/a-large-small.vue'
import ChipIcon from 'iobeya-vue-icons/vueComponents/Chip.vue'
import DotIcon from 'iobeya-vue-icons/vueComponents/dot.vue'
import HashIcon from 'iobeya-vue-icons/vueComponents/hash.vue'
import MousePointerIcon from 'iobeya-vue-icons/vueComponents/mouse-pointer.vue'
import SearchIcon from 'iobeya-vue-icons/vueComponents/search.vue'
import CardIcon from 'iobeya-vue-icons/vueComponents/card.vue'
import XIcon from 'iobeya-vue-icons/vueComponents/x.vue'
import SquarePenIcon from 'iobeya-vue-icons/vueComponents/square-pen.vue'
import EyeOffIcon from 'iobeya-vue-icons/vueComponents/eye-off.vue'
import BanIcon from 'iobeya-vue-icons/vueComponents/ban.vue'
import ChevronDownCircleIcon from 'iobeya-vue-icons/vueComponents/chevron-down-circle.vue'
import SquareIcon from 'iobeya-vue-icons/vueComponents/square.vue'
import UsersIcon from 'iobeya-vue-icons/vueComponents/users.vue'
import BoardIcon from 'iobeya-vue-icons/vueComponents/Board.vue'
import ColoredChipIcon from 'iobeya-vue-icons/vueComponents/ColoredChip.vue'
import EyeIcon from 'iobeya-vue-icons/vueComponents/eye.vue'
import NetworkIcon from 'iobeya-vue-icons/vueComponents/network.vue'
import PlusIcon from 'iobeya-vue-icons/vueComponents/plus.vue'
import TrashIcon from 'iobeya-vue-icons/vueComponents/trash-2.vue'
import StarIcon from 'iobeya-vue-icons/vueComponents/star.vue'
import UserIcon from 'iobeya-vue-icons/vueComponents/user.vue'
import StrokeStyleIcon from 'iobeya-vue-icons/vueComponents/stroke-style.vue'
import RepeatIcon from 'iobeya-vue-icons/vueComponents/repeat.vue';
import MinimumStrokeIcon from 'iobeya-vue-icons/vueComponents/stroke-width-1.vue';
import MediumStrokeFilledIcon from 'iobeya-vue-icons/vueComponents/stroke-width-2--filled.vue';
import MaximumStrokeFilledIcon from 'iobeya-vue-icons/vueComponents/stroke-width-3--filled.vue';
import StrokeDashIcon from 'iobeya-vue-icons/vueComponents/stroke-dash.vue';
import StrokeDotIcon from 'iobeya-vue-icons/vueComponents/stroke-dot.vue';
import XSquareIcon from 'iobeya-vue-icons/vueComponents/x-square.vue';
import CreditCardIcon from 'iobeya-vue-icons/vueComponents/credit-card.vue';
import CheckIcon from 'iobeya-vue-icons/vueComponents/check.vue';
import RightArrowIcon from 'iobeya-vue-icons/vueComponents/arrow-right.vue';
import SpinnerIcon from 'iobeya-vue-icons/vueComponents/Spinner.vue';
import iObeyaLogoIcon from 'iobeya-vue-icons/vueComponents/iObeyaLogo.vue';
import xCircleFilledIcon from 'iobeya-vue-icons/vueComponents/x-circle--filled.vue';
import SettingsIcon from 'iobeya-vue-icons/vueComponents/settings-2.vue';
import InfoIcon from 'iobeya-vue-icons/vueComponents/info.vue';
import BellIcon from 'iobeya-vue-icons/vueComponents/bell.vue';
import ArrowDownWideNarrowIcon from 'iobeya-vue-icons/vueComponents/arrow-down-wide-narrow.vue';
import ListFilterIcon from 'iobeya-vue-icons/vueComponents/list-filter.vue';
import ListIcon from 'iobeya-vue-icons/vueComponents/list.vue';
import LayoutGridIcon from 'iobeya-vue-icons/vueComponents/layout-grid.vue';
import LayoutMuralIcon from 'iobeya-vue-icons/vueComponents/layout-mural.vue';
import FileUpIcon from 'iobeya-vue-icons/vueComponents/file-up.vue'
import XCircle from 'iobeya-vue-icons/vueComponents/x-circle.vue';
import LogOutIcon from 'iobeya-vue-icons/vueComponents/log-out.vue';
import LanguagesIcon from 'iobeya-vue-icons/vueComponents/languages.vue';
import LinkIcon from 'iobeya-vue-icons/vueComponents/link-2.vue';
import UserPlusIcon from 'iobeya-vue-icons/vueComponents/user-plus.vue';
import AlertCircleIcon from 'iobeya-vue-icons/vueComponents/alert-circle.vue';
import TargetIcon from 'iobeya-vue-icons/vueComponents/target.vue';
import TrendingUpIcon from 'iobeya-vue-icons/vueComponents/trending-up.vue';
import GoalIcon from 'iobeya-vue-icons/vueComponents/goal.vue';
import FolderClosedIcon from 'iobeya-vue-icons/vueComponents/folder-closed.vue';
import AlertTriangleIcon from 'iobeya-vue-icons/vueComponents/alert-triangle.vue';
import FolderOpenIcon from 'iobeya-vue-icons/vueComponents/folder-open.vue';
import FilePenLineIcon from 'iobeya-vue-icons/vueComponents/file-pen-line.vue';
import GroupIcon from 'iobeya-vue-icons/vueComponents/group.vue';
import ClipBoardListIcon from 'iobeya-vue-icons/vueComponents/clipboard-list.vue';
import LogoFontIcon from 'iobeya-vue-icons/vueComponents/logo-font.vue';
import LogoTypeIcon from 'iobeya-vue-icons/vueComponents/logo-type.vue';
import PaperClipIcon from 'iobeya-vue-icons/vueComponents/paperclip.vue';
import ClockIcon from 'iobeya-vue-icons/vueComponents/clock.vue';
import LayoutPanelTopIcon from 'iobeya-vue-icons/vueComponents/layout-panel-top.vue';
import UnplugIcon from 'iobeya-vue-icons/vueComponents/unplug.vue';
import TicketCheckIcon from 'iobeya-vue-icons/vueComponents/ticket-check.vue';
import GlobeIcon from 'iobeya-vue-icons/vueComponents/globe.vue';
import ArrowUpRightIcon from 'iobeya-vue-icons/vueComponents/arrow-up-right.vue';
import MinusIcon from 'iobeya-vue-icons/vueComponents/minus.vue';
import CalendarCheckIcon from 'iobeya-vue-icons/vueComponents/calendar-check.vue';
import CalendarRangeIcon from 'iobeya-vue-icons/vueComponents/calendar-range.vue';
import PencilIcon from 'iobeya-vue-icons/vueComponents/pencil.vue';
import Settings2 from 'iobeya-vue-icons/vueComponents/settings-2.vue';
import ArrowDownLeft from 'iobeya-vue-icons/vueComponents/arrow-down-left.vue';
import CheckCircle2Icon from 'iobeya-vue-icons/vueComponents/check-circle-2.vue';
import CheckCircle2FilledIcon from 'iobeya-vue-icons/vueComponents/check-circle-2--filled.vue';
import PanelTopLeftIcon from 'iobeya-vue-icons/vueComponents/panel-top-left.vue';
import PanelBottom from 'iobeya-vue-icons/vueComponents/panel-bottom.vue';
import LayoutCardResizable from 'iobeya-vue-icons/vueComponents/layout-card-resizable.vue';
import MousePointerClickIcon from 'iobeya-vue-icons/vueComponents/mouse-pointer-click.vue';
import BarChartIcon from 'iobeya-vue-icons/vueComponents/bar-chart.vue';
import EmbedIcon from 'iobeya-vue-icons/vueComponents/code-2.vue';
import PowerbiIcon from 'iobeya-vue-icons/vueComponents/social-power-bi.vue';

import NewspaperIcon from 'iobeya-vue-icons/vueComponents/newspaper.vue';
import KeyboardIcon from 'iobeya-vue-icons/vueComponents/keyboard.vue';
import BlocksIcon from 'iobeya-vue-icons/vueComponents/blocks.vue';
import LinkedinOriginalIcon from 'iobeya-vue-icons/vueComponents/LinkedIn - Original.vue';
import LinkedinDarkIcon from 'iobeya-vue-icons/vueComponents/LinkedIn - Dark.vue';
import ExternalLinkIcon from 'iobeya-vue-icons/vueComponents/external-link.vue';
import { containerSizeMap, iconSizesMap } from '../../constants';
import ArrowRightIcon from 'iobeya-vue-icons/vueComponents/arrow-right.vue';
import CloudUploadIcon from 'iobeya-vue-icons/vueComponents/cloud-upload.vue';
import DropletIcon from 'iobeya-vue-icons/vueComponents/droplet.vue';
import ImageIcon from 'iobeya-vue-icons/vueComponents/image.vue';
import UstensilsIcon from 'iobeya-vue-icons/vueComponents/ustensils.vue';
import UstensilsCrossedIcon from 'iobeya-vue-icons/vueComponents/ustensils-crossed.vue';
import UniversityIcon from 'iobeya-vue-icons/vueComponents/university.vue';
import TestTubeDiagonalIcon from 'iobeya-vue-icons/vueComponents/test-tube-diagonal.vue';
import UploadIcon from 'iobeya-vue-icons/vueComponents/upload.vue'
import GitForkIcon from 'iobeya-vue-icons/vueComponents/git-fork.vue'
import DropletsIcon from 'iobeya-vue-icons/vueComponents/droplets.vue';
import Repeat2Icon from 'iobeya-vue-icons/vueComponents/repeat-2.vue';
import Trash2Icon from 'iobeya-vue-icons/vueComponents/trash-2.vue';
import HelpCircleIcon from 'iobeya-vue-icons/vueComponents/help-circle.vue';
import BarChart4Icon from 'iobeya-vue-icons/vueComponents/bar-chart-4.vue';
import Rows3Icon from 'iobeya-vue-icons/vueComponents/rows-3.vue';
import ArrowUpRightFromSquareIcon from 'iobeya-vue-icons/vueComponents/arrow-up-right-from-square.vue';
import thumbUpIcon from 'iobeya-vue-icons/vueComponents/thumbs-up.vue';
import UnlockIcon from 'iobeya-vue-icons/vueComponents/unlock.vue';
import MegaphoneIcon from 'iobeya-vue-icons/vueComponents/megaphone.vue';
import BookAIcon from 'iobeya-vue-icons/vueComponents/book-a.vue';
import ChevronsLeftIcon from 'iobeya-vue-icons/vueComponents/chevrons-left.vue';
import ChevronsRightIcon from 'iobeya-vue-icons/vueComponents/chevrons-right.vue';
import DownloadIcon from 'iobeya-vue-icons/vueComponents/download.vue';
import QcdIcon from 'iobeya-vue-icons/vueComponents/qcd.vue';
import RefreshCcwDotIcon from 'iobeya-vue-icons/vueComponents/refresh-ccw-dot.vue';
import RefreshCwIcon from 'iobeya-vue-icons/vueComponents/refresh-cw.vue';
import SplitIcon from 'iobeya-vue-icons/vueComponents/split.vue';
import PersonWalkingIcon from 'iobeya-vue-icons/vueComponents/person-walking.vue';
import GaugeIcon from 'iobeya-vue-icons/vueComponents/gauge.vue';
import RouteIcon from 'iobeya-vue-icons/vueComponents/route.vue';
import BadgeCheckIcon from 'iobeya-vue-icons/vueComponents/badge-check.vue';
import CircuitBoardIcon from 'iobeya-vue-icons/vueComponents/circuit-board.vue';
import ScrollTextIcon from 'iobeya-vue-icons/vueComponents/scroll-text.vue';
import CrownIcon from 'iobeya-vue-icons/vueComponents/crown.vue';
import CarrotIcon from 'iobeya-vue-icons/vueComponents/carrot.vue';
import CakeSliceIcon from 'iobeya-vue-icons/vueComponents/cake-slice.vue'
import CheckCircle from 'iobeya-vue-icons/vueComponents/circle.vue';
import UsersRoundIcon from 'iobeya-vue-icons/vueComponents/users-round.vue';
import AppWindowMacIcon from 'iobeya-vue-icons/vueComponents/app-window-mac.vue';
import messageCircleQuestionIcon from 'iobeya-vue-icons/vueComponents/message-circle-question.vue';
import MinusCircleIcon from 'iobeya-vue-icons/vueComponents/minus-circle.vue';
import CalendarClockIcon from 'iobeya-vue-icons/vueComponents/calendar-clock.vue'
import KeyRoundIcon from 'iobeya-vue-icons/vueComponents/key-round.vue';
import PlugIcon from 'iobeya-vue-icons/vueComponents/plug.vue';
import MediumNoteIcon from 'iobeya-vue-icons/vueComponents/medium-note.vue';
import RectangleHorizontalIcon from 'iobeya-vue-icons/vueComponents/rectangle-horizontal.vue';
import ServerIcon from 'iobeya-vue-icons/vueComponents/server.vue';
import ArchiveIcon from 'iobeya-vue-icons/vueComponents/archive.vue';
import RotateCw from 'iobeya-vue-icons/vueComponents/rotate-cw.vue';
import CircleDashedIcon from 'iobeya-vue-icons/vueComponents/circle-dashed.vue';
import GalleryHorizontalIcon from 'iobeya-vue-icons/vueComponents/gallery-horizontal.vue';
import DataTableIcon from 'iobeya-vue-icons/vueComponents/table-properties.vue';
import CopyPlusIcon from 'iobeya-vue-icons/vueComponents/copy-plus.vue';
import FolderTreeIcon from 'iobeya-vue-icons/vueComponents/folder-tree.vue';
import AsteriskSquareIcon from 'iobeya-vue-icons/vueComponents/asterisk-square.vue';
import ItalicIcon from 'iobeya-vue-icons/vueComponents/italic.vue';
import BoldIcon from 'iobeya-vue-icons/vueComponents/bold.vue';
import UnderlineIcon from 'iobeya-vue-icons/vueComponents/underline.vue';
import LinkAttachedIcon from 'iobeya-vue-icons/vueComponents/link.vue';
import ArrowUpFromDotIcon from 'iobeya-vue-icons/vueComponents/arrow-up-from-dot.vue';
import SwatchBookIcon from 'iobeya-vue-icons/vueComponents/swatch-book.vue';
import chevronsUpIcon from 'iobeya-vue-icons/vueComponents/chevrons-up.vue';
import FlameIcon from 'iobeya-vue-icons/vueComponents/flame.vue';
import Footprint from 'iobeya-vue-icons/vueComponents/footprints.vue';
import ImageUpIcon from 'iobeya-vue-icons/vueComponents/image-up.vue';
import Tally5 from 'iobeya-vue-icons/vueComponents/tally-5.vue';
import Triangle from 'iobeya-vue-icons/vueComponents/triangle.vue';
import Diamond from 'iobeya-vue-icons/vueComponents/diamond.vue';
import Pentagon from 'iobeya-vue-icons/vueComponents/pentagon.vue';
import Hexagon from 'iobeya-vue-icons/vueComponents/hexagon.vue';
import MessageCircle from 'iobeya-vue-icons/vueComponents/message-circle.vue';
import ArrowBigLeft from 'iobeya-vue-icons/vueComponents/arrow-big-left.vue';
import ArrowBigRight from 'iobeya-vue-icons/vueComponents/arrow-big-right.vue';
import Milestone from 'iobeya-vue-icons/vueComponents/milestone.vue';
import Braces from 'iobeya-vue-icons/vueComponents/braces.vue';

export default ({
  name: 'IconLoader',
  components: {
    FlameIcon,
    chevronsUpIcon,
    SwatchBookIcon,
    LinkAttachedIcon,
    UnderlineIcon,
    BoldIcon,
    ItalicIcon,
    BarChart4Icon,
    Rows3Icon,
    PowerbiIcon,
    EmbedIcon,
    BarChartIcon,
    ArrowRightIcon,
    ArrowUpRightFromSquareIcon,
    CalendarRangeIcon,
    CalendarCheckIcon,
    MinusIcon,
    ArrowDownLeft,
    ClockIcon,
    LayoutPanelTopIcon,
    PaperClipIcon,
    ClipBoardListIcon,
    FilePenLineIcon,
    FolderOpenIcon,
    FolderClosedIcon,
    AlertTriangleIcon,
    GoalIcon,
    TrendingUpIcon,
    TargetIcon,
    AlertCircleIcon,
    LinkIcon,
    LogoTypeIcon,
    LogoFontIcon,
    ArrowUpRightIcon,
    GlobeIcon,
    UserPlusIcon,
    FileUpIcon,
    XCircle,
    LayoutTemplateIcon,
    MenuIcon,
    UndoIcon,
    RedoIcon,
    ArrowLeftIcon,
    CalendarDaysIcon,
    CalendarIcon,
    MoreHorizontalIcon,
    WatchIcon,
    ArrowUpIcon,
    ArrowDownIcon,
    SpinnerIcon,
    CheckIcon,
    RightArrowIcon,
    BookMarkIcon,
    ChevronDownIcon,
    CardFieldsIcon,
    HomeIcon,
    HistoryIcon,
    RoomIcon,
    SmileIcon,
    StarFilledIcon,
    VerticalLineIcon,
    BubbleIcon,
    ChevronRightIcon,
    FunctionIcon,
    LinkModeIcon,
    ChvevronsUpDownIcon,
    TextIcon,
    PlusCircleIcon,
    ChevronUpIcon,
    MoreVerticalIcon,
    HandIcon,
    LockIcon,
    ALargeSmallIcon,
    ChipIcon,
    DotIcon,
    HashIcon,
    MousePointerIcon,
    SearchIcon,
    CardIcon,
    XIcon,
    SquarePenIcon,
    EyeOffIcon,
    BanIcon,
    ChevronDownCircleIcon,
    SquareIcon,
    UsersIcon,
    BoardIcon,
    ColoredChipIcon,
    EyeIcon,
    NetworkIcon,
    PlusIcon,
    TrashIcon,
    StarIcon,
    UserIcon,
    StrokeStyleIcon,
    RepeatIcon,
    MinimumStrokeIcon,
    MediumStrokeFilledIcon,
    MaximumStrokeFilledIcon,
    StrokeDashIcon,
    StrokeDotIcon,
    XSquareIcon,
    CreditCardIcon,
    iObeyaLogoIcon,
    xCircleFilledIcon,
    SettingsIcon,
    InfoIcon,
    BellIcon,
    ArrowDownWideNarrowIcon,
    ListFilterIcon,
    ListIcon,
    LayoutGridIcon,
    LayoutMuralIcon,
    LogOutIcon,
    LanguagesIcon,
    GroupIcon,
    UnplugIcon,
    TicketCheckIcon,
    PencilIcon,
    Settings2,
    CheckCircle2Icon,
    CheckCircle2FilledIcon,
    PanelTopLeftIcon,
    MousePointerClickIcon,
    NewspaperIcon,
    BlocksIcon,
    KeyboardIcon,
    LinkedinOriginalIcon,
    LinkedinDarkIcon,
    ExternalLinkIcon,
    CloudUploadIcon,
    DropletIcon,
    ImageIcon,
    UstensilsIcon,
    UstensilsCrossedIcon,
    UniversityIcon,
    TestTubeDiagonalIcon,
    UploadIcon,
    GitForkIcon,
    DropletsIcon,
    Repeat2Icon,
    Trash2Icon,
    HelpCircleIcon,
    thumbUpIcon,
    ChevronLeftIcon,
    UnlockIcon,
    BookAIcon,
    ChevronsLeft: ChevronsLeftIcon,
    ChevronRight: ChevronRightIcon,
    ChevronsRight: ChevronsRightIcon,
    DownloadIcon,
    QcdIcon,
    RefreshCcwDotIcon,
    SplitIcon,
    PersonWalkingIcon,
    GaugeIcon,
    RouteIcon,
    BadgeCheckIcon,
    CircuitBoardIcon,
    ScrollTextIcon,
    CrownIcon,
    CarrotIcon,
    CakeSliceIcon,
    CheckCircle,
    UsersRoundIcon,
    AppWindowMacIcon,
    messageCircleQuestionIcon,
    MinusCircleIcon,
    CalendarClockIcon,
    KeyRoundIcon,
    PlugIcon,
    MediumNoteIcon,
    RectangleHorizontalIcon,
    ServerIcon,
    PanelBottom,
    LayoutCardResizable,
    ArchiveIcon,
    RotateCw,
    CircleDashedIcon,
    GalleryHorizontalIcon,
    Footprint,
    ImageUpIcon,
    Tally5,
    DataTableIcon,
    CopyPlusIcon,
    FolderTreeIcon,
    AsteriskSquareIcon,
    ArrowUpFromDotIcon,
    Triangle,
    Diamond,
    Pentagon,
    Hexagon,
    MessageCircle,
    ArrowBigLeft,
    ArrowBigRight,
    Milestone,
    Braces
  },
  props: {
    name: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: ''
    },
    stroke: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'default',
    },
    hasBackgroundColor: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: 'var(--list-element-asset-icon-color-background)'
    },
    customDimension: {
      type: String,
      default: ''
    }
  },
  emits: ['icon-click'],
  data() {
    return {
      iconsMap: {
        Flame: FlameIcon,
        chevronsUp: chevronsUpIcon,
        SwatchBook: SwatchBookIcon,
        LinkAttached: LinkAttachedIcon,
        Underline: UnderlineIcon,
        Bold: BoldIcon,
        Italic: ItalicIcon,
        BarChart4: BarChart4Icon,
        Rows3: Rows3Icon,
        PowerBI: PowerbiIcon,
        Embed: EmbedIcon,
        BarChart:BarChartIcon,
        ArrowRight: ArrowRightIcon,
        CalendarRange: CalendarRangeIcon,
        CalendarCheck: CalendarCheckIcon,
        Minus: MinusIcon,
        Clock: ClockIcon,
        LayoutPanelTop: LayoutPanelTopIcon,
        Globe: GlobeIcon,
        PaperClip: PaperClipIcon,
        ClipBoardList: ClipBoardListIcon,
        FilePenLine: FilePenLineIcon,
        FolderOpen: FolderOpenIcon,
        AlertTriangle: AlertTriangleIcon,
        FolderClosed: FolderClosedIcon,
        Goal: GoalIcon,
        TrendingUp: TrendingUpIcon,
        Target: TargetIcon,
        AlertCircle: AlertCircleIcon,
        Link: LinkIcon,
        LogoType: LogoTypeIcon,
        LogoFont: LogoFontIcon,
        ArrowUpRight: ArrowUpRightIcon,
        UserPlus: UserPlusIcon,
        FileUp:FileUpIcon,
        XCircle: XCircle,
        LayoutTemplate: LayoutTemplateIcon,
        Menu: MenuIcon,
        Undo: UndoIcon,
        Redo: RedoIcon,
        ArrowLeft: ArrowLeftIcon,
        CalendarDays: CalendarDaysIcon,
        Calendar: CalendarIcon,
        MoreHorizontal: MoreHorizontalIcon,
        Watch: WatchIcon,
        ArrowUp: ArrowUpIcon,
        ArrowDown: ArrowDownIcon,
        Spinner: SpinnerIcon,
        Check: CheckIcon,
        RightArrow: RightArrowIcon,
        BookMark: BookMarkIcon,
        ChevronDown: ChevronDownIcon,
        CardFields: CardFieldsIcon,
        Home: HomeIcon,
        Room: RoomIcon,
        History: HistoryIcon,
        Smile: SmileIcon,
        StarFilled: StarFilledIcon,
        VerticalLine: VerticalLineIcon,
        Bubble: BubbleIcon,
        ChevronRight: ChevronRightIcon,
        Function: FunctionIcon,
        LinkMode: LinkModeIcon,
        ChvevronsUpDown: ChvevronsUpDownIcon,
        Text: TextIcon,
        PlusCircle: PlusCircleIcon,
        ChevronUp: ChevronUpIcon,
        MoreVertical: MoreVerticalIcon,
        Hand: HandIcon,
        Lock: LockIcon,
        ALargeSmall: ALargeSmallIcon,
        Chip: ChipIcon,
        Dot: DotIcon,
        Hash: HashIcon,
        MousePointer: MousePointerIcon,
        Search: SearchIcon,
        Card: CardIcon,
        X: XIcon,
        SquarePen: SquarePenIcon,
        EyeOff: EyeOffIcon,
        Ban: BanIcon,
        ChevronDownCircle: ChevronDownCircleIcon,
        Square: SquareIcon,
        Users: UsersIcon,
        Board: BoardIcon,
        ColoredChip: ColoredChipIcon,
        Eye: EyeIcon,
        Network: NetworkIcon,
        Plus: PlusIcon,
        Trash: TrashIcon,
        Star: StarIcon,
        User: UserIcon,
        StrokeStyle: StrokeStyleIcon,
        Repeat: RepeatIcon,
        MinimumStroke: MinimumStrokeIcon,
        MediumStrokeFilled: MediumStrokeFilledIcon,
        MaximumStrokeFilled: MaximumStrokeFilledIcon,
        StrokeDash: StrokeDashIcon,
        StrokeDot: StrokeDotIcon,
        XSquare: XSquareIcon,
        CreditCard: CreditCardIcon,
        iObeyaLogo: iObeyaLogoIcon,
        xCircleFilled: xCircleFilledIcon,
        Settings: SettingsIcon,
        Info: InfoIcon,
        Bell: BellIcon,
        ArrowDownWideNarrow: ArrowDownWideNarrowIcon,
        ListFilter: ListFilterIcon,
        List: ListIcon,
        LayoutGrid: LayoutGridIcon,
        LayoutMural: LayoutMuralIcon,
        LogOut: LogOutIcon,
        Languages: LanguagesIcon,
        Pencil: PencilIcon,
        Group: GroupIcon,
        Unplug: UnplugIcon,
        TicketCheck: TicketCheckIcon,
        ArrowDownLeft,
        'settings-2': Settings2,
        'CheckCircle-2': CheckCircle2Icon,
        'CheckCircle-2-Filled': CheckCircle2FilledIcon,
        PanelTopLeft: PanelTopLeftIcon,
        MousePointerClick: MousePointerClickIcon,
        Newspaper: NewspaperIcon,
        Blocks: BlocksIcon,
        Keyboard: KeyboardIcon,
        LinkedinOriginal: LinkedinOriginalIcon,
        LinkedinDark: LinkedinDarkIcon,
        ExternalLink: ExternalLinkIcon,
        CloudUpload: CloudUploadIcon,
        Droplet: DropletIcon,
        Image: ImageIcon,
        Ustensils: UstensilsIcon,
        UstensilsCrossed: UstensilsCrossedIcon,
        University: UniversityIcon,
        TestTubeDiagonal: TestTubeDiagonalIcon,
        Upload: UploadIcon,
        GitFork: GitForkIcon,
        Droplets: DropletsIcon,
        Repeat2: Repeat2Icon,
        Trash2: Trash2Icon,
        HelpCircle: HelpCircleIcon,
        ArrowUpRightFromSquare:ArrowUpRightFromSquareIcon,
        ThumbUp: thumbUpIcon,
        Unlock: UnlockIcon,
        Megaphone: MegaphoneIcon,
        BookA: BookAIcon,
        ChevronLeft: ChevronLeftIcon,
        ChevronsLeft: ChevronsLeftIcon,
        ChevronsRight: ChevronsRightIcon,
        Download: DownloadIcon,
        Qcd: QcdIcon,
        RefreshCcwDot: RefreshCcwDotIcon,
        RefreshCw: RefreshCwIcon,
        Split: SplitIcon,
        PersonWalking: PersonWalkingIcon,
        Gauge: GaugeIcon,
        Route: RouteIcon,
        BadgeCheck: BadgeCheckIcon,
        CircuitBoard: CircuitBoardIcon,
        ScrollText: ScrollTextIcon,
        Crown: CrownIcon,
        Carrot: CarrotIcon,
        CakeSlice: CakeSliceIcon,
        Circle: CheckCircle,
        UsersRound: UsersRoundIcon,
        AppWindowMac: AppWindowMacIcon,
        messageCircleQuestion: messageCircleQuestionIcon,
        MinusCircle: MinusCircleIcon,
        CalendarClock: CalendarClockIcon,
        KeyRound: KeyRoundIcon,
        Plug: PlugIcon,
        MediumNote: MediumNoteIcon,
        RectangleHorizontal: RectangleHorizontalIcon,
        Server: ServerIcon,
        PanelBottom,
        LayoutCardResizable,
        Archive: ArchiveIcon,
        RotateCw,
        CircleDashed: CircleDashedIcon,
        GalleryHorizontal: GalleryHorizontalIcon,
        Footprint,
        ImageUp: ImageUpIcon,
        Tally5,
        DataTable: DataTableIcon,
        CopyPlus: CopyPlusIcon,
        FolderTree: FolderTreeIcon,
        AsteriskSquare: AsteriskSquareIcon,
        ArrowUpFromDot: ArrowUpFromDotIcon,
        Triangle,
        Diamond,
        Pentagon,
        Hexagon,
        MessageCircle,
        ArrowBigLeft,
        ArrowBigRight,
        Milestone,
        Braces
      }
    };
  },
  computed: {
    iconComp() {
      return this.iconsMap[this.name];
    },
    getIconSize() {
      return iconSizesMap[this.size];
    },
    getContainerSize() {
      return containerSizeMap[this.size];
    },
    containerStyle() {
      if (this.hasBackgroundColor && this.getContainerSize) {
        return {
          background: this.backgroundColor,
          width: this.getContainerSize,
          height: this.getContainerSize,
          display: 'flex',
          'justify-content': 'center',
          'align-items': 'center',
        };
      }
      return {};
    },
  }
});
</script>

<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-icon.scss" />
