<template>
  <div
    :style="moveStyle"
    class="selection-area"
    data-target="board"
    tabindex="0"
  >
    <iob-contextual-menu
      v-if="contextualMenuShown"
      :container-style="containerStyle"
      :no-edit="true"
      :locked="hasLockedElements"
      :selected-elements-count="contextualMenuText"
      @item-click="handleContextualMenuEvent"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { transformElementCoordinates } from 'SRC/utils/geometrics/transform';
import { computeFormulaInSelectionSize } from 'BOARD/utils/utils';
import {
  FORMULA_TOP_CTX_MENU_EQUATION,
  MAX_ZL_SCALE_EDITOR,
  e
} from 'GLOBALS/constants';

export default {
  name: 'SelectionArea',
  data() {
    return {
      isLocked: false
    };
  },
  computed: {
    ...mapState('board', ['zoomLevel', 'elements', 'contextualMenuShown', 'boardElementsBeingSelected', 'userSelectedElements']),
    ...mapGetters('board', ['getBoardElementDataType', 'getSelectedBoardElements']),
    ...mapGetters('app', ['getViewTypeSize']),
    containerStyle() {
      let scale = 1 / this.zoomLevel;
      const { EXP_POW, EXP_TIMES, OFFSET } = FORMULA_TOP_CTX_MENU_EQUATION;
      const exponent = EXP_POW * this.zoomLevel;
      let top = EXP_TIMES * Math.pow(e, exponent) + OFFSET;
      if (this.zoomLevel < MAX_ZL_SCALE_EDITOR) {
        top = -120;
        scale = 1 / MAX_ZL_SCALE_EDITOR - (MAX_ZL_SCALE_EDITOR - this.zoomLevel);
      }
      return `margin: -24px auto auto; width:100%;transform: scale(${scale}); top: ${top}px; right: 0; left: unset;`;
    },
    moveStyle() {
      const borderRadius = this.zoomLevel > 1 ? (4 * this.zoomLevel) : (4 / this.zoomLevel);
      return {
        top: `${this.position.top}px`,
        left: `${this.position.left}px`,
        width: `${this.position.width}px`,
        height: `${this.position.height}px`,
        borderRadius: `${borderRadius}px`
      };
    },
    hasLockedElements() {
      return this.getSelectedBoardElements.some((e) => e.locked);
    },
    contextualMenuText() {
      return `${this.userSelectedElements?.length} elements`;
    },
    position() {
      const userSelectedElements = Object.entries(this.elements).reduce((acc, [elementId, data]) => {
        if (this.userSelectedElements.includes(elementId)) {
          return [...acc, data];
        }
        return acc;
      }, []
      );
      const elementsInSelection = userSelectedElements.map((e) =>  {
        const dataTypeName = this.getBoardElementDataType(e.id);
        let size;
        if (dataTypeName.includes('Formula')) {
          size = computeFormulaInSelectionSize(this.zoomLevel, e.id);
        } else {
          size = {height: e.height, width: e.width};
        }
        return transformElementCoordinates(this.zoomLevel, size.height, size.width)(e);
      });
      const startY = Math.min(...elementsInSelection.map((e) => e.coordinates.y1)) - 4 * (1 + this.zoomLevel);
      const endY = Math.max(...elementsInSelection.map((e) => e.coordinates.y2)) + 4 * (1 + this.zoomLevel);
      const startX = Math.min(...elementsInSelection.map((e) => e.coordinates.x1)) - 4 * (1 + this.zoomLevel);
      const endX = Math.max(...elementsInSelection.map((e) => e.coordinates.x2)) + 4 * (1 + this.zoomLevel);
      return {
        top: Math.trunc(startY / this.zoomLevel),
        height: Math.trunc((endY - startY) / this.zoomLevel),
        left: Math.trunc(startX / this.zoomLevel),
        width: Math.trunc((endX - startX) / this.zoomLevel)
      };
    }
  },
  methods: {
    ...mapActions('board', ['deleteUserSelectedElements', 'toggleSelectedElementsLockState']),
    handleContextualMenuEvent({eventName}) {
      const contextualMenuEventMap = {
        onLockToggle: this.toggleSelectedElementsLockState,
        onDelete: this.deleteUserSelectedElements
      };
      const clickedEvent = contextualMenuEventMap[eventName];
      if (clickedEvent) {
        clickedEvent();
      }
    },
    handleLockClick() {
      this.toggleSelectedElementsLockState();
      this.isLocked = !this.isLocked;
    }
  }
};
</script>

<style scoped>
.selection-area {
  position: absolute;
  border: 2px solid rgb(51 104 246 / 100%);
}
</style>
