<template>
  <div class="IobTextarea">
    <div
      v-if="$slots.default"
      class="IobTextarea-title"
    >
      <div class="slot-content">
        <slot />
      </div>
      <div
        v-if="!isError && showCaractersCounterOnFocus"
        class="IobTextarea-caractersCounter"
      >
        <h6>
          {{ characterCounter }}/{{ maxCaracters }}
        </h6>
      </div>
    </div>
    <div class="IobTextarea-textArea">
      <textarea
        ref="titleRef"
        v-model="textAreaValue"
        :rows="minRows"
        :placeholder="placeholder"
        :class="classList"
        :style="styles"
        :maxlength="maxCaracters"
        :readonly="isReadonly"
        @blur="onBlur"
        @input.prevent="onInput"
        @focus="handleFocus"
      />
      <icon-loader
        v-if="isError && error"
        name="Info"
        size="small"
        color="var(--app-form-group-color-icon-alert, #D34343)"
      />
    </div>
    <div
      v-if="characterCounter>=maxCaracters || (isError && error)"
      class="IobTextarea-message"
    >
      <icon-loader
        v-if="!isError"
        name="Info"
        size="xsmall"
        color="var(--app-form-group-color-text-warning, #1D51CE)"
      />
      <p :class="errorHandlingStyles">
        {{ errorHandlingText }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, nextTick, onMounted, defineProps, computed, defineEmits, onUnmounted} from 'vue';

const props = defineProps({
  type: {
    type: String,
    default: 'default',
    validator: (value) => ['default', 'outlined'].includes(value)
  },
  size: {
    type: String,
    default: 'large',
    validator: (value) => ['default', 'large'].includes(value)
  },
  styles: {
    type: String,
    default: 'max-height: 500px;'
  },
  isError: {
    type: Boolean,
    default: false
  },
  value: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: 'Add some text...'
  },
  minRows: {
    type: Number,
    default: 1
  },
  maxCaracters: {
    type: String,
    default: '300'
  },
  showCaractersCounter: {
    type: Boolean,
    default: true
  },
  warningDescription: {
    type: String,
    default: 'Maximum caracters reached'
  },
  errorMessage: {
    type: String,
    default: 'You must specify a title'
  },
  classNames: {
    type: String,
    default: ''
  },
  isReadonly: {
    type: Boolean,
    default: false
  },
  isFocused: {
    type: Boolean,
    default: false
  }
});

const titleRef = ref(null);
const textAreaValue = computed(() => props.value);
const error = ref(true);
const focused = ref(false);

const emits = defineEmits(['update:modelValue']);

const onInput = (event) => {
  resize();
  emits('update:modelValue', event.target.value);
};
const onBlur = () => {
  resize();
  if(characterCounter.value === 0){
    error.value = true;
  }
  focused.value = false;
};

const showCaractersCounterOnFocus = computed(()=> {
  return props.showCaractersCounter && focused.value;
});

const classList = computed(() => {
  return [
    `IobTextarea IobTextarea--${props.size}--textarea`,
    `IobTextarea--${props.size}--textarea-${props.isError  && (characterCounter.value === 0) ? 'error' : 'default'}`,
    props.type === 'outlined' ? 'IobTextarea--outlined' : '',
    props.classNames,
  ].join(' ');
});

const errorHandlingText = computed(()=> {
  return props.isError ? props.errorMessage : props.warningDescription;
});

const errorHandlingStyles = computed(()=> {
  return `IobTextarea-message-text IobTextarea-message-text-${props.isError ? 'error' : 'default'}`
});
const resize = ()=> {
  if(titleRef.value){
    titleRef.value.style.height = 'auto';
    titleRef.value.style.height = `${titleRef.value.scrollHeight}px`;
  }
}
const characterCounter = computed (()=> {
  return textAreaValue.value.length;
});

const handleFocus = () => {
  if(characterCounter.value === 0 || props.isError){
    error.value =  true;
  }
  focused.value = true;
}

onMounted(() => {
  nextTick(()=> {
    resize();
  });
  if(textAreaValue.value.length > props.maxCaracters){
    textAreaValue.value = textAreaValue.value.substring(0, props.maxCaracters);
  }
  if (props.isFocused) {
    titleRef.value.focus();
  }

  window.addEventListener('resize', resize);
});

onUnmounted(() => {
  window.removeEventListener('resize', resize);
});

</script>

<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-textArea.scss" />
<style lang="scss" src="./IobTextarea.scss" scoped />
