import { POWERBI_URL_PATTERNS } from 'GLOBALS/constants';
import store from '@/store';
import { get } from 'SRC/api';

function createResultObject(urlType, contentType, pathParams) {
  return { urlType, contentType, pathParams };
}
function validateResult(pathParams) {
  return !Object.values(pathParams).some((value) => value === '');
}
function getPathParams(url) {
  const parser = new URL(url);
  const pathParts = parser.pathname.split('/').filter((part) => part !== '');
  return pathParts;
}

function getValue(queryParams, queryParamKey, pathParts, pathPartKey, pathPartOffset) {
  return queryParams.get(queryParamKey) || pathParts[pathParts.indexOf(pathPartKey) + pathPartOffset];
}

export async function retrieveAccessToken(token) {
  if (token) {
    return token;
  } else {
    const settings = await getSettings();
    if (settings) {
      const tokenUri = `/oauth/settings/${settings.id}/my-token`;
      const accessToken = await retrieveToken(tokenUri);
      return accessToken;
    } else {
      return store.getters['powerbi/getAccessToken'];
    }
  }
}

export async function getSettings() {
  if (store.getters['powerbi/getSettings'] == null) {
    try {
      const response = await get('/oauth/settings?type=powerbi');
      return response.data[0];
    } catch (error) {
      return null;
    }
  } else {
    return store.getters['powerbi/getSettings'];
  }
}

export async function retrieveToken(tokenUri) {
  try {
    const response = await get(tokenUri);
    return response.accessToken;
  } catch (error) {
    console.error('Error retrieving token:', error);
    return null;
  }
}

export async function authenticatedFetch(apiUrl, token) {
  if (!apiUrl) {
    return null;
  }
  token = token || await retrieveAccessToken();
  if (token) {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    };
    return await fetch(apiUrl, requestOptions);
  } else {
    throw new Error('Error: No token found');
  }
}

export async function getAppReportId(appId, originalReportId, token) {
  if (!originalReportId) {
    return originalReportId;
  }
  const apiUrl = `https://api.powerbi.com/v1.0/myorg/apps/${appId}/reports`;
  try {
    const response = await authenticatedFetch(apiUrl, token);
    if (!response || response.status !== 200) {
      return originalReportId;
    }
    const data = await response.json();
    if (data?.value) {
      const report = data.value.find((report) => report.originalReportObjectId === originalReportId);
      return report ? report.id : originalReportId;
    }
  } catch (error) {
    console.error('Error: api call failed', error);
    return originalReportId;
  }
  return originalReportId;
}

export async function getAppDashboardId(appId, originalDashboardId, token) {
  if (!originalDashboardId) {
    return originalDashboardId;
  }
  const apiUrl = `https://api.powerbi.com/v1.0/myorg/apps/${appId}/dashboards`;
  try {
    const response = await authenticatedFetch(apiUrl, token);
    if (!response || response.status !== 200) {
      return originalDashboardId;
    }
    const data = await response.json();
    if (data?.value) {
      const dashboard = data.value.find((dashboard) => dashboard.webUrl.includes(originalDashboardId));
      return dashboard ? dashboard.id : originalDashboardId;
    }
  } catch (error) {
    console.error('Error: api call failed', error);
    return originalDashboardId;
  }
  return originalDashboardId;
}

async function parseAppUrl(urlObj, pathParts, queryParams, token) {
  let pathParams;

  const groupId = getValue(queryParams, 'ctid', pathParts, 'groups', 1);
  const appId = getValue(queryParams, 'appId', pathParts, 'apps', 1);
  const originalReportId = getValue(queryParams, 'reportObjectId', pathParts, 'reports', 1);
  const reportId = await getAppReportId(appId, originalReportId, token);
  const pageName = getValue(queryParams, 'reportPage', pathParts, 'reports', 2);
  const visualId = queryParams.get('visual');
  const bookmarkGuid = queryParams.get('bookmarkGuid');

  if (pathParts.includes('visual') || urlObj.search.includes('visual')) {
    pathParams = {
      groupId,
      appId,
      reportId,
      pageName,
      visualId,
      bookmarkGuid,
      embedUrl: `https://app.powerbi.com/reportEmbed?visualId=${visualId}&appId=${appId}&groupId=${groupId}`
    };
    return validateResult(pathParams) ? createResultObject('App', 'Visual', pathParams) : null;
  } else if (pathParts.includes('reports') || urlObj.search.includes('reportObjectId')) {
    pathParams = {
      groupId,
      appId,
      reportId,
      pageName,
      bookmarkGuid,
      embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${reportId}&appId=${appId}&groupId=${groupId}`
    };
    return validateResult(pathParams) ? createResultObject('App', 'Report', pathParams) : null;
  } else if (pathParts.includes('dashboards') || urlObj.search.includes('dashboards')) {
    const originalDashboardId = getValue(queryParams, 'dashboardObjectId', pathParts, 'dashboards', 1);
    const dashboardId = await getAppDashboardId(appId, originalDashboardId, token);
    pathParams = {
      groupId,
      appId,
      dashboardId,
      embedUrl: `https://app.powerbi.com/dashboardEmbed?dashboardId=${dashboardId}&appId=${appId}&groupId=${groupId}`
    };
    return validateResult(pathParams) ? createResultObject('App', 'Dashboard', pathParams) : null;
  } else {
    pathParams = {
      groupId,
      appId
    };
    return validateResult(pathParams) ? createResultObject('App', 'App', pathParams) : null;
  }
}

function parseDashboardUrl(pathParts) {
  const pathParams = {
    groupId: pathParts[1],
    dashboardId: pathParts[3],
    embedUrl: `https://app.powerbi.com/dashboardEmbed?dashboardId=${pathParts[3]}&groupId=${pathParts[1]}`
  };
  return validateResult(pathParams) ? createResultObject('Dashboard', 'Dashboard', pathParams) : null;
}

function parseVisualUrl(pathParts, queryParams) {
  const pathParams = {
    groupId: pathParts[1],
    reportId: pathParts[3],
    pageName: pathParts[4],
    visualId: queryParams.get('visual'),
    bookmarkGuid: queryParams.get('bookmarkGuid'),
    embedUrl: `https://app.powerbi.com/reportEmbed?visualId=${queryParams.get('visual')}&groupId=${pathParts[1]}`
  };
  return validateResult(pathParams) ? createResultObject('Visual', 'Visual', pathParams) : null;
}

function parseReportUrl(pathParts, queryParams) {
  const pathParams = {
    groupId: pathParts[1],
    reportId: pathParts[3],
    pageName: pathParts[4],
    bookmarkGuid: queryParams.get('bookmarkGuid'),
    embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${pathParts[3]}&groupId=${pathParts[1]}`
  };
  return validateResult(pathParams) ? createResultObject('Report', 'Report', pathParams) : null;
}

export default (url, token) => {
  try {
  // Define regex patterns for different types of Power BI URLs
    const appPattern = POWERBI_URL_PATTERNS.appPattern;
    const dashboardPattern = POWERBI_URL_PATTERNS.dashboardPattern;
    const reportPattern = POWERBI_URL_PATTERNS.reportPattern;
    const visualPattern = POWERBI_URL_PATTERNS.visualPattern;

    const urlObj = new URL(url);
    const queryParams = new URLSearchParams(urlObj.search);
    const pathParts = getPathParams(url);
    // Test the URL against each pattern
    if (appPattern.test(url)) {
      return parseAppUrl(urlObj, pathParts, queryParams, token);
    } else if (dashboardPattern.test(url)) {
      return parseDashboardUrl(pathParts);
    } else if (visualPattern.test(url)) {
      return parseVisualUrl(pathParts, queryParams);
    } else if (reportPattern.test(url)) {
      return parseReportUrl(pathParts, queryParams);
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};
