<template>
  <div class="flex items-center">
    <input
      :id="id"
      :checked="checked"
      :disabled="disabled"
      type="checkbox"
      :class="classList"
      @click="(event) => executeCheckboxFunction({event, id})"
    >
    <label
      for="green-checkbox"
      :style="labelStyle"
      class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
    >{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'CheckboxComponent',
  props: {
    color: {
      type: String,
      default: 'black'
    },
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    outlined: {
      type: Boolean,
      required: true
    },
    shape: {
      type: String,
      default: 'rounded'
    },
    borderColor: {
      type: String,
      default: 'border-gray'
    },
    size: {
      type: String,
      default: 'standard'
    },
    mode: {
      type: String,
      default: 'light'
    },
    checked: {
      type: Boolean,
      default: false
    },
    labelStyle: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    shapeClassMap: {
      standard: 'text-base',
      rounded: 'rounded'
    },
    outlinedClasses: {
      'border-gray': 'border-2 border-gray-300 focus:ring-transparent focus:border-black'
    },
    modeClassMap: {
      gray: 'bg-gray-100',
      light: 'bg-white',
      darkGray: 'bg-gray-300',
      black: 'bg-black'
    },
    colorClasses: {
      red: 'text-red-600',
      green: 'text-green-600',
      purple: 'text-purple-600',
      teal: 'text-teal-600',
      yellow: 'text-yellow-400',
      orange: 'text-orange-500',
      black: 'text-black'
    },
    sizeClasses: {
      standard: 'w-4 h-4'
    }
  }),
  computed: {
    classList() {
      return `${this.modeClassMap[this.mode]} ${this.shapeClassMap[this.shape]} ${this.colorClasses[this.color]}
      ${this.outlined && this.outlinedClasses[this.borderColor]} ${this.sizeClasses[this.size]}`;
    }
  },
  methods: {
    executeCheckboxFunction(data) {
      data.id ? this.$emit('checkbox-event', data) : this.$emit('checkbox-event', data.event);
    }
  }
};
</script>

<style scoped src="./Checkbox.css" />
