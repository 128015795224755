<template>
  <div class="InputWithIcon">
    <div
      v-if="iconsArray.length === 1"
      class="InputWithIcon-iconSelector"
    >
      <div class="InputWithIcon-icon">
        {{ selectedIconImage }}
      </div>
    </div>
    <outside-click-listener
      v-else
      @outside-click="handleClickOutsideSearchMenu"
    >
      <search-menu
        v-if="isDropdownMenuOpen"
        :options="dropdownItems"
        :search-menu-style="searchMenuStyle"
        :options-container-height="optionsContainerHeight"
        @click-item="selectItem"
      />
      <div
        class="InputWithIcon-iconSelector"
        @click="toggleMenu"
      >
        <div class="InputWithIcon-icon">
          {{ selectedIconImage }}
        </div>
        <icon icon-name="chevron-down" />
      </div>
    </outside-click-listener>
    <input
      ref="inputWithIcon"
      class="InputWithIcon-input"
      :placeholder="placeholder"
      :value="modelValueObject ? modelValueObject.inputText : inputText"
      @input="(event) => $emit('update:inputText', event.target.value)"
      @keyup.enter="(event) => $emit('press-enter', event)"
    >
  </div>
</template>

<script>
import Icon from '../Icon/Icon.vue';
import SearchMenu from '../SearchMenu/SearchMenu.vue';
import OutsideClickListener from '../OutsideClickListener/OutsideClickListener.vue';
import { parseIfJson } from '../../utils';

export default {
  name: 'InputWithIcon',
  components: { Icon, SearchMenu, OutsideClickListener },
  props: {
    icons: {
      type: [String, Array],
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Object],
      required: true
    },
    searchMenuStyle: {
      type: String,
      default: ''
    },
    optionsContainerHeight: {
      type: String,
      default: 'fit-content'
    }
  },
  emits: ['update:modelValueObject', 'press-enter', 'click-item', 'update:inputText'],
  data() {
    return {
      isDropdownMenuOpen: false,
      inputText: ''
    };
  },
  computed: {
    modelValueObject() {
      return parseIfJson(this.modelValue) || null;
    },
    iconsArray() {
      return parseIfJson(this.icons) || [];
    },
    selectedIconImage() {
      return this.modelValueObject ? this.modelValueObject.icon.icon : '';
    },
    dropdownItems() {
      return this.iconsArray.map((icon) => ({
        label: `${icon.icon} ${icon.label}`,
        value: icon.value,
        icon: icon.icon,
        onClick: () => this.update('icon', icon)
      }));
    }
  },
  mounted() {
    this.focusInput();
  },
  methods: {
    focusInput() {
      this.$refs.inputWithIcon.focus();
    },
    update(key, value) {
      this.$emit('update:modelValueObject', {
        ...this.modelValueObject,
        [key]: value,
      });
    },
    selectItem({ item }) {
      this.isDropdownMenuOpen = false;
      this.$emit('click-item', item);
    },
    handleClickOutsideSearchMenu() {
      this.isDropdownMenuOpen = false;
    },
    toggleMenu(e) {
      e.stopPropagation();
      this.isDropdownMenuOpen = !this.isDropdownMenuOpen
    }
  }
};
</script>

<style src="./InputWithIcon.css" />
