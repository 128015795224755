import actions from './actions';
import mutations from './mutations';

const HierarchyModule = {
  namespaced: true,
  state: {
    parent: null,
    children: [],
    dependencies: [],
    eligibleChildren: [],
    eligibleParents: [],
    eligibleDependencies: [],
    eligibleChildDataTypes: [],
    eligibleParentDataTypes: [],
    eligibleChildrenInitialCount: 0,
    createdDependencies: []
  },
  mutations,
  actions
};

export default HierarchyModule;
