<template>
  <div>
    <fetch-data-wrapper>
      <router-view />
    </fetch-data-wrapper>
    <simple-popup
      v-if="relationError"
      :key="!!relationError ? relationError.title : 'relationError'"
      :title="relationError.title"
      :content="relationError.content"
      @button-click="resetRelationError"
    />
    <loading-page-layer
      v-if="!currentUser && !circuitOpened "
      size="extraLarge"
      spinner-color="blue"
    />

    <!-- specific dialogs -->
    <cancel-element-relations-editor v-if="dialogBoxFlags.isCancelElementRelationsEditing" />
    <history-component v-if="specificDialogBoxFlags.isHistoryDialogOpened" />

    <!-- generic dialogs -->
    <iob-dialog-box
      v-if="displayedDialogBoxKey && dialogBoxFlags[displayedDialogBoxKey] && dialogBoxInfo"
      v-bind="dialogBoxInfo"
      @submitAction="() => dialogBoxInfo.submitFunc()"
      @cancelAction="() => dialogBoxInfo.cancelFunc()"
    />

    <!-- notifications -->
    <notification-component v-if="isNotificationDisplayed" />
  </div>
</template>

<script>
import { RouterView  } from 'vue-router';
import NotificationComponent from 'SRC/components/Notification/Notification.vue';
import { mapState, mapMutations, mapActions } from 'vuex';
import HistoryComponent from 'SRC/board/components/History/History.vue';
import FetchDataWrapper from './FetchDataWrapper/FetchDataWrapper.vue';
import CancelElementRelationsEditor from 'SRC/board/components/CancelElementRelationsEditor/CancelElementRelationsEditor.vue';
import { userCircuitBreaker, vmsCircuitBreaker } from 'SRC/api';
import {getToken, isAuthenticated} from '@iobeya/common-tools';
import store from 'SRC/store';

export default {
  name: 'App',
  components: {
    RouterView,
    NotificationComponent,
    HistoryComponent,
    FetchDataWrapper,
    CancelElementRelationsEditor
  },
  data() {
    return {
      userCircuit: null,
      vmsCircuit: null
    };
  },
  computed: {
    ...mapState('board', ['dialogBoxFlags', 'specificDialogBoxFlags', 'notifications', 'dialogBoxInfo']),
    ...mapState('users', ['currentUser']),
    ...mapState('app', ['relationError', 'circuitOpened']),
    isNotificationDisplayed() {
      const displayedNotification = Object.values(this.notifications).find(
        (notification) => notification && notification.isNotificationDisplayed
      );
      return !!displayedNotification;
    },
    displayedDialogBoxKey() {
      return Object.entries(this.dialogBoxFlags)
        .find(([, value]) => Boolean(value))?.[0];
    }
  },
  watch: {
    notifications: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          if (this.displayNotificationTimeOut) {
            clearTimeout(this.displayNotificationTimeOut);
          }
          if (this.isNotificationDisplayed) {
            this.displayNotification();
          }
        }
      }
    }
  },
  created() {
    this.userCircuit = userCircuitBreaker;
    this.vmsCircuit = vmsCircuitBreaker;
  },
  async mounted() {
    this.vmsCircuit.on('open', () => {
      console.log('vmsCircuit : open');
      this.setCircuitOpened(true);
      this.$router.push({path: '/unavailableService'});
    });
    this.userCircuit.on('open', () => {
      console.log('userCircuit : open');
      this.setCircuitOpened(true);
      this.$router.push({path: '/unavailableService'});
    });
    this.userCircuit.on('halfOpen', async () => {
      console.log('userCircuit : halfOpen');
      await this.fetchAllUsers();

    });
    this.vmsCircuit.on('halfOpen', async () => {
      console.log('vmsCircuit : halfOpen');
      await this.vmsCircuit.fire('/boards?page=1&size=25');
    });
    store.dispatch('auth/insertToken', {token: getToken(), authenticated: isAuthenticated()});
  },
  updated() {
    const currentPath = this.$route.path;
    this.vmsCircuit.on('close', () => {
      if (currentPath === '/unavailableService') {
        this.$router.push({path: '/'});
      }
    });
    this.userCircuit.on('close', () => {
      if (currentPath === '/unavailableService') {
        this.$router.push({path: '/'});
      }
    });
  },
  methods: {
    ...mapMutations('board', ['setNotificationsInfo']),
    ...mapMutations('app', ['resetRelationError']),
    ...mapMutations('app', ['setCircuitOpened']),
    ...mapActions('users', [
      'fetchAllUsers'
    ]),
    getNotificationsKeys() {
      const keys = Object.keys(this.notifications);
      return keys.find(
        (key) => this.notifications[key].isNotificationDisplayed
      );
    },
    displayNotification() {
      this.displayNotificationTimeOut = setTimeout(() => {
        const displayedNotificationKey = this.getNotificationsKeys(this.notifications);
        const value = {};
        value[displayedNotificationKey] = {
          ...this.notifications[displayedNotificationKey],
          isNotificationDisplayed: false
        };
        this.setNotificationsInfo(value);
      }, 5000);
    }
  }
};
</script>

<style>
body {
  margin: 0;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  touch-action: none;
  overflow: hidden;
  user-select: none;
}

:root {
  --transparent: #e5e5e5;
  --light: #fff;
  --dark: #14131b;
  --dark--10: rgb(20 19 27 / 10%);
  --dark--20: rgb(20 19 27 / 20%);
  --neutral-400: #999b9e;
  --neutral-700: #595f63;
  --neutral-900: #323a3e;
  --caution-50: #fff0f1;
  --caution-200: #feb3b9;
  --caution-400: #f2737f;
  --caution-600: #c34652;
}
</style>
