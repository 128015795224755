export const validateHexChar = (c) => {
  if (c.length < 0 || c.length > 1) {
    return '0';
  }
  if (isNaN(c)) {
    const validChars = ['A', 'B', 'C', 'D', 'E', 'F'];
    if (validChars.includes(c.toUpperCase())) {
      return c.toUpperCase();
    } else {
      return '0';
    }
  } else {
    return c;
  }
};

export const cleanHexa = (hexa) => {
  if (!hexa || hexa.length < 3) {
    return '#000000FF';
  }
  let _cleaned = hexa.toUpperCase();
  if (_cleaned.startsWith('#')) {
    _cleaned = _cleaned.substr(1);
  }
  if (_cleaned.length < 3) {
    return '#000000FF';
  }
  let r, g, b, a;
  r = g = b = '00';
  a = 'FF';
  if (_cleaned.length === 3) {
    const rgb = _cleaned
      .split('')
      .map((r) => `0${r}`)
      .map((r) => validateHexChar(r));
    r = rgb[0];
    g = rgb[1];
    b = rgb[2];
  } else if (_cleaned.length >= 6) {
    const rgb = _cleaned.split('').map((r) => validateHexChar(r));
    r = rgb[0] + rgb[1];
    g = rgb[2] + rgb[3];
    b = rgb[4] + rgb[5];
    if (rgb.length === 8) {
      a = rgb[6] + rgb[7];
    }
  }
  return `#${r}${g}${b}${a}`;
};

export const parseHexa = (hexa) => {
  const hexaArr = cleanHexa(hexa).substr(1).split('');
  const h = hexaArr[0] + hexaArr[1];
  const e = hexaArr[2] + hexaArr[3];
  const x = hexaArr[4] + hexaArr[5];
  const ha = hexaArr[6] + hexaArr[7];
  const r = parseInt(h, 16);
  const g = parseInt(e, 16);
  const b = parseInt(x, 16);
  const a = parseInt(ha, 16) / 255;
  return {
    hexa: `#${h}${e}${x}${ha}`,
    r,
    g,
    b,
    a
  };
};
export const calculateLineColor = (linePos, lineWidth) => {
  const perc = linePos / lineWidth;
  const value = perc % (1 / 6);
  const colorPerc = value / (1 / 6);
  let percRed = 1;
  let percGreen = 1;
  let percBlue = 1;
  if (perc < 1 / 6) {
    percGreen = colorPerc;
    percBlue = 0;
  } else if (perc < 2 / 6) {
    percRed = 1 - colorPerc;
    percBlue = 0;
  } else if (perc < 3 / 6) {
    percRed = 0;
    percBlue = colorPerc;
  } else if (perc < 4 / 6) {
    percRed = 0;
    percGreen = 1 - colorPerc;
  } else if (perc < 5 / 6) {
    percRed = colorPerc;
    percGreen = 0;
  } else {
    percGreen = 0;
    percBlue = 1 - colorPerc;
  }
  return {
    r: Math.min(255, Math.max(0, Math.round(percRed * 255))),
    g: Math.min(255, Math.max(0, Math.round(percGreen * 255))),
    b: Math.min(255, Math.max(0, Math.round(percBlue * 255)))
  };
};
export const componentToHex = (c) => {
  const hex = c.toString(16).toUpperCase();
  return hex.length === 1 ? `0${hex}` : hex;
};
export const validate = (colorLazy) => {
  let r = Number(colorLazy.r);
  let g = Number(colorLazy.g);
  let b = Number(colorLazy.b);
  let a = Number(colorLazy.a);
  if (isNaN(r) || r === null) {
    r = 0;
  }
  if (isNaN(g) || g === null) {
    g = 0;
  }
  if (isNaN(b) || b === null) {
    b = 0;
  }
  if (isNaN(a) || a === null) {
    a = 1;
  }
  r = Math.min(255, Math.max(0, r));
  g = Math.min(255, Math.max(0, g));
  b = Math.min(255, Math.max(0, b));
  a = Math.min(1, Math.max(0, a));
  return {
    r,
    g,
    b,
    a,
    hexa: `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}${componentToHex(Math.round(a * 255))}`
  };
};
export const normalizeColorData = (colorData) => {
  const normalized = {
    r: null,
    g: null,
    b: null,
    variable: null
  };
  const { r, g, b } = colorData;
  if (b > g && b > r) {
    normalized.b = 1;
  } else if (g > r && g > b) {
    normalized.g = 1;
  } else {
    normalized.r = 1;
  }
  if (r < g && r < b) {
    normalized.r = 0;
  } else if (g < r && g < b) {
    normalized.g = 0;
  } else {
    normalized.b = 0;
  }
  Array.from('rgb').forEach((col) => {
    if (normalized[col] === null) {
      normalized.variable = col;
      normalized[col] = colorData[col] / 255;
    }
  });
  return normalized;
};

