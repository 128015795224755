const mutations = {
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken;
  },
  setSettings(state, settings) {
    state.settings = settings;
  }
};

export default mutations;
