<template>
  <dialog-box :dialog-box-specific-style="dialogBoxStyle">
    <span class="CancelElementRelationsEditor-header">Proceed without saving changes?
    </span>
    <div class="CancelElementRelationsEditor-body">
      <span class="CancelElementRelationsEditor-message">
        Closing this window without saving your new related element(s)
        will delete the changes you've made
      </span>
    </div>

    <div class="CancelElementRelationsEditor-actions">
      <design-system-button
        :is-dark="true"
        button-type="secondary"
        size="small"
        label="Cancel"
        @click="closeDialog()"
      />
      <design-system-button
        style="height: 40px; margin-left: 10px;"
        size="large"
        button-type="destructive"
        label="Discard changes"
        @click="discardChanges()"
      />
    </div>
  </dialog-box>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
  name: 'CancelElementRelationsEditor',
  data() {
    return {
      dialogBoxStyle: 'display: flex; flex-direction: column; position: absolute; width: 480px; height: 212px; left: 35%; top: 35%;'
    };
  },
  computed: {
    ...mapState('board', [
      'dialogBoxFlags'
    ])
  },
  methods: {
    ...mapMutations('board', [
      'setDialogBoxFlags'
    ]),
    ...mapActions('editor', [
      'closeEditor'
    ]),
    closeDialog() {
      this.setDialogBoxFlags({...this.dialogBoxFlags, isCancelElementRelationsEditing: false}) ;
    },
    discardChanges() {
      this.closeDialog();
      this.closeEditor();
    }
  }
};
</script>

<style src="./CancelElementRelationsEditor.css">
</style>

