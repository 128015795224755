<template>
  <div class="formulaGroupContainer">
    <div
      :id="`formula-${boardElementId}`"
      class="result-container"
      :style="{ ...outline }"
      data-target="board-element"
    >
      <div
        v-if="result === null"
        style="display: flex;"
        data-target="board-element"
      >
        <icon-loader
          name="Function"
          color="#9B9B9B"
          style="margin-left: 5px;margin-top: 1px;"
          size="xl"
          data-target="board-element"
        />
        <icon-loader
          v-if="error"
          name="Dot"
          color="#9B9B9B"
          data-target="board-element"
          style="align-self: self-end; transform: translate(-5px, -2px);"
        />
      </div>
      <div
        data-target="board-element"
        :style="'margin: auto'"
      >
        {{ result }}
      </div>
    </div>
    <formula-widget-editor
      v-show="isEdition"
      :attributes="attributes"
      :formula-editor-style="editorStyle"
      :dataset-element-id="datasetElementId"
      :board-element-id="boardElementId"
      :result="result"
      :error="error"
      :set-error="setError"
      :set-result="setResult"
      :done-action="doneAction"
    />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
import FormulaWidgetEditor from 'BOARD/components/widgets/formula/FormulaWidgetEditor.vue';
import {
  FORMULA_TOP_EDITOR_EQUATION,
  MAX_ZL_SCALE_EDITOR
} from 'GLOBALS/constants';

export default {
  name: 'FormulaWidget',
  components: { FormulaWidgetEditor },
  props: {
    fields: {
      type: Array,
      required: false,
      default: () => []
    },
    attributes: {
      type: Object,
      required: false,
      default: () => ({
        expression: '',
        name: ''
      })
    },
    hasOutline: {
      type: Boolean,
      default: false
    },
    outlineColor: {
      type: String,
      required: true
    },
    datasetElementId: {
      type: String,
      required: true
    },
    boardElementId: {
      type: String,
      required: true
    },
    isEdition: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      result: null,
      error: false,
      expression: this.attributes.expression,
      name: this.attributes.name
    };
  },
  computed: {
    ...mapGetters('users', ['getUserById']),
    ...mapState('board', ['formulaInEditionBoardElementId', 'zoomLevel']),
    ...mapState({ boardId: (state) => state.board.id }),
    isEditorShown() {
      return this.formulaInEditionBoardElementId === this.boardElementId;
    },
    outline() {
      if (this.hasOutline) {
        return {
          outline: `${Math.max(2, 2 * (1 / this.zoomLevel))}px ${
            this.outlineColor
          } solid`
        };
      }
      return { outline: '2px solid #cacaca' };
    },
    editorStyle() {
      let scale = 1 / this.zoomLevel;
      const { NUMERATOR, EXPONENT, OFFSET } = FORMULA_TOP_EDITOR_EQUATION;
      let top = NUMERATOR / Math.pow(this.zoomLevel, EXPONENT) + OFFSET;
      if (this.zoomLevel < MAX_ZL_SCALE_EDITOR) {
        scale =
          1 / MAX_ZL_SCALE_EDITOR - (MAX_ZL_SCALE_EDITOR - this.zoomLevel);
        top = 411;
      }
      return `transform: scale(${scale}); top:${top}px`;
    }
  },
  methods: {
    ...mapMutations('board', ['setFormulaInEditionBoardElementId']),
    ...mapActions('board', ['removeFormulaFromSelection', 'removeSelection']),
    setResult(value) {
      this.result = value;
    },
    setError(value) {
      this.error = value;
    },
    doneAction() {
      this.removeFormulaFromSelection(this.boardElementId);
      this.setFormulaInEditionBoardElementId(null);
      this.removeSelection([this.boardElementId]);
    }
  }
};
</script>

<style src="./FormulaWidget.css" />
