<template>
  <div>
    <div class="absolute w-full h-full top-0 left-0 opacity-90 bg-zinc-100" />
    <slot />
    <div
      role="status"
      class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
    >
      <Spinner
        :size="size"
        :color="spinnerColor"
      />
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<script>
import Spinner from '../Spinner/Spinner.vue';

export default {
  name: 'LoadingPageLayer',
  components: { Spinner },
  props: {
    size: {
      type: String,
      default: 'small'
    },
    spinnerColor: {
      type: String,
      default: 'gray'
    }
  }
};
</script>
