<template>
  <div
    ref="widget"
    data-target="board-element"
    class="ListWidget level-container level-2"
    :style="{ padding: 43 * (props.scale || 1) + 'px' }"
  >
    <div
      v-show="isHighlighted"
      data-target="board-element"
      class="ListWidget-highlighted"
    />
    <div
      data-target="board-element"
      class="ListWidget-indicator"
      :style="rightIndicatorWidth"
    />
    <div
      data-target="board-element"
      class="ListWidget-body"
      :style="containerStyle"
    >
      <div
        data-target="board-element"
        class="ListWidget-body-header"
      >
        <div
          data-target="board-element"
          class="ListWidget-body-header-info"
        >
          <icon-loader
            data-target="board-element"
            name="List"
            size="xl"
          />
          <span
            data-target="board-element"
            class="ListWidget-body-header-info-title"
          >
            {{ title }}
          </span>
          <span
            data-target="board-element"
            class="ListWidget-body-header-info-subtext"
          >
            •
          </span>
          <span
            data-target="board-element"
            class="ListWidget-body-header-info-subtext"
          >
            {{ totalListLength }}
          </span>
        </div>
        <iob-button
          data-target="board-element"
          type="ghost"
          color="primary"
          left-icon="Plus"
          :show-left-icon="true"
          label="New"
          size="large"
          button-style="height:75px; font-size: 32px; padding: 0 20px;"
          custom-button-text="display: block"
          left-icon-size="2xl"
          @click="addNewDatasetElement"
        />
      </div>
      <div
        v-if="!isResizing && !isLoading"
        data-target="board-element"
        class="ListWidget-body-content"
      >
        <iob-list-item
          v-for="(item, index) in displayedField"
          :key="index"
          data-target="board-element"
          :data-list-item-id="item.id"
          :left-items="item.leftItems"
          :right-items="item.rightItems"
          :hide-right-section="hideRightSection"
          size="medium"
          :style="`width: ${listItemWidth}; padding: 0;`"
          :has-details="false"
          icon-size="default"
          @click="handleListItemClick"
        />
      </div>
      <div
        v-else
        data-target="board-element"
        class="ListWidget-body-content"
      >
        <ghost-list-line
          v-for="(item, index) in ghostLinesLength"
          :key="index"
          :style="`height: 88px; width: ${listItemWidth}; display: flex`"
          class="ListWidget-body-content-ghostLines"
        />
      </div>
    </div>
    <div
      data-target="board-element"
      class="ListWidget-footer"
      :style="{
        ...containerStyle,
        bottom: `${38.8 * props.scale}px`,
        width: listItemWidth,
      }"
    >
      <span
        data-target="board-element"
        class="ListWidget-footer-results"
      >
        {{ currentPageText }}
      </span>
      <div
        data-target="board-element"
        class="ListWidget-footer-pages"
      >
        <iob-action-button
          data-target="board-element"
          type="ghost"
          color="secondary"
          icon-name="ChevronsLeft"
          :disabled="currentPage === 1"
          class="ListWidget-footer-pages-first"
          @click="goToPage(1)"
        />
        <iob-action-button
          data-target="board-element"
          type="ghost"
          color="secondary"
          icon-name="ChevronLeft"
          :disabled="currentPage === 1"
          class="ListWidget-footer-pages-previous"
          @click="previous"
        />
        <button
          v-for="index in start > end ? [] : range(start, end)"
          :key="index"
          data-target="board-element"
          class="ListWidget-footer-pages-button"
          :class="{ 'ListWidget-footer-pages-button--selected': currentPage === index }"
          :disabled="numberOfPages === 0"
          @click="goToPage(index)"
        >
          {{ index }}
        </button>
        <span
          v-if="hasReachedEnd"
          data-target="board-element"
        >...</span>
        <button
          data-target="board-element"
          class="ListWidget-footer-pages-button"
          :class="{
            'ListWidget-footer-pages-button--selected': currentPage === numberOfPages,
          }"
          :disabled="numberOfPages <= 1"
          @click="goToPage(numberOfPages)"
        >
          {{ numberOfPages }}
        </button>
        <iob-action-button
          data-target="board-element"
          type="ghost"
          color="secondary"
          icon-name="ChevronRight"
          :disabled="currentPage >= numberOfPages"
          class="ListWidget-footer-pages-next"
          @click="next"
        />
        <iob-action-button
          data-target="board-element"
          type="ghost"
          color="secondary"
          icon-name="ChevronsRight"
          :disabled="currentPage >= numberOfPages"
          class="ListWidget-footer-pages-last"
          @click="last"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, watch, ref } from 'vue';
import { range } from 'lodash';
import GhostListLine from './ghostListLine/GhostListLine.vue';
import { BOARDGET_LIST_BREAKPOINT } from '../../../../globals/constants';
import { useStore } from 'vuex';

const props = defineProps({
  list: {
    type: Array,
    required: true
  },
  filterId: {
    type: String,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  scale: {
    type: Number,
    default: 1
  },
  rowsPerPage: {
    type: Number,
    default: 5
  },
  width: {
    type: Number,
    default: 1
  },
  height: {
    type: Number,
    default: 1
  },
  canBeHighlighted: {
    type: Boolean,
    default: true
  },
  elementId: {
    type: String,
    required: true
  },
  isResizing: {
    type: Boolean,
    default: false
  },
  previousRowsPerPage: {
    type: Number,
    default: 5
  }
});

const store = useStore();
const emit = defineEmits(['update']);
const filters  = computed(() => store.state.board.filters);
const isLoading = computed(() => filters.value[props.filterId].isLoading);
const getCurrentPage = computed(() => filters.value[props.filterId].currentPage);
const currentPage = ref(1);
const totalListLength = computed(() => filters.value[props.filterId].totalCount);
const ghostLinesLength = ref(props.rowsPerPage);

const displayedField = computed(() =>
  props.list.map((item) => {
    const leftItems = [
      {
        fieldType: 'icon-with-label',
        value: {
          text: item.type.text,
          iconColor: item.type.color,
          iconName: item.type.iconName
        }
      },
      {
        fieldType: 'title',
        value: item.friendlyId
      },
      {
        fieldType: 'text',
        value: item.title
      }
    ];
    let rightItems = [];
    if (item.status) {
      rightItems = [
        {
          fieldType: 'status',
          value: item.status
        },
        ...(item.horizon ? [{
          fieldType: 'horizon',
          value: item.horizon
        }] : []),
        ...(item['due-date'] ? [{
          fieldType: 'dueDate',
          value: item['due-date']
        }] : []),
        {
          fieldType: 'user',
          value: item.user
        }
      ];
    } else {
      rightItems = [
        {
          fieldType: 'title',
          value: item.initialValue ? item.initialValue + item.unit : ''
        },
        {
          fieldType: 'title',
          value: item.targetValue ? item.targetValue + item.unit : ''
        },
        {
          fieldType: 'title',
          value: item.bestInClass
        }
      ];
    }
    return {
      leftItems,
      rightItems,
      id: item.id
    };
  })
);

const numberOfPages = computed(() => Math.ceil(totalListLength.value / props.rowsPerPage));
const start = computed(() => {
  if (numberOfPages.value <= 5) {
    return 1;
  }

  if (currentPage.value <= 3) {
    return 1;
  }

  if (currentPage.value >= numberOfPages.value - 3) {
    return numberOfPages.value - 5;
  }

  return currentPage.value - 2;
});
const end = computed(() => {
  if (numberOfPages.value <= 5) {
    return numberOfPages.value;
  }

  if (currentPage.value <= 3) {
    return 5;
  }

  if (currentPage.value >= numberOfPages.value - 3) {
    return numberOfPages.value;
  }

  return currentPage.value + 2;
});
const rightIndicatorWidth = computed(() => ({ width: `${8 * props.scale}px` }));

/**43 is the padding and 30 is a fixed offset */
const boardgetListPadding = 43;
const boardgetListOffset = 30;
const listItemWidth = computed(
  () =>
    (props.width - boardgetListPadding * (props.scale || 1)) / props.scale -
    boardgetListOffset
);

const hideRightSection = ref(props.width < BOARDGET_LIST_BREAKPOINT * props.scale);

watch(getCurrentPage, (value) => {
  currentPage.value = value;
});

watch(
  () => props.isResizing,
  async (value, oldValue) => {
    if (value) {
      ghostLinesLength.value = Math.min(props.rowsPerPage, totalListLength.value);
    }
    if (oldValue === true && value === false) {
      if (props.previousRowsPerPage !== props.rowsPerPage) {
        await store.dispatch('board/fetchFilterResults', {
          id: props.filterId,
          pageSize: props.rowsPerPage
        });
      }
    }
    if (props.width < BOARDGET_LIST_BREAKPOINT * props.scale) {
      hideRightSection.value = true;
      return;
    }
    hideRightSection.value = false;
  },
  { immediate: true }
);

const currentPageText = computed(() => {
  const start =
    numberOfPages.value > 0 ? (currentPage.value - 1) * props.rowsPerPage + 1 : 0;
  const end = Math.min(currentPage.value * props.rowsPerPage, totalListLength.value);
  return `${start} - ${end} of ${totalListLength.value}`;
});

const hasReachedEnd = computed(
  () => numberOfPages.value > 5 && currentPage.value < numberOfPages.value - 3
);

const isHighlighted = computed(() => props.canBeHighlighted);

const containerStyle = computed(() => ({
  transform: `scale(${props.scale})`,
  transformOrigin: 'top left',
  width: 'max-content',
  maxHeight: `${90 / props.scale}%`,
  overFlowY: 'clip'
}));
const handleListItemClick = () => {
  store.commit('editor/setFilterId', props.filterId);
  emit('update', { currentPage: currentPage.value });
};

const addNewDatasetElement = async () => {
  const elementsFilter = await store.dispatch('board/getFilterDataType', {
    filterId: props.filterId
  });
  const type = elementsFilter.attributeSearches?.type?.[0] ?? null;
  const relatedElementId = elementsFilter.relatedElementIds[0];
  const requestBody = {
    typeId: elementsFilter.dataTypeId,
    attributes: {
      owner: store.state.users.currentUser.id,
      type
    }
  };
  await store.dispatch('board/createDatasetElementInBoardget', {
    requestBody,
    filterId: props.filterId,
    ...(relatedElementId ? { relatedElementId } : {})
  });
  await store.dispatch('board/fetchFilterResults', { id: props.filterId, pageSize: props.rowsPerPage });
  currentPage.value = 1;
};

const goToPage = async (page) => {
  if (page < 1 || page > numberOfPages.value) {
    return;
  }

  currentPage.value = page;
  const startIndex = (page - 1) * props.rowsPerPage;
  const endValue = totalListLength.value - startIndex;

  const pageSize = endValue < props.rowsPerPage ? endValue : props.rowsPerPage;
  await store.dispatch('board/fetchFilterResults', {
    id: props.filterId,
    pageSize,
    page
  });
};

const previous = async () => {
  if (currentPage.value > 1) {
    currentPage.value--;
    await goToPage(currentPage.value);
  }
};
const next = async () => {
  if (currentPage.value < numberOfPages.value) {
    currentPage.value++;
    await goToPage(currentPage.value);
  }
};

const last = async () => {
  currentPage.value = numberOfPages.value;
  await goToPage(currentPage.value);
};
</script>

<style lang="scss" src="./ListWidget.scss" scoped />
