import { boardElementsSizes, dataTypesIcons, DEFAULT_BOARD_ELEMENT_SIZE } from 'GLOBALS/constants';

export default {
  getTitleAttribute: (state) => (dataTypeId) =>
    state.dataTypes[dataTypeId].attributes.find(({originRef}) => originRef.endsWith('/vms/title')),
  getDataTypeIcon: (state) => (dataTypeId) => {
    const enumArr = state.dataTypes[dataTypeId]?.attributes.find((attr) => attr.name === 'type')?.enum;
    const dataTypeName = state.dataTypes[dataTypeId]?.name;
    if (enumArr) {
      return dataTypesIcons[dataTypeName]?.icons.children;
    } else {
      return dataTypesIcons[dataTypeName]?.icons.parent;
    }
  },
  getDataTypeColor: (state) => (dataTypeId) => {
    const dataTypeName = state.dataTypes[dataTypeId]?.name;
    return dataTypesIcons[dataTypeName]?.color;
  },
  getDataTypeName: (state) => (dataTypeId, element) => element?.attributes.type ? element.attributes.type : state.dataTypes[dataTypeId].name,
  getDataTypeTypeName: (state) => (dataTypeId) => state.dataTypes[dataTypeId].namespace?.concat(`/${state.dataTypes[dataTypeId].name}`),
  getLevelName: (state) => (levelId) => state.levels.find((level) => level.id === levelId)?.attributes?.['level-name'] || 'None',
  getDataTypeByName: (state) => (dataTypeName) => Object.values(state.dataTypes).find((datatype) => datatype.name === dataTypeName),
  getViewTypeSize: (state) => (viewTypeId) => {
    const viewType = state.viewTypes && state.viewTypes[viewTypeId];
    if (!viewType) {
      return DEFAULT_BOARD_ELEMENT_SIZE;
    }
    if (viewType.dataTypeName === 'indicator') {
      return boardElementsSizes[viewType.dataTypeName];
    }
    return state.viewTypes?.[viewTypeId]?.toolType
      ? boardElementsSizes[state.viewTypes[viewTypeId].toolType] || DEFAULT_BOARD_ELEMENT_SIZE
      : DEFAULT_BOARD_ELEMENT_SIZE;
  },
  getLevelsTree: (state) => state.levelsTree || []
};
