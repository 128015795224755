<template>
  <div
    data-modal-backdrop="static"
    tabindex="-1"
    aria-hidden="true"
    class="fixed top-0 left-0 right-0 w-full h-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 max-h-full bg-gray-700 bg-opacity-50"
  >
    <div
      class="DialogBox-content"
      :class="!noPadding ? 'DialogBox-padding' : ''"
      :style="dialogBoxSpecificStyle"
    >
      <icon-loader
        v-if="hasCloseIcon"
        name="X"
        :stroke="iconStroke"
        class="DialogBox-closeIcon"
        @icon-click="() => $emit('close-icon-click')"
      />
      <slot />
      <div
        v-if="hasSubmitGroup"
        class="button-group"
      >
        <custom-button
          label="Cancel"
          shape="rounded"
          mode="light"
          outlined
          @button-click="() => $emit('cancel-click')"
        />
        <custom-button
          label="Save"
          class="ml-2"
          shape="rounded"
          :mode="disabled ? 'gray' : 'dark'"
          :disabled="disabled"
          :icon-name="submitted && disabled ? 'Spinner' : ''"
          color="gray"
          @button-click="() => $emit('submit-click')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import IconLoader from '../IconLoader/IconLoader.vue';
import CustomButton from '../CustomButton/CustomButton.vue';

export default {
  name: 'DialogBox',
  components: { IconLoader, CustomButton },
  props: {
    dialogBoxSpecificStyle: {
      type: String,
      default: ''
    },
    hasCloseIcon: {
      type: Boolean,
      default: false
    },
    iconStroke: {
      type: String,
      default: 'black'
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    hasSubmitGroup: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    submitted: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close-icon-click', 'cancel-click', 'submit-click']
};
</script>

<style scoped src="./DialogBox.css" />
