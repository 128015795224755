<template>
  <div>
    <div
      v-for="(knob, index) of knobs"
      :key="index"
      :class="getKnobClasses(knob)"
      :data-target="`knob-${knob}`"
      :style="knobAdditionalStyle[knob]"
    />
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps({
  zoomLevel: {
    type: Number,
    required: true
  },
  feedbackColor: {
    type: String,
    required: true
  },
  cornerKnobs: {
    type: Array,
    default: () => []
  },
  middleKnobs: {
    type: Array,
    default: () => []
  }
});

const knobs = computed(() => props.cornerKnobs.concat(props.middleKnobs));

const getKnobClasses = (knob) => ({
  'knob': true,
  'knob-rounded': true,
  [`knob-${knob}`]: true
});
const knobAdditionalStyle = computed(() => knobs.value
  .reduce((acc, knob) => ({...acc, [knob]: getknobAdditionalStyle(knob, props.zoomLevel)}), {}));
const getknobAdditionalStyle = (knob, zoomLevel) => {
  const size = Math.max(Math.trunc(10 / zoomLevel), 10);
  const halfSize = Math.trunc(size / 2);
  const cornerKnobOffset = `calc(100% - ${halfSize}px)`;
  const style = {
    outline: `${Math.max(2, 2 * (1 / zoomLevel))}px solid ${String(props.feedbackColor)}`
  };
  const middleKnobsWidth = Math.max(Math.trunc(28 / zoomLevel), 28);
  const middleKnobsHeight = Math.max(Math.trunc(8 / zoomLevel), 8);
  const middleKnobsHalfHeight = Math.trunc(middleKnobsHeight / 2);
  const middleKnobsHalfWidth = Math.trunc(middleKnobsWidth / 2);
  const middleKnobsHorizontalOffset = `calc(50% - ${middleKnobsHalfWidth}px)`;
  const middleKnobsVerticalOffset =  `calc(100% - ${middleKnobsHalfHeight}px)`;
  const knobToStyleMap = {
    tl: {
      height: size,
      width: size,
      top: -halfSize,
      left: -halfSize
    },
    tr: {
      height: size,
      width: size,
      top: -halfSize,
      left: cornerKnobOffset
    },
    bl: {
      height: size,
      width: size,
      top: cornerKnobOffset,
      left: -halfSize
    },
    br: {
      height: size,
      width: size,
      top: cornerKnobOffset,
      left: cornerKnobOffset
    },
    tm: {
      height: middleKnobsHeight,
      width: middleKnobsWidth,
      top: -middleKnobsHalfHeight,
      left: middleKnobsHorizontalOffset
    },
    lm: {
      height: middleKnobsWidth,
      width: middleKnobsHeight,
      top: middleKnobsHorizontalOffset,
      left: -middleKnobsHalfHeight
    },
    bm: {
      height: middleKnobsHeight,
      width: middleKnobsWidth,
      top: middleKnobsVerticalOffset,
      left: middleKnobsHorizontalOffset
    },
    rm: {
      height: middleKnobsWidth,
      width: middleKnobsHeight,
      top: middleKnobsHorizontalOffset,
      left: middleKnobsVerticalOffset
    }
  };
  const additionalStyle = knobToStyleMap[knob] || {};
  return {
    ...style,
    ...additionalStyle
  };
};

</script>

<style scoped src="./ResizeKnobs.scss" lang="scss" />
