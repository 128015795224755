<template>
  <div
    class="badge flex"
    :style="badgeStyle"
  >
    <icon-loader
      v-if="iconName"
      :name="iconName"
      :size="iconSize"
      :stroke="iconStroke"
      :color="iconColor"
      style="margin-right: 6px"
    />
    <div style="margin: auto">
      {{ label }}
    </div>
  </div>
</template>

<script>
import IconLoader from '../IconLoader/IconLoader.vue';

export default {
  name: 'BadgeComponent',
  components: { IconLoader },
  props: {
    label: {
      type: String,
      required: true
    },
    iconName: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    iconSize: {
      type: String,
      default: 'xsmall'
    },
    iconColor: {
      type: String,
      default: 'grey'
    },
    iconStroke: {
      type: String,
      default: 'black'
    }
  },
  data: () => ({
    styleMap: {
      grey: {
        color: 'rgba(55, 65, 81, 1)',
        backgroundColor: 'rgba(243, 244, 246, 1)'
      },
      green: {
        color: 'rgba(3, 84, 63, 1)',
        backgroundColor: 'rgba(222, 247, 236, 1)'
      }
    }
  }),
  computed: {
    badgeStyle() {
      return this.styleMap[this.color];
    }
  }
};
</script>

<style scoped src="./Badge.css" />
