<template>
  <div 
    :data-test-id="`IobColorPickerElement-${state}-${size}-${color}-${type}`"
    class="IobColorPickerElement"
  >
    <img
      v-if="opacity === 0"
      :src="transparentImage"
      :class="`IobColorPickerElement--Transparent--${state}`"
      :style="`width: ${sizeMap[size]}; height: ${sizeMap[size]}`"
      alt="transparent"
    >

    <button 
      v-else-if="['Square', 'Circle', 'Border' ].includes(type)"
      :class="classList"
      :style="styleObject"
    >
      <svg
        v-if="type === 'Border'"
        xmlns="http://www.w3.org/2000/svg"
        :width="sizeMap[size]"
        :height="sizeMap[size]"
        viewBox="0 0 24 24"
        fill="none"
      >
        <mask
          id="path-1-inside-1_20189_14800"
          fill="white"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4 0C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0H4ZM5.5 4.5C4.94772 4.5 4.5 4.94772 4.5 5.5V18.5C4.5 19.0523 4.94772 19.5 5.5 19.5H18.5C19.0523 19.5 19.5 19.0523 19.5 18.5V5.5C19.5 4.94772 19.0523 4.5 18.5 4.5H5.5Z"
          />
        </mask>
        <path
          d="M1 4C1 2.34315 2.34315 1 4 1V-1C1.23858 -1 -1 1.23858 -1 4H1ZM1 20V4H-1V20H1ZM4 23C2.34315 23 1 21.6569 1 20H-1C-1 22.7614 1.23858 25 4 25V23ZM20 23H4V25H20V23ZM23 20C23 21.6569 21.6569 23 20 23V25C22.7614 25 25 22.7614 25 20H23ZM23 4V20H25V4H23ZM20 1C21.6569 1 23 2.34315 23 4H25C25 1.23858 22.7614 -1 20 -1V1ZM4 1H20V-1H4V1ZM5.5 5.5V3.5C4.39543 3.5 3.5 4.39543 3.5 5.5H5.5ZM5.5 18.5V5.5H3.5V18.5H5.5ZM5.5 18.5H3.5C3.5 19.6046 4.39543 20.5 5.5 20.5V18.5ZM18.5 18.5H5.5V20.5H18.5V18.5ZM18.5 18.5V20.5C19.6046 20.5 20.5 19.6046 20.5 18.5H18.5ZM18.5 5.5V18.5H20.5V5.5H18.5ZM18.5 5.5H20.5C20.5 4.39543 19.6046 3.5 18.5 3.5V5.5ZM5.5 5.5H18.5V3.5H5.5V5.5Z"
          fill="#14131B"
          fill-opacity="0.16"
          mask="url(#path-1-inside-1_20189_14800)"
        />
      </svg>
    </button>
    <IconLoader
      v-if="type === 'Icon'"
      :style="styleObject"
      :class="classList"
      :name="iconName"
      :size="size"
      :color="color"
    />
  </div>
</template>

<script>
import IconLoader from '../../IconLoader/IconLoader.vue';

export default {
  name: 'IobColorPickerElement',
  components: { IconLoader },
  props: {
    state: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: 'default',
      validator: (value) => ['default', 'small', 'xsmall'].includes(value),
    },
    color: {
      type: String,
      default: 'blue-500',
    },
    opacity: {
      type: Number,
      default: 1,
      validator: (value) => value >= 0 && value <= 1,
    },
    iconName: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'Square',
      validator: (value) => ['Square', 'Circle', 'Icon', 'Border'].includes(value),
    },
  },
  data() {
    return {
      sizeMap: {
        default: '32px',
        small: '24px',
        xsmall: '16px',
      },
      transparentImage: require('../../../assets/img/background-color-square.jpg'),
    };
  },
  computed: {
    classList() {
      return `IobColorPickerElement--${this.type}--${this.state}`;
    },
    styleObject() {
      const size = this.sizeMap[this.size];
      return {
        width: `var(--app-color-picker-element-size-${this.size}, ${size})`,
        height: `var(--app-color-picker-element-size-${this.size}, ${size})`,
        backgroundColor: (this.type === 'Circle' || this.type === 'Square') ? this.color : 'transparent',
        opacity: this.opacity,
        stroke: this.color
      };
    },
  },
};
</script>
<style lang="scss" scoped src="./IobColorPickerElement.scss" />
<style
  lang="scss"
  src="iobeya-design-tokens/scss/app/iobeya-board-element-color.scss"
/>
