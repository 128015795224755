import {
  POWERBI_URL_PATTERNS,
  IFRAME_PATTERN,
  URL_PATTERN
} from 'GLOBALS/constants.js';

export function getUrlFromEmbeddedCode(embeddedCode) {
  const parser = new DOMParser();
  const parsedIframe = parser.parseFromString(embeddedCode, 'text/html');
  const iFrame = parsedIframe.getElementsByTagName('iframe');
  if (iFrame.length) {
    return iFrame[0].getAttribute('src');
  }
  return null;
}
export function encodeURL(url) {
  const htmlSanitizationMap = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    '\'': '&#x27;'
  };
  return (url && typeof url === 'string') ? url.replace(/(&)|(<)|(>)|(")|(')/ig, (s) => htmlSanitizationMap[s]) : '';
}

export function unsanitizeUrl(url) {
  const htmlUnsanitizationMap = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#x27;': '\''
  };
  return (url && typeof url === 'string') ? url.replace(/(&amp;)|(&lt;)|(&gt;)|(&quot;)|(&#x27;)/ig, (s) => htmlUnsanitizationMap[s]) : '';
}

export function sanitizeUrl(url) {
  return (url && typeof url === 'string') ? encodeURL(unsanitizeUrl(url.trim())) : '';
}

/**
 * Extracts the URL from an embedded iframe code.
 *
 * @param {string} embed The embedded iframe code as a string.
 * @returns {{url: string, type: string}} An object containing the `url` and `type` of embed content, or empty string if input is invalid.
 */
export const parseEmbed = (embed) => {
  const embedData = {
    url: '',
    type: ''
  };

  const url = IFRAME_PATTERN.test(embed) ? getUrlFromEmbeddedCode(embed) : embed;

  if (URL_PATTERN.test(url)) {
    embedData.url = url;
    embedData.type = 'webcontent';
  }
  // Test if is powerBi report or dashboard or visual or app
  if (POWERBI_URL_PATTERNS.basePattern.test(embedData.url) || POWERBI_URL_PATTERNS.appPattern.test(embedData.url)) {
    embedData.type = 'powerbi';
  }

  return embedData;
};
