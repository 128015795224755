<template>
  <div id="outside-click-container">
    <slot />
  </div>
</template>

<script>

export default {
  name: 'OutsideClickListener',
  props: {
    keySupport: {
      type: String,
      default: '',
    },
  },
  emits: ['outside-click'],
  data() {
    return {
      keyTimeout: null,
      clickTimeout: null,
    };
  },
  mounted() {
    this.keyTimeout = setTimeout(()=> {
      document.addEventListener('keydown', this.handleCancelKey)
    }, 100);
    this.clickTimeout = setTimeout(() => {
      document.addEventListener('click', this.handleClickOutside);
    }, 100);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
    document.removeEventListener('keydown', this.handleCancelKey);
    clearTimeout(this.clickTimeout);
    clearTimeout(this.keyTimeout);
  },
  methods: {
    handleClickOutside(e) {
      if (!this.$el.contains(e.target)){
        this.$emit('outside-click', e);
      }
    },
    handleCancelKey(e) {
      if ((this.keySupport.length > 0 && e.key === this.keySupport)){
        this.$emit('outside-click', e);
      }
    }
  }
};
</script>

<style scoped src="./OutsideClickListener.css" />
