<template>
  <tr
    tabindex="0"
    class="IobTable-row level-container elevation-on-hover level-0"
    :class="loadingClass"
    @click="handleRowClick"
  >
    <IobTableCell
      v-for="(column, index) in columns"
      :key="index"
      v-bind="column"
      :is-loading="isLoading || column.data?.isLoading"
      :has-vertical-separator="hasVerticalSeparator && index !== columns.length - 1"
      :enable-greyed-out="enableGreyedOut"
      :search-data="searchData"
      :row-index="rowIndex"
      @onClickItem="(item) => handelCellItemClick(item, column.name)"
      @onClickId="(data) => emits('onClickId', data)"
    />
    <div class="IobTable-row-border" />
  </tr>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';
import IobTableCell from '../IobTableCell/IobTableCell.vue';

const props = defineProps({
  columns: {
    type: [Array, String],
    default: () => [],
  },
  hasVerticalSeparator: {
    type: Boolean,
    default: false
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  searchData: {
    type: String,
    default: ''
  },
  enableGreyedOut: {
    type: Boolean,
    default: false
  },
  rowIndex: {
    type: Number,
    default: 0
  }
});
const loadingClass = computed(() => ({ 'IobTable-row-loading': props.isLoading }));
const emits = defineEmits(['onClickCellItem', 'onClickId', 'onClickTableRow']);

const handelCellItemClick = (item, columnName) => {
  emits('onClickCellItem', { item, columnName });
};

const handleRowClick = () => {
  emits('onClickTableRow');
}
</script>

<style lang="scss" scoped>
.IobTable-row {
    height: var(--app-table-row-sizing-height);
    border-radius: var(--app-table-row-radius-default);
    position: relative;
    cursor: pointer;

    &-loading {
        pointer-events: none;
    }

    &:hover,
    &:focus,
    &:focus-visible {
        border: none;
        background: none;

        &:after {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            border-radius: var(--app-table-row-radius-default);
            border: 1px solid var(--app-table-row-color-border-default);
            background-color: var(--elevation-color-background);
            border-width: var(--elevation-size-border-width);
            border-style: solid;
            border-color: var(--elevation-color-border);
            box-shadow: var(--elevation-shadow-3-x) var(--elevation-shadow-3-y) var(--elevation-shadow-3-blur) var(--elevation-shadow-3-spread) var(--elevation-shadow-3-color),
                var(--elevation-shadow-2-x) var(--elevation-shadow-2-y) var(--elevation-shadow-2-blur) var(--elevation-shadow-2-spread) var(--elevation-shadow-2-color),
                var(--elevation-shadow-1-x) var(--elevation-shadow-1-y) var(--elevation-shadow-1-blur) var(--elevation-shadow-1-spread) var(--elevation-shadow-1-color);
        }

        +.IobTable-row:not(:focus-visible) {
            &:after {
                border-top: none;
            }
        }
    }

    &:after {
        position: absolute;
        content: "";
        top: 0;
        left: var(--app-table-row-padding-horizontal);
        right: var(--app-table-row-padding-horizontal);
        bottom: 0;
        margin: auto;
        border-top: 1px solid var(--app-table-row-color-border-default);
    }

    &:first-child:not(:hover)::after {
        border-top: none;
    }

    &:last-child {
        &:after {
            border-bottom: 1px solid var(--app-table-row-color-border-default);
        }
    }

    &-border {
        position: absolute;
        content: "";
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        margin: 0 auto;
        z-index: 1;
        border-radius: var(--app-table-row-radius-default);
        border: var(--focus-width) solid var(--focus-color-focus);
        display: none;
        pointer-events: none;
    }

    &:focus-visible {
        outline: none;

        &:after {
            border: none;
        }

        .IobTable-row-border {
            display: block;
        }
    }
}
</style>
