<template>
  <div :class="iobeyaLogoClasses">
    <logo-type
      v-if="showPicture"
      :class="logoTypeClass"
    />
    <logo-font
      v-if="showText"
      :class="logoFontClass"
      color="var(--logo-color-text)"
    />
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import LogoFont from 'iobeya-vue-icons/vueComponents/logo-font.vue';
import LogoType from 'iobeya-vue-icons/vueComponents/logo-type.vue';

const props = defineProps({
  size: { type: String, default: 'default' },
  direction: { type: String, default: 'horizontal' },
  showPicture: { type: Boolean, default: true },
  showText: { type: Boolean, default: true },
});

const gapClassMap = {
  xs: 'IobeyaLogo-gap-xs',
  default: 'IobeyaLogo-gap-default',
  xl: 'IobeyaLogo-gap-xl',
  '2xl': 'IobeyaLogo-gap-2xl',
  '3xl': 'IobeyaLogo-gap-3xl',
  '4xl': 'IobeyaLogo-gap-4xl',
};

const logoTypeSizesMap = {
  xs: 'IobeyaLogo-logoType-xs',
  default: 'IobeyaLogo-logoType-default',
  xl: 'IobeyaLogo-logoType-xl',
  '2xl': 'IobeyaLogo-logoType-2xl',
  '3xl': 'IobeyaLogo-logoType-3xl',
  '4xl': 'IobeyaLogo-logoType-4xl',
};

const logoFontSizesMap = {
  xs: 'IobeyaLogo-logoFont-xs',
  default: 'IobeyaLogo-logoFont-default',
  xl: 'IobeyaLogo-logoFont-xl',
  '2xl': 'IobeyaLogo-logoFont-2xl',
  '3xl': 'IobeyaLogo-logoFont-3xl',
  '4xl': 'IobeyaLogo-logoFont-4xl',
};

const iobeyaLogoClasses = computed(() => {
  let gapValue = gapClassMap[props.size];
  if (props.direction === 'horizontal') {
    return `IobeyaLogo ${gapValue}`;
  }
  return `IobeyaLogo IobeyaLogo-verticalDirection ${gapValue}`;
});

const logoTypeClass = computed(() => {
  return logoTypeSizesMap[props.size];
});

const logoFontClass = computed(() => {
  return logoFontSizesMap[props.size];
});
</script>

<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-logo.scss" />
<style lang="scss" scoped src="./IobeyaLogo.scss" />
