<template>
  <div class="WarningBox-container flex items-center">
    <slot />
    <icon-loader
      style="margin-left: 20px; cursor: pointer"
      name="X"
      :color="iconColor"
      @click="() => $emit('close-warning-click')"
    />
  </div>
</template>

<script>
import IconLoader from '../IconLoader/IconLoader.vue';

export default {
  name: 'WarningBox',
  components: { IconLoader },
  props: {
    iconColor: {
      type: String,
      default: 'rgba(142, 75, 16, 1)'
    }
  },
  emits: ['close-warning-click']
};
</script>

<style scoped src="./WarningBox.css" />
