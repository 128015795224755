import {CurrentDateOccurence} from 'SRC/utils/currentDateOccurence/CurrentDateOccurence';
import {DatesSorter} from './DatesSorter';
import {parseDateByFrequency} from 'BOARD/utils/timeSeriesUtils';
import {HORIZON_TYPES} from 'GLOBALS/constants';

/**
 * Extracts a subset of glidepath values starting from the closest occurences of current date, with a limit of GLIDEPATH_KEYS_LIMIT (e.g., 8).
 *
 * @param {Object} glidepathValues - An object where keys are dates and values are glidepath values.
 * @param {string} glidepathFrequency - The frequency of the glidepath (e.g., 'monthly', 'quarterly')
 * @param {number} GLIDEPATH_KEYS_LIMIT - The maximum number of glidepath (e.g., 8)
 * @param dateRange - The date range to filter the glidepath values
 * @returns {Object} The glidepath values from the current date
 */
export const getGlidepathValuesFromCurrentDate = (glidepathValues, glidepathFrequency, GLIDEPATH_KEYS_LIMIT, dateRange) => {
  const keys = Object.keys(glidepathValues);
  if (keys.length <= GLIDEPATH_KEYS_LIMIT) {
    return glidepathValues;
  }
  /**
   * Sorts the dates according to the the specified frequency.
   * @type {DatesSorter}
   */
  const datesSorterInstance = new DatesSorter(glidepathFrequency, keys);
  const orderedKeys = datesSorterInstance.generate();

  const {startIndex, endIndex} = getRangeIdexes(orderedKeys, glidepathFrequency, dateRange);

  const start = startIndex > 0 ? startIndex : 0;
  const end = endIndex > -1 && endIndex < GLIDEPATH_KEYS_LIMIT  ? endIndex + 1 : start + GLIDEPATH_KEYS_LIMIT;
  return orderedKeys.slice(start, end)
    .reduce((acc, el) => ({...acc, [el]: glidepathValues[el] || ''}), {});
};

const getRangeIdexes = (orderedKeys, frequency, dateRange) => {
  const startDate = dateRange?.startDate ?? new Date();
  const startUTCDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getUTCDate()));
  const currentStartDateOccurenceGenerator = new CurrentDateOccurence(frequency, startUTCDate);
  let endIndex = -1;
  if (dateRange?.endDate) {
    const endDate = dateRange.endDate;
    const endUTCDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getUTCDate()));
    const currentEndDateOccurenceGenerator = new CurrentDateOccurence(frequency, endUTCDate);
    const endKey = currentEndDateOccurenceGenerator.generate();
    endIndex = orderedKeys.indexOf(endKey);
  }
  const startKey = currentStartDateOccurenceGenerator.generate();
  const startIndex = orderedKeys.indexOf(startKey);
  return {startIndex, endIndex};
};

/**
 * Returns a time window (start date and end date) based on the provided horizon object.
 * @param {Object} kpi - Object containing the configuration of the time window.
 * @returns {Object} - Object containing the properties startDate and endDate.
 */
export const getKPIHorizonWindow = (kpi) => {
  const horizon = kpi?.attributes?.horizon ?? {};
  const createdOn = kpi?.createdOn;
  let startDate,  endDate;
  if (horizon.type === HORIZON_TYPES.CUSTOM) {
    startDate = new Date(horizon['start-date']) ?? new Date(createdOn);
    endDate = new Date(horizon['end-date']);
  } else if (horizon.type === HORIZON_TYPES.STANDARD) {
    startDate = new Date(createdOn);
    endDate = new Date(Date.UTC(horizon.year, horizon.period, 0));
  }
  return {
    startDate: utcDate(startDate),
    endDate: utcDate(endDate, 23, 59, 59)
  };
};

/**
 * Converts a given date to a UTC date with specified hours, minutes, and seconds.
 *
 * @param {Date|string} date - The date to convert. Can be a Date object or a date string.
 * @param {number} [hh=0] - The hours to set in the UTC date. Defaults to 0.
 * @param {number} [mm=0] - The minutes to set in the UTC date. Defaults to 0.
 * @param {number} [ss=0] - The seconds to set in the UTC date. Defaults to 0.
 * @param {number} [ms=0] - The milliseconds to set in the UTC date. Defaults to 0.
 * @returns {Date|null} - The converted UTC date, or null if the input date is invalid.
 */
export const utcDate = (date, hh = 0, mm = 0, ss = 0, ms = 0) => {
  if (!date) {
    return null;
  }
  const baseDate = typeof date === 'string' ? new Date(date) : date;

  if (isNaN(baseDate.getTime())) {
    return null;
  }
  return new Date(Date.UTC(
    baseDate.getUTCFullYear(),
    baseDate.getUTCMonth(),
    baseDate.getUTCDate(),
    hh, // Hours
    mm, // Minutes
    ss,  // Seconds
    ms // Milliseconds
  ));
};

/**
 * Filters an array of dates based on a specified time window and frequency.
 *
 * @param {Array} datesArray - The array of dates to filter.
 * @param {Object} timeWindow - An object containing the start and end dates of the time window.
 * @param {string} frequency - The frequency of the dates (e.g., 'Weekly:First day of the week', 'Monthly:First day of the month', etc.).
 * @returns {Array} - The filtered array of dates.
 */
export const filterDates = (datesArray = [], timeWindow = {}, frequency = '') => {
  const start = timeWindow['start-date'];
  const end = timeWindow['end-date'];
  const startDate = utcDate(start);
  const endDate = utcDate(end, 23, 59, 59);

  return datesArray.filter((date) => {
    const parsedDate = parseDateByFrequency(date, frequency);
    if (!parsedDate) {
      return false;
    }

    const { startDate: parsedStartDate, endDate: parsedEndDate } = parsedDate;
    return (
      (!startDate || parsedEndDate >= startDate) &&
      (!endDate || parsedStartDate <= endDate)
    );
  });
};

