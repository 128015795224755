<template>
  <div class="IobSidebar-footer">
    <slot />
  </div>
</template>

<script setup>

</script>

<style lang="scss" src="./IobSidebarFooter.scss" scoped />
