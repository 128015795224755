<template>
  <div class="flex items-center">
    <img
      v-if="avatarUrl"
      :class="classesList"
      :src="avatarUrl"
      :alt="altText"
      :style="imgStyle"
    >
    <div
      v-else
      class="relative inline-flex items-center justify-center overflow-hidden bg-gray-100 dark:bg-gray-600"
      :style="altStyle"
      :class="classesList"
    >
      <span class="font-medium text-gray-600 dark:text-gray-300">{{ altText }}</span>
    </div>
    <div
      :style="textStyle"
      style="margin-left: 8px;"
    >
      {{ userName }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AvatarWithName',
  props: {
    userName: {
      type: String,
      required: true
    },
    avatarUrl: {
      type: String,
      required: true
    },
    altText: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    textStyle: {
      type: String,
      default: ''
    },
    imgStyle: {
      type: String,
      default: ''
    },
    altStyle: {
      type: String,
      default: ''
    }
  },
  computed: {
    classesList() {
      if (this.size === 'small') {
        return 'w-6 h-6 rounded';
      }
      return 'w-9 h-9 rounded';
    }
  }
};
</script>

<style scoped src="./AvatarWithName.css" />
