<template>
  <iob-side-editor
    elevation="3"
    size="medium"
    :title="$t('bookmark.button.edit.title')"
    @close="closeSettings"
  >
    <template #content>
      <div class="form-group">
        <div class="form-group-label">
          <label>{{ $t('bookmark.button.link.label') }}</label>
        </div>
        <iob-textarea
          :value="formLink"
          :is-error="isInvalidLink"
          :is-focused="true"
          :error-message="$t('bookmark.button.link.error')"
          :placeholder="$t('bookmark.button.link.placeholder')"
          max-caracters="2048"
          styles="min-height: 68px; max-height: 300px;"
          type="outlined"
          size="default"
          @update:modelValue="validateLink"
        />
      </div>
      <div class="form-group">
        <div class="form-group-label">
          <label>{{ $t('bookmark.button.label.label') }}</label>
        </div>
        <iob-label-field
          :model-value="label"
          :placeholder="$t('bookmark.button.label.placeholder')"
          has-clear-button
          @update:modelValue="handleChange('label', $event)"
        />
      </div>
    </template>
  </iob-side-editor>
</template>

<script setup>
import {computed, defineEmits, ref} from 'vue';
import {useStore} from 'vuex';
import debounce from 'lodash/debounce';
import parser from '../../bookmark/utils/parser';

const store = useStore();
const datasetElementId = computed(() => store.state.editor.datasetElementId);
const datasetElement = computed(() => store.state.board.datasetElements[datasetElementId.value]);

const label = computed(() => datasetElement.value ? datasetElement.value.attributes.label : '');
const isInvalidLink = ref(false);

const formLink = ref(datasetElement.value ? datasetElement.value.attributes.link : '');

const validateLink = (link) => {
  isInvalidLink.value = parser(link);
  formLink.value = link;
  if (!isInvalidLink.value) {
    handleChange('link', formLink.value);
  }
};

const handleChange = debounce((name, value) => {
  store.dispatch('board/updateDatasetElementAttributes', {
    datasetElementId: datasetElementId.value,
    attributes: {
      ...datasetElement.value.attributes,
      [name]: value
    }
  });
}, 800);

const emit = defineEmits(['close']);
const closeSettings = () => {
  emit('close');
};
</script>

<style lang="scss" scoped>
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: var(--gray-900, #242230);
    /* text-s/font-semibold */
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */

    &-label {
      &-characterCount {
        float: right;
        color: #B1AEC6;
        text-align: right;
        /* text-xs/font-regular */
        font-family: 'Noto Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 166.667% */
        letter-spacing: 0.14px;
      }
    }
    &-helper-text {
      color: rgba(145, 145, 145, 1);
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
  }
</style>

