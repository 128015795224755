<template>
  <div
    class="IobCardPreview level-container"
    :class="classList"
    :style="style"
  >
    <div
      class="IobCardPreview-cover"
      :style="style"
    >
      <IobImage
        :src="cover"
        :render-type="imageRatio"
      />
    </div>
    <div
      v-show="title"
      class="IobCardPreview-title"
    >
      {{ title }}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import IobImage from '../../../Atoms/IobImage/IobImage.vue';

const props = defineProps({
  title: {
    type: String,
    default: 'Title',
  },
  cover: {
    type: String,
    default: null,
  },
  imageRatio: {
    type: String,
    default: '16:9',
    validator: (value) => ['1:1', '16:9', '4:3', '21:9', 'golden', 'fit-to-box', 'fit-to-img'].includes(value),
  },
  elevation: {
    type: Number,
    validator: (value) => [0, 1, 2, 3].includes(value),
    default: 1,
  }
});

const style = computed(() => ({
  height: props.imageRatio === 'fit-to-box' ? '100%' : 'auto'
}));

const classList = computed(() => [
  `level-${props.elevation}`,
]);
</script>
<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-app-cards.scss" />
<style lang="scss" src="./IobCardPreview.scss" scoped />
