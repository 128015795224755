const mutations = {
  setDataType(state, dataType) {
    state.dataType = dataType;
  },
  setDatasetElementId(state, datasetElementId) {
    state.datasetElementId = datasetElementId;
  },
  setHierarchicalCount(state, hierarchicalCount) {
    state.hierarchicalCount = hierarchicalCount;
  },
  setIsEditorOpen(state, value) {
    state.isEditorOpen = value;
  },
  setEditorType(state, editorType) {
    state.editorType = editorType;
  },
  setFilterId(state, filterId) {
    state.filterId = filterId;
  },
  setFilterResult(state, filterResult) {
    state.filterResult = filterResult;
  },
  setPreviousDatasetElementIds(state, previousDatasetElementIds) {
    state.previousDatasetElementIds = previousDatasetElementIds;
  }
};

export default mutations;
