export const getElementId = (elements, datasetElementId) => {
  let elementId = '';
  for (const [key, value] of Object.entries(elements)) {
    if (value?.datasetElement?.id === datasetElementId) {
      elementId = key;
      break;
    }
  }
  return elementId;
};
