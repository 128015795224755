<template>
  <div
    class="IobBadgeElement"
    @click="handleDropdownElementClick"
  >
    <iob-badge
      :size="size"
      :color="getBadgeColor"
      :selectable="false"
      :label="text"
    />
    <icon-loader
      v-if="iconName"
      :name="iconName"
      :size="iconSize"
      :style="{display: 'flex'}"
    />
  </div>
</template>     
  
<script setup>
import { defineProps, defineEmits, computed } from 'vue'; 
import { defaultBadgeColors } from '../../../constants';
import IconLoader from '../../IconLoader/IconLoader.vue';
import IobBadge from '../../Atoms/IobBadge/IobBadge.vue';

const props = defineProps({
  text: {
    type: String,
    default: ''
  },
  size: {
    type: String,
    default: 's'
  },
  color: {
    type: String,
    default: 'primary'
  },
  iconName: {
    type: String,
    default: ''
  },
});
const emits = defineEmits(['handleDropdownElement']);
const getBadgeColor = computed(() => {        
  return defaultBadgeColors[props.text]
})

const handleDropdownElementClick = () => {
  emits('handleDropdownElement', {text: props.text, color: props.color, size: props.size });
}

</script>
  
  <style lang="scss" src="./IobBadgeElement.scss" scoped />