<template>
  <nav class="HeaderNav">
    <div class="HeaderNav-left">
      <div
        v-if="showLeft"
        class="HeaderNav-left-container"
      >
        <div class="HeaderNav-left-icons">
          <div class="hide-desktop-small hide-desktop-large">
            <iob-button
              size="medium"
              color="secondary"
              type="ghost"
              left-icon="Menu"
              :show-left-icon="true"
              @click="handleBtnClick('menuBtnAction')"
            />
          </div>

          <iob-button
            v-if="navBtn"
            size="medium"
            color="secondary"
            type="ghost"
            :left-icon="navBtn.icon"
            :label="navBtn.label"
            :show-left-icon="true"
            @click="handleBtnClick('navBtnAction')"
          />

          <iob-button
            v-if="returnBtn"
            size="medium"
            color="secondary"
            type="ghost"
            left-icon="ArrowLeft"
            :show-left-icon="true"
            @click="handleBtnClick('returnBtnAction')"
          />

          <iob-button
            v-if="roomTitle !== ''"
            size="medium"
            color="secondary"
            type="ghost"
            left-icon="LayoutTemplate"
            :show-left-icon="true"
          />
        </div>
        <div class="HeaderNav-left-text">
          <div
            v-if="roomTitle !== ''"
            class="HeaderNav-left-text-roomTitle"
          >
            <h1>{{ roomTitle }}</h1>
          </div>
          <icon-loader
            v-if="roomTitle !== ''"
            name="ChevronRight"
            size="medium"
            :color="iconColor"
            :stroke="iconColor"
          />

          <div
            v-if="showLeft && pageTitle !== ''"
            class="board-title"
          >
            <h1>{{ pageTitle }}</h1>
          </div>
          <iob-badge
            v-if="showLeft && level !== ''"
            v-bind="{ color: 'primary', type: 'soft', size: 's', label: level }"
          />
        </div>
        <div class="HeaderNav-left-icons">
          <iob-button
            v-if="favoriteBtn"
            size="medium"
            color="secondary"
            type="ghost"
            left-icon="Star"
            :show-left-icon="true"
          />

          <div class="HeaderNav-left-icons-moreBtn">
            <iob-button
              v-if="moreBtn"
              class="HeaderNav-left-icons-moreBtn"
              size="medium"
              color="secondary"
              type="ghost"
              left-icon="MoreHorizontal"
              :show-left-icon="true"
              :selected="toggleDropdown"
              @click="toggleDropdown = !toggleDropdown"
            />
            <outside-click-listener
              v-if="toggleDropdown"
              @outside-click="() => toggleDropdown = false"
            >
              <iob-dropdown
                class="HeaderNav-left-icons--dropdown"
                :items="dropdownItems"
                @dropdown-element-item="handleDropdownItemClick"
              />
            </outside-click-listener>
          </div>
        </div>
      </div>
    </div>
    <div class="HeaderNav-middle">
      <div
        v-if="showMiddle"
        class="HeaderNav-middle-container"
      >
        <iob-button
          :disabled="disableUndo"
          size="medium"
          color="secondary"
          type="ghost"
          left-icon="Undo"
          :show-left-icon="true"
          @click="handleBtnClick('undoBtnAction')"
        />

        <iob-button
          :disabled="disableRedo"
          size="medium"
          color="secondary"
          type="ghost"
          left-icon="Redo"
          :show-left-icon="true"
          @click="handleBtnClick('redoBtnAction')"
        />
      </div>
    </div>
    <div class="HeaderNav-right">
      <div
        v-if="showRight"
        class="HeaderNav-right-container"
      >
        <div
          v-if="showCollaborators && collaborators.length > 0"
          class="HeaderNav-right-collaborations"
        >
          <iob-avatar-group
            size="large"
            :max-displayed-collorators="maxDisplayedCollorators"
            :collaborators="collaborators"
            :dropdown-items="clickedCollaborator"
            :hidden-list-style="hiddenListStyle"
            @clickCollaboratorPicture="handleCollaboratorClick"
          />

          <div
            v-if="collaboratorsBtn || inviteBtn"
            class="HeaderNav-right-collaborations-invite"
          >
            <iob-button
              v-if="inviteBtn"
              size="medium"
              color="secondarySoft"
              type="filled"
              left-icon="UserPlus"
              :show-left-icon="true"
              label="Invite room"
            />
            <iob-button
              v-if="collaboratorsBtn"
              size="medium"
              color="secondary"
              type="ghost"
              left-icon="Users"
              :show-left-icon="true"
            />
          </div>

          <div class="HeaderNav-right-collaborations-seperator">
            <iob-separator :is-vertical="true" />
          </div>
        </div>
        <div class="HeaderNav-right-userIcons">
          <iob-button
            v-if="infoBtn && showRight"
            size="medium"
            color="secondary"
            type="ghost"
            left-icon="HelpCircle"
            :show-left-icon="true"
            @click="handleBtnClick('infoBtnAction')"
          />

          <iob-button
            v-if="notificationBtn && showRight"
            size="medium"
            color="secondary"
            type="ghost"
            left-icon="Bell"
            :show-left-icon="true"
            @click="handleBtnClick('notificationBtnAction')"
          />

          <iob-button
            v-if="newsPaperBtn && showRight"
            :class="newsPaperBtnClass"
            size="medium"
            color="secondary"
            type="ghost"
            left-icon="Newspaper"
            :show-left-icon="true"
            @click="handleBtnClick('newsPaperBtnAction')"
          />

          <iob-avatar
            v-if="!isLoading"
            :dropdown-items="avatarDropdownItems"
            :picture="picture"
            :alt-text="altText"
            :user="user"
            size="large"
            :deleted-user="deletedUser"
            @avatar-dropdown-element-item="handleAvatarDropdownItemClick"
            @click="handleBtnClick('avatarClick')"
          />
          <iob-skeleton
            v-else
            width="36px"
            shape="rect"
          />
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
import IconLoader from '../../IconLoader/IconLoader.vue';
import IobButton from '../IobButton/IobButton.vue';
import IobDropdown from '../IobDropdown/IobDropdown.vue';
import IobAvatar from '../IobAvatar/IobAvatar.vue';
import IobAvatarGroup from '../IobAvatarGroup/IobAvatarGroup.vue';
import IobSeparator from '../../Atoms/IobSeparator/IobSeparator.vue';
import IobBadge from '../../Atoms/IobBadge/IobBadge.vue';
import OutsideClickListener from '../../OutsideClickListener/OutsideClickListener.vue';
import { defineProps, ref, defineEmits } from 'vue';
import IobSkeleton from '../../Atoms/IobSkeleton/IobSkeleton.vue';

defineProps({
  hiddenListStyle: {
    type: String,
    default: 'max-height: 640px;'
  },
  dropdownItems: {
    type: Array,
    default: () => []
  },
  picture: {
    type: String,
    default: ''
  },
  avatarDropdownItems: {
    type: Array,
    default: () => []
  },
  altText: {
    type: String,
    required: true,
    default: 'JD'
  },
  showLeft: {
    type: Boolean,
    default: true
  },
  showRight: {
    type: Boolean,
    default: true
  },
  collaboratorsBtn: {
    type: Boolean,
    default: false
  },
  inviteBtn: {
    type: Boolean,
    default: false
  },
  showCollaborators: {
    type: Boolean,
    default: true
  },
  maxDisplayedCollorators: {
    type: Number,
    default: 2
  },
  collaborators: {
    type: Array,
    default: () => []
  },
  pageTitle: {
    type: String,
    default: ''
  },
  roomTitle: {
    type: String,
    default: ''
  },
  navBtn: {
    type: Object,
    default: null
  },
  menuBtn: {
    type: Boolean,
    default: true
  },
  returnBtn: {
    type: Boolean,
    default: true
  },
  homeBtn: {
    type: Boolean,
    default: false
  },
  moreBtn: {
    type: Boolean,
    default: true
  },
  showMiddle: {
    type: Boolean,
    default: true
  },
  disableRedo: {
    type: Boolean,
    default: true
  },
  disableUndo: {
    type: Boolean,
    default: true
  },
  infoBtn: {
    type: Boolean,
    default: true
  },
  notificationBtn: {
    type: Boolean,
    default: true
  },
  newsPaperBtn: {
    type: Boolean,
    default: false
  },
  newsPaperBtnClass: {
    type: String,
    default: ''
  },
  favoriteBtn: {
    type: Boolean,
    default: true
  },
  level: {
    type: String,
    default: ''
  },
  user: {
    type: Object,
    default: null
  },
  deletedUser: {
    type: Boolean,
    default: false
  },
  isLoading: {
    type: Boolean,
    default: false
  }
});

const clickedCollaborator = ref([]);
const toggleDropdown = ref(false);
const iconColor = ref(
  'var(--button-color-text-secondary-ghost-default, #5D587B)'
);

const emits = defineEmits([
  'returnBtnAction',
  'redoBtnAction',
  'undoBtnAction',
  'dropdownElementClick',
  'infoBtnAction',
  'menuBtnAction',
  'newsPaperBtnAction',
  'notificationBtnAction',
  'avatarDropdownElementClick',
  'avatarClick',
  'navBtnAction'
]);

const handleBtnClick = (action) => {
  emits(action);
};
const handleDropdownItemClick = ({item}) => {
  if (!item.action || item.action === '') return;
  emits('dropdownElementClick', item);
  toggleDropdown.value = false;
};
const handleAvatarDropdownItemClick = (item) => {
  if (!item.action) return;
  emits('avatarDropdownElementClick', item);
  toggleDropdown.value = false;
};

const handleCollaboratorClick = (item) => {
  clickedCollaborator.value = [
    { ...item, text: `${item.firstname} ${item.lastname}`, type: 'menu' }
  ];
};
</script>

<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-app-header.scss" />
<style lang="scss" scoped src="./IobHeaderNav.scss" />
