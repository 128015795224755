<template>
  <div>
    <multi-label-field
      id="mulitLabelFieldSelect"
      :multiple-choices="selectedItems"
      :input-value="inputValue"
      :deletable="deletable"
      :container-style="multiLabelFieldStyle"
      @delete-chip-click="(data) => $emit('delete-label-field-click', data)"
      @update:inputValue="(value) => $emit('search-input-value-change', value)"
      @execute-click-action="toggleMenu"
    />
    <outside-click-listener
      v-if="searchMenuOpened"
      @outside-click="hideMenu"
    >
      <search-menu
        :search-field="false"
        :options="options"
        :input-data="inputValue"
        :style="`width: ${sMenuWidth}px`"
        :search-menu-style="searchMenuStyle"
        :none-option="false"
        @click-item="handleItemClick"
      />
    </outside-click-listener>
  </div>
</template>

<script>
import SearchMenu from '../SearchMenu/SearchMenu.vue';
import MultiLabelField from '../MultiLabelField/MultiLabelField.vue';
import OutsideClickListener from '../OutsideClickListener/OutsideClickListener.vue';

export default {
  name: 'MultipleSelect',
  components: {SearchMenu, MultiLabelField, OutsideClickListener},
  props: {
    options: {
      type: Array,
      default: () => ([]),
      required: true
    },
    selectedItems: {
      type: Array,
      default: () => ([])
    },
    deletable: {
      type: Boolean,
      default: true
    },
    inputValue: {
      type: String,
      default: ''
    },
    multiLabelFieldStyle: {
      type: String,
      default: ''
    },
    searchMenuStyle: {
      type: String,
      default: ''
    }
  },
  emits: ['click-item', 'delete-label-field-click', 'search-input-value-change'],
  data: () => ({
    searchMenuOpened: false,
    sMenuWidth: ''
  }),
  mounted() {
    this.$nextTick(() => {
      const mulitLabelFieldSelect = document.getElementById('mulitLabelFieldSelect');
      if (mulitLabelFieldSelect) {
        this.sMenuWidth = mulitLabelFieldSelect.clientWidth;
      }
    });
  },
  methods: {
    handleItemClick({item}) {
      this.$emit('click-item', item);
    },
    toggleMenu(e) {
      e.stopPropagation();
      this.searchMenuOpened = !this.searchMenuOpened;
    },
    hideMenu() {
      this.searchMenuOpened = false;
    }
  }
};
</script>
