<template>
  <div
    class="MultiLabelField-container"
    :style="containerStyle"
    @click="handleClickAction"
  >
    <iob-chip
      v-for="(item, index) in multipleChoicesArray"
      :key="index"
      :item="item"
      style="margin-right: 4px; margin-bottom: 4px;"
      :deletable="deletable"
      @delete-icon-click="(value) => $emit('delete-chip-click', {index, value})"
    />
    <input
      ref="MultiLabelFieldInput"
      class="MultiLabelField-input"
      :value="inputValue"
      @input="(event) => $emit('update:inputValue', event.target.value)"
    >
  </div>
</template>

<script>
import IobChip from '../IobChip/IobChip.vue';
import { parseIfJson } from '../../utils';

export default {
  name: 'MultiLabelField',
  components: { IobChip },
  props: {
    multipleChoices: {
      type: [String, Array],
      default: () => [],
      required: false
    },
    deletable: {
      type: Boolean,
      default: true
    },
    inputValue: {
      type: String,
      default: ''
    },
    containerStyle: {
      type: String,
      default: ''
    }
  },
  emits: ['execute-click-action', 'update', 'delete-chip-click'],
  computed: {
    multipleChoicesArray() {
      return parseIfJson(this.multipleChoices) || [];
    }
  },
  methods: {
    handleClickAction(e) {
      this.$refs.MultiLabelFieldInput.focus();
      this.$emit('execute-click-action', e)
    }
  }
};
</script>

<style scoped src="./MultiLabelField.css" />
