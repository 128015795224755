<template>
  <outside-click-listener @outside-click="$emit('close')">
    <iob-sidebar
      :size="props.size"
      :elevation="props.elevation"
      class="IobSideEditor-container"
    >
      <iob-sidebar-header v-if="title">
        <div class="IobSideEditor-header">
          <h1 class="IobSideEditor-header-title">
            {{ props.title }}
          </h1>
          <iob-action-button
            icon="close"
            size="default"
            color="secondary"
            type="ghost"
            @click="$emit('close')"
          />
        </div>
      </iob-sidebar-header>

      <iob-sidebar-content 
        class="IobSideEditor-content"
      >
        <slot name="content" />
      </iob-sidebar-content>
      <iob-sidebar-footer>
        <slot name="footer" />
      </iob-sidebar-footer>
    </iob-sidebar>
  </outside-click-listener>
</template>
<script setup>
import { defineProps } from 'vue';
import IobSidebar from '../../Atoms/Sidebar/IobSidebar.vue';
import IobSidebarHeader from '../../Atoms/Sidebar/SidebarHeader/IobSidebarHeader.vue';
import IobSidebarFooter from '../../Atoms/Sidebar/SidebarFooter/IobSidebarFooter.vue';
import IobSidebarContent from '../../Atoms/Sidebar/SidebarContent/IobSidebarContent.vue';
import IobActionButton from '../IobActionButton/IobActionButton.vue';
import OutsideClickListener from '../../OutsideClickListener/OutsideClickListener.vue';

const props = defineProps({
  size: {
    type: String,
    default: 'medium',
    validator: (value) => ['small', 'medium', 'large'].includes(value)
  },
  elevation: {
    type: [Number, String],
    default: 3
  },
  title: {
    type: String,
    default: ''
  }
});
</script>
<style lang="scss" src="./IobSideEditor.scss" scoped />