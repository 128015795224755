import actions from './actions';
import mutations from './mutations';

const WorkspaceModule = {
  namespaced: true,
  state: {
    boards: [],
    nextBoardsQuery: 'initial',
    boardParams: {
      page: 1,
      size: 20
    },
    rooms: []
  },
  mutations,
  actions
};

export default WorkspaceModule;
