<template>
  <div class="IobSidebar-content">
    <slot />
  </div>
</template>

<script setup>

</script>

<style lang="scss" src="./IobSidebarContent.scss" scoped />
