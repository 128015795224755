<template>
  <button
    class="Button"
    :class="{
      'Button--with-label': label,
      'Button--with-icon': icon,
      'Button--small': size === 'small',
      'Button--large': size === 'large',
      'Button--primary': buttonType === 'primary',
      'Button--secondary': buttonType === 'secondary',
      'Button--destructive': buttonType === 'destructive',
      'Button--extensible': isExtensible,
      'Button--dark': isDark
    }"
    :disabled="isDisabled"
    @click="$emit('click')"
  >
    <img
      v-if="icon"
      alt=""
      class="Button-icon"
      :src="icon"
    >
    <span
      v-if="label"
      class="Button-label"
    >{{ label }}</span>
  </button>
</template>

<script>
export default {
  name: 'IobButton',
  props: {
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    buttonType: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isExtensible: {
      type: Boolean,
      default: false
    },
    isDark: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click']
};
</script>

<style scoped src="./Button.css" />
