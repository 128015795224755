<template>
  <div
    id="hidden-avatar-list"
    class="HiddenAvatarList"
    @mouseover="expandedUsersOnBoardList = true"
    @mouseleave="expandedUsersOnBoardList = false"
  >
    <iob-avatar
      size="default"
      :alt-text="altText"
    />
    <user-list-pop-over
      v-if="expandedUsersOnBoardList"
      class="HiddenAvatarList-popover"
      :users="users"
    />
  </div>
</template>

<script>
import IobAvatar from '../Molecules/IobAvatar/IobAvatar.vue';
import UserListPopOver from '../UserListPopOver/UserListPopOver.vue';
import { parseIfJson } from '../../utils';

export default {
  name: 'HiddenAvatarList',
  components: {
    IobAvatar,
    UserListPopOver
  },
  props: {
    users: {
      type: [String, Array],
      required: true
    }
  },
  data() {
    return {
      expandedUsersOnBoardList: false
    };
  },
  computed: {
    altText() {
      return `+${this.usersArray.length}`;
    },
    usersArray() {
      return parseIfJson(this.users) || [];
    }
  }
};
</script>

<style scoped src="./HiddenAvatarList.css" />
