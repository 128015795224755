<template>
  <div
    class="Room-content"
  >
    <p>Nothing in Room yet 😄</p>
  </div>
</template>

<script>
export default {
  name: 'RoomComponent'
};
</script>

  <style scoped src='./Room.css' />
