<template>
  <div @click="manageVersionSelection(version)">
    <icon-loader
      v-if="version.id === currentVersion.id"
      class="Version-circleIcon"
      name="Chip"
      size="default"
    />
    <div
      v-if="version.id === currentVersion.id"
      class="Version-currentVersion"
    >
      <span class="Version-currentVersionName"> {{ version.name }}</span>
    </div>
    <div
      v-else
      class="Version"
    >
      <div class="Version-verticalLine" />
      <div
        class="Version-content"
      >
        <div
          class="Version-otherVersions"
        >
          <span
            v-if="version.name && version.isMarked"
            class="Version-name"
          > {{ version.name }}</span>
          <span
            v-if="version.dateTime"
            :class="[version.isMarked ? 'Version-markedVersionDatetime' : 'Version-unmarkedVersionDatetime']"
          >{{
            version.dateTime
          }}</span>
          <span
            v-if="version.isMarked && version.description"
            class="Version-description"
          >{{
            version.description
          }}</span>
          <span
            v-if="version.daysLeft"
            class="Version-daysLeft"
          >{{ version.daysLeft }} days left</span>

        <!-- <div class="Version-modifiersInformation">
          <div
            v-if="version.modifierCount === 1 && modifier"
            class="Version-modifier"
          >
            <icon-loader
              class="Version-coloredChip"
              name="ColoredChip"
              :color="modifierColor"
            />
            <span class="Version-modifierName"> {{ modifierName }}</span>
          </div>
          <span
            v-else-if="version.modifierCount === 1 && !!modifier"
            class="Version-deletedUser"
          > Deleted user</span>
          <span
            v-else
            class="Version-modifierCount"
          > {{ version.modifierCount }} modifiers</span>
        </div> -->
        </div>
      </div>
    </div>

    <div
      v-if="version.isSelected || version.isMarked"
      :class="[version.id !== currentVersion.id ? 'Version-icons' : 'Version-currentVersionMarkIcon']"
    >
      <div class="Version-bubbleTooltip">
        <icon-loader
          class="Version-markIcon"
          name="BookMark"
          :color="version.isMarked ? markedIconColor : ''"
          :stroke="version.isMarked ? markedIconColor : '#14131b'"
          @click="manageMarkVersion($event, version)"
        />
        <span class="Version-tooltiptext"> {{ displayedMessage(version, 'mark') }} </span>
      </div>
      <div
        v-if="version.id !== currentVersion.id"
        class="Version-details"
      >
        <div
          v-if="version.isSelected"
          id="version-details"
          class="Version-bubbleTooltip"
        >
          <icon-loader
            class="Version-detailsIcon"
            name="MoreVertical"
            color="#14131b"
            @click="toggleVersionDetails($event)"
          />
          <span class="Version-tooltiptext"> {{ displayedMessage(version, 'details') }} </span>
        </div>
        <div
          v-if="openDetails"
          id="restore-version-menu"
        >
          <multi-level-menu
            :multi-levelmenu-style="multiLevelmenuStyle"
            :items="menuItems"
            @click="processMultiLevelClick"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';
import { DEFAULT_USER_COLOR, VERSION_DETAILS_ITEMS, VERSION_MODES } from 'GLOBALS/constants.js';

export default {
  name: 'VersionItem',
  props: {
    version: {
      type: Object,
      required: true
    }
  },
  emits: ['version-click'],
  data() {
    return {
      markedIconColor: '#004AFA',
      modifierName: '',
      modifierColor: '',
      markVersion: false,
      modifier: null,
      openDetails: false,
      multiLevelmenuStyle: 'right: 5%; margin: auto;',
      restoreVersionNotificationContent: {
        message: 'Version restored',
        action: 'Undo',
        undo: async () => {
          await this.addBoardVersionsToStore();
          await this.restoreVersion(this.recentVersions[0].id);
          this.setNotificationsInfo({restoreVersion: { ...this.notifications.restoreVersion, isNotificationDisplayed: false }});
        }
      },
      unmarkVersionNotificationContent: {
        message: 'Version unmarked',
        action: 'Undo',
        undo: async () => {
          await this.editVersion({version: {...this.version, marked: true}, versionId: this.version.id});
          this.setNotificationsInfo({unmarkVersion: { ...this.notifications.unmarkVersion, isNotificationDisplayed: false }});
        }
      },
      displayNotification: false
    };
  },
  computed: {
    ...mapGetters('users', [
      'getUserById'
    ]),
    ...mapState('board', [
      'openedComponents',
      'currentVersion',
      'dialogBoxFlags',
      'versionToBeDeletedId',
      'notifications',
      'recentVersions'
    ]),
    menuItems() {
      let menuItems = VERSION_DETAILS_ITEMS;
      if (this.version.isMarked) {
        return menuItems;
      } else {
        menuItems = menuItems.filter((item) => item.id !== 1);
        return menuItems;
      }
    }
  },
  created() {
    this.getModifierInformartion();
    window.addEventListener('click', this.hideVersionDetailsMenu);
  },
  beforeUnmount() {
    window.removeEventListener('click', this.hideVersionDetailsMenu);
  },
  methods: {
    ...mapMutations('board', [
      'setOpenedComponents',
      'setMode',
      'setDialogBoxFlags',
      'setVersionToBeDeletedId',
      'setNotificationsInfo'
    ]),
    ...mapActions('board', [
      'restoreVersion',
      'editVersion',
      'addBoardVersionsToStore'
    ]),
    hideVersionDetailsMenu(e) {
      const restoreVersionMenu = 'restore-version-menu';
      if (document.getElementById(restoreVersionMenu) && !document.getElementById(restoreVersionMenu).contains(e.target) &&
        document.getElementById('version-details') && !document.getElementById('version-details').contains(e.target)) {
        const historyMenu = document.getElementById(restoreVersionMenu);
        historyMenu.className = 'Version-hideDetails';
      }
    },
    getModifierInformartion() {
      if (this.version.modifierCount === 1) {
        this.modifier = this.getUserById(this.version.modifierId);
        if (this.modifier) {
          this.modifierName = `${this.modifier.firstname} ${this.modifier.lastname}`;
          if (this.modifier.color) {
            this.modifierColor = this.modifier.color;
          } else {
            this.modifierColor = DEFAULT_USER_COLOR;
          }
        }
      }
    },
    async manageMarkVersion(event, version) {
      event.stopPropagation();
      if (version.id === this.currentVersion.id) {
        this.setMode(VERSION_MODES[0]);
        this.openCreateVersionDialogBox();
        return;
      }

      if (!version.isMarked) {
        this.setMode(VERSION_MODES[2]);
        this.openCreateVersionDialogBox();
        return;
      }
      if (version.numberOfDaysSinceCreation <= 30) {
        const versionInfo = { name: version.name, description: version.description, marked: false };
        await this.unmarkVersion(versionInfo);
        return;
      }
      this.setVersionToBeDeletedId(version.id);
      this.openDeleteVersionDialogBox();
    },
    async unmarkVersion(version) {
      await this.editVersion({ version, versionId: this.version.id });
      this.setNotificationsInfo({
        unmarkVersion: {
          ...this.notifications.unmarkVersion,
          isNotificationDisplayed: true,
          message: this.unmarkVersionNotificationContent.message,
          actionName: this.unmarkVersionNotificationContent.action,
          callbackfn: this.unmarkVersionNotificationContent.undo
        }
      });
    },
    openCreateVersionDialogBox() {
      this.setDialogBoxFlags({...this.dialogBoxFlags, isCreateVersionDialogOpened: true});
    },
    openDeleteVersionDialogBox() {
      this.setDialogBoxFlags({...this.dialogBoxFlags, isDeleteVersionDialogOpened: true});
    },
    toggleVersionDetails(event) {
      event.stopPropagation();
      this.openDetails = !this.openDetails;
    },
    manageVersionSelection(version) {
      this.openDetails = false;
      this.$emit('version-click', version);
    },
    displayedMessage(version, value) {
      if (value === 'mark') {
        if (version.isMarked) {
          return 'Unmark';
        } else {
          return 'Mark this version';
        }
      } else if (value === 'details') {
        return 'Options';
      } else {
        return '';
      }
    },
    closeVersionHistory() {
      const openedComponents = this.openedComponents.filter((componentName) => componentName !== 'VersionHistory');
      this.setOpenedComponents(openedComponents);
    },
    processMultiLevelClick({ action }) {
      this[action]();
    },
    async restoreBoardVersion() {
      this.closeVersionHistory();
      await this.restoreVersion(this.version.id);
      this.setNotificationsInfo({
        restoreVersion: {
          ...this.notifications.restoreVersion,
          isNotificationDisplayed: true,
          message: this.restoreVersionNotificationContent.message,
          actionName: this.restoreVersionNotificationContent.action,
          callbackfn: this.restoreVersionNotificationContent.undo
        }
      });
    },
    createBoardfromVersion: () => ({}),
    editBoardVersion() {
      this.setMode(VERSION_MODES[1]);
      this.openCreateVersionDialogBox();
    }
  }
};

</script>
<style src="./Version.css" />
