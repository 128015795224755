<template>
  <div
    id="searchMenu"
    class="absolute z-10 bg-white rounded-lg shadow dark:bg-gray-700 mt-3"
    :style="searchMenuStyle"
  >
    <div
      v-if="searchField"
      class="p-3"
    >
      <label-field
        id="search-input"
        :input-style="inputDataObject.style"
        :model-value="inputDataObject.value"
        :placeholder="inputDataObject.placeholder"
        :start-icon="inputDataObject.startIcon"
        :start-icon-size="inputDataObject.startIconSize"
        :start-icon-color="inputDataObject.startIconColor"
        :start-icon-stroke="inputDataObject.startIconStroke"
        :border-color="borderColor"
        is-focused="true"
        :mode="mode"
        :shape="shape"
        outlined
        @update:modelValue="currentSelectorInputValue = $event"
      />
    </div>
    <scroll-bar
      id="scroll-container"
      :scroll-container-style="scrollContainerStyle"
    >
      <ul
        v-show="filteredOptions.length > 0"
        id="SearchMenuOptions"
        class="pb-3 text-sm text-gray-700"
        aria-labelledby="dropdownSearchButton"
      >
        <li
          v-for="(item, index) in filteredOptions"
          :key="index"
          class="px-3"
          @click="handleItemClick({event: $event, item})"
        >
          <div class="flex items-center pl-2 rounded hover:bg-gray-100">
            <checkbox
              v-if="item.hasCheckbox"
              :id="item.id"
              class="mr-3.5"
              :checked="item.checked"
              :disabled="item.disabled"
              outlined
              @checkbox-event="setCheckbox"
            />
            <icon-loader
              v-if="item.iconName && item.iconName.length"
              :name="item.iconName"
              :color="item.iconColor"
              :stroke="item.iconStroke"
              :size="item.iconSize"
            />
            <label class="py-2 ml-2 text-sm"> {{ item.label }}</label>
          </div>
        </li>
        <li v-if="$slots">
          <slot />
        </li>
      </ul>
    </scroll-bar>
    <div
      v-show="filteredOptions.length === 0"
      class="SearchMenu-emptySearch"
    >
      No results found for "{{ currentSelectorInputValue }}"
    </div>
  </div>
</template>

<script>
import IconLoader from '../IconLoader/IconLoader.vue';
import LabelField from '../LabelField/LabelField.vue';
import ScrollBar from '../ScrollBar/ScrollBar.vue';
import Checkbox from '../Checkbox/Checkbox.vue';
import { parseIfJson } from '../../utils';

export default {
  name: 'SearchMenu',
  components: { IconLoader, LabelField, ScrollBar, Checkbox },
  props: {
    options: {
      type: [String, Array],
      required: true
    },
    inputData: {
      type: [String, Object],
      default: () => ({})
    },
    searchField: {
      type: Boolean,
      default: true
    },
    searchMenuStyle: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'light'
    },
    shape: {
      type: String,
      default: 'rounded'
    },
    borderColor: {
      type: String,
      default: 'border-gray'
    },
    optionsContainerHeight: {
      type: String,
      default: 'fit-content'
    }
  },
  emits: ['click-item', 'checked-item'],
  data() {
    return {
      currentSelectorInputValue: '',
      scrollContainerStyle: 'overflow-y: hidden;',
      defaultInputValue: {placeholder:'Search ...', startIcon:'Search', value:'', style:'width: 100%', startIconColor: 'gray' }
    };
  },
  computed: {
    optionsArray() {
      return parseIfJson(this.options) || [];
    },
    filteredOptions() {
      return this.applySearchFilter(this.currentSelectorInputValue, this.optionsArray);
    },
    inputDataObject() {
      return parseIfJson(this.inputData) || this.defaultInputValue;
    }
  },
  mounted() {
    const seachInput = document.getElementById('search-input');
    if (seachInput) {
      seachInput.focus();
    }
    this.updateScrollContainerStyle();
  },
  methods: {
    handleItemClick({event, item}) {
      event.stopPropagation();
      this.$emit('click-item', {event, item});
    },
    updateScrollContainerStyle() {
      const searchMenuOptions = document
        .getElementById('SearchMenuOptions');
      let searchMenuOptionsHeight;
      if (searchMenuOptions) {
        searchMenuOptionsHeight = searchMenuOptions.getBoundingClientRect().height;
      }
      if (searchMenuOptionsHeight && searchMenuOptionsHeight > this.optionsContainerHeight) {
        this.scrollContainerStyle = `max-height: ${this.optionsContainerHeight}`;
      }
    },
    setCheckbox(data) {
      this.$emit('checked-item', data);
    },
    applySearchFilter(search, items) {
      return items.filter((item) =>
        item.label.toLowerCase().includes(search.toLowerCase())
      );
    }
  }
};
</script>

<style scoped src="./SearchMenu.css" />
