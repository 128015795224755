<template>
  <div class="IobLoadingBarWithLabel">
    <iob-loading-bar
      :progress="progress"
      :color="color"
    />
    <span :class="labelClasses">
      {{ label }}
    </span>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';
import IobLoadingBar from '../../Atoms/IobLoadingBar/IobLoadingBar.vue';

const props = defineProps({
  label: { type: String, default: 'Action in progress...' },
  color: { type: String, default: 'primary' },
  progress: { type: Number, default: 0 },
});

const labelClasses = computed(() => {
  let whiteLabelClass = '';
  if (props.color === 'white') {
    whiteLabelClass = 'IobLoadingBarWithLabel-label-white ';
  }
  return `IobLoadingBarWithLabel-label ${whiteLabelClass}`;
});
</script>

<style lang="scss" scoped src="./IobLoadingBarWithLabel.scss" />
