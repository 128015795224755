<template>
  <div class="IobCircleLoaderWithLabel">
    <iob-circle-loader :color="color" />
    <span class="IobCircleLoaderWithLabel-label"> {{ label }}</span>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import IobCircleLoader from '../../Atoms/IobCirlceLoader/IobCircleLoader.vue';

defineProps({
  color: {
    type: String,
    default: 'primary',
  },
  label: {
    type: String,
    required: true,
  },
});
</script>

<style lang="scss" scoped src="./IobCircleLoaderWithLabel.scss" />
