const mutations = {
  setSelectedTabIndex(state, selectedTabIndex) {
    state.selectedTabIndex = selectedTabIndex;
  },
  setFieldTypebeingManaged(state, {value, tile}) {
    state.fieldTypebeingManaged = value;
    if (tile) {
      state.openedTile = tile;
      return;
    }
    state.openedTile = null;
  },
  setFields(state, value) {
    state.fields = [...state.fields, value];
  },
  setFieldTypes(state, value) {
    state.fieldTypes = value;
  },
  resetFields(state) {
    state.fields = [{
      startIcon: 'ALargeSmall',
      value: 'Title',
      disabled: true,
      style: 'width: 330px;',
      details: {}
    }];
  },
  updateFields(state, value) {
    state.fields = value;
  },
  setFieldTypeId(state, value) {
    state.fieldId = value;
  },
  setHierarchy(state, {value, type}) {
    state.hierarchy[type] = [...state.hierarchy[type], value];
  },
  deleteHierarchyItem(state, {value, type}) {
    state.hierarchy[type] = state.hierarchy[type].filter((e) => e.name !== value.name);
  },
  setSelectedLayout(state, value) {
    state.selectedLayout = value;
  },
  setFieldPlacement(state, value) {
    state.fieldPlacement = value;
  },
  setCardName(state, value) {
    state.cardName = value;
  },
  setDefaultColor(state, value) {
    state.defaultColor = value;
  },
  setNumberFormats(state, value) {
    state.numberFormats = value;
  },
  resetState(state, newState) {
    Object.assign(state, newState);
  }
};

export default mutations;
