export const transformCoordinates = (borders) => (position) => ({
  x1: position.x1 - borders.left,
  x2: position.x2 - borders.left,
  y1: position.y1 - borders.top,
  y2: position.y2 - borders.top
});
export const transformElementCoordinates = (zoomLevel, elementHeight, elementWidth) => (el) => ({
  id: el.id,
  coordinates: {
    x1: zoomLevel * el.position.x,
    x2: zoomLevel * el.position.x + elementWidth * zoomLevel,
    y1: zoomLevel * el.position.y,
    y2: zoomLevel * el.position.y + elementHeight * zoomLevel
  }
});
export const rectangle = {
  overlaps: (el1) => (el2) => {
    const isBetween = (p, x1, x2) => (p > x1 && p < x2) || (p < x1 && p > x2);
    return (
      ((isBetween(el2.x1, el1.x1, el1.x2) ||
        isBetween(el2.x2, el1.x1, el1.x2)) &&
        (isBetween(el2.y1, el1.y1, el1.y2) ||
          isBetween(el2.y2, el1.y1, el1.y2) ||
          (el2.y1 < el1.y1 && el2.y2 > el1.y1))) ||
      ((isBetween(el1.x1, el2.x1, el2.x2) ||
        isBetween(el1.x2, el2.x1, el2.x2)) &&
        (isBetween(el1.y1, el2.y1, el2.y2) ||
          isBetween(el1.y2, el2.y1, el2.y2) ||
          (el1.y1 < el2.y1 && el1.y2 > el2.y1)))
    );
  }
};
