import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const AppModule = {
  namespaced: true,
  state: {
    viewTypes: null,
    dataTypes: null,
    levels: [],
    levelsTree: [],
    formulas: null,
    layouts: null,
    systemDatatypes: {},
    relationTypes: [],
    errors: [],
    exceptions: [],
    relationError: null,
    circuitOpened: false,
    featuresList: []
  },
  getters,
  mutations,
  actions
};

export default AppModule;
