<template>
  <div
    class="icon-container"
    :class="{
      'selected-mode': isModeSelected,
      'hovered-icon': isItemHovered,
    }"
  >
    <icon-loader
      :name="iconName"
      :color="iconColor"
      :stroke="iconStroke"
      :icon-style="isItemClickable && 'cursor: pointer;'"
      style="margin: auto;"
    />
    <span
      v-if="isItemHovered"
      class="tooltiptext"
    >
      {{ selectedVisualLinkAction }}
    </span>
    <span
      v-if="label.length"
      class="label-icon"
    >
      {{ label }}
    </span>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'InteractionModeIcon',
  props: {
    isModeSelected: {
      type: Boolean,
      default: false
    },
    isItemHovered: {
      type: Boolean,
      default: false
    },
    isItemClickable: {
      type: Boolean,
      default: true
    },
    iconName: {
      type: String,
      required: true
    },
    componentName: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    stroke: {
      type: String,
      default: ''
    },
    isItemDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      itemDisabledColor: '#00000066'
    };
  },
  computed: {
    ...mapState('relations', ['selectedVisualLinkAction']),
    iconColor() {
      if (this.componentName === 'VisualLinkContextualMenu') {
        return this.isItemDisabled ? this.itemDisabledColor : this.color;
      }
      return this.isModeSelected ? '#fff' : '#14131b99';
    },
    iconStroke() {
      if (this.componentName === 'InteractionModeSelector') {
        return this.isModeSelected ? '#fff' : '#14131b99';
      }
      return this.isItemDisabled ? this.itemDisabledColor : this.stroke;
    }
  }
};
</script>
<style scoped src="./InteractionModeIcon.css" />
