export const listViewType = {
  'kind': 'ElementViewType',
  'id': '55184f22-f0d2-4758-881e-25d13d24c0f2',
  'toolType': 'list',
  'dataTypeId': '3069e307-b259-4d0c-8ab3-19ad39a2ad21',
  'dataTypeNamespace': '/boardget-data-types/opex',
  'dataTypeName': 'List',
  'dataTypeVersion': 1,
  'layoutId': 'a4a11aec-3351-408f-a442-a642049d8362',
  'layouts': [
    {
      'levelOfDetail': -1,
      'tiles': [
        {
          'fieldName': 'title',
          'label': 'Title',
          'type': 'text',
          'fields': [
            {
              'name': 'title',
              'label': 'Title',
              'type': 'text',
              'iconName': null
            }
          ],
          'x': 1,
          'y': 1,
          'w': 8,
          'h': 6
        }
      ]
    },
    {
      'levelOfDetail': 0,
      'tiles': [
        {
          'fieldName': 'friendly-id',
          'label': 'Id',
          'type': 'tag',
          'fields': [
            {
              'name': 'friendly-id',
              'label': 'Id',
              'type': 'tag',
              'iconName': null
            }
          ],
          'x': 1,
          'y': 1,
          'w': 4,
          'h': 1
        },
        {
          'fieldName': 'title',
          'label': 'Title',
          'type': 'text',
          'fields': [
            {
              'name': 'title',
              'label': 'Title',
              'type': 'text',
              'iconName': null
            }
          ],
          'x': 1,
          'y': 2,
          'w': 8,
          'h': 4
        }
      ]
    }
  ],
  // eslint-disable-next-line max-len
  'iconUri': 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODQiIGhlaWdodD0iNjIiIHZpZXdCb3g9IjAgMCA4NCA2MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSI4MyIgaGVpZ2h0PSI2MSIgcng9IjMuNSIgZmlsbD0iI0Y3RjdGNyIgc3Ryb2tlPSIjNkU2RTZFIi8+CjxwYXRoIGQ9Ik0wLjUgNDcuNUg4My41VjU4QzgzLjUgNTkuOTMzIDgxLjkzMyA2MS41IDgwIDYxLjVINEMyLjA2NyA2MS41IDAuNSA1OS45MzMgMC41IDU4VjQ3LjVaIiBmaWxsPSIjRjdGN0Y3IiBzdHJva2U9IiM2RTZFNkUiLz4KPHJlY3QgeD0iMTAiIHk9IjE1IiB3aWR0aD0iMzYiIGhlaWdodD0iNiIgcng9IjMiIGZpbGw9IiNDQUNBQ0EiLz4KPHJlY3QgeD0iMTAiIHk9IjI2IiB3aWR0aD0iNjQiIGhlaWdodD0iNiIgcng9IjMiIGZpbGw9IiNDQUNBQ0EiLz4KPC9zdmc+Cg==',
  'hidden': false,
  'config': {
    'resize': {
      'cornerKnobs': ['tr', 'tl', 'br', 'bl'],
      'middleKnobs': ['tm', 'bm', 'lm', 'rm']
    },
    'contextualMenu': [
      {
        'action': 'onLockToggle',
        'iconName': 'Lock',
        'hasSeparator': true,
        'componentType': 'icon'
      },
      {
        'componentType': 'icon',
        'iconName': 'settings-2',
        'iconColor': '#14131B',
        'iconColorInEdit': '#fff',
        'backgroundColor': '#3368f6',
        'iconColorChangeCondition': 'isInEdition',
        'action': 'onEdit',
        'hasSeparator': true
      },
      {
        'action': 'onDelete',
        'iconName': 'Trash',
        'componentType': 'icon'
      }
    ]
  },
  resizable: 'FREE_HOMOTHETIC',
  'self': '/v0/element-view-types/55184f22-f0d2-4758-881e-25d13d24c0f2'
};
