import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const TimeSeriesModule = {
  namespaced: true,
  state: {
    timeSeriesPoints: {},
    valueType: {},
    timeSeriesActualValues: {}
  },
  mutations,
  actions,
  getters
};
export default TimeSeriesModule;
