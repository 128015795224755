import {createI18n} from 'vue-i18n';
import en from '@/plugins/locales/ressources-locale_en.json';
import fr from '@/plugins/locales/ressources-locale_fr.json';
import { locale, fallbackLocale } from '@/plugins/locales/config';

const i18n = createI18n({
  legacy: false,
  locale,
  fallbackLocale,
  globalInjection: true,
  messages: {
    en,
    fr
  }
});

export default i18n;
