<template>
  <IobModal
    id="Embed-modal"
    modal-type="popup"
    size="small"
    :show-modal="true"
    :has-sidebar-footer="true"
    :should-close-on-outside-click="false"
    @close="close"
  >
    <template #popupHeader>
      <div class="Embed-header">
        <span class="Embed-title">{{ $t(embedTitle) }}</span>
        <span
          v-if="isWebContentEnabled"
          class="Embed-description"
        >{{ $t('embed.content.creation.description') }} </span>
      </div>
    </template>
    <template #popupContent>
      <form class="Embed-content">
        <div class="Embed-formGroup">
          <div class="Embed-inputTitle pb-3">
            {{ $t(embedInputTitle) }}
          </div>
          <input
            v-model="embedData"
            :class="{ 'Embed-field--error': error, 'Embed-field mb-3': true }"
            :type="'text'"
            :placeholder="$t(urlPlaceholder)"
            :value="''"
            @input="resetError"
            @keypress.enter.prevent="initBoardElement"
          >
          <span
            v-if="!error"
            class="Embed-msg Embed-msg--info"
          ><icon-loader
            name="Info"
            size="small"
            color="#2D6BFF"
            style="margin-right: 8px;"
          />{{ $t(validUrlMessage) }}</span>
          <span
            v-if="error"
            class="Embed-msg Embed-msg--error"
          >{{ $t(invalidUrlMessage) }}</span>
        </div>
      </form>
    </template>
    <template #popupFooter>
      <div class="Embed-buttonGroup">
        <IobButton
          :label="$t('btn-cancel')"
          color="secondarySoft"
          size="medium"
          type="filled"
          @click="close"
        />
        <IobButton
          :label="$t('btn-add')"
          color="primary"
          size="medium"
          type="filled"
          :disabled="embedData.length === 0"
          @click="initBoardElement"
        />
      </div>
    </template>
  </IobModal>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex';
import { parseEmbed, sanitizeUrl } from 'BOARD/components/Embed/utils/embed-parser';
import { getViewTypeId } from 'BOARD/components/Embed/utils/embed-utils';
import {
  FEATURES,
  INTERACTION_MODES
} from 'GLOBALS/constants.js';
import { isFeatureEnabled, isValidUrl} from 'SRC/utils/utils';

export default {
  name: 'EmbedCreationModal',
  data: () => ({
    embedData: '',
    error: false
  }),
  computed: {
    ...mapState('app', ['dataTypes', 'viewTypes', 'featuresList']),
    ...mapState('board', [
      'selectedInteractionMode'
    ]),
    ...mapGetters('app', ['getViewTypeSize']),
    ...mapGetters('board', ['getElementInitialPosition']),
    ...mapState({
      boardId: (state) => state.board.id,
      viewTypeId: (state) => state.dock.viewTypeId,
      dataTypeId: (state) => state.dock.dataTypeId,
      isWebContentEnabled: (state) => isFeatureEnabled(state.app.featuresList, FEATURES.WEB_CONTENT)
    }),
    embedTitle() {
      return this.isWebContentEnabled ? 'embed.content.creation.title' : 'embed.powerbi.creationModal.title';
    },
    embedInputTitle() {
      return this.isWebContentEnabled ? 'embed.content.creation.label' : 'URL';
    },
    urlPlaceholder() {
      return this.isWebContentEnabled ? 'embed.content.creation.placeholder' : 'embed.powerbi.creationModal.urlPlaceholder';
    },
    validUrlMessage() {
      return this.isWebContentEnabled ? 'embed.content.creation.warning' : 'embed.editor.message.validUrlMessage';
    },
    invalidUrlMessage() {
      return this.isWebContentEnabled ? 'embed.content.creation.error' : 'embed.editor.message.invalidUrlMessage';
    }
  },
  methods: {
    ...mapMutations('board', [
      'setSelectedInteractionMode'
    ]),
    ...mapMutations('dock', ['setViewTypeId']),
    ...mapActions('board', ['createBoardElement']),
    async initBoardElement() {
      const parsedData = this.isWebContentEnabled ? parseEmbed(this.embedData.trim()) : { type: 'powerbi', url: this.embedData };
      this.error = !isValidUrl(parsedData.url);
      if (this.error) {
        return;
      }

      const sanitizedUrl = sanitizeUrl(parsedData.url);
      const dataTypeName = this.dataTypes[this.dataTypeId]?.name;
      const embedViewTypeId = this.isWebContentEnabled ? getViewTypeId(this.viewTypes, parsedData.type) : this.viewTypeId;
      this.setViewTypeId(embedViewTypeId);
      const size = this.getViewTypeSize(embedViewTypeId);
      const requestBody = {
        boardId: this.boardId,
        viewTypeId: embedViewTypeId,
        position: this.getElementInitialPosition(size),
        datasetElement: {
          attributes: {
            url: sanitizedUrl
          }
        },
        width: size.width,
        height: size.height
      };
      this.elementId = await this.createBoardElement({
        requestBody,
        dataTypeName
      });
      if (this.selectedInteractionMode === INTERACTION_MODES.PANNING) {
        this.setSelectedInteractionMode({
          value: INTERACTION_MODES.SELECT
        });
      }
      this.close();
    },
    close() {
      this.$store.commit('dock/setCreationComponent', null);
    },
    resetError() {
      this.error = false;
    }
  }
};
</script>

<style scoped src='./EmbedCreationModal.css' />
