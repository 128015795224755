<template>
  <div>
    <div
      v-for="linkType in relationTypes"
      :key="linkType.name"
      class="flex items-center LinksTypesMenu-line"
      :style="linkType.type === itemDisabled.type && itemDisabled.style"
      @click="clickAction(linkType)"
    >
      <colored-circle
        size="16px"
        :color="`#${linkType.color}`"
        :has-padding="true"
        :is-selected="isLinkTypeSelected(linkType)"
        :with-tooltip="false"
      />
      <span style="font-size: 14px; margin-left: 13px;">
        {{ linkType.name }}
      </span>
    </div>
    <div v-if="displayShowHideLabel">
      <div class="LinksTypesMenu-separator" />
      <div
        class="flex items-center cursor-pointer"
        @click="toggleLinks"
      >
        <icon-loader
          :key="iconName"
          :name="iconName"
        />
        <span style="font-size: 14px; margin-left: 13px;">
          {{ showHideLabel }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { LINKS_TYPES } from 'GLOBALS/constants.js';
export default {
  name: 'LinksTypesMenu',
  props: {
    displayShowHideLabel: {
      type: Boolean,
      default: true
    },
    componentName: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      itemDisabledColor: '#00000066'
    };
  },
  computed: {
    ...mapState('board', ['selectedLinkType', 'boardData']),
    ...mapState('app', ['relationTypes']),
    ...mapState('relations', ['selectedVisualLinkType']),
    showHideLabel() {
      return this.boardData.displayLinks ? 'Hide links' : 'Display links';
    },
    iconName() {
      return this.boardData.displayLinks ? 'EyeOff' : 'Eye';
    },
    itemDisabled() {
      if (this.componentName !== 'VisualLinkContextualMenu') {
        return {};
      }
      return {style: `color:${this.itemDisabledColor}`,
        type: LINKS_TYPES.HierarchicalRelationType};
    }
  },
  mounted() {
    if (!this.selectedLinkType) {
      const linkTypeData = this.relationTypes.find((relationType) => relationType.type === LINKS_TYPES.HierarchicalRelationType);
      this.setSelectedLinkType({...linkTypeData, isSelectedDefaultLinkType: true});
    }
  },
  methods: {
    ...mapActions('board', ['updateBoard']),
    ...mapActions('app', ['setRelationTypes']),
    async toggleLinks() {
      await this.updateBoard({ displayLinks: !this.boardData.displayLinks, name: this.boardData.name });
    },
    ...mapMutations('board', ['setSelectedLinkType']),
    ...mapMutations('relations', ['setSelectedVisualLinkType']),
    clickAction(linkType) {
      if (this.componentName === 'VisualLinkContextualMenu') {
        if (linkType.type === LINKS_TYPES.HierarchicalRelationType) {
          return ;
        }
        this.setSelectedVisualLinkType(linkType);
        this.$emit('link-type-change', linkType);
        return;
      }
      this.setSelectedLinkType(linkType);
    },
    isLinkTypeSelected(linkType) {
      return this.componentName === 'VisualLinkContextualMenu' ?
        linkType.name === this.selectedVisualLinkType.name :
        linkType.name === this.selectedLinkType?.name;
    }
  }
};
</script>

<style>
.LinksTypesMenu-line {
  padding: 5px;
  cursor: pointer;
}

.LinksTypesMenu-separator {
  width: 100%;
  height: 1px;
  margin-bottom: 16px;
  background-color: #e5e7eb;
}
</style>

