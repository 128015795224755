<template>
  <button
    class="IobButtonElevation level-1 level-container focus-outside"
    @click="emit('action-select', action)"
  >
    <div
      class="IobButtonElevation-left"
    >
      <iob-icon-shape
        :icon-name="iconName"
        :color="iconColor"
        size="xl"
        type="square"
        :focused="false"
        :is-clickable="false"
        :show-focus="false"
        :show-kanji="true"
      />
    </div>
    <div class="IobButtonElevation-right">
      <div class="IobButtonElevation-right-content">
        <div class="IobButtonElevation-right-content-title">
          <div
            :class="`IobButtonElevation-right-content-title-number
            IobButtonElevation-right-content-title-number--${state}`"
          >
            {{ titleNumber }}
          </div>
          <div
            :class="`IobButtonElevation-right-content-title-text
            IobButtonElevation-right-content-title-text--${state}`"
          >
            {{ title }}
          </div>
        </div>
        <div
          class="IobButtonElevation-right-content-subtitle"
        >
          {{ subtitle }}
        </div>
      </div>
    </div>
  </button>
</template>

<script setup>
import IobIconShape from '../../Atoms/IobIconShape/IobIconShape.vue';
import { defineProps } from 'vue';

defineProps({
  state: {
    type: String,
    default: 'default'
  },
  type: {
    type: String,
    default: ''
  },
  iconName: {
    type: String,
    default: 'Hand'
  },
  iconColor: {
    type: String,
    default: 'primary'
  },
  title: {
    type: String,
    default: ''
  },
  subtitle: {
    type: String,
    default: ''
  },
  titleNumber: {
    type: String,
    default: ''
  },
  action: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['action-select']);

</script>

<style lang="scss" src="./IobButtonElevation.scss" scoped />
<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-button.scss" />