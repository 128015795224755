<template>
  <scroll-bar
    id="scroll-container"
    :scroll-container-style="scrollContainerStyle"
    @scroll="onScroll"
  >
    <history-line
      v-for="(line, index) in history"
      :key="index"
      :data="line"
      :is-end-of-section="isSectionEnd(index)"
      :component-name="componentName"
    />
  </scroll-bar>
</template>

<script>
import HistoryLine from 'BOARD/components/History/BoardHistory/HistoryLine/HistoryLine.vue';
import { mapActions, mapState, mapMutations } from 'vuex';

export default {
  name: 'BoardHistory',
  components: { HistoryLine },
  data: () => ({
    currentPage: 1,
    scrollContainerStyle: 'position: absolute; top: 125px; left: 20px;',
    componentName: null,
    isLoading: false
  }),
  computed: {
    ...mapState('board', [
      'history'
    ])
  },
  async mounted() {
    this.componentName = this.$options.name;
    await this.getHistory(this.currentPage);
  },
  unmounted() {
    this.setHistory([]);
  },
  methods: {
    ...mapActions('board', ['getHistory']),
    ...mapMutations('board', [
      'setHistory'
    ]),
    async loadMorePosts() {
      await this.getHistory(this.currentPage + 1);
      this.currentPage += 1;
      this.isLoading = false;
    },
    onScroll() {
      const offsetY = 5;
      const el = document.getElementById('scroll-container');
      const { scrollTop, clientHeight, scrollHeight } = el;
      if (scrollTop + clientHeight + offsetY >= scrollHeight && !this.isLoading) {
        this.isLoading = true;
        this.loadMorePosts();
      }
    },
    isLastElement(index) {
      return index === this.history.length;
    },
    isSectionEnd(index) {
      return index + 1 < this.history.length && this.history[index + 1].displayPicture === false || this.isLastElement(index) ? false : true;
    }
  }
};
</script>

