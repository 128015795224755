<template>
  <div>
    <div
      tabindex="0"
      :class="classList"
      :style="`height: ${customDimension}px; width: ${customDimension}px`"
      @click="handleAvatarClick"
    >
      <div :class="avatarWrapper">
        <icon-loader
          v-if="deletedUser"
          class="IobAvatar--icon"
          name="User"
          :size="size"
        />
        <iob-image
          v-else-if="picture"
          :src="picture"
          class="IobAvatar-image"
          render-type="1:1"
        />
        <div v-else>
          <span :style="altTextStyle">{{ altText }}</span>
        </div>
        <div class="IobAvatar-overlay" />
      </div>
      <div
        v-if="colorBottom"
        :class="collabColorBottom"
        :style="`background-color: ${color};`"
      />
      <div
        v-if="colorLeft"
        :class="collabColorLeft"
        :style="`background-color: ${color};`"
      />
    </div>
    <outside-click-listener
      v-if="toggleDropdown"
      @outside-click="() => toggleDropdown = false"
    >
      <iob-dropdown
        v-if="hasDropdown"
        ref="dropdownRef"
        class="IobAvatar-dropdown"
        :style="{ position: 'absolute' }"
        :items="dropdownItems"
        :user="user"
        @dropdown-element-item="handleDropdownItemClick"
      />
    </outside-click-listener>
  </div>
</template>

<script setup>
import {defineProps, computed, ref, defineEmits, nextTick} from 'vue';
import IconLoader from '../../IconLoader/IconLoader.vue';
import IobDropdown from '../IobDropdown/IobDropdown.vue';
import IobImage from '../../Atoms/IobImage/IobImage.vue';
import OutsideClickListener from '../../OutsideClickListener/OutsideClickListener.vue';

const props = defineProps({
  picture: {
    type: String,
    default: ''
  },
  altText: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: 'default',
  },
  deletedUser: {
    type: Boolean,
    default: false,
  },
  selected: {
    type: Boolean,
    default: false,
  },
  colorBottom: {
    type: Boolean,
    default: false,
  },
  colorLeft: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: ''
  },
  dropdownItems: {
    type: Array,
    default: () => []
  },
  user: {
    type: Object,
    default: null
  },
  altTextStyle: {
    type: String,
    default: ''
  },
  customDimension: {
    type: String,
    default: ''
  }
});

const toggleDropdown = ref(false);
const dropdownRef = ref(null);
const isDropdownLoaded = ref(false);
const checkDropdownMenuExisting = async () => {
  await nextTick();
  if (dropdownRef.value) {
    isDropdownLoaded.value = true;
  }
};
const handleAvatarClick = async () => {
  toggleDropdown.value = !toggleDropdown.value;
  toggleDropdown.value ? await setDropdownMenuPosition() : null;
  emits('clickAvatarPicture');
};
const emits = defineEmits([
  'dropdownElementClick',
  'avatarDropdownElementItem',
  'clickAvatarPicture',
]);

const handleDropdownItemClick = ({item}) => {
  if (!item.action) return;
  emits('dropdownElementClick', item);
  emits('avatarDropdownElementItem', item);
  toggleDropdown.value = false;
};

const collabColorBottom = computed(() => {
  return `IobAvatar-collabColor IobAvatar-collabColor--bottom IobAvatar-collabColor-${props.size}--bottom`;
});

const collabColorLeft = computed(() => {
  return `IobAvatar-collabColor IobAvatar-collabColor--left IobAvatar-collabColor-${props.size}--left`;
});
const avatarWrapper = computed(() => {
  return `IobAvatar-wrapper IobAvatar-wrapper-border-${props.size}`;
});
const hasDropdown = computed(() => {
  return toggleDropdown.value && props.dropdownItems && props.dropdownItems.length
});
const isSelectedClass = computed(()=> {
  return props.selected || (toggleDropdown.value && props.dropdownItems.length) ? `IobAvatar-focus IobAvatar-${props.size}-focus IobAvatar-hover` : '';
});
const classList = computed(() => {
  const avatarSizeClass = `IobAvatar-${props.size}`;
  return ['IobAvatar', 'focus-outside', isSelectedClass.value, avatarSizeClass].join(' ');
});
const setDropdownMenuPosition = async () => {
  await checkDropdownMenuExisting();
  if (isDropdownLoaded.value) {
    const dropdownElement = document.querySelector('.IobDropdown');
    const positionInfo = dropdownElement.getBoundingClientRect();

    const avatarDropdown = document.querySelector('.IobAvatar-dropdown');
    avatarDropdown.style.position = 'absolute';
    avatarDropdown.style.transform = `translate(-${positionInfo.width-40}px, 10px)`;
    avatarDropdown.style.zIndex = '10';

  }
}
</script>

<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-avatar.scss" />
<style lang="scss" src="./IobAvatar.scss" scoped />
