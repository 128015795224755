<template>
  <div
    class="IobSubmenu-category"
  >
    <div
      class="IobSubmenu-category-title"
      @click="emits('category-select')"
    >
      <icon-loader
        class="IobSubmenu-category-title-leftIcon"
        :name="iconName"
        size="default"
      />
      <span class="IobMenuCategory-title-label">

        <slot />
      </span>
    </div>
    <div class="IobSubmenu-category-divider">
      <div class="IobSubmenu-category-divider-icon" />
      <iob-separator
        type="submenu"
        class="IobSubmenu-category-divider-separator"
      />
    </div>
  </div>
</template>

<script setup>
import IconLoader from '../../../IconLoader/IconLoader.vue';
import IobSeparator from '../../../Atoms/IobSeparator/IobSeparator.vue';
import { defineProps, defineEmits } from 'vue';

defineProps({
  iconName: {
    type: String,
    default: ''
  }
})

const emits = defineEmits(['category-select']);

</script>

<style lang="scss" src="./IobSubmenuCategory.scss" scoped />
