<template>
  <div
    data-target="board-element"
    class="FormulaEditor"
    :style="formulaEditorStyle"
    @pointerdown="stopPropagation"
    @keyup.esc="doneAction"
  >
    <div
      v-if="datasetElementId"
      class="FormulaEditor-result-container"
    >
      <scroll-bar
        scroll-container-style="max-height: 50px;"
      >
        <span class="FormulaEditor-equal-sign">=</span>
        <span
          v-if="error"
          class="FormulaEditor-error"
        >{{ errorText }} </span>
        <span v-else-if="result"> {{ result }} </span>
        <span
          v-else
          class="FormulaEditor-Blank"
        > Blank </span>
      </scroll-bar>
    </div>
    <div
      v-else
      class="FormulaEditor-result-container"
    >
      <scroll-bar
        scroll-container-style="max-height: 50px;"
      >
        <span
          v-if="error"
          class="FormulaEditor-error"
        >{{ errorText }} </span>
      </scroll-bar>
    </div>
    <input
      v-if="isFormulaNameShown"
      class="WidgetInputField"
      :placeholder="'Add name'"
      :value="attributes.name"
      @keyup.enter="focusExpression"
      @input="(e) => updateElementAttribute('name', e.target.value, e)"
    >
    <div class="FormulaWidgetEditor-formulaInputSection">
      <input
        :id="`expression-formula-${boardElementId}`"
        ref="input"
        class="WidgetInputField"
        :placeholder="'Add Formula'"
        :value="attributes.expression"
        :class="{ 'error-background': error }"
        tabindex="0"
        @keyup.enter="doneAction"
        @input="(e) => datasetElementId ? updateElementAttribute('expression', e.target.value, e) : evaluateFormulaExpression(e.target.value)"
      >
      <div
        v-show="error"
        class="FormulaWidgetEditor-cautionIcon"
      >
        <caution-mark
          v-show="error"
          color="rgb(195, 70, 82, 1)"
          height="48"
          margin-left="-55px"
        />
      </div>
    </div>
    <div class="button-container">
      <button
        class="done-button"
        @click="doneAction"
      >
        Done
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
import BoardContextClass from './BoardContext';
import { evaluate } from '@iobeya/vms-formula-evaluator/evaluator';
import { buildVmsTree, parse } from '@iobeya/vms-formula-evaluator/vmsExprTree';
import { isError } from '@iobeya/vms-formula-evaluator/errors';

export default {
  name: 'FormulaWidgetEditor',
  props: {
    attributes: {
      type: Object,
      required: false,
      default: () => ({
        expression: '',
        name: ''
      })
    },
    datasetElementId: {
      type: String,
      required: true
    },
    boardElementId: {
      type: String,
      required: true
    },
    result: {
      type: [String, Number, null],
      required: true
    },
    setResult: {
      type: Function,
      required: true
    },
    error: {
      type: Boolean,
      required: true
    },
    setError: {
      type: Function,
      required: true
    },
    doneAction: {
      type: Function,
      required: true
    },
    isFormulaNameShown: {
      type: Boolean,
      default: true
    },
    formulaEditorStyle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      expression: this.attributes.expression,
      name: this.attributes.name,
      timeOut: null,
      errorText: null,
      timer: null
    };
  },
  computed: {
    ...mapGetters('users', ['getUserById']),
    ...mapState('board', [
      'zoomLevel',
      'datasetElements',
      'elements',
      'formulaInEditionBoardElementId'
    ]),
    ...mapState({ boardId: (state) => state.board.id }),
    isEditorShown() {
      return this.formulaInEditionBoardElementId === this.boardElementId;
    }
  },
  watch: {
    elements: {
      async handler() {
        if (
          this.datasetElementId &&
          this.datasetElements[this.datasetElementId]
        ) {
          await this.evaluateFormula(this.attributes.expression);
        }
      },
      deep: true,
      immediate: true
    },
    datasetElements: {
      async handler() {
        if (
          this.datasetElementId &&
          this.datasetElements[this.datasetElementId]
        ) {
          await this.evaluateFormula(
            this.datasetElements[this.datasetElementId].attributes.expression
          );
        }
      },
      deep: true
    }
  },
  mounted() {
    this.timer = setTimeout(() => this.$refs.input.focus());
  },
  beforeUnmount() {
    clearTimeout(this.timer);
  },
  unmounted() {
    this.setFormulaInEditionBoardElementId(null);
  },
  methods: {
    ...mapActions('board', [
      'updateDatasetElementAttributes'
    ]),
    ...mapMutations('board', ['setFormulaInEditionBoardElementId']),
    async evaluateFormulaExpression(expression) {
      this.expression = expression;
      this.$emit('update:expression', expression);
      await this.evaluateFormulaExpressionSyntax(expression);
    },
    async evaluateFormulaExpressionSyntax(expression) {
      if (!expression) {
        this.setError(false);
        return;
      }
      try {
        const res = parse(expression);
        if (res) {
          this.setError(false);
          this.errorText = null;
        }
      } catch (error) {
        this.setError(true);
        this.errorText = error;
      }
    },
    async evaluateFormula(expression) {
      const evalCtx = new BoardContextClass(this.boardId);
      if (!expression) {
        this.setResult(null);
        this.setError(false);
        return;
      }
      try {
        const tree = buildVmsTree(expression);
        const res = await evaluate(tree, evalCtx);
        if (isError(res)) {
          this.handleError(res.message);
          this.setResult(null);
          return;
        }
        this.setResult(res);
        clearTimeout(this.timeOut);
        this.setError(false);
        this.errorText = null;
      } catch (error) {
        this.setResult(null);
        this.handleError(error);
      }
    },
    handleError(error) {
      this.timeOut = setTimeout(() => {
        if (this.result === null && this.expression) {
          this.setError(true);
          this.errorText = error;
        }
      }, 1000);
    },
    focusExpression() {
      this.$refs.input.focus();
    },
    async updateElementAttribute(name, value, event) {
      if (this.datasetElementId) {
        event.stopPropagation();
        event.preventDefault();
        this[name] = value;
        this.updateDatasetElementAttributes({
          datasetElementId: this.datasetElementId,
          attributes: {
            ...this.attributes,
            [name]: value
          }
        });
      }
    },
    stopPropagation(e) {
      e.stopPropagation();
    }
  }
};
</script>

<style src="./FormulaWidget.css" />
