<template>
  <div
    class="Tag"
    :class="{
      'Tag--small': isSmall
    }"
    :style="{
      backgroundColor: color
    }"
  >
    <img
      v-if="icon"
      class="Tag-icon"
      :src="icon"
    >
    <span class="Tag-label">{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: 'IobTag',
  props: {
    label: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    isSmall: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped src="./Tag.css"></style>
