<template>
  <div
    class="BoardElementFeedback"
    data-target="board-element"
    :style="{
      width: '100%',
      height: '100%',
      transform: `translateX(${feedbackPositionLeft}px)`,
      top: `${feedbackPositionBottom}px`
    }"
  >
    <div
      v-if="!isSelected && !isHighlighted &&
        isTextDefined && (!isWidget || (isWidget && isInEditionOnlyByOthers))"
      class="BoardElementFeedback-infos"
      :style="{
        'background-color': color,
        transform: `scale(${feedbackScale}%)`
      }"
    >
      <div
        v-if="!isSelected && text"
        class="BoardElementFeedback-infos-text"
      >
        {{ text }}
      </div>
      <div
        v-if="isEdition"
        class="BoardElementFeedback-infos-icon"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 2L18 6L7 17H3V13L14 2Z"
            stroke="#fff"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M3 22H21"
            stroke="#fff"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {
  MIN_ZOOM_LEVEL,
  FEEDBACK_BOTTOM_POSITION,
  FEEDBACK_LEFT_POSITION
} from 'GLOBALS/constants';

export default {
  name: 'BoardElementFeedback',
  props: {
    color: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    isEdition: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      required: true
    },
    isHighlighted: {
      type: Boolean,
      required: true
    },
    isWidget: {
      type: Boolean,
      required: true
    },
    isInEditionOnlyByOthers: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    ...mapState('board', [
      'zoomLevel'
    ]),
    feedbackScale() {
      return Math.max(1, (1 / Math.max(this.zoomLevel, MIN_ZOOM_LEVEL))) * 100;
    },
    feedbackPositionLeft() {
      return FEEDBACK_LEFT_POSITION + 2;
    },
    feedbackPositionBottom() {
      return FEEDBACK_BOTTOM_POSITION - 10;
    },
    isTextDefined() {
      return ![undefined, null, ''].includes(this.text);
    }
  }
};
</script>

<style scoped src='./BoardElementFeedback.css' />
