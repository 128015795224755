export default {
  setEligibleChildren(state, eligibleChildren) {
    state.eligibleChildren = eligibleChildren;
  },
  setEligibleParents(state, eligibleParents) {
    state.eligibleParents = eligibleParents;
  },
  setChildren(state, children) {
    state.children = children;
  },
  setParent(state, parent) {
    state.parent = parent;
  },
  setCreatedDependencies(state, createdDependencies) {
    state.createdDependencies = createdDependencies;
  },
  setEligibleDependencies(state, eligibleDependencies) {
    state.eligibleDependencies = eligibleDependencies;
  },
  setEligibleChildDataTypes(state, eligibleChildDataTypes) {
    state.eligibleChildDataTypes = eligibleChildDataTypes;
  },
  setEligibleParentDataTypes(state, eligibleParentDataTypes) {
    state.eligibleParentDataTypes = eligibleParentDataTypes;
  },
  setEligibleChildrenInitialCount(state, eligibleChildrenInitialCount) {
    state.eligibleChildrenInitialCount = eligibleChildrenInitialCount;
  },
  setDependencies(state, dependencies) {
    state.dependencies = dependencies;
  }
};
