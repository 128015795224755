<template>
  <div class="IobContextualMenu-category">
    <iob-button
      v-if="componentType === 'button'"
      color="secondary"
      type="ghost"
      size="medium"
      show-left-icon
      :left-icon="iconName"
      :data-test-id="'IobContextualMenu-button-'+iconName"
      :label="buttonLabel"
      :selected="isButtonSelected"
      @click="handleButtonClick"
    />
    <iob-button
      v-if="componentType === 'icon'"
      color="secondary"
      type="ghost"
      size="medium"
      show-left-icon
      :data-test-id="'IobContextualMenu-icon-'+iconName"
      :left-icon="iconName"
      @click="handleButtonClick"
    />
    <span
      v-if="componentType === 'text'"
      :data-test-id="'IobContextualMenu-text-'+text"
      class="IobContextualMenu-text"
    >{{
      text
    }}</span>
    <div
      v-if="componentType === 'colorsPopover'"
      class="IobContextualMenu-category"
    >
      <iob-select
        :data-test-id="'IobContextualMenu-colorsPopover-'+elementType"
        color="secondary"
        type="ghost"
        shape-type="square"
        :shape-color="bgColor"
        has-dropdown-color-picker
        :element-type="elementType"
        @select-color="handeleClickedAction"
      />
    </div>
    <div
      v-if="componentType === 'dropdownButton'"
      class="IobContextualMenu-category"
    >
      <iob-select
        :data-test-id="'IobContextualMenu-dropdownButton-'+iconName ? iconName : dropdownTitle"
        color="secondary"
        type="ghost"
        :icon-name="iconName"
        :has-arrow="hasArrow"
        :label="dropdownTitle !== 'layoutDropdownTitle' ? dropdownTitle : ''"
        :items="list"
        :dropdown-style="customStyle"
        @update:modelValue="handeleClickedAction"
      />
    </div>
    <div v-else-if="componentType === 'resizeWay'">
      <iob-select
        data-test-id="IobContextualMenu-resizeWay"
        has-arrow
        color="secondary"
        type="ghost"
        :icon-name="selectedLayout"
        :items="icons"
        :dropdown-style="resizeWayDropdownStyle"
        @update:modelValue="(value) => $emit('setViewType', value)"
      />
    </div>
    <div>
      <iob-button
        v-if="componentType === 'moreMenu'"
        data-test-id="IobContextualMenu-moreMenu"
        color="secondary"
        type="ghost"
        size="medium"
        show-left-icon
        left-icon="MoreHorizontal"
        @click="toggleMoreMenu = true"
      />
      <outside-click-listener
        v-if="toggleMoreMenu"
        @outside-click="toggleMoreMenu = false"
      >
        <iob-dropdown
          :items="list"
          class="IobContextualMenu-category-moreMenu"
          @DropdownElementItem="toggleMoreMenu = false"
        />
      </outside-click-listener>
    </div>
    <iob-separator
      v-if="hasSeparator"
      is-vertical
      class="IobContextualMenu-separator"
    />
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue';
import IobSelect from '../../Organisms/IobSelect/IobSelect.vue';
import IobSeparator from '../../Atoms/IobSeparator/IobSeparator.vue';
import IobButton from '../../Molecules/IobButton/IobButton.vue';
import IobDropdown from '../../Molecules/IobDropdown/IobDropdown.vue';
import OutsideClickListener from '../../OutsideClickListener/OutsideClickListener.vue';

const props = defineProps({
  iconColor: {
    type: String,
    default: '#14131B'
  },
  isButtonWithSelect: {
    type: Boolean,
    default: false
  },
  isButtonClicked: {
    type: Boolean,
    default: false
  },
  bgColor: {
    type: String,
    default: ''
  },
  elementType: {
    type: String,
    default: 'card'
  },
  componentType: {
    type: String,
    required: true
  },
  iconName: {
    type: String,
    default: ''
  },
  icons: {
    type: Array,
    default: () => []
  },
  dropdownTitle: {
    type: String,
    default: ''
  },
  list: {
    type: Array,
    default: () => []
  },
  hasSeparator: {
    type: Boolean,
    default: false
  },
  text: {
    type: String,
    default: ''
  },
  color: {
    type: String,
    default: ''
  },
  isFreeSize: {
    type: Boolean,
    default: false
  },
  customStyle: {
    type: String,
    default: ''
  },
  buttonLabel: {
    type: String,
    default: ''
  }
});
const toggleMoreMenu = ref(false);
const isButtonSelected = ref(props.isButtonClicked);
const resizeWayDropdownStyle = ref(
  'padding: var(--app-dropdown-layout-padding-default, 24px); gap: var(--app-dropdown-layout-gap-default, 16px); display: flex; flex-direction: row;'
);

const selectedLayout = computed(() => {
  if (props.isFreeSize || props.layout === 'rectangle') {
    return props.icons[1].iconName;
  }
  return props.icons[0].iconName;
});

const emits = defineEmits(['item-click', 'dropdown-click']);

const handeleClickedAction = (value) => {
  emits('dropdown-click', value);
};

const handleButtonClick = () => {
  emits('item-click');
  if (props.isButtonWithSelect) {
    isButtonSelected.value = !isButtonSelected.value;
  }
};
</script>

<style lang="scss" scoped src="./IobContextualMenu.scss" />
<style
  lang="scss"
  src="iobeya-design-tokens/scss/app/iobeya-app-dropdown-layout.scss"
/>
