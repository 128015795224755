<template>
  <ul :class="classList">
    <slot />
  </ul>
</template>

<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps({
  showIcons: {
    type: Boolean,
    default: true
  }
})

const classList = computed(() => {
  return {
    'IobMenu': true,
    'noIcons': !props.showIcons
  }
})
</script>

<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-app-menu.scss" />
<style lang="scss" src="./IobMenu.scss" scoped />
