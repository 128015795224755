/**
 * Adds a title element to the relations data based on the type name.
 * @param {array} elements 
 * @param {string} typeName 
 * @returns {array}
 */
export const addTitleElementToRelationsData = (elements, typeName) => {
  elements = elements.filter((item) => item.type !== 'title');
  elements.unshift({
    title: `Recent ${typeName} `,
    type: 'title',
  });
  return elements;
};

/**
 * Returns the relations data with the required format.
 * @param {array} data 
 * @returns {array}
 */
export const formatRelationsData = (data) => {
  return data.map((item) => {
    return {
      title: item.attributes['friendly-id'],
      text: item.attributes.title || '',
      id: item.id,
      attributes: item.attributes,
      typeId: item.typeId,
    };
  });
};

/**
 * Checks the condition and calls the function with the argument.
 * @param {boolean} condition 
 * @param {function fn(arg) {}} fn
 }} fn 
 * @param {string} arg 
 */
export const handleCheck = (condition, fn, arg) => {
  if (condition) {
    fn(arg);
  }
  return condition;
};